import React from 'react'
import EditProjectAdmin from '../../../module/admin/projectAdmin/EditProjectAdmin'

const AdminEditProjectPage = () => {
  return (
    <div>
       <EditProjectAdmin/> 
    </div>
  )
}

export default AdminEditProjectPage