import React from 'react'
import { Modal } from 'react-bootstrap'

type Props={
    isDeleteModal:boolean
    okClose:()=>void
    okDelete:()=>void
    }
const DeleteSegmentModal = ({isDeleteModal,okClose,okDelete}:Props) => {


  return (
    <div>
        <Modal
        show={isDeleteModal}
        onHide={okClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className='model-title'>Delete Segment</h5>
        </Modal.Header>
        <Modal.Body>
      <p className='fs-5'> Do you want to delete Segment?</p>  
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-tranparent border-primary text-primary' onClick={okClose}>
            Close
          </button>
          <button className='btn btn-primary' onClick={okDelete} >Ok</button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DeleteSegmentModal