
import { createSlice } from "@reduxjs/toolkit";

interface ScreenStates {
    canvasWidth:number|null,
    canvasHeight:number|null
    
}

const initialState: ScreenStates = {
    canvasWidth:null,
    canvasHeight:null

};
const WindowResolutionSlice=createSlice({
    name: "windowResolution",
    initialState,
    reducers: {
        updateWindowResolution: (state, action) => {
            const{canvasWidth,canvasHeight}= action.payload
            state.canvasWidth =canvasWidth
            state.canvasHeight = canvasHeight
    }
}
}
)
export const {updateWindowResolution}=WindowResolutionSlice.actions
export const getCanvasSize = (state:{windowResolution:ScreenStates})=>state.windowResolution
export default WindowResolutionSlice.reducer;