import React, { useEffect, useState } from "react";
import "../LeftSections.scss";
import { getMasterArray } from "../../../../slice/canvas/masterArray/MasterArraySlice";
import { useSelector } from "react-redux";
import { Swatch } from "../../../../Model/Job/SamModel";

import { getSegregateSegment } from '../../../../slice/segegratedSlice/SegregatedSlice'
import { JobSegmentModel } from '../../../../Model/Job/JobModel'
import { SvgImageModel } from '../../../../Model/svgModel/SvgModel'
import { getUserLogin } from '../../../../slice/user/UserSlice'
import { Dropdown } from "react-bootstrap";

const s3path = process.env.REACT_APP_S3BucketMaterial;

type Props = {
  groupName: string;
};
const CurrentSelectedSwatch = ({ groupName }: Props) => {
  const path = `${s3path}/liv/materials`;
  const [segratedSegment, setSegregatedSegment] = useState<JobSegmentModel[]>(
    []
  );
  const getMasterArrays = useSelector(getMasterArray);
  const getSegregateSegments = useSelector(getSegregateSegment);
  const [overallSwatch, setOverAllSwatch] = useState<Swatch[]>([]);

  useEffect(() => {
    if (getSegregateSegments && getSegregateSegments.length > 0) {
      setSegregatedSegment(getSegregateSegments);
    }
  }, [getSegregateSegments]);

  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0 &&
      groupName
    ) {
      const segGroup = getMasterArrays.allSeg.find(
        (item) => item.name == groupName
      );
      if (
        segGroup &&
        segGroup.overAllSwatch &&
        segGroup.overAllSwatch.length > 0
      ) {
        setOverAllSwatch(segGroup.overAllSwatch);
      } else {
        setOverAllSwatch([]);
      }
    } else {
      setOverAllSwatch([]);
    }
  }, [getMasterArrays, groupName]);
  return (
    <>
      <div className="selected-items p-2">
        <h6>Current Selected Area</h6>
        <div className="row g-2">
          {overallSwatch && overallSwatch.length > 0 ? (
            overallSwatch.map((item, index) => {
              let isChecked = false;
              const swatchId = item.swatch_id;
              segratedSegment.find((item) =>
                Object.keys(item).forEach((seg) => {
                  const value = item[seg].swatch?.swatch_id;
                  console.log("value", value);
                  if (value == swatchId) {
                    console.log("checked");
                    isChecked = true;
                  }
                })
              );
              return (
                <div className="col-2" key={index}>
                  <div className="palletsbox">
                    {isChecked && (
                      <span className="pallets-check">
                        {" "}
                        <i className="bi bi-check-lg"></i>{" "}
                      </span>
                    )}
                    <img src={`${path}/${item?.photo}`} alt="" />
                    {/* <span className="pallet-infomation-icon">
                      <i className="bi bi-three-dots-vertical"></i>
                    </span> */}

                    <Dropdown className="pallet-information-dropdown">
                      <Dropdown.Toggle
                        // variant="light"
                        className="bg-transparent border-0 p-0 header-user d-flex align-items-center gap-1 shadow-none"
                        id="dropdown-basic">
                        <span className="pallet-infomation-icon">
                          <i className="bi bi-three-dots-vertical"></i>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="end">
                        <Dropdown.Item className="pallet-info-list"> 
                            <i className="bi bi-trash3"></i>
                              Delete
                              </Dropdown.Item>
                        {/* <Dropdown.Item>Dashboard 2</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No swatches available</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentSelectedSwatch;

{
  /* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette3.jpg" alt="" />
                                  </div>
                              </div> */
}
{
  /* <div className='col-2'>
                                  <div className='palletsbox active'>
                                      <img src="/img/palette3.jpg" alt="" />
                                      <svg className="checkicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                                          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                                      </svg>
                                  </div>
                              </div> */
}
{
  /* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div> */
}
{
  /* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div> */
}
{
  /* <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div> */
}
