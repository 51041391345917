import React, { useEffect, useState } from 'react'
import ShowBrands from './ShowBrands'
import LeftSection from '../leftSection/LeftSection'
import Project from '../leftSection/Project';
import ProjectBrand from './projects/Projects';
import Swatch from "../brandPage/swatch/Swatch"
import JobBrand from './jobs/JobBrand';
import GetAllMaterialsHome from '../getAllMaterials/GetAllMaterialsHome';
import StyleHome from './style/StyleHome';
import SwatchBrandHome from './brand/SwatchBrandHome';
import CategoryHome from './category/CategoryHome';

const ShowBrandHome = () => {
  const [isJobBrand, setIsJobbrand] = useState<boolean>(false);
  const [isProject, setIsProject] = useState<boolean>(false);
  const [isSwatch, setIsSwatchBrand] = useState<boolean>(false);

  const [isStyle, setIsStyle] = useState<boolean>(false);
  const [isBrand, setIsBrand] = useState<boolean>(false);
  const [isCategory, setIsCategory] = useState<boolean>(false);


  const handleJob = () => {
  
    setIsJobbrand(true);
    setIsSwatchBrand(false);
    setIsProject (false)
    setIsBrand(false)
    setIsStyle(false)
    setIsCategory(false)
  }

  const handleProject = () => {
    
    setIsJobbrand(false);
    setIsProject(true)
    setIsSwatchBrand(false);
    setIsStyle(false)
    setIsBrand(false)
    setIsCategory(false)
  }

  const handleSwatch=()=>{
    setIsSwatchBrand(true);
    setIsProject(false)
    setIsJobbrand(false);
    setIsStyle(false)
    setIsBrand(false)
    setIsCategory(false)
  }
    
  const handleStyle=()=>{
    setIsStyle(true)
    setIsSwatchBrand(false);
    setIsProject(false)
    setIsJobbrand(false);
    setIsBrand(false)
    setIsCategory(false)
  }
    

  const handleBrand=()=>{
    setIsBrand(true)
    setIsSwatchBrand(false);
    setIsProject(false)
    setIsJobbrand(false);
   
    setIsStyle(false)
    setIsCategory(false)
  }
    

  const handleCategory=()=>{
    setIsCategory(true)

    setIsBrand(false)
    setIsSwatchBrand(false);
    setIsProject(false)
    setIsJobbrand(false);
   
    setIsStyle(false)
  

  }

  return (
    <div className='row'>


      {/* <LeftSection
        openJob={handleJob}
        openProject={handleProject}
        openSwatch={handleSwatch}
        openStyletable={handleStyle}
        openSwatchBrand={handleBrand}
        openSwatchCategory={handleCategory}
      /> */}
<div className="col-9 brand-right-section">
     {isJobBrand &&
           <JobBrand/>
          }

        {isProject &&
          <ProjectBrand />
        }
     {isSwatch &&
        <Swatch />
      }

       {/*  style home*/}
      <StyleHome
      isStyle={isStyle}/>


         {/*  brand Home */}
         <SwatchBrandHome
        isBrand={isBrand}
      />

        {/* category Home */}
      
      <CategoryHome
       isCategory={isCategory}
      />

      </div>

   

      {/* getAll materials */}
      <GetAllMaterialsHome/>
    </div>
  )
}

export default ShowBrandHome