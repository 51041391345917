import React from 'react'
import { Routes, Route } from 'react-router-dom';
import DasboardPage from '../../page/dasboardpage/DasboardPage';

import AdminBrandTablePage from '../../page/admin/adminBrand/AdminBrandTablePage';
import AdminCategoryTablePage from '../../page/admin/adminCategory/AdminCategoryTablePage';
import AdminStyleTablePage from '../../page/admin/adminstyle/AdminStyleTablePage';
import AdminSwatchesTablePage from '../../page/admin/adminSwatches/AdminSwatchesTablePage';
const DasboardRoute = () => {
  return (
   
      <Routes>
        <Route path="/" element={<DasboardPage />}/>
        <Route path="/swatch-brand" element={<AdminBrandTablePage/>} />
        <Route path="/swatch-categories" element={<AdminCategoryTablePage />} />
        <Route path="/swatch-styles" element={<AdminStyleTablePage />} />
        <Route path="/swatches" element={<AdminSwatchesTablePage />} />

      </Routes>
    
  )
}

export default DasboardRoute
