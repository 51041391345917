import React from 'react'

import { Button } from 'react-bootstrap'
import { Link } from "react-router-dom"; // import Link from react-router-dom
import "./Create_Image.scss"
const AssetImage =process.env.REACT_APP_ASSERT_Image

const proimg = `${AssetImage}/images/login-img.jpg`
const Create_Image = () => {
  return (
    <div>
      <section className='get-startd-top py-3 pt-md-5'>
        <div className='container'>
          <div >
            <h3>Project One</h3>
            <p>1 hour ago</p>
          </div>

          <div className='row pro-images'>
            <div className='col-4 mt-3 '>
              <div className='proimg cursor-pointer'>
                <img className='rounded-3  overflow-hidden' src={proimg} alt="proimg" />

                <div className="pro-act  d-flex">

                  <div className='pro-edit-btn'>
                    <Link to="/generate-image/edit" className="edit-btn rounded-pill text-white">
                      <span>Edit</span>
                    </Link>
                  </div>

                  <div className="pro-act-btn">
                    <Button className={`btn-ba rounded-pill bg-dark  shadow-none`}>
                      <i className="bi bi-upload"></i>
                    </Button>
                    <Button className={`btn-ba rounded-pill bg-dark shadow-none`}>
                      <i className="bi bi-bookmark"></i>
                    </Button>
                  </div>

                </div>


              </div>

              <div className='d-flex flex-column gap-1 mt-3'>
                <h6 className='m-0'>My Upload</h6>
              </div>

            </div>

            <div className='col-4 mt-3 '>
              <div className='proimg'>
                <img className='rounded-3  overflow-hidden' src={proimg} alt="proimg" />

                <div className="pro-act">

                <div className='pro-edit-btn'>
                    <Link to="/generate-image/edit" className="edit-btn rounded-pill text-white">
                      <span>Edit</span>
                    </Link>
                  </div>

                  <div className="pro-act-btn">
                    <Button className={`btn-ba rounded-pill bg-dark border-0 shadow-none`}>
                      <i className="bi bi-upload"></i>
                    </Button>
                    <Button className={`btn-ba rounded-pill bg-dark border-0 shadow-none`}>
                      <i className="bi bi-bookmark"></i>
                    </Button>
                  </div>

                </div>

              </div>

              <div className='d-flex flex-column gap-1 mt-3'>
                <h6 className='m-0'>My Upload</h6>
              </div>

            </div>

            <div className='col-4 mt-3 '>
              <div className='proimg'>
                <img className='rounded-3  overflow-hidden' src={proimg} alt="proimg" />

                <div className="pro-act">

                 <div className='pro-edit-btn'>
                    <Link to="/generate-image/edit" className="edit-btn rounded-pill text-white">
                      <span>Edit</span>
                    </Link>
                  </div>

                  <div className="pro-act-btn">
                    <Button className={`btn-ba rounded-pill bg-dark border-0 shadow-none`}>
                      <i className="bi bi-upload"></i>
                    </Button>
                    <Button className={`btn-ba rounded-pill bg-dark border-0 shadow-none`}>
                      <i className="bi bi-bookmark"></i>
                    </Button>
                  </div>

                </div>

              </div>

              <div className='d-flex flex-column gap-1 mt-3'>
                <h6 className='m-0'>My Upload</h6>
              </div>

            </div>

            <div className='col-4 mt-3 '>
              <div className='proimg'>
                <img className='rounded-3  overflow-hidden' src={proimg} alt="proimg" />

                <div className="pro-act">
{/* 
                  <div className='pro-edit-btn'>
                    <span className={`edit-btn rounded-pill text-white `}>Edit</span>
                  </div> */}
                   <div className='pro-edit-btn'>
                    <Link to="/generate-image/edit" className="edit-btn rounded-pill text-white">
                      <span>Edit</span>
                    </Link>
                  </div>

                  <div className="pro-act-btn">
                    <Button className={`btn-ba rounded-pill bg-dark border-0 shadow-none`}>
                      <i className="bi bi-upload"></i>
                    </Button>
                    <Button className={`btn-ba rounded-pill bg-dark border-0 shadow-none`}>
                      <i className="bi bi-bookmark"></i>
                    </Button>
                  </div>

                </div>

              </div>

              <div className='d-flex flex-column gap-1 mt-3'>
                <h6 className='m-0'>My Upload</h6>
              </div>

            </div>


          </div>



        </div>
      </section>
    </div>
  )
}

export default Create_Image
