import React, { useEffect, useState } from 'react'
import LeftSection from '../../leftSection/LeftSection';
import JobBrand from '../../brandPage/jobs/JobBrand';
import GetAllMaterialsHome from '../../getAllMaterials/GetAllMaterialsHome';
import JobsTable from '../../brandPage/jobs/table/JobTable';
import Header from '../../header/Header';

const AdminJobTable = () => {

  
  return (
    <>
    {/* <Header/> */}
     <div className='row'   >


<LeftSection
 
/>
<div className="col-9 brand-right-section">

     <JobsTable/>
    




</div>



{/* getAll materials */}
<GetAllMaterialsHome/>
</div>
    </>
   
  )
}

export default AdminJobTable