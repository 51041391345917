import React, { useEffect, useState } from "react";
import "./LeftSection.css";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import Media from "./Media";
import Project from "./Project";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserLogin } from "../../slice/user/UserSlice";
import { userModel } from "../../Model/user/UserModel";
import Segments from "./Segments";
import Shape from "./Shape";
import User from "./User";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import Setting from "./Setting";
import Paths from "./Paths";
import { changeActiveFolder, resetMedia } from "../../slice/media/MediaSlice";

type Props = {
  openJob: () => void,
  openProject: () => void,
  openSwatch: () => void,
  openStyletable: () => void,
  openSwatchBrand: () => void,
  openSwatchCategory: () => void,
};
const LeftSection = () => {

  const [isLogin, setIsLogin] = useState<boolean>(false)

  const dispatch = useDispatch()
  const navigator = useNavigate()
  const getUserLogins = useSelector(getUserLogin) as userModel[]

  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [getUserLogins])


  // const handleJob =() => {
  //   console.log("job 2")
  //   openJob()
  // }
  // const handleProject =() => {
  //   console.log("project 2")
  //   openProject()
  // }

  // const handleSwatch =() => {
  //   openSwatch()
  // }
  // const handleStyle=()=>{
  //   openStyletable()
  // }


  // const handleBrand=()=>{
  //   openSwatchBrand()
  // }


  // const handleCategory=()=>{
  //   openSwatchCategory()
  // }

  const handleSegment = () => {
    navigator("/segments")
  }

  const handleUser = () => {
    navigator("/user")
  }

  const handleShape = () => {
    navigator("/shape")
  }

  const handleMedia=()=>{
   
    dispatch(resetMedia())
    navigator("/media/folder")
  }
  return (
    <div className="col-3" style={{ width: "290px", padding: "0",  }}>
      {<Card className="left-section">


        <CardBody className="left-content">
          <div>
            <ul className="list-card mt-3">


              <li className="pro-list py-1" style={{ borderBottom: "1px solid #ddd" }}>
                <div className="d-flex card-list-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-columns-gap mx-3" viewBox="0 0 16 16">
                    <path d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
                  </svg>
                  <h2 className="project-title">Dashboard</h2>
                </div>
              </li>




              <Media
              // callbackSwatch={handleSwatch}
              // callBackStyle={handleStyle}
              // callBackBrand={handleBrand}
              // callBackCategory={handleCategory}
              />






              <Project
              // callbackLeftSection={handleJob}
              // callbackProject={handleProject}

              />


              {/* <Segments/> */}
              <li className="pro-list py-1" onClick={handleSegment}>
                <div className="d-flex card-list-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid mx-3" viewBox="0 0 16 16">
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
                  </svg>
                  <h2 className="project-title">Segments</h2>
                </div>
              </li>

              {/* <User/> */}
              <li className="pro-list " onClick={handleUser}>
                <div className="d-flex card-list-title ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person mx-3" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
                  </svg>
                  <h2 className="project-title">Users</h2>
                </div>
              </li>

              {/* <Shape/> */}
              <li className="pro-list py-1" onClick={handleShape}>
                <div className="d-flex card-list-title">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bounding-box-circles mx-3" viewBox="0 0 16 16">
                    <path d="M2 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2M0 2a2 2 0 0 1 3.937-.5h8.126A2 2 0 1 1 14.5 3.937v8.126a2 2 0 1 1-2.437 2.437H3.937A2 2 0 1 1 1.5 12.063V3.937A2 2 0 0 1 0 2m2.5 1.937v8.126c.703.18 1.256.734 1.437 1.437h8.126a2 2 0 0 1 1.437-1.437V3.937A2 2 0 0 1 12.063 2.5H3.937A2 2 0 0 1 2.5 3.937M14 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2M2 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2m12 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
                  </svg>
                  <h2 className="project-title">Shapes</h2>
                </div>
              </li>

              <Paths/>

            <Setting/>


              <Accordion allowMultiple style={{borderTop:"1px solid #ddd"}}>
                <li className="pro-list py-1" >
                  <AccordionItem className="accordion-item">
                    <AccordionButton className="data-list py-2">
                      <Box as='span' flex='1' textAlign='left' className="media-list-pro">
                        <i className="bi bi-image-fill mx-2"></i>Material Library
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <div className="list-title list-library">
                        <ul>
                          <li >
                            <div className="list-type">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-dot mx-2"
                                viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                              </svg>
                              <div>
                                <span>Category</span>
                              </div>
                            </div>
                          </li>

                          <li >
                            <div className="list-type">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-dot mx-2"
                                viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                              </svg>
                              <div>
                                <span>Brands</span>
                              </div>
                            </div>
                          </li>

                          <li  >
                            <div className="list-type">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-dot mx-2"
                                viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                              </svg>
                              <div>
                                <span>Style</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="list-type">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-dot mx-2"
                                viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                              </svg>
                              <div>
                                <span>Swatch</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div className="list-type"
                            onClick={handleMedia}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                fill="currentColor"
                                className="bi bi-dot mx-2"
                                viewBox="0 0 16 16">
                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                              </svg>
                              <div>
                                <span>Media</span>
                              </div>
                            </div>
                          </li>

                        </ul>
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                </li>
              </Accordion>






            </ul>
          </div>



        </CardBody>
      </Card>}

    </div>
  );
};

export default LeftSection;
