import React from 'react'
import GetAllMaterialsHome from '../../getAllMaterials/GetAllMaterialsHome'
import LeftSection from '../../leftSection/LeftSection'
import Projects from '../../brandPage/projects/Projects'

const AdminProjectsTable = () => {
  return (
    <div className='row'>


      <LeftSection
       
      />
<div className="col-9 brand-right-section">

           <Projects/>
          


      

      </div>

   

      {/* getAll materials */}
      <GetAllMaterialsHome/>
    </div>
  )
}

export default AdminProjectsTable