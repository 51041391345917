import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';

import { GetJobsAll } from '../../../../api/jobs/JobApi';
import { addAllJobData, getAllJobData } from '../../../../slice/Admin/JobSlice/JobSlice';

const GetAllJobs = () => {
   const dispatch= useDispatch();
   const getAllJobDatas= useSelector(getAllJobData)
   useEffect(()=>{
    if(getAllJobDatas &&
        getAllJobDatas.length===0
    ){
        getJobs()
    }
   },[getAllJobDatas])

    const getJobs=async()=>{

        try{
            
      const response = await GetJobsAll();
      if(response && response.status===200){
         dispatch(addAllJobData(response.data))
      }
        }catch(err){
            
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default GetAllJobs