import React from 'react'
import "./SimmerLeft.scss"
const SimmerLeft = () => {
    return (

        <div className="left-section-img">
            <div className="shine-shimmer d-flex justify-content-between w-100 mt-3 align-items-center border-bottom pb-3 px-3">
                <div className="shine shimmer-icon"></div>
                <div className="shine text-shimmer"></div>
                <div className="shine shimmer-icon"></div>
            </div>

            <div className="shine-shimmer border-bottom py-1 px-3 d-flex justify-content-between">
                <div className="shine shimmer-icon"></div>
                <div className="shine shimmer-icon"></div>
                <div className="shine shimmer-icon"></div>
                <div className="shine shimmer-icon"></div>
            </div>

            <div className="shimmer-box-shine">
                <div className="seg-shimmer pt-2">
                    <div className="shine seg-btn"></div>
                    <div className="shine seg-btn"></div>
                    <div className="shine seg-btn"></div>
                    <div className="shine seg-btn"></div>
                    <div className="shine seg-btn"></div>
                    <div className="shine seg-btn"></div>
                </div>

                <div className='tab-con-shimm'>
                    <div className="shimmer-shine-button pb-5 pt-1 border-bottom">
                        <div className="shine seg-btn-shimmer"></div>
                        <div className="shine seg-btn-shimmer"></div>
                        <div className="shine seg-btn-shimmer"></div>
                        <div className="shine seg-btn-shimmer"></div>

                        <div className="shimmer-icons">
                            <div className="shine seg-icon-shimmer"></div>
                        </div>

                    </div>

                    <div className="shimmer-shine-btn pt-1 border-bottom">
                        <div className="shine segs-button-shimmer"></div>
                        <div className="shine segs-button-shimmer"></div>
                        <div className="shine segs-button-shimmer"></div>
                        <div className="shine segs-button-shimmer"></div>
                        <div className="shine segs-button-shimmer"></div>
                        <div className="shine segs-button-shimmer"></div>

                    </div>

                    {/* <div className="shimmer-shine-action">
                        <div className="shine segs-action-shimmer"></div>
                        <div className="shine segs-action-shimmer"></div>
                        <div className="shine segs-action-shimmer"></div>
                    </div> */}


                    <div className="shimmer-shine-area border-bottom">
                        <div className="shine segs-text-shimmer"></div>
                        <div className="shimmer-shine-btn">
                            <div className="shine segs-button-shimmer"></div>
                            <div className="shine segs-button-shimmer"></div>
                            <div className="shine segs-button-shimmer"></div>
                            <div className="shine segs-button-shimmer"></div>
                            <div className="shine segs-button-shimmer"></div>
                            <div className="shine segs-button-shimmer"></div>

                        </div>

                    </div>
                    <div className="shine-shimmer pt-2">
                        <div className="shine pallet-shimmer"></div>
                        <div className="shine pallet-shimmer"></div>
                        <div className="shine pallet-shimmer"></div>
                    </div>


                    <div className="pallet-shimmer-images">
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                        <div className="shine pallet-img-shimmer"></div>
                    </div>





                </div>
            </div>
        </div>

    )
}

export default SimmerLeft;
