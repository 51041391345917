import React from 'react'
import ShowBrands from '../../module/brandPage/ShowBrands'
import ShowBrandHome from '../../module/brandPage/ShowBrandHome'

const Brands = () => {
  return (
    <div >
        <ShowBrandHome/>
    </div>
  )
}

export default Brands