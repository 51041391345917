import React, { useEffect, useRef, useState } from 'react';

import { MaterialStyleModel, SwatchStyleModel } from '../../../Model/material/MaterialStyleModel';
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialStyle, getSwatchStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import Loading from '../../loading/Loading';
import GetSwatchBrand from '../../getAllMaterials/brand/GetSwatchBrand';
import { MaterialBrandModel, SwatchBrandModel } from '../../../Model/material/MaterialBrandModel';
import { addEditBrand, getSwatchBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { Breadcrumb, Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import CreateProjectModel from '../CreateProjectModel';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/transfer/search';
import { startLoading, stopLoading } from '../../../slice/loading/LoadingSlice';
import DelteBrandApi from '../../admin/AdminMaterial/brand/api/DeleteBrandApi';
import CreateBrandModel from './AddBrand';
import AddBrandApi from '../../admin/AdminMaterial/brand/api/AddBrandApi';

const SwatchBrandTable = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [selectAll, setSelectAll] = useState(false);
  const [allBrand, setAllBrand] = useState<SwatchBrandModel[]>([]);
  const navigate = useNavigate()
  const dispatch= useDispatch()
  const searchInput = useRef<InputRef>(null);
  const searchInputTitle = useRef(null);
  const getSwatchBrands = useSelector(getSwatchBrand)
  useEffect(() => {
    if (getSwatchBrands && getSwatchBrands.length > 0) {
      setAllBrand(getSwatchBrands);
    } else if (getSwatchBrands && getSwatchBrands.length == 0) {

    }
  }, [getSwatchBrands]);

  const handleEdit = (record: SwatchBrandModel) => {
     dispatch(addEditBrand(record))
    navigate(`/dashboard/swatch-brand/${record.id}/edit`)
  }

     const [isDelete, setIsDelete]= useState<boolean>(false)
     const[ deleteId, setDeleteId]= useState<number|undefined>(0)
    const handleDelete=(record:SwatchBrandModel)=>{
      setDeleteId(record?.id)
      setIsDelete(true)
      dispatch(startLoading())
    }



  const columns: TableColumnsType<SwatchBrandModel> = [

    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1 }}>
          <Input
            placeholder="Search Id"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });

            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.current?.select();
          });
        }
      },
      onFilter: (value, record) => {
        if (!record.id) {
          return false;
        }

        return record.id.toString() === value.toString();
      },
      filterSearch: true,
    },

    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1 }}>
           <Input
            ref={searchInput}
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

            // onPressEnter={() => confirm({ closeDropdown: false })}
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}

            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 1000);
        }
      },
      onFilter: (value, record) => record.title?.toLowerCase().includes(value.toString().toLowerCase()) || false,
      filterSearch: true,
    },
  



    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render:(value, record)=>{
        return(
            <span>{record.category?.title}</span>
        )
      }, 
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1, }}>
          <Input
            ref={searchInput}
            placeholder="Search Category"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });

            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            setTimeout(() => searchInput.current?.select(), 1000);
          });
        }
      },
      onFilter: (value, record) => {
        const valUpper = value.toString().toUpperCase();
        const valLower = value.toString().toLowerCase();
      
        if (record && record.category && record.category.title) {
          const categoryTitle = record.category.title.toString().toUpperCase();
          return categoryTitle.includes(valUpper) || categoryTitle.includes(valLower);
        }

        return false;
      },
      filterSearch: true,
    },

    
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Sort Order',
      dataIndex: 'sort_order',
      key: 'sort_order',
      width:"100",
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (
        status === 1 ? 'Active' : 'Inactive'
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => {
        return (
          <div className="d-flex action">
            <div className="action-icon-table" onClick={() => handleEdit(record)}>
            <i className="bi bi-pencil-square mx-0"></i>
            </div>

            <div className="action-icon-table" 
            //
            >
               <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
          <i className="bi bi-box-arrow-up-right mx-0"></i>
          </div>

            <div className="action-icon-table trash-icon-media"
             onClick={()=>handleDelete(record)}
            >
                <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>




        )
      },
    },
  ]


// delete barnd
const handleResetDelete=(mess:string)=>{
       if(mess==="success"){
        setIsDelete(false)
        setDeleteId(0)
        dispatch(stopLoading())
        alert("Selected brand is deleted successfully")
       } else if (mess==="fail"){
        setIsDelete(false)
        setDeleteId(0)
        dispatch(stopLoading())
        alert("Error in  deleted brand")

       }

}

// add new Brand

const [ isAddApi, setIsAddApi]= useState<boolean>(false)
const [ newBrand, setNewBrand]= useState<MaterialBrandModel |null>()
const handleNewBrand=(data:MaterialBrandModel)=>{
   setIsAddApi(true)
   setNewBrand(data)
   dispatch(startLoading())
}

    const handleResetNewBrand=(mess:string)=>{
     if(mess==="success"){
      setIsAddApi(false)
      setNewBrand(null)
        dispatch(stopLoading())
        alert("Brand added successfully")
     }  else if (mess=="fail"){
      setIsAddApi(false)
      setNewBrand(null)
        dispatch(stopLoading())
        alert("Error in adding brand")
     }

    }

  return (
    <>


      <div>
        <div className="row mt-3">
          <div className="col-9 brand-title">
            {/* <h1 className='job-title'>Brand</h1> */}
            <p>{allBrand.length} entries Found </p>
          </div>

{/* 
          <div className="col-3 breadcrumb-link">
          <Breadcrumb
              items={[

                {
                  title: <a href="">Material Library</a>,
                },

                {
                  title: 'Brand',
                },
              ]}
            />
          </div> */}
        </div>

        
        {/* <div className='row table-input'>

          <div className='col-6 search-bar'>
            <Search
              placeholder="Search brand"
           
            />

          </div>
          <div className='col-6'>
         <CreateBrandModel
         newAddBrand={handleNewBrand}
         />
          </div>
        </div> */}



        <Card className='mb-5 card-table-category'>

        {allBrand &&
          allBrand.length == 0 ? (
          <Loading />
        ) :
          (
            <Table
              columns={columns}
              dataSource={allBrand?.map((item) => ({ ...item, key: item.id }))}
            // onChange={onChange}
            // showSorterTooltip={{ target: 'sorter-icon' }}
            pagination={{
              position: ['topRight', 'bottomRight'], // Positions pagination at the top and bottom
              defaultPageSize: 200,
             
              showTotal: (total) => <span className="ant-pagination-total-text ">Total <span className='total-page '> <i> {total} </i></span> items</span>,
              showSizeChanger: true, // Show page size changer
              pageSizeOptions: ['100', '200', '300', '400', '500', '600', '1000'], // Page size options
            }}

            />)}
            </Card>

      </div>
      


      {/*   delete brand )} */}
      {isDelete &&
       deleteId!=undefined &&
      <DelteBrandApi
      deleteId={deleteId}
      resetDelete={handleResetDelete}
      />
      }


      {/*  add new brand */}

      {isAddApi &&
      newBrand!=null &&
      <AddBrandApi
      resetAdd={handleResetNewBrand}
      addBrandData={newBrand}
      />
      }
    </>
  );
};

export default SwatchBrandTable;


