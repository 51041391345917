import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getToast, resetMessage } from '../../slice/messageToast/ToastSlice';



const MessageToast = () => {
  const [show, setShow] = useState(true);
  const[ toastMessage, setToastMessage]=useState<string|null>(null)
  const [toastType, setToastType] = useState<string|null>(null); // Can be 'success' or 'error'

  const getToasts= useSelector(getToast)
   const dispatch= useDispatch()

  useEffect(()=>{
   if(getToasts.isShow &&
     getToasts.message 
    ) {

    setShow(getToasts.isShow)
 setToastMessage(getToasts.message)
 //setToastType(  getToasts.toastType )
   } else{
    setShow(false)
 setToastMessage(null)
 setToastType(null)
   }
  },[getToasts])

  // close toast component
  useEffect(()=>{
    if(!show){
       dispatch(resetMessage())
    }
  },[show])
  
  return (
    <ToastContainer position="top-end" className="p-3">
      <Toast onClose={() => setShow(false)} 
      show={show} delay={5000} autohide
      // bg={toastType === 'success' ? 'success' : 'danger'}
      >
        <Toast.Header>
          <img
            src="https://via.placeholder.com/20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">Alert</strong>
          {/* <strong className="me-auto">{toastType === 'success' ? 'Success' : 'Error'}</strong>
           */}
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default MessageToast;
