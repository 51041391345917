import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Example from '../RadioCard';
// import "./EditProjectFrom.css"
import "../EditProjectFrom.css"
import { useNavigate } from 'react-router-dom';
import useSelection from 'antd/es/table/hooks/useSelection';
import { getEditCategorys } from '../../../slice/materialSlice/MaterialCategorySlice';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';
import { useSelector } from 'react-redux';


type Props={
    updateCategory:(data:MaterialCategoryModel)=>void
}
const EditCategory = ({updateCategory}:Props) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
     const navigate= useNavigate()


     const handleBackToProjects=()=>{
        navigate("/dashboard/swatch-categories")
         
     }
     const [editCategoryData, setEditCategoryData]= useState<MaterialCategoryModel>()
     const getEditCategory=useSelector(getEditCategorys) as MaterialCategoryModel
     const [editTitle, setEditTitle] = useState<string>('');
     const [editSlug, setEditSlug] = useState<string>('');
     const [editDescription, setEditDescription] = useState<string>('');
     const [editSortOrder, setEditSortOrder] = useState<string>('');
     const [status, setStatus] = useState<number>(0);
     
     useEffect(()=>{

        if(getEditCategory && getEditCategory.id){
            setEditCategoryData(getEditCategory)
            
            setEditTitle(getEditCategory.title || '');
            setEditSlug(getEditCategory.slug || '');
            setEditDescription(getEditCategory.description || '');
            setEditSortOrder(getEditCategory.sort_order?.toString() || '');
            setStatus(getEditCategory.status || 0);
        }
     },[getEditCategory])

     const updateStatus=(data:string)=>{
        const val= parseInt(data)
         setStatus(val)
     }

     // handle cancel
     const handleCancel=()=>{
        handleBackToProjects()
     }


     const handleSortOrde=(data:string)=>{
           
        setEditSortOrder(data)
     }
     const handleSave=()=>{

        const data:MaterialCategoryModel={
                id:editCategoryData?.id,
                title:editTitle,
                slug:editSlug,
                description:editDescription,
                sort_order:parseInt(editSortOrder),
                status:status



        }
             updateCategory(data)
     }
    return (
        <div>
            <div className="back-icon"  onClick={handleBackToProjects}>
                <i className="bi bi-arrow-left-short"></i>
                <span>Back</span>
            </div>

            <Card className="card-edit py-4 mt-4">
                <div className="row container from-edit">
                    <div className="col-6 from-list">
                        <FormLabel>Title</FormLabel>
                        <Input className="input-primary" 
                          value={editTitle}
                          onChange={(e) => setEditTitle(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list">
                        <FormLabel>Slug</FormLabel>
                        <Input className="input-primary"
                       value={editSlug}
                       onChange={(e) => setEditSlug(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list mt-2" >
                        <FormLabel>Description</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }}
                        value={editDescription}
                        onChange={(e) => setEditDescription(e.target.value)}
                        />
                    </div>

                   

                    <div className="col-6 mt-2">
                        <FormLabel>Sort order</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}
                        value={editSortOrder}
                        onChange={ handleSortOrde}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                            
                        </NumberInput>
                    </div>

                    <div className="col-3 mt-2">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button radio-button-from'>
                           {editCategoryData?.status !== undefined  && 
                           <Example
                            statusValue={editCategoryData?.status}
                            sendValue={updateStatus}
                            />}
                        </div>

                    </div>

                    <div className='btn-from'>
                    <Button className='from-btn' onClick={handleCancel}>Cancel</Button>
                    <Button className='from-save-btn' onClick={handleSave}>Save</Button>        
                    </div>
                    


                </div>
            </Card>
        </div>
    );
};

export default EditCategory;