import React, { useState } from 'react'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import LeftSection from '../../../leftSection/LeftSection'
import EditCategory from '../../../brandPage/category/EditCategory'
import { MaterialCategoryModel } from '../../../../Model/material/MaterialCategoryModel'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice'
import UpdateCategoryApi from './api/UpdateCategoryApi'
import Header from '../../../header/Header'

const AdminCategoryEdit = () => {
     const navigate= useNavigate()
     const dispatch= useDispatch()
  const [ isEditApi, setIsEditApi]= useState<boolean>(false)
  const [editCategory, setEditCategory]= useState<MaterialCategoryModel|null>()
   const handleUpdate=(data:MaterialCategoryModel)=>{
    dispatch(startLoading())
    setEditCategory(data)
    setIsEditApi(true)
   }


   const handleResetUpdate=(mess:string)=>{
      if(mess==="success"){
        setIsEditApi(false)
        setEditCategory(null)
        dispatch(stopLoading())
        navigate("/dashboard/swatch-categories")
        alert("category updated successfully")
        
      }else if( mess==="fail"){
        setIsEditApi(false)
        setEditCategory(null)
        dispatch(stopLoading())
        navigate("/dashboard/swatch-categories")
        alert("Error in updating category")
      }
   }
  return (
   <>
   {/* <Header/> */}
    <div className='row'>


<LeftSection
 
/>
<div className="col-9 brand-right-section">

 <EditCategory
 updateCategory={handleUpdate}

 />


 {/* update category Api */}
       { isEditApi &&editCategory &&
       <UpdateCategoryApi
       resetUpdate={handleResetUpdate}
       editCategory={editCategory}
       />}
</div>



{/* getAll materials */}
<GetAllMaterialsHome/>
</div>
   </>
  )
}

export default AdminCategoryEdit