// import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  getAllSwatchImages,
  getSelectedSegment,
  getUserJobData
} from '../../../../slice/userJobSlice/UserJobSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSwatches } from '../../../../slice/materialSlice/MaterialSlice'
import { SwatchesModel } from '../../../../Model/material/MaterialModel'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { segmentationValue } from '../../../../Model/apiModel/APiModel'
import { GroupMasterSegment, JobSegmentationModel } from '../../../../Model/masterArray/MasterArrayModel'
import CallApiSwatch from './CallApiSwatch'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { addSvgImage, addSvgOption, addSvgPhotoImage, getSvgOption } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { SelectedGroupModel } from '../../../../Model/svgModel/SvgModel'
import { stopLoading } from '../../../../slice/loading/LoadingSlice'
import { SegmentAnnotationModel } from '../../../../Model/replaceSwatchModel/ReplaceWindowSwatch'
import ReplaceSwatchHome from '../replaceSwatch/ReplaceSwatchHome'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import SegmentBaseSwatch from '../segmentbasedSwatch/SegmentBaseSwatch'

import { getDoorSwatches, getGarageSwatches, getGutterSwatches, getRoofSwatches, getTrimSwatches, getWallSwatches, getWindowSwatches, resetDoorSwatches, resetWallSwatches, resetWindowSwatches, resetRoofSwatches, resetTrimSwatches, resetGutterSwatches, resetGarageSwatches } from '../../../../slice/swatches/SwatchSlices'
import { CatBrandStyleSwatchModel } from '../../../../Model/material/CatBrandStyleSwatchModel'
import Loading from '../../../loading/Loading'
import GetAllSwatchImage from '../segmentbasedSwatch/GetAllSwatchImage'
import { getSelectedSegmentForSwatch } from '../../../../slice/segment/SegmentSlice'
import { getGroupName } from '../../../../slice/toolActive/ToolActiveSlice'

const s3path= process.env.REACT_APP_S3BucketMaterial

type Props={
    allSegDetail:JobSegmentationModel[]
}


const SwatchImages = () => {
    const [svgPoints, setSvgPoints] = useState<number[]>([]); // Points for the polygon
    const [palletUrl, setPalletUrl] = useState<string|null>(null); // Base64 string for the image
   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
   
    const getSelectedSegments= useSelector(getSelectedSegment)
    const dispatch= useDispatch()
    const path =`${s3path}/liv/materials`;
    const getMasterArrays= useSelector(getMasterArray)
    const [ allSwatchImages, setAllSwatchImage]= useState<CatBrandStyleSwatchModel[]>([])

    const [selctedSwatch, setSelectedSwatch]= useState< CatBrandStyleSwatchModel|null>()
    const [isApi, setIsApi]= useState<boolean>(false)
   
    const getAllSwatchImagess= useSelector(getAllSwatchImages)
    const [groupSegmentData, setGroupSegmentData]= useState<JobSegmentationModel[]>([])

    const [projectId, setProjectId]= useState<number|null>(null)
    const getSvgOptions= useSelector(getSvgOption)
       
    const doorSwatches= useSelector(getDoorSwatches)
    const windowSwatches= useSelector(getWindowSwatches)
    const roofSwatches= useSelector(getRoofSwatches)
    const trimSwatches= useSelector(getTrimSwatches)
    const garageSwatches= useSelector(getGarageSwatches)
    const gutterSwatches= useSelector(getGutterSwatches)
    const wallSwatches= useSelector(getWallSwatches) 
    const[currentSwatches, setCurrentSwatches]= useState<string|null>(null)
    const getSelectedSegmentForSwatchs= useSelector(getSelectedSegmentForSwatch)
    const getGroupNames= useSelector(getGroupName)
    // change swatches based on segment selection
    // useEffect(() => {
    //   //console.log("getSelectedSegments",getSelectedSegments[0])
    //     if (getSelectedSegmentForSwatchs && 
    //       getSelectedSegmentForSwatchs.length > 0) {
    //       const segment=getSelectedSegmentForSwatchs[0] as GroupMasterSegment
    //          if(segment){
    //           Object.keys(segment).map((key:string)=>{
    //             const segmentData=segment[key] as JobSegmentationModel[]

    //                   if(segmentData && segmentData.length>0){

    //                     const segmentType =segmentData[0]?.details?.seg_type
    //                     setAllSwatchImage([])
    //                  if (segmentType === "Door" && doorSwatches && doorSwatches.length > 0) {
    //                      //console.log("Setting door swatches")
    //                      setCurrentSwatches("Door")
    //                      setAllSwatchImage(doorSwatches)
    //                  } else if (segmentType === "Wall" && wallSwatches && wallSwatches.length > 0) {
    //                      //console.log("Setting wall swatches")
    //                        setCurrentSwatches("Wall")
    //                      setAllSwatchImage(wallSwatches)
    //                  } else if (segmentType === "Window" && windowSwatches && windowSwatches.length > 0) {
    //                      //console.log("Setting window swatches")
    //                      setCurrentSwatches("Window")
    //                      setAllSwatchImage(windowSwatches)
    //                  } else if (segmentType === "Roof" && roofSwatches && roofSwatches.length > 0) {
    //                      //console.log("Setting roof swatches")
    //                      setCurrentSwatches("Roof")
    //                      setAllSwatchImage(roofSwatches)
    //                  } else if (segmentType === "Garage" && garageSwatches && garageSwatches.length > 0) {
    //                      //console.log("Setting garage swatches")
    //                      setCurrentSwatches("Garage")
    //                      setAllSwatchImage(garageSwatches)
    //                  } else if (segmentType === "Gutter" && gutterSwatches && gutterSwatches.length > 0) {
    //                      //console.log("Setting gutter swatches")
    //                      setCurrentSwatches("Gutter")
    //                      setAllSwatchImage(gutterSwatches)
    //                  } else if (segmentType === "Trim" && trimSwatches && trimSwatches.length > 0) {
    //                      //console.log("Setting trim swatches")
    //                      setCurrentSwatches("Trim")
    //                      setAllSwatchImage(trimSwatches)
    //                  }
                     
    //                   }
            
    //            })
    //          }
        
           
    //     }
    //     else if (getSelectedSegmentForSwatchs &&
    //       getSelectedSegmentForSwatchs.length==0 &&
    //       wallSwatches && wallSwatches.length > 0){
         
    //         //cx  onsole.log("Setting door swatches")
    //         setCurrentSwatches("Wall")
    //         setAllSwatchImage(wallSwatches)
        
    // }
    // }, [getSelectedSegmentForSwatchs, doorSwatches, wallSwatches,windowSwatches])
    
    useEffect(() => {
     
        if (getGroupNames ) {
         
                     if (getGroupNames === "Door" && doorSwatches && doorSwatches.length > 0) {
                         //console.log("Setting door swatches")
                         setCurrentSwatches("Door")
                         setAllSwatchImage(doorSwatches)
                     } else if (getGroupNames === "Wall" && wallSwatches && wallSwatches.length > 0) {
                         //console.log("Setting wall swatches")
                           setCurrentSwatches("Wall")
                         setAllSwatchImage(wallSwatches)
                     } else if (getGroupNames === "Window" && windowSwatches && windowSwatches.length > 0) {
                         //console.log("Setting window swatches")
                         setCurrentSwatches("Window")
                         setAllSwatchImage(windowSwatches)
                     } else if (getGroupNames === "Roof" && roofSwatches && roofSwatches.length > 0) {
                         //console.log("Setting roof swatches")
                         setCurrentSwatches("Roof")
                         setAllSwatchImage(roofSwatches)
                     } else if (getGroupNames === "Garage" && garageSwatches && garageSwatches.length > 0) {
                         //console.log("Setting garage swatches")
                         setCurrentSwatches("Garage")
                         setAllSwatchImage(garageSwatches)
                     } else if (getGroupNames === "Gutter" && gutterSwatches && gutterSwatches.length > 0) {
                         //console.log("Setting gutter swatches")
                         setCurrentSwatches("Gutter")
                         setAllSwatchImage(gutterSwatches)
                     } else if (getGroupNames === "Trim" && trimSwatches && trimSwatches.length > 0) {
                         //console.log("Setting trim swatches")
                         setCurrentSwatches("Trim")
                         setAllSwatchImage(trimSwatches)
                     }
                     
           
        }
       
    }, [getGroupNames, doorSwatches, wallSwatches,windowSwatches])

   
  // update the selected segment for swatch
  useEffect(()=>{
    if(getSelectedSegments && getSelectedSegments.length>0){
      setGroupSegmentData(getSelectedSegments)
    }
  },[getSelectedSegments])

      
  const handleSwatch=(data:CatBrandStyleSwatchModel)=>{
    if(data.is_featured){
      handleSwatchFeature(data)

    }else{
      console.log("no feature")
      setSelectedSwatch(data)
       const swatchUrl=`${path}/${data.photo}`
   
      setPalletUrl(swatchUrl)
      if(groupSegmentData && groupSegmentData.length==0){
       dispatch(addMessage({
         isShow:true,
         mess:"Please select the segment",
          toastType:"Error"
        }))
        // alert('Please select the segment')
      }else {
       dispatch(addSvgOption({
         isOpenModal:true,
         svgModelName:""
        }))
      }
    }
       
   
  
  }
  const handleSwatchFeature=(data:CatBrandStyleSwatchModel)=>{
    console.log("yes feature")
   setSelectedSwatch(data)
    const swatchUrl=data.bucket_path??""
   setPalletUrl(swatchUrl)
   if(groupSegmentData && groupSegmentData.length==0){
    
    dispatch(addMessage({
      isShow:true,
      mess:"Please select the segment",
       toastType:"Error"
     }))
     // alert('Please select the segment')
   }else {
    dispatch(addSvgOption({
      isOpenModal:true,
      svgModelName:""
     }))
   }
   
  
  }
 
  // React svg
useEffect(()=>{

  if(getSvgOptions.svgModelName==="reactSvg" && 
    groupSegmentData && 
    groupSegmentData.length>0 && palletUrl){
    reactSvg(groupSegmentData,palletUrl)
  }

  
},[getSvgOptions,groupSegmentData,palletUrl])

// open opencv
useEffect(()=>{

  if(getSvgOptions.svgModelName==="openCv" &&
   !getSvgOptions.isOpenModal &&!isApi &&
    palletUrl){
        console.log("setIsApi is true")
        setIsApi(true)
      }
     
  
  
},[getSvgOptions,palletUrl, isApi])


const reactSvg=(groupSegmentData:JobSegmentationModel[],swatchUrl:string)=>{
  const allSelected:SelectedGroupModel[]=[]
  if(groupSegmentData && groupSegmentData.length>0 && swatchUrl){
          groupSegmentData.map(item=>{
                  if(item.segName && item.details?.annotation && item.details.bb_annotation_int){
                      const data={
                          segname:item.segName,
                          annoatation:item.details?.annotation ??[],
                          coOrdinate:item.details?.bb_annotation_int??[]
                      }
                      allSelected.push(data)
                  }
          })
       
    if(allSelected && allSelected.length>0){
      dispatch(addSvgImage({
          swatchUrl:swatchUrl,
          selectedGroup:allSelected
         }))
    }
         
     }
}



  const handleShow = (data:CatBrandStyleSwatchModel) =>{

   setShow(true);
    setSelectedSwatch(data)
    const allSelected:SelectedGroupModel[]=[]
     //const swatchUrl=data?.photo_svg
    const swatchUrl=`${path}/${data.photo}`;
    setPalletUrl(swatchUrl)
  } 

 const[ coOrdinate, setCoordinate]= useState<number[]>([])

// set svg selected seg  point
 



  const handleResetSwatch=(mess:boolean)=>{
    setSelectedSwatch(null)
    setPalletUrl(null)
    setIsApi(false)
    dispatch(stopLoading())
    if (mess) {

      console.log("swatch updated")
      // alert('Swatch Image applied ')
    } else if (!mess) {
    
      // alert('Error on swatch image')
      dispatch(addMessage({
        isShow:true,
        mess:"Error on swatch image",
         toastType:"Error"
       }))
    }
  }


  // reload swatches
  const handleReloadSwatches=()=>{
    if(currentSwatches){
    setAllSwatchImage([])
      if(currentSwatches==="Door"){
          dispatch(resetDoorSwatches())
      }else if(currentSwatches==="Wall"){
        dispatch(resetWallSwatches())
      }else if(currentSwatches==="Window"){
        dispatch(resetWindowSwatches())
      }else if(currentSwatches==="Roof"){
        dispatch(resetRoofSwatches())
      }else if(currentSwatches==="Garage"){
        dispatch(resetGarageSwatches())
      }else if(currentSwatches==="Gutter"){
        dispatch(resetGutterSwatches())
      }else if(currentSwatches==="Trim"){
        dispatch(resetTrimSwatches())
      }
      
      
    }
  }
  
  return (
    <>
    
    {/* get all swatch image based on segment */}
     {/* <SegmentBaseSwatch/> */}

       {/* <CurrentSwatchImage/> */}

<div className='d-flex justify-content-end reload-swatches'>
       <span 
       onClick={handleReloadSwatches}
       ><i className="bi bi-arrow-clockwise">Reload</i></span>
</div>



       {allSwatchImages &&
        allSwatchImages.length== 0 ?(
          <h6 className='d-flex justify-content-center align-items-center'>Loading 
            <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><circle className="spinner qM83" cx="4" cy="12" r="2"></circle><circle className="spinner oXPr" cx="12" cy="12" r="2"></circle><circle className="spinner ZTLf" cx="20" cy="12" r="2"></circle></svg></h6>
        ):(
          allSwatchImages.map((item, index) => (
            <div className="col-4" key={`${item.id}-${index}`}>
              
              <div className="palletsbox ">
                <div   >
                <img src={`${path}/${item?.photo}`} alt="swatch" 
                    onClick={() => handleSwatch(item)}
                    />
                  {/* {item.is_featured?
                  (<img src={`${item.bucket_path}`} alt="swatch" 
                    onClick={() => handleSwatchFeature(item)}
                  />):(
                     
                    <img src={`${path}/${item?.photo}`} alt="swatch" 
                    onClick={() => handleSwatch(item)}
                    />
                  )} */}
              
                
                <svg
                  className="checkicon md"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  ></circle>
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  ></path>
                </svg>
                </div>
              
  
                
                <span
                  role="button"
                  className="pallets-info"
                  onClick={() => handleShow(item)} // Show Offcanvas
                >
                  <i className="bi bi-info"></i>
                </span>
              </div>
              
            </div>
           
          )
        )
       )}
         <button className='btn btn-primary'>All Materials Loaded</button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement='bottom'
        className='bottom_side_new h-auto'
      >
        <Offcanvas.Header closeButton>
          {selctedSwatch  && (
            <Offcanvas.Title className='text-center'>
              {selctedSwatch.title}
            </Offcanvas.Title>
          ) }
          {/* <Offcanvas.Title className='text-center'>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          {(selctedSwatch  && palletUrl)? (
            <div className='row mb-4'>
              <div className='col-12 col-md-6 m-auto'>
                <div className='row'>
                  <div className='col-12 col-md-4'>
                    <img
                      className='rounded-3'
                      src={palletUrl}
                      alt='Selected Swatch'
                    />
                  </div>
                  <div className='col-12 col-md-1 d-flex align-items-center justify-content-center'>
                    <span className='h-100 border border-1'></span>
                  </div>
                  <div className='col-12 col-md-7'>
                    <h4>
                      Category :
                      <span>
                        {selctedSwatch.category.title||
                          'N/A'}
                      </span>
                    </h4>
                    <h4>
                      Brand :
                      <span>
                        {selctedSwatch.brand?.title||
                          'N/A'}
                      </span>
                    </h4>
                    <h4>
                      Style :
                      <span>
                        {selctedSwatch.style?.title ||
                          'N/A'}
                      </span>
                    </h4>
                    <h4>
                      id :
                      <span>
                        {selctedSwatch.id ||
                          'N/A'}
                      </span>
                    </h4>
                    <p>Model Size: Model Here</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Select a swatch to see details.</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* {isApi &&palletUrl && (
        <CallApiSwatch
        palletUrl={palletUrl}
        groupSegmentData={groupSegmentData}
        resetOpenCv={handleResetSwatchApi}
        />
      )} */}

      {/* Replace Swatch Home */}

     { palletUrl!=null &&
     isApi &&
     selctedSwatch  &&
     <ReplaceSwatchHome
     selctedSwatch={selctedSwatch}
      palletUrl={palletUrl}
      resetSwatch={handleResetSwatch}
      />}

      <GetAllSwatchImage/>
    </>
  )
}

export default SwatchImages
