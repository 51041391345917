import React, { useEffect, useRef, useState } from 'react'
import { DeleteSegmemtFromGroup } from '../api/SelectSwatchApi'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import { useDispatch } from 'react-redux'


type Props={
    segName:string
    jobId:number
    resetDelete:(data:boolean)=>void
}
const DeleteSegmentApi = ({segName,resetDelete,jobId}:Props) => {
    const[ name, setName]= useState<string|null>(null)
    const[ jobIds, setJobId]= useState<number|null>(null)
     const dispatch= useDispatch()
    const isApi= useRef(true)
    const isData= useRef(true)
    useEffect(()=>{
        
        if(segName && jobId && isData){
            isData.current= false
            setName(segName)
            setJobId(jobId)
        }
    },[segName,jobId])

    useEffect(()=>{
        if(name!=null && jobIds!= null&& isApi.current){
               isApi.current= false
               segMentDelete(name, jobId)
        }
    },[name])

    const segMentDelete=async(name:string, jobId:number)=>{
        //  console.log("Api hit")
        try{
         
             const response = await DeleteSegmemtFromGroup( jobId, name);
            //  console.log("response", response)
             if(response && response.status===200){
                resetDelete(true)
                isApi.current= true
             }

        }catch(err){
            resetDelete(false)
            isApi.current= true
            dispatch(addMessage({
                isShow: true,
                mess: "Error on Deleting",
                toastType:"Error"
              }))
            // console.log(err)
        }

    }

  return (
    <div></div>
  )
}

export default DeleteSegmentApi