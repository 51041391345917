
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../slice/messageToast/ToastSlice';
import { GetGenAiStyle } from '../../../../api/genAi/GenAiAPi';
const backEndUrl=process.env.REACT_APP_BACKEND_URL



type Props = {
    imagePath: string;
    promptData:string
    resetImagePath:()=>void
  };
const PromptToAiImage = ({imagePath,promptData,resetImagePath}:Props) => {
   const dispatch= useDispatch()
   const isApi= useRef(true)

   useEffect(()=>{
    if(imagePath  &&
        promptData &&
        isApi.current){
            isApi.current=false
        handleGenAiRequest(imagePath,promptData)
    }
   },[imagePath])



   const handleGenAiRequest=async(imagePath:string, prompt:string)=>{

     try{
    const response = await GetGenAiStyle(imagePath,prompt)
         if(response ){
            dispatch(addMessage({
                isShow:true,
                mess:`Image generation started`,
                 toastType:"success"
               }))
            isApi.current=true
                   resetImagePath()
         }
     }catch(err){
       
        dispatch(addMessage({
                    isShow:true,
                    mess:`Error on Ai Image Generation- ${err}`,
                     toastType:"Error"
                   }))


                   resetImagePath()
     }

   }
    // const handleGenAiRequest = async (imagePath:string) => {
    //     // if (!socket || socket.readyState !== WebSocket.OPEN) {
    //     if(imagePath){
    //       dispatch(addMessage({
    //         isShow:true,
    //         mess:"WebSocket is not connected. Please wait or try again."
    //        }))
    //      // alert("WebSocket is not connected. Please wait or try again.")
    //       console.log("WebSocket is not connected. Please wait or try again.");
    //       const message="WebSocket is not connected. Please wait or try again."
         
          
    //     }
        
    //     fetch(`${backEndUrl}/genai/gen-ai-images`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         url: `${imagePath}`, // Replace with actual image URL
    //         prompt: [
    //           "Make this Craftsman style",
    //           // /"Make this Colonial Revival style",
    //           // "Make this Georgian style",
    //           // "Make this Tudor style",
    //           // "Make this Cape Cod style",
    //           // "Make this French Country style",
    //           // "Make this Prairie style",
    //           // "Make this American Foursquare style",
    //           // "Make this Victorian style",
    //           // "Make this Shingle style"
    //         ], // Replace with actual prompt
    //       }),
    //     })
    //     .then(response => response.json())
    //     .then(data =>{
    //       console.log("API Response:", data)
    //       isApi.current=true
    //       resetImagePath()
    //     } )
    //     .catch(error => console.error("Error making API request:", error));
    
       
    //   };
    
  return (
    <>
        
    </>
  )
}

export default PromptToAiImage