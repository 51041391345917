import React from 'react'
import { Routes, Route } from 'react-router-dom';
import UserTablePage from '../../page/user/UserTablePage';
import UserEdit from '../../page/user/UserEdit';
const UsersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserTablePage />} />
      <Route path="/edit" element={<UserEdit />} />
    </Routes>
  )
}

export default UsersRoutes
