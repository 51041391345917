import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
// import Example from './RadioCard';
// import "./EditProjectFrom.css"
import StatusRadio from "./StautusRadioCard"
import { useDispatch, useSelector } from 'react-redux';
import { getMaterialStyle, getSwatchStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import { getMaterialBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { getMaterialCategorys } from '../../../slice/materialSlice/MaterialCategorySlice';
import { MaterialBrandModel } from '../../../Model/material/MaterialBrandModel';
import { MaterialStyleModel } from '../../../Model/material/MaterialStyleModel';
import { getUserLogin } from '../../../slice/user/UserSlice';
const CreateFromEdit: React.FC = () => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            // Handle the file
            console.log(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            // Handle the file
            console.log(event.target.files[0]);
        }
    };
     
    const dispatch= useDispatch();
    const getSwatchStyles= useSelector(getSwatchStyle)
    const getMaterialStyles= useSelector(getMaterialStyle) as MaterialStyleModel[]
    const getMaterialBrands= useSelector(getMaterialBrand)
     const getMaterialCategoryss= useSelector(getMaterialCategorys)
     const [ styleId, setStyleId]= useState<number>(0)
     const [allBrand, setAllBrand]= useState<MaterialBrandModel[]>([])
     const [user_id, setuser_id]= useState<number>(0)
     const [user_name, setuser_name]= useState<string>("")
     const [role_id, setrole_id]= useState<number>(0)
     const [role_name, setrole_name]= useState<string>('')
     const [material_category_id, setmaterial_category_id]= useState<number>(0)
     const [material_brand_id, setmaterial_brand_id]= useState<number>(0)
     const [material_category_name, setmaterial_category_name]= useState<string>("")
     const [material_brand_name, setmaterial_brand_name]= useState<string>("")
     const [material_brand_style_id, setmaterial_brand_style_id]= useState<number>(0)
      const getUserLogins= useSelector(getUserLogin);

     const [title, settitle]= useState<string>("")
      
     const [description, setdescription]= useState<string>("")
      
     const [photo, setphoto]= useState<string>("")
     const [manufacturer_request_note, setmanufacturer_request_note]= useState<string>("")
      
     const [is_admin, setis_admin]= useState<number>(0)
      
     const [suggested_swatches, setsuggested_swatches]= useState<string[]>([])
      
     const [finish_needed, setfinish_needed]= useState<number>(0)
     const [status, setstatus]= useState<number>(0)
      
     const [manufacturer_request, setmanufacturer_request]= useState<number>(0)
     const [created, setcreated]= useState<string>("")
     const [modified, setmodified]= useState<string>("")
    
     useEffect(()=>{
        if(getUserLogins && 
            getUserLogins[0]?.id && 
            getUserLogins[0]?.name && 
            getUserLogins[0]?.role){
            setuser_id(getUserLogins[0].id )
            setuser_name(getUserLogins[0].name)
               if(getUserLogins[0].role==="customer"){
                setrole_id(1)
                setrole_name(getUserLogins[0].role)
               }
        }
      },[getUserLogins])
        const handleSelectStyle=(id:string)=>{
            console.log("style id", id)
            const styId=parseInt(id)
            const style= getSwatchStyles.find(item=>item.id===styId) 
                console.log("style", style)
                if(style?.brand && 
                    style.brand.id &&
                    style.brand.title &&
                    style.brand.category &&
                    style.brand.category.id &&
                    style.brand.category.title
                ){
                    setmaterial_brand_id(style.brand.id)
                    setmaterial_brand_name(style.brand.title)
                    setmaterial_category_id(style.brand.category.id)
                    setmaterial_category_name(style.brand.category.title)
                }
        }
     return (
        <div>
           


            <Card className="card-edit pb-3 mb-6 ">
                <div className="row container from-edit">

            

                    <div className="col-12 from-list">
                        <FormLabel>Users </FormLabel>
                        <Input className="input-primary" 
                        value={user_name}/>
                    </div>

                    <div className="col-12 from-lable from-list">
                        <FormLabel>Image</FormLabel>
                        <Box
                            className="box-primary"
                            backgroundColor="rgb(246, 246, 249)"
                            border="1px solid #cbd5e0"
                            borderRadius="md"
                            padding="34px 0"
                            textAlign="center"
                            onClick={handleDragImage}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                className="bi bi-image-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                            </svg>
                            <Text
                                mt={2}
                                color="gray.500"
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: 'rgb(102, 102, 135)',
                                }}
                            >
                                Click to add an asset or drag and drop one in this area
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                display="none"
                            />
                        </Box>
                    </div>

                

                    <div className="col-6 mt-2">
                        <FormLabel>Role</FormLabel>
                        <Select className="input-primary" >
                            <option value={role_id}>{role_name}</option>
                            
                        </Select>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Material Brand Style </FormLabel>
                        <Select className="input-primary"
                        value={styleId}
                            onChange={(e)=>handleSelectStyle(e.target.value)}
                        >
                        {getSwatchStyles &&
                        getSwatchStyles.length>0 &&
                        getSwatchStyles.map((item)=>(
                        <option value={item.id}>{item.title}</option>
                           
                        ))
                        }
                        </Select>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Material Category</FormLabel>
                        <Select className="input-primary"
                        value={material_category_id}
                        >
                        <option value={material_category_id}>{material_category_name}</option>
                           
                        </Select>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Material Brand </FormLabel>
                        <Select className="input-primary" 
                         value={material_brand_id}
                        >
                        <option value={material_brand_id}>{material_brand_name}</option>

                        </Select>
                    </div>

                 

                    <div className="col-12 mt-2">
                        <FormLabel>Title</FormLabel>
                        <Input className="input-primary" />
                    </div>

                    <div className="col-12 from-list" >
                        <FormLabel>Description</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} />
                    </div>

                   

                    <div className="col-6 mt-2">
                        <FormLabel>Admin</FormLabel>
                        <Select className="input-primary" >
                        <option>Add relation</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>

                        </Select>

                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Finish Needed</FormLabel>
                        <Select className="input-primary">
                        <option>Add relation</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>

                        </Select>
                    </div>


                    <div className="col-12 from-list" >
                        <FormLabel>Manufacturer request note</FormLabel>
                        <Textarea style={{ backgroundColor: "#f6f6f9", }} />
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Swatches</FormLabel>
                        <Select className="input-primary" >
                        <option>Add relation</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                        </Select>

                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button checkbox-status'>
                            <StatusRadio />
                        </div>

                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Created</FormLabel>
                        <Input
                            className="input-primary"
                            type="datetime-local"
                            placeholder="Select Date and Time"
                        />
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Modified</FormLabel>
                        <Input
                            className="input-primary"
                            type="datetime-local"
                            placeholder="Select Date and Time"
                        />
                    </div>




                </div>
            </Card>
        </div>
    );
};

export default CreateFromEdit;