import React from 'react'
import Home from '../../module/homePage/Home'
import { Header } from 'antd/es/layout/layout'

const HomePage = () => {
  return (
    <div>
    
      <Home/>
      </div>
  )
}

export default HomePage