import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobModel } from "../../../Model/Job/JobModel";


interface JobStates {
    jobs:JobModel[]
   
    
}

const initialState:JobStates = {
   jobs:[]
};
const JobSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        addAllJobData:(state,action)=>{
            state.jobs= action.payload
        }
        , 
        resetJobs:(state)=>{
            state.jobs=[]
        },
        

    }
}
)

export const {addAllJobData,resetJobs}=JobSlice.actions;
export const getAllJobData=  (state:{jobs:JobStates})=>state.jobs.jobs
export default JobSlice.reducer