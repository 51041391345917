import React from 'react'
import ReAnnotationCanvasHome from '../../../module/canavas/reannotationCanvas/ReAnnotationCanvasHome'
  
const ReAnnotationPage = () => {
  return (
    <div>
        <ReAnnotationCanvasHome/>
    </div>
  )
}

export default ReAnnotationPage