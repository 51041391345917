import React, { useEffect, useRef } from 'react'
import { GetAllDoorSwatches, GetAllWallSwatches } from '../../../../../api/material/catBrandStyleSwatch/CatBrandStyleSwatchApi'
import { CatBrandStyleSwatchModel } from '../../../../../Model/material/CatBrandStyleSwatchModel'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'


type WallSwatchProps={
    swatchWall: (swatch: CatBrandStyleSwatchModel[]) => void
}
const WallSwatch = ({swatchWall}:WallSwatchProps) => {
     const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{
        if(isApi.current){
            getAllWallSwatches()
            isApi.current=false
        }   
    },[])

    const getAllWallSwatches=async()=>{
        try{    
            const response=await GetAllWallSwatches()
            //console.log("response Door",response)
            if(response && response.status===200){
                
                swatchWall(response.data.materials)
            }
        }catch(err){
               dispatch(addMessage({
                isShow: true,
                message: "Error in getting all door swatches",
               }))
           // console.log("err in getting all door swatches",err)
            swatchWall([])
        }
    }

  return (
    <>
    </>
  )
    }

export default WallSwatch