import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';

import { CiIndent, CiEdit } from "react-icons/ci";
import DragDrop from './DragDrop';

// Define a type for the country data
interface Country {
  name: string;
  nativeName: string;
  capital: string;
  flag: string;
}

const PageContent: React.FC = () => {
  const [countries, setCountries] = useState<Country[]>([]); // Use Country type for state

  const getCountries = async () => {
    try {
      const response = await axios.get<Country[]>("https://restcountries.com/v2/all");
      setCountries(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Define the columns for DataTable
  const columns = [
    {
      name: "Name",
      selector: (row: Country) => row.name, // Selector returns a primitive value
      sortable: true,
    },
    {
      name: "Native Name",
      selector: (row: Country) => row.nativeName,
      sortable: true,
    },
    {
      name: "Capital",
      selector: (row: Country) => row.capital,
    },
    {
      name: "Flag",
      cell: (row: Country) => <img width={40} height={40} src={row.flag} alt={row.name} />, // Use cell for custom rendering
    },
    {
      name: "action",
      cell: (row: Country) => [<CiIndent />, <CiEdit />], // Use cell for custom rendering
    },
  ];

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
    <div className="page-content p-3">
      <header className='border-bottom pb-3 mb-3'>
        <h5>Material Library</h5>
        <small>Category</small>
      </header>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Home</a></li>
          <li className="breadcrumb-item"><a href="#">Library</a></li>
          <li className="breadcrumb-item active" aria-current="page">Data</li>
        </ol>
      </nav>

      <div className='card '>
        <div className='card-header bg-white p-2 d-flex justify-content-between align-items-center'>
            <input
              type="search"
              className="form-control w-auto"
              placeholder="Search"
          />
          <button type='button' className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#exampleModal">Create new entry</button>
        </div>
        {/* <DataTable 
            columns={columns} 
            data={countries} 
            pagination
            fixedHeader
          /> */}
      </div>

   
    </div>

   
    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">Create new entry</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body p-4">
            <div className='row g-2'>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Title</label>
                <input type='text' className='form-control' placeholder='Enter your title'/>
              </div>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Slug</label>
                <select className="form-select" aria-label="Default select example">
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className='col-12 col-md-12'>
                <label className='form-label'>Description</label>
                <textarea className="form-control" id="exampleFormControlTextarea1"></textarea>
              </div>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Short order</label>
                <input type='text' className='form-control' placeholder='Enter your title'/>
              </div>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Status</label>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active / Inactive</label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-link text-secondary text-decoration-none" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>

    
    </>
  );
};

export default PageContent;