import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea, RadioGroup, Radio, Button } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import Example from '../style/StyleRadioCard';
import { useDispatch, useSelector } from 'react-redux';
import { getEditBrand, resetEditBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { MaterialBrandModel } from '../../../Model/material/MaterialBrandModel';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';
import { getMaterialCategorys } from '../../../slice/materialSlice/MaterialCategorySlice';
import { useNavigate } from 'react-router-dom';


   type Props={
    updatData:(data:MaterialBrandModel)=>void
   }

const EditBrandForm= ({updatData}:Props) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
       const getEditBrands= useSelector(getEditBrand)
       const navigate= useNavigate()
  const dispatch= useDispatch();
      const [categoryId, setCategoryId]= useState<number>()
      const [brandId, setBrandId]= useState<number>()
      const [title, setTitle]= useState<string>()
      const [slug, setSlug]= useState<string>()
      const [description, setDescription]= useState<string>()
      const [sort_order, setSort_order]= useState<number>()
      const [status, setStatus]= useState<number|undefined>()
      const [category, setCategory]= useState<MaterialCategoryModel>()
      
      const [allCategory, setAllCategory]= useState<MaterialCategoryModel[]>([])
      

      const getMaterialCategoryss= useSelector(getMaterialCategorys) as MaterialCategoryModel[]

  useEffect(()=>{
    if(getEditBrands &&
         getEditBrands.id &&
          getMaterialCategoryss){
            setBrandId(getEditBrands.id)
        setCategory(getEditBrands.category)
        setCategoryId(getEditBrands.category?.id)
        setTitle(getEditBrands.title)
        setSlug(getEditBrands.slug)
        setDescription(getEditBrands.description)
        setSort_order(getEditBrands.sort_order)
        setStatus(getEditBrands.status)
    }
  },[getEditBrands,getMaterialCategoryss])



   const handleSortOrder=(val:string)=>{
    setSort_order(parseInt(val))
   }
 

   const handleStatus=(val:string)=>{
    if(val){
        setSort_order(parseInt(val))
    }
  
   }


   const handleSelectCategory=(val:number)=>{

      if(val){
        console.log("val",val);
        setCategoryId(val)
      }

   }


   // back 
   const handleBack=()=>{
    dispatch(resetEditBrand())
    navigate("/dashboard/swatch-brands")

      
   }

   // cancel 
   const handleCancel= ()=>{
    dispatch(resetEditBrand())
    navigate("/dashboard/swatch-brands")

   }

   const handleSave=()=>{

    const data:MaterialBrandModel={
        id:brandId,
    material_category_id: categoryId,
    title: title,
    slug: slug,
    description: description,
    sort_order: sort_order,
    status: status
    }

    updatData(data)
   }
    return (

        <div>
            <div className="back-icon"
             onClick={handleBack}
            >
                <i className="bi bi-arrow-left-short"></i>
                <span>Back</span>
            </div>

            <Card className="card-edit py-4 mt-4">
                <div className="row container from-edit">

                <div className="col-6 from-list">
                        <FormLabel> Material Brand </FormLabel>
                        <Input className="input-primary" 
                           value={title}
                           onChange={(e)=>setTitle(e.target.value)}
                        />
                    </div>
                    <div className="col-6 from-list">
                        <FormLabel> Material Category </FormLabel>
                        <Select className="input-primary" placeholder="JobView"
                         value={categoryId}
                         onChange={(e) => handleSelectCategory(Number(e.target.value))}
                        >
                     {getMaterialCategoryss && getMaterialCategoryss.length > 0 &&
                     getMaterialCategoryss.map(item => (
                        <option key={item.id} value={item.id}>
                                 {item.title}
                            </option>
    ))
  }
                            </Select>
                    </div> 

                  
                    <div className="col-6 from-list">
                        <FormLabel> Slug </FormLabel>
                        <Input className="input-primary" 
                         value={slug}
                         onChange={(e)=>setSlug(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list" >
                        <FormLabel>Description</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} 
                         value={description}
                         onChange={(e)=>setDescription(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list" >
                        <FormLabel>Sort Order</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}
                        value={sort_order}
                        onChange={ handleSortOrder}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                            
                        </NumberInput>
                    </div>
                    

                    <div className="col-3 mt-2">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button radio-button-from'>
                        <RadioGroup   className='radio-btn'
                        onChange={handleStatus}
                        value={status !== undefined ? status.toString() : ''}

                         >
                                <Radio value="1">Active</Radio>
                                <Radio value="0">Inactive</Radio>
                            </RadioGroup>
                        </div>

                    </div>

                    <div className='btn-from'>
                        <Button className='from-btn' onClick={handleCancel}>  
                            Cancel 
                        </Button>
                        <Button className='from-save-btn'  onClick={handleSave}>  
                            Save 
                        </Button>
                    </div>


                </div>
            </Card>
        </div>
    );
};

export default EditBrandForm;