import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea } from '@chakra-ui/react';
import React, { useRef } from 'react';
import Example from './RadioCard';
// import "./EditProjectFrom.css"
// import "../brandPage/projects/EditProjectFrom.css"

const EditCreateProject: React.FC = () => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            // Handle the file
            console.log(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            // Handle the file
            console.log(event.target.files[0]);
        }
    };

    return (
        <div>
           

            <Card className="card-edit  ">
                <div className="row container from-edit">
                    <div className="col-12 from-list">
                        <FormLabel>Name</FormLabel>
                        <Input className="input-primary" />
                    </div>

                    <div className="col-12 from-lable from-list">
                        <FormLabel>Image</FormLabel>
                        <Box
                            className="box-primary"
                            backgroundColor="rgb(246, 246, 249)"
                            border="1px solid #cbd5e0"
                            borderRadius="md"
                            padding="34px 0"
                            textAlign="center"
                            onClick={handleDragImage}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                className="bi bi-image-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                            </svg>
                            <Text
                                mt={2}
                                color="gray.500"
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: 'rgb(102, 102, 135)',
                                }}
                            >
                                Click to add an asset or drag and drop one in this area
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                display="none"
                            />
                        </Box>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>SegmentCount</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button checkbox-status'>
                            {/* <Example /> */}
                        </div>

                    </div>

                    <div className="col-12 from-lable from-list">
                        <FormLabel>Stage</FormLabel>
                        <Select className="input-primary" placeholder="JobView">
                            <option>Choose here</option>
                            <option>Designing</option>
                            <option>Reviewing</option>
                            <option>Approval</option>
                            <option>Completed</option>
                            <option>Closed</option>

                        </Select>
                    </div>

                    <div className="col-12 from-list" >
                        <FormLabel>imageDetailsJson</FormLabel>
                        <Textarea style={{ backgroundColor: "#f6f6f9", }} />
                    </div>

                    <div className="col-6 from-lable from-list">
                        <FormLabel>Jobs</FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>1548</option>
                            <option>1549</option>
                            <option>1550</option>
                            <option>1551</option>


                        </Select>
                    </div>

                    <div className="col-6 from-lable from-list">
                        <FormLabel>Customer</FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>aa</option>
                            <option>bb</option>
                            <option>cc</option>
                        </Select>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>PixelUnitInch</FormLabel>
                        <NumberInput precision={2} step={0.2} style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>

                    </div>


                    <div className="col-6 mt-2">
                        <FormLabel>PixelUnitFeet</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-12 from-list" >
                        <FormLabel>ImageBase64</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} />
                    </div>

                    <div className="col-12 from-list">
                        <FormLabel>ReferenceLink</FormLabel>
                        <Input className="input-primary" />
                    </div>


                    <div className="col-6 from-lable from-list">
                        <FormLabel>Media  </FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>aa</option>
                            <option>bb</option>
                            <option>cc</option>
                        </Select>
                    </div>

                    <div className="col-6 from-lable from-list">
                        <FormLabel>user  </FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>aa@bb.com</option>
                            <option>aa@bb.com</option>
                            <option>aa@bb.com</option>

                        </Select>
                    </div>

                    <div className="col-6 from-lable from-list">
                        <FormLabel>Models  </FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>M1</option>
                            <option>M2</option>
                        </Select>
                    </div>

                    <div className="col-6 from-list">
                        <FormLabel>ModelVersion</FormLabel>
                        <Input className="input-primary" />
                    </div>

                    <div className="col-6 from-lable from-list">
                        <FormLabel>Combinations </FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>M1</option>
                            <option>M2</option>
                        </Select>
                    </div>


                    <div className="col-6 from-lable from-list">
                        <FormLabel>Designer </FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                            <option>M1</option>
                            <option>M2</option>
                        </Select>
                    </div>


                    <div className="col-12 from-lable from-list">
                        <FormLabel>ProjectImage</FormLabel>
                        <Box
                            className="box-primary"
                            backgroundColor="rgb(246, 246, 249)"
                            border="1px solid #cbd5e0"
                            borderRadius="md"
                            padding="34px 0"
                            textAlign="center"
                            onClick={handleDragImage}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                className="bi bi-image-fill"
                                viewBox="0 0 16 16"
                            >
                                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                            </svg>
                            <Text
                                mt={2}
                                color="gray.500"
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '400',
                                    color: 'rgb(102, 102, 135)',
                                }}
                            >
                                Click to add an asset or drag and drop one in this area
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                display="none"
                            />
                        </Box>
                    </div>
                    <div className="col-12 from-list" >
                        <FormLabel>ProjectImageUrl</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} />
                    </div>


                </div>
            </Card>
        </div>
    );
};

export default EditCreateProject;