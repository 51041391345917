import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUser, getUserLogin } from '../slice/user/UserSlice'

const AuthRefresh = () => {
    const dispatch= useDispatch()
    const getUserLogins= useSelector(getUserLogin)
    const isLogin= useRef(true)
    useEffect(()=>{
      if(getUserLogins && getUserLogins.length===0){
        isLogin.current= false
        const userDetail = (localStorage.getItem("getUserLogins"))
        if(userDetail){
          const user=JSON.parse(userDetail)
          dispatch(addUser(user))
        }
      }
        else if(getUserLogins && getUserLogins.length>0 && isLogin.current){
          isLogin.current= false
            localStorage.setItem("getUserLogins",JSON.stringify(getUserLogins[0]))
        }
          
            
        
    },[getUserLogins])
  return (
    <div>
        
    </div>
  )
}

export default AuthRefresh