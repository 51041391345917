import React from 'react'
import EditShapeFrom from '../../module/brandPage/shapes/EditShapeFrom'

const ShapeEdit = () => {
  return (
    <div>
      <EditShapeFrom/>
    </div>
  )
}

export default ShapeEdit
