import React from 'react'
import AdminEditJob from '../../../module/admin/jobAdmin/AdminEditJob'

const AdminEditJobPage = () => {
  return (
    <div  style={{marginTop:50}}>
        <AdminEditJob/>
    </div>
  )
}

export default AdminEditJobPage