import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Card, Input, Table } from "antd";
import type { InputRef, TableColumnsType } from "antd";

import {
  MaterialStyleModel,
  SwatchStyleModel,
} from "../../../Model/material/MaterialStyleModel";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialStyle,
  getSwatchStyle,
} from "../../../slice/materialSlice/MaterialStyleSlice";
import Loading from "../../loading/Loading";
import { SwatchBrandModel } from "../../../Model/material/MaterialBrandModel";
import { getSwatchBrand } from "../../../slice/materialSlice/MaterialBrandSlice";
import Search from "antd/es/transfer/search";
import StyleCreateFrom from "./StyleCreateFrom";
import { startLoading, stopLoading } from "../../../slice/loading/LoadingSlice";
import { addEditStyle } from "../../../slice/materialSlice/MaterialStyleSlice";

// import AddBrandApi from '../../admin/AdminMaterial/brand/api/AddBrandApi';

import AddStyleApi from "../../admin/AdminMaterial/style/api/AddStyleApi";
import DeleteStyleApi from "../../admin/AdminMaterial/style/api/DeleteStyleApi";
// import DeleteStyleApi from '../../admin/AdminMaterial/style/api/DeleteStyleApi';
import { useNavigate } from "react-router-dom";

const StyleTable: React.FC = () => {
  const dispatch = useDispatch();

  const [isStyleAddApi, setIsStyleAddApi] = useState<boolean>(false);
  const [newstyle, setNewStyle] = useState<MaterialStyleModel | null>();

  const searchInput = useRef<InputRef>(null);
  const [allStyle, setAllStyle] = useState<SwatchStyleModel[]>([]);
  const getSwatchStyles = useSelector(getSwatchStyle) as SwatchStyleModel[];
  // Get all material styles
  const getMaterialStyles = useSelector(
    getMaterialStyle
  ) as MaterialStyleModel[];
  useEffect(() => {
    if (getSwatchStyles && getSwatchStyles.length > 0) {
      console.log("getSwatchStyles", getSwatchStyles);
      setAllStyle(getSwatchStyles);
    } else if (getSwatchStyles && getSwatchStyles.length == 0) {
    }
  }, [getSwatchStyles]);

  const handleNewBrand = (data: MaterialStyleModel) => {
    setIsStyleAddApi(true);
    setNewStyle(data);
    dispatch(startLoading());
  };

  const handleResetNewStyle = (mess: string) => {
    if (mess === "success") {
      setIsStyleAddApi(false);
      setNewStyle(null);
      dispatch(stopLoading());
      alert("Brand added successfully");
    } else if (mess == "fail") {
      setIsStyleAddApi(false);
      setNewStyle(null);
      dispatch(stopLoading());
      alert("Error in adding brand");
    }
  };

  const [isStyleDelete, setIsStyleDelete] = useState<boolean>(false);
  const [deleteStyleId, setStyleDeleteId] = useState<number | undefined>(0);

  const handleDelete = (record: MaterialStyleModel) => {
    setIsStyleDelete(true);
    setStyleDeleteId(record.id ? record.id : 0);
  };

  const handleStyleResetDelete = (mess: string) => {
    if (mess === "success") {
      setIsStyleDelete(false);
      setStyleDeleteId(0);
      dispatch(stopLoading());
      alert("category delete successfully");
    } else if (mess === "fail") {
      setIsStyleDelete(false);
      setStyleDeleteId(0);
      dispatch(stopLoading());
      alert("Error in deleting category");
    }
  };

  const navigate = useNavigate();

  const getSwatchStyl = useSelector(getSwatchStyle);
  useEffect(() => {
    if (getSwatchStyl && getSwatchStyl.length > 0) {
      setAllStyle(getSwatchStyl);
    } else if (getSwatchStyl && getSwatchStyl.length == 0) {
    }
  }, [getSwatchStyl]);

  const handleStyleEdit = (record: SwatchStyleModel) => {
    dispatch(addEditStyle(record));
    navigate(`/dashboard/swatch-styles/${record.id}/edit`);
  };

  const columns: TableColumnsType<SwatchStyleModel> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            placeholder="Search Id"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.current?.select();
          });
        }
      },
      onFilter: (value, record) => {
        if (!record.id) {
          return false;
        }

        return record.id.toString() === value.toString();
      },
      filterSearch: true,
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            ref={searchInput}
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            // onPressEnter={() => confirm({ closeDropdown: false })}
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 1000);
        }
      },
      onFilter: (value, record) =>
        record.title?.toLowerCase().includes(value.toString().toLowerCase()) ||
        false,
      filterSearch: true,
    },

    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (value, record) => {
        return record.brand?.title;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            ref={searchInput}
            placeholder="Search Brand"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            setTimeout(() => searchInput.current?.select(), 1000);
          });
        }
      },
      onFilter: (value, record) => {
        const valUpper = value.toString().toUpperCase();
        const valLower = value.toString().toLowerCase();

        if (record && record.brand && record.brand.title) {
          return (
            record.brand.title.toUpperCase().includes(valUpper) ||
            record.brand.title.toLowerCase().includes(valLower)
          );
        }

        return false;
      },
      filterSearch: true,
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, record) => {
        return record.brand?.category?.title;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            ref={searchInput}
            placeholder="Search Category"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            setTimeout(() => searchInput.current?.select(), 1000);
          });
        }
      },
      onFilter: (value, record) => {
        const valUpper = value.toString().toUpperCase();
        const valLower = value.toString().toLowerCase();

        if (
          record &&
          record.brand &&
          record.brand.category &&
          record.brand.category.title
        ) {
          return (
            record.brand.category.title.toUpperCase().includes(valUpper) ||
            record.brand.category.title.toLowerCase().includes(valLower)
          );
        }

        return false;
      },
      filterSearch: true,
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      width: "200px",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (value, record) => {
        return record.brand?.title;
      },
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "sort_order",
      width: "100px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      render: (status: number) => (status === 1 ? "Active" : "Inactive"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (value, record) => {
        return (
          <div className="d-flex action">
            <div
              className="action-icon-table"
              onClick={() => handleStyleEdit(record)}>
              <i className="bi bi-pencil-square mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-box-arrow-up-right mx-0"></i>
            </div>
            <div
              className="action-icon-table trash-icon-media"
              onClick={() => handleDelete(record)}>
              <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* Conditional Rendering for EditProjectForm */}

      <div>
        <div className="row mt-3">
          <div className="col-9 brand-title">
            <h1 className="job-title">Style</h1>
            <p>{allStyle.length} entries Found </p>
          </div>

          <div className="col-3 breadcrumb-link">
            {/* <CreateProjectModel /> */}
            <Breadcrumb
              items={[
                {
                  title: <a href="">Material Library</a>,
                },
                {
                  title: "Style",
                },
              ]}
            />
          </div>
        </div>

        <div className="row table-input">
          <div className="col-6 search-bar">
            <Search
              placeholder="Search style"
              //  onSearch={handleSearch}
              //  enterButton
              // style={{ marginBottom: '20px' }}
            />
          </div>

          <div className="col-6">
            <StyleCreateFrom newAddBrand={handleNewBrand} />
          </div>
        </div>

        <Card className="mb-5 card-table-category">
          {allStyle && allStyle.length == 0 ? (
            <Loading />
          ) : (
            <Table
              columns={columns}
              dataSource={allStyle?.map((item) => ({ ...item, key: item.id }))}
              // onChange={onChange}
              // showSorterTooltip={{ target: 'sorter-icon' }}
              pagination={{
                position: ["topRight", "bottomRight"], // Positions pagination at the top and bottom
                defaultPageSize: 200,

                showTotal: (total) => (
                  <span className="ant-pagination-total-text ">
                    Total{" "}
                    <span className="total-page ">
                      {" "}
                      <i> {total} </i>
                    </span>{" "}
                    items
                  </span>
                ),
                showSizeChanger: true, // Show page size changer
                pageSizeOptions: [
                  "100",
                  "200",
                  "300",
                  "400",
                  "500",
                  "600",
                  "1000",
                ], // Page size options
              }}
            />
          )}
        </Card>
      </div>

      {/*   delete brand )} */}

      {isStyleDelete && deleteStyleId != undefined && (
        <DeleteStyleApi
          deleteStyleId={deleteStyleId}
          resetDeleteStyle={handleStyleResetDelete}
        />
      )}

      {/*  add new brand */}

      {isStyleAddApi && newstyle != null && (
        <AddStyleApi resetAdd={handleResetNewStyle} addBrandData={newstyle} />
      )}
    </>
  );
};

export default StyleTable;
