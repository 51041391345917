import Drawer from "./Drawer";
import RightBar from "./RightBar";
import BottomBar from "./BottomBar";
import { ControlsProvider } from "./contexts/ControlsProvider";

 const Canvas3DHome=()=> {
  return (
    <main 
    className="w-full h-full"
    >
      <section className="w-full h-full flex flex-col">
        <div className="flex flex-grow">
          <ControlsProvider>
            <div className="flex-grow cursor-grab">
              <Drawer />
            </div>

            <div className="w-1/4 h-full bg-gray-200">
              <RightBar />
            </div>
          </ControlsProvider>
        </div>

        <div className="w-full h-24 bg-gray-300">
          <BottomBar />
        </div>
      </section>
    </main>
  );
}

export default Canvas3DHome