import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SegmentsTable from '../../page/segment/SegmentsTablePage';
import SegmentsTablePage from '../../page/segment/SegmentsTablePage';
import SegmentEdit from '../../page/segment/SegmentEdit';



const SegmentsRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SegmentsTablePage />} />
       <Route path="/edit" element={<SegmentEdit />} /> 
    </Routes>
  );
};

export default SegmentsRoutes;

