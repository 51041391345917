import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CatBrandStyleSwatchModel } from "../../Model/material/CatBrandStyleSwatchModel";


interface SwatchesStates {
    doorSwatches:CatBrandStyleSwatchModel[]
    windowSwatches:CatBrandStyleSwatchModel[]
    roofSwatches:CatBrandStyleSwatchModel[]
    garageSwatches:CatBrandStyleSwatchModel[]
    gutterSwatches:CatBrandStyleSwatchModel[]
    wallSwatches:CatBrandStyleSwatchModel[]
    trimSwatches:CatBrandStyleSwatchModel[]
   
    
}

const initialState: SwatchesStates = {
doorSwatches:[],
windowSwatches:[],
roofSwatches:[],
garageSwatches:[],
gutterSwatches:[],
wallSwatches:[],
trimSwatches:[],
};

const SwatchesSlice = createSlice({
    name: "swatches",
    initialState,
    reducers: {
       addDoorSwatches:(state,action)=>{
            state.doorSwatches=action.payload
       },
       addWindowSwatches:(state,action)=>{
        state.windowSwatches=action.payload
       },
       addRoofSwatches:(state,action)=>{
        state.roofSwatches=action.payload
       },
       addGarageSwatches:(state,action)=>{
        state.garageSwatches=action.payload
        },
        addGutterSwatches:(state,action)=>{
            state.gutterSwatches=action.payload
        },
        addWallSwatches:(state,action)=>{
            state.wallSwatches=action.payload
        },
        addTrimSwatches:(state,action)=>{
            state.trimSwatches=action.payload
        },
        resetDoorSwatches:(state)=>{
            state.doorSwatches=[]
        },  
        resetWindowSwatches:(state)=>{
            state.windowSwatches=[]
        },  
        resetRoofSwatches:(state)=>{
            state.roofSwatches=[]
        },
        resetGarageSwatches:(state)=>{
            state.garageSwatches=[]
        },
        resetGutterSwatches:(state)=>{
            state.gutterSwatches=[]
        },  
        resetWallSwatches:(state)=>{
            state.wallSwatches=[]
        },
        resetTrimSwatches:(state)=>{
            state.trimSwatches=[]
        },  

    }
}
)

export const {
    addDoorSwatches,resetDoorSwatches,
    addWindowSwatches,resetWindowSwatches,
    addRoofSwatches,resetRoofSwatches,
    addGarageSwatches,resetGarageSwatches,
    addGutterSwatches,resetGutterSwatches,
    addWallSwatches,resetWallSwatches,
    addTrimSwatches,resetTrimSwatches}=SwatchesSlice.actions;
export const getDoorSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.doorSwatches;
export const getWindowSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.windowSwatches;
export const getRoofSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.roofSwatches;
export const getGarageSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.garageSwatches;
export const getGutterSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.gutterSwatches;
export const getWallSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.wallSwatches;
export const getTrimSwatches=  (state:{swatches:SwatchesStates})=>state.swatches.trimSwatches;
export default SwatchesSlice.reducer