
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OptionSvgModel, SvgImageModel } from "../../Model/svgModel/SvgModel";


interface SvgImageStates {
    svgImageArray:SvgImageModel[]
    svgPhotoArray:SvgImageModel[]
     svgOption:OptionSvgModel
    
  
}

const initialState: SvgImageStates = {
    svgImageArray:[],
    svgPhotoArray:[],
    svgOption:{}

};

const SvgImageSlice = createSlice({
    name: "svgImage",
    initialState,
    reducers: {
      resetSVGImage:(state)=>{
        state.svgImageArray=[]
        state.svgPhotoArray=[]
        state.svgOption={}
      },
      addSvgImage:(state,action)=>{
        const {swatchUrl,selectedGroup}= action.payload
           state.svgImageArray.push({
            swatchUrl:swatchUrl,
            selectedGroup:selectedGroup
           })
      },
      
      resetSvgImage:(state)=>{
        state.svgImageArray=[]
      },
      addSvgPhotoImage:(state,action)=>{
        const {swatchUrl,selectedGroup}= action.payload
           state.svgPhotoArray.push({
            swatchUrl:swatchUrl,
            selectedGroup:selectedGroup
           })
      },
      
      resetSvgPhotImage:(state)=>{
        state.svgPhotoArray=[]
        state.svgOption.isOpenModal=false
        state.svgOption.svgModelName=""
      },
      addSvgOption:(state,action)=>{
        const {isOpenModal,svgModelName}= action.payload
        state.svgOption.isOpenModal=isOpenModal
        state.svgOption.svgModelName= svgModelName
      },
      addSvgOpenVCImage:(state,action)=>{
        const {SwatchImage}= action.payload
        // state.svgOption.imagePath=imagePath
        // state.svgOption.segmentname=segmentname
        state.svgOption.SwatchImage=SwatchImage
        
      },
      resetSvgModel:(state)=>{
        state.svgOption={}
      },

      
    }
}
)

export const {addSvgImage,resetSvgImage,
  addSvgPhotoImage,resetSvgPhotImage,
  addSvgOption,resetSvgModel,addSvgOpenVCImage,
  resetSVGImage

}=SvgImageSlice.actions;
export const getSvgImage=  (state:{svgImage:SvgImageStates})=>state.svgImage.svgImageArray
export const getSvgPhotImage=  (state:{svgImage:SvgImageStates})=>state.svgImage.svgPhotoArray
export const getSvgOption=  (state:{svgImage:SvgImageStates})=>state.svgImage.svgOption
export default SvgImageSlice.reducer