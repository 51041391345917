import React from 'react'
import AdminHome from '../../module/admin/HomeAdmin'

const AdminPage = () => {
  return (
    <div  style={{marginTop:50}}>
        <AdminHome/>
    </div>
  )
}

export default AdminPage