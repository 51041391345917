import React from 'react'
import AdminJobTable from '../../../module/admin/jobAdmin/AdminJobTable'

const AdminJobTablePage = () => {
  return (
    <div style={{marginTop:50}}>
        <AdminJobTable/>
    </div>
  )
}

export default AdminJobTablePage