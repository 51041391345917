import React, { useEffect, useRef, useState } from 'react'
import { CreateFolderS3Bucket } from '../../../../api/folderApi/FolderApi'
import GetAllS3BucketFolder from '../GetAllS3BucketFolder'
import { ErrMess } from '../../../../Model/mediaModel/MediaModel'


type Props={
    resetCreateFolder:()=>void
    folderName:string
}
const CreateFolderApi = ({resetCreateFolder,folderName}:Props) => {
    const [isAllFolder, setIsAllFolder]= useState<boolean>(false)
    const isApi= useRef(true)
    useEffect(()=>{
        if(folderName!="" && isApi.current){
            isApi.current= false
            createFolderInS3Bucket(folderName)
        }
    },[folderName])


    const createFolderInS3Bucket=async(folderName:string)=>{

        try{
            const response= await CreateFolderS3Bucket(folderName)
            // console.log(response)
            if(response && response.status==200){
                setIsAllFolder(true)
            }

        }catch(err){
            const error=err as ErrMess
            // alert(error.response.data)
              const mess=error.response.data.message
              alert(mess)
              isApi.current= true
              resetCreateFolder()
        }
    }

   const handleResetS3Folder=()=>{
        setIsAllFolder(false)
        isApi.current= true
        resetCreateFolder()
       }
  return (
    <div>
        
        { 
        isAllFolder &&
         <GetAllS3BucketFolder
          resetMain={handleResetS3Folder}
          isAllFolder={isAllFolder}
          />}

          
    </div>
  )
}

export default CreateFolderApi