import React, { useEffect } from 'react';
import { hoverChange } from './Sam';
import Mouse from "../../samImage/icon/mouse.png"

type Props= {
    title: string;
    description: string;
}

const MenuComponent = ({ title, description }:Props) => {
    useEffect(() => {
        const handleClick = (event: React.MouseEvent) => {
            const target = event.currentTarget;
            const children = target.children;

            if (target.classList.contains("select-hover")) {
                target.classList.add("hover");
                target.classList.remove("select-hover");
                hoverChange(false);
            } else {
                target.classList.add("select-hover");
                target.classList.remove("hover");
                hoverChange(true);
            }
        };

        return () => {
            // Cleanup function if necessary
        };
    }, []);

    return (
        <div className="box button-group hover" 
        // name="menu_button"
        // onClick={handleClick}
         >
            <div className="flex menu-title">
                <div>
                    <img className="menu-icon" src={Mouse} alt="Mouse Icon" style={{width:50}} />
                </div>
                <div>
                    <div><p className="menu-inner">{title}</p></div>
                </div>
            </div>
            <div>
                <p className="info-text">{description}</p>
            </div>
            <div className="parameters"></div>
        </div>
    );
};

export default MenuComponent;
