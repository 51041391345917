import React, { useEffect, useRef, useState } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

type Props = {
  position: number[];
  controls: {
    doorColor: string;
    windowColor: string;
    wallsColor: string;
    roofColor: string;
    doorType: string;
    windowType: string;
    wallsType: string;
    roofType: string;
  };
};

const ProjectOne: React.FC<Props> = (props) => {
  const { nodes, materials } = useGLTF('/project-second-transformed.glb') as any;

  const doorRef = useRef<THREE.Mesh>(null);
  const windowRef = useRef<THREE.Mesh>(null);
  const wallsRef = useRef<THREE.Mesh>(null);
  const roofRef = useRef<THREE.Mesh>(null);

  const [materialStates, setMaterialStates] = useState<{
    doorMaterial?: THREE.Material;
    windowMaterial?: THREE.Material;
    wallsMaterial?: THREE.Material;
    roofMaterial?: THREE.Material;
    doorOriginalColor?: THREE.Color;
    windowOriginalColor?: THREE.Color;
    wallsOriginalColor?: THREE.Color;
    roofOriginalColor?: THREE.Color;
  }>({
    doorMaterial: undefined,
    windowMaterial: undefined,
    wallsMaterial: undefined,
    roofMaterial: undefined,
    doorOriginalColor: undefined,
    windowOriginalColor: undefined,
    wallsOriginalColor: undefined,
    roofOriginalColor: undefined,
  });

  // Function to set material and color
  const setMaterialAndColor = (
    ref: React.RefObject<THREE.Mesh>,
    materialKey: string,
    type: keyof typeof materialStates,
    color: string,
    originalColorKey: keyof typeof materialStates
  ) => {
    if (ref.current) {
      const originalMaterial = materials[materialKey];
      const clonedMaterial = originalMaterial.clone();
      ref.current.material = clonedMaterial;

      setMaterialStates((prevState) => ({
        ...prevState,
        [type]: clonedMaterial,
        [originalColorKey]: originalMaterial.color.clone(),
      }));

      // Set the color of the cloned material
      if (color !== 'Standard') {
        (clonedMaterial as THREE.MeshStandardMaterial).color.set(color);
       }
       //else if (prevState[originalColorKey]) {
      //   (clonedMaterial as THREE.MeshStandardMaterial).color.set(prevState[originalColorKey]);
      // }
    }
  };

  // Set materials and colors on mount
  useEffect(() => {
    if (materials) {
      setMaterialAndColor(doorRef, 'door_m', 'doorMaterial', props.controls.doorColor, 'doorOriginalColor');
      setMaterialAndColor(windowRef, 'window_big_m', 'windowMaterial', props.controls.windowColor, 'windowOriginalColor');
      setMaterialAndColor(wallsRef, 'walls.png', 'wallsMaterial', props.controls.wallsColor, 'wallsOriginalColor');
      setMaterialAndColor(roofRef, 'roof.png', 'roofMaterial', props.controls.roofColor, 'roofOriginalColor');
    }
  }, [materials]);

  // Update material color on control change
  useEffect(() => {
    if (materialStates.doorMaterial) {
      (materialStates.doorMaterial as THREE.MeshStandardMaterial).color.set(
        props.controls.doorColor === 'Standard'
          ? materialStates.doorOriginalColor!
          : props.controls.doorColor
      );
    }

    if (materialStates.windowMaterial) {
      (materialStates.windowMaterial as THREE.MeshStandardMaterial).color.set(
        props.controls.windowColor === 'Standard'
          ? materialStates.windowOriginalColor!
          : props.controls.windowColor
      );
    }

    if (materialStates.wallsMaterial) {
      (materialStates.wallsMaterial as THREE.MeshStandardMaterial).color.set(
        props.controls.wallsColor === 'Standard'
          ? materialStates.wallsOriginalColor!
          : props.controls.wallsColor
      );
    }

    if (materialStates.roofMaterial) {
      (materialStates.roofMaterial as THREE.MeshStandardMaterial).color.set(
        props.controls.roofColor === 'Standard'
          ? materialStates.roofOriginalColor!
          : props.controls.roofColor
      );
    }
  }, [props.controls, materialStates]);

  return (
    <group dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_4.geometry}
        material={materials['pipe.png']}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_5.geometry}
        material={materials['porch.png']}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_6.geometry}
        material={materials['foundation.png']}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        ref={wallsRef}
        castShadow
        receiveShadow
        geometry={nodes.Object_7.geometry}
        material={materialStates.wallsMaterial}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        ref={roofRef}
        castShadow
        receiveShadow
        geometry={nodes.Object_8.geometry}
        material={materialStates.roofMaterial}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_9.geometry}
        material={materials['wood.png']}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_10.geometry}
        material={materials['room.png']}
        rotation={[Math.PI / 2, 0, 0]}
        scale={2.656}
      />
      <mesh
        ref={doorRef}
        castShadow
        receiveShadow
        geometry={nodes.Object_12.geometry}
        material={materialStates.doorMaterial}
        position={[-0.782, 2.923, 9.956]}
        scale={0.025}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_20.geometry}
        material={materials['Material.001']}
        position={[-9.97, 10.362, 8.62]}
        scale={[4.709, 7.01, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_22.geometry}
        material={materials.bed_m_inst}
        position={[10.259, 2.833, 3.443]}
        scale={0.025}
      />
      <mesh
        ref={windowRef}
        castShadow
        receiveShadow
        geometry={nodes.Object_25.geometry}
        material={materialStates.windowMaterial}
        position={[10.093, 7.054, 13.394]}
        rotation={[-Math.PI, 0.003, -Math.PI]}
        scale={0.025}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_31.geometry}
        material={materials.blinds_m_Inst}
        position={[10.156, -0.379, 12.953]}
        scale={0.025}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_33.geometry}
        material={materials.switch_m_Inst}
        position={[4.234, 6.099, 7.289]}
        rotation={[-Math.PI, 1.509, -Math.PI]}
        scale={0.04}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_35.geometry}
        material={materials.lampholder_m}
        position={[10.212, 10.021, 5.107]}
        scale={0.025}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_38.geometry}
        material={materials.bulb_base_m}
        position={[10.238, 9.798, 5.127]}
        scale={0.025}
      />
    </group>
  );
};

export default ProjectOne;
