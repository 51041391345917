import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { InferenceSession, Tensor } from "onnxruntime-web";
import MenuComponent from './Component';
import Callary from "../../samImage/icon/callary.png";
import Refresh from "../../samImage/icon/refresh.png";
import * as ort from 'onnxruntime-web';
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
} from "use-file-picker/validators";

import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../slice/messageToast/ToastSlice';
import AppContext from './new/useContext/createContext';
import { modelData, setParmsandQueryModel } from './new/modelApi/ModelApi';
import { onnxMaskToImage } from './new/helper/maskUtils';
import npyjs from "npyjs";
import LoadingModal from './new/modelApi/LoadingModal';
import Stage from './new/Stage';
import { useFilePicker } from 'use-file-picker';
import { handleImageScale } from './new/helper/scaleHelper';
import { addImageData, getClick, getClicks, getImageData, getPredMask, getpredMasksHistory, setImage, setMaskImg, setPredMask, setPredMasks } from '../../slice/samModel/SamModelSlice';
import { getProjectId } from '../../slice/userJobSlice/UserJobSlice';
import { getUserImageFile, getUserNewProject } from '../../slice/user/UserProject';
import { getUserLogin } from '../../slice/user/UserSlice';
// const ort = require("onnxruntime-web");

const IMAGE_PATH = "";
//const IMAGE_PATH = "/img/default.jpg";
//const IMAGE_EMBEDDING = "/npy/image_embedding.npy";
const IMAGE_EMBEDDING = "";
const MODEL_DIR = "/decoder/sam_onnx_quantized.onnx"
// const ModelDir=process.env.REACT_APP_MODEL_DIR
 // Function to convert image data URL to Base64
const convertToBase64 = async (dataUrl:string) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(dataUrl); // dataUrl is already Base64 if it is from file content.
    } catch (error) {
      reject(error);
    }
  });
};

const IndexPage: React.FC = () => {
  const dispatch = useDispatch();
  const canvasRef = useRef<fabric.Canvas | null>(null);
  const [model, setModel] = useState<typeof InferenceSession | null>(null);
  const [tensor, setTensor] = useState<typeof Tensor | null>(null);
  const [hasClicked, setHasClicked] = useState<boolean>(false);
  const [modelScale, setModelScale] = useState<{ height: number; width: number; samScale: number } | null>(null);
   const getImageDatas= useSelector(getImageData)
   const getProjectIdss= useSelector(getProjectId)
    const imageUpload= useRef(true)

    console.log("MODEL_DIR",MODEL_DIR)

   const { openFilePicker, filesContent,clear } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new FileTypeValidator(["jpg", "png"]),
      new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
    ],
  });

  useEffect(() => {
    // Initialize the ONNX model
    const initModel = async () => {
      try {
        if (!MODEL_DIR) return console.log("MODEL_DIR is not defined"); // Guard clause for MODEL_DIR
        const model = await InferenceSession.create(MODEL_DIR);
        setModel(model);
      } catch (e) {
        console.error("Error initializing model:", e);
      }
    };
    initModel();

    // Load the image
    const url = new URL(IMAGE_PATH, window.location.origin);
    // console.log("url", url);
    //loadImage(url.href, url.pathname.split("/").pop()!, true);
  }, []);

   
  useEffect(() => {
    if (filesContent.length > 0 &&
       imageUpload.current 
        ) {
      imageUpload.current= false
      const file = filesContent[0].content;
      const name = filesContent[0].name;

      handleResetState();
      loadImage(file, name, false,600);
      clear();
    }else {
      imageUpload.current= true
    }
  }, [filesContent,getProjectIdss]);
  
  // const getProjectIds= useSelector(getProjectId)
  const loadImage = async (dataUrl: string, imageName: string, init: boolean,projectId:number) => {
    // console.log("Loading image...");
    try {
      const img = new Image();
      img.src = dataUrl;
      img.onload = async () => {
        const { height, width, samScale } = handleImageScale(img);
        // console.log("Image dimensions:", height, width, samScale);
        setModelScale({ height, width, samScale });
        img.width = width;
        img.height = height;
        const base64Image = await convertToBase64(dataUrl);
        dispatch(addImageData({
          width:width,
          height:height
        }))
        dispatch(setImage(base64Image));
        // dispatch(setImage(img));

        if (init) {
          try {
            const embedding = await loadNpyTensor(IMAGE_EMBEDDING, "float32");
           // console.log("Embedding loaded:", embedding);
            setTensor(embedding);
          } catch (e) {
            handleResetState();
            console.error("Error loading embedding:", e);
          }
        } else {
         // console.log("Preparing to query model with image...");
          setParmsandQueryModel({
            width: img.width,
            height: img.height,
            uploadScale: samScale,
            imgData: img,
            handleSegModelResults,
            imgName: imageName,
            projectId:projectId
          });
        }
      };
    } catch (error) {
      console.error("Error loading image:", error);
    }
  };

  const handleSegModelResults = ({ tensor }: { tensor: typeof Tensor }) => {
    // console.log("Segmentation model results received.", tensor);
    setTensor(tensor);
  };


  const loadNpyTensor = async (tensorFile: string, dType: string) => {
    const npLoader = new npyjs();
    const npArray = await npLoader.load(tensorFile);
    // console.log("Loaded tensor shape:", npArray.shape); // Log the shape of the loaded tensor
  
    if (npArray.shape.length !== 4) {
      throw new Error(`Invalid shape for image_embeddings tensor. Expected 4 dimensions, got ${npArray.shape.length}`);
    }
  
    return new ort.Tensor(dType, npArray.data, npArray.shape);
  };

  const handleResetState = () => {
    // console.log("Resetting state...");
    setHasClicked(false);
    // Resetting other states if necessary
  };

  const clicks = useSelector(getClicks);
  const getpredMasks = useSelector(getPredMask);
  const getpredMasksHistorys = useSelector(getpredMasksHistory);
   const [maskImage,setMaskImage]= useState<HTMLImageElement|null>(null)
   const [maskImageString,setMaskImageString]= useState<string|null>(null)
  // Run the ONNX model every time clicks have changed
  useEffect(() => {
    runONNX();
  }, [clicks, hasClicked, model, tensor, modelScale, getpredMasks]);

  const runONNX = async () => {
   
    try {
      if (!model || !clicks || !tensor || !modelScale) return;

      // Prepare the model input in the correct format for SAM.
      const feeds = modelData({
        clicks,
        tensor,
        modelScale,
        last_pred_mask: getpredMasks,
      });
      
      // console.log("feeds", feeds);
      if (feeds) {
// Run the SAM ONNX model with the feeds returned from modelData()
const results = await model.run(feeds);
const output = results["masks"]; // model.outputNames[0] = "masks"

if (hasClicked) {
  const pred_mask = results["low_res_masks"]; // model.outputNames[2] = "low_res_masks"
  // console.log("pred_mask", pred_mask);
  setPredMask(pred_mask);
  if (!getpredMasksHistorys) {
    dispatch(setPredMasks(pred_mask))
   // setPredMasks([...(getpredMasks || []), pred_mask]);
  }
}

// The predicted mask returned from the ONNX model is an array which is
// rendered as an HTML image using onnxMaskToImage() from maskUtils.tsx.
 //console.log("output",output)
const image=onnxMaskToImage(output.data, output.dims[2], output.dims[3])
//console.log(",maskimage",image)
setMaskImage(image)

const base64MaskImage=await convertToBase64(image.src) as string

//console.log(",base64MaskImage",base64MaskImage)
setMaskImageString(base64MaskImage)
dispatch(setMaskImg(base64MaskImage))
      }
    }
     catch (e) {
      console.error("Error running ONNX model:", e);
    }
  };
  
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex', height: 'calc(100% - 150px)' }}>
      <div className="menu">
          <div className="px-4">
            <div className="gx-5">
              <div className="col" style={{ width: 300 }}>
                <div className="p-3 border bg-light content-layer" id="menu-components">
                  <div className="flex box button-group">
                    <img className="menu-icon" src={Callary} alt="Callary" style={{ width: 50 }} />
                    <div id="fileButton" style={{ marginRight: '15px' }}>
                      <label>Upload</label>
                    </div>
                    <img className="menu-icon" src={Refresh} alt="Refresh" style={{ width: 50 }} />
                    <div id="reset">
                      <label>Reset</label>
                    </div>
                  </div>
                  <MenuComponent
                    title="Click & Hover"
                    description="You can see the status of the segmentation by hovering over it. If you click on an object more than once, you can refine it further."
                  />
                  <button
                    className="bg-purple-900 text-white hover:bg-blue-400 font-bold py-2 px-4 rounded"
                    onClick={() => openFilePicker()}
                  >
                    Select image{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: '100%', width: '100%', margin: '0 0 0 30px' }} id="frame_main">
        <div id="root" className="w-full h-full"><div className="container py-10 px-10 min-w-full flex flex-col items-center">
            <Stage
              hasClicked={hasClicked}
              setHasClicked={setHasClicked} 
               maskImage={maskImage}
               maskImageString ={maskImageString} 
              />
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
