import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import "../ShowBrands.css";
import { Image } from "antd";
import CreateProjectFrom from "../../../createproject/CreateProjectFrom";
import Search from "antd/es/transfer/search";
import { JobModel } from "../../../../Model/Job/JobModel";
// import EditJobs from .EditJobs";
// import JobCreateProject from "./JobCreateProject";
import { Breadcrumb } from 'antd';
import GetAllJobs from "../jobapi/GetAllJobs";

import GetJob from "../jobapi/GetJob";
import { useNavigate } from "react-router-dom";
import Loading from "../../../loading/Loading";
import { getLoading, startLoading, stopLoading } from "../../../../slice/loading/LoadingSlice";
import { getAllJobData } from "../../../../slice/Admin/JobSlice/JobSlice";
import { addJobData } from "../../../../slice/userJobSlice/UserJobSlice";
import GetJobbase64 from "../jobapi/GetJobbase64";

 const path=  process.env.REACT_APP_S3Bucket


const JobsTable = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(false);
  const [isEditFrom, setIsEditForm] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const getLoadings = useSelector(getLoading)
  const dispatch = useDispatch()
  const [jobAllTables, setJobAllTables] = useState<JobModel[]>([])
  const getAllJob = useSelector(getAllJobData)
  const navigate = useNavigate()
  // get all Jobs
  useEffect(() => {
    if (getAllJob &&
      getAllJob.length > 0) {
      setJobAllTables(getAllJob)
    }
  }, [getAllJob])

  //  view a job 
  const [jobId, setJobId] = useState<number | null | undefined>()
  const [isJob, setIsJob] = useState<boolean>(false)
  const [isJobImage, setIsJobImage] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string | null>()
  const handleViewJob = (job: JobModel) => {
    dispatch(startLoading())
    // console.log("Job record", job)
    setIsJobImage(true)
    setImageUrl(job.image)
    dispatch(addJobData(job))
    if (job && job.id) {
      setJobId(job.id)
      // setIsJob(true)
    }
  }
  const handleResetImageUrl = (mess: string) => {
    if (mess === "success") {
      setIsJobImage(false)
      setImageUrl(null)
      dispatch(stopLoading())
      navigate(`/project/view/${jobId}`)
    } else if ("fail") {
      setIsJobImage(false)
      setImageUrl(null)
      dispatch(stopLoading())
      alert("Error in getting Image 64")
    }
  }



  const columns: TableColumnsType<JobModel> = [

    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1 }}>
          <Input
            placeholder="Search Id"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });

            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.current?.select();
          });
        }
      },
      onFilter: (value, record) => {
        if (!record.id) {
          return false;
        }

        return record.id.toString() === value.toString();
      },
      filterSearch: true,
    },

    // {
    //   title: 'Title',
    //   dataIndex: 'title',
    //   key: 'title',
    //   filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
    //     <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1, }}>
    //       <Input
    //         ref={searchInput}

    //         placeholder="Search sku"
    //         value={selectedKeys[0]}
    //         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //         onKeyUp={(e) => {
    //           confirm({ closeDropdown: false });

    //         }}
    //         style={{ width: 188, marginBottom: 8, display: "block" }}
    //       />
    //     </div>
    //   ),
    //   onFilterDropdownVisibleChange: (visible) => {
    //     if (visible) {
    //       setTimeout(() => {
    //         setTimeout(() => searchInput.current?.select(), 1000);
    //       });
    //     }
    //   },
    //   onFilter: (value, record) => {
    //     let check = false;
    //     const valUpper = value.toString().toUpperCase();
    //     const valLower = value.toString().toLowerCase();

    //     if (record && record.title) {
    //       check = record.title.includes(valUpper) || record.title.includes(valLower);
    //     }


    //     return check;
    //   },
    //   filterSearch: true,
    // },
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      render: (value, record) => {
   const ImagePath= `${path}/projects/${record.project_id}/${value}`
  //  console.log("valye", value)
  //  console.log("record", record)
    
  
        return <Image width={50} src={`${ImagePath}`} />;
      },
    },
    {
      title: 'Project Id',
      dataIndex: 'project_id',
      key: 'project_id',
      render: (value, record) => {
        return (
          <span>{record.project_id}</span>
        )
      }
    },


    {
      title: 'Job View',
      dataIndex: 'job_view',
      key: 'job_view',
    },

    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => {
        return (
         
<div className="d-flex action">
<div className="action-icon-table"    >
  <i className="bi bi-pencil-square mx-0"></i>
</div>
<div className="action-icon-table">
  <i className="bi bi-copy mx-0"></i>
</div>
<div className="action-icon-table" onClick={()=>handleViewJob(record)}>
  <i className="bi bi-box-arrow-up-right mx-0"></i>
</div>
<div className="action-icon-table trash-icon-media"  >
  <i className="bi bi-trash3 mx-0"></i>
</div>
</div>




        )
      },
    },
  ]
  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSelectAllChange = () => {
    const newCheckedItems: { [key: number]: boolean } = {};

    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };


  const handleEditFrom = () => {
    //navigate("jobs/edit")
  };



  return (
    <>



      <div>

        <div className="row mt-3">
          <div className="col-9 brand-title">
            <h1 className='job-title'>Job</h1>
            <p className="m-0">0 entries Found</p>
          </div>
          <div className="col-3 breadcrumb-link">
            <Breadcrumb
              items={[
                {
                  title: <a href="">Project</a>,
                },
                {
                  title: "Job",
                },
              ]}
            />

          </div>
        </div>
        <div className="row table-input">
          <div className="col-6 search-bar">
            <Search
              placeholder="Search job"
            //  onSearch={handleSearch}
            //  enterButton
            // style={{ marginBottom: '20px' }}
            />
          </div>

          <div className="col-6">
            {/* <JobCreateProject /> */}
          </div>
        </div>


        <Card className="mt-4">



          {jobAllTables &&
            jobAllTables.length === 0 ?
            (<Loading />) :
            (<Table
              columns={columns}
              dataSource={jobAllTables?.map((item) => ({ ...item, key: item.id }))}
            // onChange={onChange}
            // showSorterTooltip={{ target: 'sorter-icon' }}
            />)
          }
        </Card>

        {getLoadings && <Loading />}
      </div>

      <GetAllJobs />

      {/* get particular Job */}
      {/* { isJob &&
  jobId!=null &&
   <GetJob
   jobId={jobId}
   resetJobs={handleResetJob}
   />} */}

      {isJobImage &&
        imageUrl &&
        <GetJobbase64
          resetImageUrl={handleResetImageUrl}
          imageUrl={imageUrl}
        />}

    </>
  );
};

export default JobsTable;
