import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // import Link from react-router-dom
import "./footer.scss";
const Footer = () => {
  return (
    <footer className="pt-3 pb-1 border-top">
      <div className="container">
        <div className="copywright text-center">
            ©2024 DZINLY®. ALL RIGHTS RESERVED.
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <nav className="navbar navbar-expand-lg bg-white py-0">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/" className="nav-link active">Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">About Us</Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">Try It Out</Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">Pricing</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
