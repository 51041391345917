import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Projects from '../../page/project/Projects';
import ProjectIds from '../../page/project/ProjectIds';
import CreateProject from '../../page/createproject/CreateProject';


const CustomerRoutes = () => {
  return (
    <Routes>
      <Route path="/project" element={<Projects />} />
      <Route path="/create-project" element={<CreateProject />} />

    </Routes>
  );
};

export default CustomerRoutes;
