import React, { useEffect, useState } from 'react'
import { GenAiAttributesModel, OptionJDetails, PromptModel } from '../../../../Model/genAiAttributes/GenAiAttributes'
import { useDispatch } from 'react-redux'
import GetPrompt from '../getPrompt/GetPrompt'
import { addPrompvalue } from '../../../../slice/genAi/GenAiAttributesSlice'


type Props={
    allGenAttrFeature:GenAiAttributesModel
   
}
const GenAiFeatures = ({allGenAttrFeature}:Props) => {

    const [selectedFeaturesValues, setSelectedFeaturesValues] = useState<PromptModel>({});
    const[promptData, setPromptData]= useState<string|null>(null)
    const [genType, setGenType]= useState<string>("feature")
      const dispatch= useDispatch()
    
    const handleDropDown=(val:string, itemKey:string)=>{
                const title=itemKey
                if(title && val){
                    dispatch(addPrompvalue({
                        promptKey:title,
                         promptvalue : val
                    }))
                 setSelectedFeaturesValues((prev)=>({
                     ...prev,
                     [title]:val
                    }))
                }
               
             }
 
   

 
  return (
    <>
        <div className='px-4'>
                        <div className='customize-sec pt-3'>
                            {allGenAttrFeature &&
                                (Object.keys(allGenAttrFeature) as Array<keyof GenAiAttributesModel>).map(item => {
                                    const value = allGenAttrFeature[item] as OptionJDetails

                                    return (
                                        <>
   
                                            {value.status && value.is_featured &&
                                                <div className='pb-3'>
                                                    <h6 className='fw-medium '>
                                                        {value.title}
                                                        <span data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Information about house angles.">
                                                            <i className="bi bi-question-circle ps-1"></i>
                                                        </span>
                                                    </h6>
                                                    <div className="dropdown w-100 ">   
                                                              <button className="btn btn-primary dropdown-toggle drop-btn rounded-1"
          
                                                               type="button"
                                                               data-bs-toggle="dropdown"
                                                               aria-expanded="false"
                                                               style={{ width: "100%", height:"38px", textAlign:"left" }}
                                                               >
                                                           
                                                             {selectedFeaturesValues && Object.keys(selectedFeaturesValues).map(gen=>(
                                 
                                                                <div>
                                                                    {gen==item?(<span className="text-start">{selectedFeaturesValues[gen]}</span>):null}
                                                                </div>
                                                                ))
                                                                    } 
                                                             
                                                            <span className="dropdown-toggle-icon"></span>      
                                                        </button>

                                                        <ul className="dropdown-menu w-100">
                                                            {value && value.options && value.options.length > 0 &&
                                                                value.options.map(val => (
                                                                    <li><a className="dropdown-item" 
                                                                    onClick={()=>handleDropDown(val,item)}
                                                                    >{val}</a></li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                })
                            }
                        </div>

                        <div className='slider-intervention pt-3'>
                            <h6 className='fw-medium '>
                                AI Intervention
                                <span data-bs-toggle="popover" data-bs-trigger="hover focus"
                                 data-bs-content="Information about house angles.">
                                    <i className="bi bi-question-circle ps-1"></i>
                                </span>
                            </h6>


                        </div>

             
                        </div>


                         
              
    </>
  )
}

export default GenAiFeatures