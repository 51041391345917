import React from 'react'
import CanvasHtml from '../../module/newCanvasHtml/CanvasHtml'

const NewHtmlCanavasPage = () => {
  return (
    <div>
        <CanvasHtml/>
    </div>
  )
}

export default NewHtmlCanavasPage