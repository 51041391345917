import { Card } from 'antd';
import React, { useEffect, useState } from 'react';

import { MaterialBrandModel } from '../../../Model/material/MaterialBrandModel';
import { getMaterialBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEditSwatchStyle, resetEditBrand } from '../../../slice/materialSlice/MaterialStyleSlice';
import { Button, FormLabel, Input, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Radio, RadioGroup, Select, Textarea } from '@chakra-ui/react';
import { MaterialStyleModel } from '../../../Model/material/MaterialStyleModel';
import { useDispatch } from 'react-redux';
import "./EditStyleForm.css"
type Props = {
    StyleupdatData: (data: MaterialStyleModel) => void;
};

const EditStyleForm = ({ StyleupdatData }: Props) => {
  const dispatch= useDispatch();

    const [styleId, setStyleId] = useState<number | undefined>(undefined);
    const [title, setTitle] = useState<string>("");
    const [slug, setSlug] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [sort_order, setSort_order] = useState<number | undefined>(undefined);
    const [status, setStatus] = useState<string>("0");

    const getMaterialBrands = useSelector(getMaterialBrand) as MaterialBrandModel[];
    const navigate = useNavigate();
    const getEditSwatchStyles = useSelector(getEditSwatchStyle);

    useEffect(() => {
        if (getEditSwatchStyles && getEditSwatchStyles.id && getMaterialBrands) {
            setStyleId(getEditSwatchStyles.id);
            setTitle(getEditSwatchStyles.title || "");
            setSlug(getEditSwatchStyles.slug || "");
            setDescription(getEditSwatchStyles.description || "");
            setSort_order(getEditSwatchStyles.sort_order);
            setStatus(getEditSwatchStyles.status?.toString() || "0");
        }
    }, [getEditSwatchStyles, getMaterialBrands]);

    const handleBack = () => {
        dispatch(resetEditBrand());
        navigate("/dashboard/swatch-styles");
    };

    const handleSortOrder = (val: string) => {
        setSort_order(parseInt(val));
    };

    const handleStatus = (val: string) => {
        setStatus(val);
    };

    const handleCancel = () => {
        // dispatch(resetEditBrand());
        // navigate("/dashboard/swatch-brands");
    };

    const handleSave = () => {
        const data: MaterialStyleModel = {
            id: styleId,
            material_brand_id: styleId,
            title: title,
            slug: slug,
            description: description,
            sort_order: sort_order,
            status: parseInt(status),
        };

        StyleupdatData(data);
    };

    return (
        <div>
            <div className="back-icon" onClick={handleBack}>
                <i className="bi bi-arrow-left-short"></i>
                <span>Back</span>
            </div>

            <Card className="card-edit py-4 mt-4">
                <div className="row container from-edit">
                    <div className="col-6 from-list">
                        <FormLabel> Material Brand </FormLabel>
                        <Select
                            className="input-primary"                            
                            value={styleId}
                            onChange={(e) => setStyleId(Number(e.target.value))}
                        >
                            {getMaterialBrands && getMaterialBrands.length > 0 &&
                                getMaterialBrands.map(item => (
                                    <option key={item.id} value={item.id}>
                                        {item.title}
                                    </option>
                                ))}
                        </Select>
                    </div>

                    <div className="col-6 from-list">
                        <FormLabel> Material Title </FormLabel>
                        <Input
                            className="input-primary"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list">
                        <FormLabel> Slug </FormLabel>
                        <Input
                            className="input-primary"
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                        />
                    </div>

                    <div className="col-12 from-list">
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            style={{ backgroundColor: "#fff" }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list">
                        <FormLabel>Sort Order</FormLabel>
                        <NumberInput
                            style={{ backgroundColor: "#fff" }}
                            value={sort_order}
                            onChange={handleSortOrder}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <div className="col-3 mt-2 ">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button radio-button-from'>
                            <RadioGroup  className='radio-btn'
                                onChange={handleStatus}
                                value={status}
                            >
                                <Radio value="1">Active</Radio>
                                <Radio value="0">Inactive</Radio>
                            </RadioGroup>
                        </div>
                    </div>

                    <div className='btn-from'>
                        <Button className='from-btn' onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className='from-save-btn' onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default EditStyleForm;


