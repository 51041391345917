import React from 'react'
import SignUpHome from '../../module/auth/signUp/SignUpHome'

const SignUppage = () => {
  return (
    <>
    <SignUpHome/>

    </>
  )
}

export default SignUppage