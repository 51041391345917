import React from 'react'

type Props={
  firstHeader:string,
  secondHeader:string,
  breadCum1:string,
  breadCum2:string,
  breadCum3:string

}
const PageContentHeader = ({firstHeader,secondHeader,breadCum1,breadCum2,breadCum3}:Props) => {
  return (
    <>
     <div className="page-content p-3">
      <header className='border-bottom pb-3 mb-3'>
        <h5>{firstHeader}</h5>
        <small>{secondHeader}</small>
      </header>

      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">{breadCum1}</a></li>
          <li className="breadcrumb-item"><a href="#">{breadCum2}</a></li>
          <li className="breadcrumb-item active" aria-current="page">{breadCum3}</li>
        </ol>
      </nav>

     

   
    </div>
    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">Create new entry</h4>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body p-4">
            <div className='row g-2'>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Title</label>
                <input type='text' className='form-control' placeholder='Enter your title'/>
              </div>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Slug</label>
                <select className="form-select" aria-label="Default select example">
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className='col-12 col-md-12'>
                <label className='form-label'>Description</label>
                <textarea className="form-control" id="exampleFormControlTextarea1"></textarea>
              </div>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Short order</label>
                <input type='text' className='form-control' placeholder='Enter your title'/>
              </div>
              <div className='col-12 col-md-6'>
                <label className='form-label'>Status</label>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Active / Inactive</label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-link text-secondary text-decoration-none" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
        
    </>
  )
}

export default PageContentHeader