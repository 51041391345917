import React from 'react'
import Generate_Imagehome from '../../module/generate_Image/Generate_Imagehome'

const Generate_Image = () => {
  return (
    <div>
     <Generate_Imagehome/>
    </div>
  )
}

export default Generate_Image
