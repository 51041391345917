import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHoverGroup,
  addNoHoverGroup,
  getMasterArray,
  getUpdateIndexing,
  resetHoverGrp,
  resetNoHoverGroup,
  resetUpdateIndexing,
  updateGroupNameActive,
} from "../../../../slice/canvas/masterArray/MasterArraySlice";
import {
  GroupMasterSegment,
  MasterArrayModel,
  MasterModel,
} from "../../../../Model/masterArray/MasterArrayModel";
import {
  changeSegmentTab,
  getTabControl,
} from "../../../../slice/tabControl/TabControlSlice";
import {
  addSelectedSegment,
  resetSelectedSegment,
} from "../../../../slice/userJobSlice/UserJobSlice";
import "../LeftSections.scss";
// import './LeftSections.scss'
import EachSeg from "../EachSegments/EachSeg";

import { ReactSVG } from "react-svg";
import { getCanvasControl } from "../../../../slice/canvas/masterArray/CanvasControlSlice";
import AddSegment from "./AddSegment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SegmentBaseSwatch from "../segmentbasedSwatch/SegmentBaseSwatch";
import SwatchImages from "../selectSwatch/SwatchImages";
import { addSelectedSegmentForSwatch } from "../../../../slice/segment/SegmentSlice";
import { addEachSegment, addGroupName, getEachSegment, getGroupName } from "../../../../slice/toolActive/ToolActiveSlice";

const Segments = () => {
  // const [isShareLink, setIsShareLink] = useState(false)

  const [isMEdiaLibrary, setIsMediaLibrary] = useState(false);
  const [allSegMaster, setAllSegMaster] = useState<MasterArrayModel[]>([]);
  const [eachSegment, setEachSegment] = useState<MasterArrayModel | null>(null);
  const [masterSegName, setMasterName] = useState<string | null>(null);
  const [controlPannel, setControlPannel] = useState<string>();
  const getMasterArrays = useSelector(getMasterArray);
  const getTabControls = useSelector(getTabControl);
  const dispatch = useDispatch();
  const getCanvasControls = useSelector(getCanvasControl);
  const getUpdateIndexings = useSelector(getUpdateIndexing);
  const getGroupNames= useSelector(getGroupName)
  const getEachSegments= useSelector(getEachSegment)
    //  console.log("getGroupNames",getGroupNames)
    //  console.log("eachSegment",eachSegment)
  // update the active 
  useEffect(()=>{
    if(getGroupNames!=""){
      
      setMasterName(getGroupNames)
     UpdateEachSegmentGroupName(getGroupNames);
    }else{

      setMasterName(null)
    }
  },[getGroupNames])
  

  // getEach segment form redux
  useEffect(()=>{if(getEachSegments){
    setEachSegment(getEachSegments)
  }},[getEachSegments])
  // Update tab panel
  useEffect(() => {
    if (getTabControls) {
      setControlPannel(getTabControls);
    }
  }, [getTabControls]);

  // Update master array
  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      setAllSegMaster(getMasterArrays.allSeg);
    } else {
      setAllSegMaster([]);
      setEachSegment(null);
    }
  }, [getMasterArrays]);

  // show first segment Swatch e.g wall
  const isFirstSeg = useRef(true);
  useEffect(() => {
    // if (allSegMaster && allSegMaster.length > 0 && isFirstSeg.current) {
    //   isFirstSeg.current = false;
    //   const name = allSegMaster[0].name;
    //   if (name) {
    //   // setMasterName(name || "");
    //     //UpdateEachSegmentGroupName(name);
    //   }
    // } else if (allSegMaster && allSegMaster.length == 0) {
    //   setMasterName(null);
    // }

    if (
      getCanvasControls &&
      getCanvasControls.groupName &&
      allSegMaster &&
      allSegMaster.length > 0
    ) {
     // console.log("getCanvasControls.isMainComment",getCanvasControls.isMainComment)
      dispatch(addGroupName(getCanvasControls.groupName))
    // console.log("canvas control", getCanvasControls.groupName)
      UpdateEachSegmentGroupName(getCanvasControls.groupName);
    }
  }, [allSegMaster, getCanvasControls]);

  const UpdateEachSegmentGroupName = (groupName: string) => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      // setAllSegMaster(getMasterArrays.allSeg)
      const group = getMasterArrays.allSeg.find(
        (item) => item.name == groupName
      );
    // setMasterName(groupName);
      if (group && group.segmentation) {
        //setEachSegment(group);
        dispatch(addEachSegment(group))
        dispatch(addSelectedSegment(group.segmentation));
      }
    }
  };
  // On click tab
  const handleEachSegment = (data: MasterArrayModel) => {
    dispatch(resetSelectedSegment());
      
    if (data.name) {
      dispatch(updateGroupNameActive({  // set the active on GroupName
        groupName:data.name
      }))
     // setMasterName(data.name);
      dispatch(addGroupName(data.name)) // set GroupName
    }
    if (data && data.segmentation && data.segmentation.length > 0) {
      dispatch(addSelectedSegment(data.segmentation));
      dispatch(addSelectedSegmentForSwatch(data.segmentation))
    }
  };

  const handleshowMedia = () => {
    setIsMediaLibrary(true);
  };

  // const handleShareLink = () => {
  //   setIsShareLink(true)
  // }

  // const handleCloseShareLink = () => {
  //   setIsShareLink(false)
  // }

  /// update the each segment after delete
  useEffect(() => {
    if (
      getUpdateIndexings &&
      getUpdateIndexings.isUpdate &&
      getUpdateIndexings.groupName
    ) {
      UpdateEachSegmentGroupName(getUpdateIndexings.groupName);
     
    }
  }, [getUpdateIndexings, getMasterArrays]);

  // hover over the group
  const handleHoverGroup = (groupName: string) => {
    // console.log("hover",groupName)
    dispatch(resetNoHoverGroup());
    dispatch(addHoverGroup(groupName));
  };

  const handleRemoveHover = (groupName: string) => {
    // console.log("handleRemoveHover", groupName)
    dispatch(resetHoverGrp());
    dispatch(addNoHoverGroup(groupName));
    // dispatch(resetHoverGrp())
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };


  return (
    <>
      <div className="d-flex align-items-start" key="segments">
        <ul
          className="nav nav-pills flex-column left-panel-menu  p-2"
          id="myTab"
          role="tablist">
          {allSegMaster &&
            allSegMaster.length > 0 &&
            allSegMaster.map((item: MasterArrayModel, index: number) => {
              const path = "https://dzinlystrapi.s3.us-east-2.amazonaws.com";

              return (
                <li
                  className={`nav-item dzi-${item.name}`}
                  role="presentation"
                  key={item.id || index}>
                  <OverlayTrigger
                    key={`overlay-${item.id || index}`}
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-${item.id}`}>{item.name}</Tooltip>
                    }>
                    <button
                      className={`nav-link ${
                        masterSegName === item.name ? "active" : ""
                      }`}
                      id={`${item.id}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#${item.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`${item.id}`}
                      aria-selected={
                        masterSegName == item.name ? "true" : "false"
                      }
                      onClick={() => handleEachSegment(item)}
                      onMouseEnter={() => handleHoverGroup(item.name ?? "")}
                      onMouseLeave={() => handleRemoveHover(item.name ?? "")}
                      style={{
                        border: `1px solid ${item.color_code}`,
                        borderRadius: "6px",
                        backgroundColor:
                          item.isActive
                            ? `${item.color_code}50`
                            : "transparent", // Added background color with opacity
                        // backgroundColor: masterSegName === item.name ? item.color_code : 'transparent', // Solid background color when active

                        transition: "background-color 0.3s ease", // Smooth transition                 // Rounded corners
                      }}>
                      <span className="icon">
                        <ReactSVG
                          src={`${item.icon}`}
                          beforeInjection={(svg) => {
                            svg.setAttribute(
                              "style",
                              "width: 100%; height: 100%;"
                            );
                            // Add a border with dynamic color
                            svg.setAttribute(
                              "style",
                              `width: 100%; height: 100%; border-radius: 4px;`
                            );
                          }}
                          onError={(error) => {
                            console.error("Error loading SVG:", error);
                          }}
                        />
                      </span>
                    </button>
                  </OverlayTrigger>
                </li>
              );
            })}

          <AddSegment />
        </ul>

        <div className="each-Seg-cont">
          <div className="tab-content tab-sub-container" id="myTabContent">
            {getEachSegments &&
            getEachSegments?.name == getGroupNames && (
              <EachSeg
                masterSegName={getGroupNames} //groupname
                eachSegment={getEachSegments }
              />
            )}
          </div>
        </div>
      </div>
      <div className="eachSeg-header">
        <div className=" swichpallets-boxs">
          <ul className="list-style-none d-flex gap-2 justify-content-between align-items-center">
            <li
              className={activeTab === 0 ? "active" : ""}
              onClick={() => handleTabClick(0)}>
              Brand
            </li>
            <li
              className={activeTab === 1 ? "active" : ""}
              onClick={() => handleTabClick(1)}>
              Style
            </li>
            <li
              className={activeTab === 2 ? "active" : ""}
              onClick={() => handleTabClick(2)}>
              Tab 3
            </li>
          </ul>
          <div className="p-3 swichpallets-box">
            <div className="row g-3">
              <SwatchImages />
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Segments;
