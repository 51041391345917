
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAddSegmentStatus } from '../../../../slice/canvas/updatevalue/UpdateValueSlice'
import AddSegmentModal from './AddSegmentModal'
import { closeAddSegmentModal, getIsAddSegmentModal } from '../../../../slice/segment/SegmentSlice'

const AddSegmentHome = () => {
    const dispatch= useDispatch()
   const getIsAddSegmentModals= useSelector(getIsAddSegmentModal)
    const[isModal, setIsModal]= useState<boolean>(false)
    useEffect(()=>{
        if(getIsAddSegmentModals){
            setIsModal(true)
        }else{
            setIsModal(false) 
        }
    },[getIsAddSegmentModals])


    const handleCloseModal=()=>{
        setIsModal(false)
        dispatch(closeAddSegmentModal())
    }
  return (
    <>

    {isModal &&
    <AddSegmentModal
    isSegmentModal={isModal}
    handleClose={handleCloseModal}
    />}
        
    </>
  )
}

export default AddSegmentHome