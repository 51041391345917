import React, { useState } from 'react'
import Sidebar from './components/Sidebar';
import PageContent from './components/PageContent';
import "./dasboard_layout.scss"
const DasboardPages = () => {
    const [isActive, setIsActive] = useState(false); // State to track whether the div is active

    const toggleActiveClass = () => {
      setIsActive(!isActive); // Toggle the isActive state
    };
  return (
    <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
         <PageContent/>
         
    </div>
  )
}

export default DasboardPages
