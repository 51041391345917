import React from 'react'
import AdminStyleTable from '../../../module/admin/AdminMaterial/style/AdminStyleTable'

const AdminStyleTablePage = () => {
  return (
    <div style={{marginTop:50}}>
        <AdminStyleTable/>

    </div>
  )
}

export default AdminStyleTablePage