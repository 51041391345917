import React from 'react'
import LeftSections from '../ImageView/LeftSections/LeftSections'
import EditCanavasRight from './editRight/EditCanavasRight'

const EditCanvasHome = () => {
  return (
    <div className='editor-wrapper'>
    <div className="row g-0 d-flex align-items-stretch h-100">
        <div className="col-12 col-md-3 bg-light">
            <div className="editor-toolbar-left">
                    <div className="canvas-left-cat h-100">
                        <LeftSections/>
                    </div>
            </div>
        </div>

        <div className="col-12 col-md-9">
        <div className="canvas-view">
                
             
                <div style={{width: 'calc(100vw - 425px)', height: 'calc(100vh - 0px)'}}>
                   <EditCanavasRight/>
                  
                </div>
            </div>
        </div>
    </div>
  
</div>
  )
}

export default EditCanvasHome