import React from 'react'
import SwatchBrandTable from './SwatchBrandTable'

  type Props={
    isBrand:boolean
  }

const SwatchBrandHome = ({isBrand}:Props) => {
  return (
    <div>

        {isBrand &&
        <SwatchBrandTable/>}
    </div>
  )
}

export default SwatchBrandHome