import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getComment } from '../../slice/projectCommet/ProjectCommentSlice'
import { getUserJobData } from '../../slice/userJobSlice/UserJobSlice'
import GetComments from '../../module/ImageView/RightSection/comment/GetComments'
import { getMasterArray } from '../../slice/canvas/masterArray/MasterArraySlice'

const CommentRefesh = () => {
      const getComments= useSelector(getComment)
      const getUserJobDatas= useSelector(getUserJobData)
      const[jobId, setJobId]= useState<number|null>(null)
      const isApi= useRef(true)

      const getMasterArrays= useSelector(getMasterArray)
      useEffect(()=>{
      
        if(getComments &&
             getComments.projectComments &&
             getComments.projectComments.length==0 &&
             !getComments.isCommentApiHit &&
             getMasterArrays &&
             getMasterArrays.jobId &&
             isApi.current
            ){
                console.log("comments")
                isApi.current= false
                setJobId(getMasterArrays.jobId)

            }else if(! getComments.isCommentApiHit){

                isApi.current= true
            }
      },[getComments,getMasterArrays])

     

      const handleResetcomments=()=>{
        setJobId(null)
      }
      
      
  return (
    <>
    { jobId &&
<GetComments
 jobId={jobId}
 resetGetComment={handleResetcomments}
 />}
    </>
  )
}

export default CommentRefesh