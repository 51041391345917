import React, { useState } from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import EditStyleForm from '../../../brandPage/style/EditStyleForm'
import { MaterialStyleModel } from '../../../../Model/material/MaterialStyleModel'
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice'
import { useDispatch } from 'react-redux'
import UpdateStyleApi from './api/UpdateStyleApi'
import { useNavigate } from 'react-router-dom'
import Header from '../../../header/Header'


const AdminStyleEdit = () => {
  const navigate= useNavigate()
const [ isEditApi , setIsStyleEditApi]= useState<boolean>(false)
const[editStyle, setEditStyle]= useState<MaterialStyleModel|null>()
  
  const dispatch= useDispatch()
  const handleUpdateBrand=(data:MaterialStyleModel)=>{
    dispatch(startLoading())
    
    setEditStyle(data)
    setIsStyleEditApi(true)

    }

    const handleResetBrand=(mess:string)=>{
      if(mess==="success"){
        setIsStyleEditApi(false)
        setEditStyle(null)
       dispatch(stopLoading())
       alert("Brand updated sucessfully")
      } else if (mess=="fail"){
        setIsStyleEditApi(false)
        setEditStyle(null)
       dispatch(stopLoading())
       alert("Error in updating Brand")
       
      }
           navigate("/dashboard/swatch-styles")
     }

  return (
     <>


     {/* <Header/> */}
       <div className='row'>


<LeftSection
 
/>
<div className="col-9 brand-right-section">

<EditStyleForm 
        StyleupdatData={handleUpdateBrand}
/>



{isEditApi && editStyle &&
       <UpdateStyleApi
       editBrand={editStyle}
       resetUpdate={handleResetBrand}
       
       />
    }

</div>




{/* getAll materials */}
<GetAllMaterialsHome/>
</div>
     </>
  )
}

export default AdminStyleEdit