import React, { useState } from 'react';
import { userModel } from '../../../Model/user/UserModel';
import { useNavigate } from 'react-router-dom';


type Props={
    registerAccount:(data:userModel)=>void
}
const SignUpForm = ({registerAccount}:Props) => {
const navigate= useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: ''
  });

  
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    if (!formData.name || !formData.email || !formData.password || !formData.confirmPassword || !formData.role) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setErrorMessage('');
    const data:userModel={
      name:formData.name,
      email:formData.email,
      password:formData.password,
      role:formData.role
    }
      setFormData({...formData,
        name:"",
        email:"",
        password:"",
        role:"",
        confirmPassword:""
      })
    registerAccount(data)
  };


   const handleLogin=()=>{
    navigate("/auth/login")
   }
  return (
    <>
      <label>
        Name
        <input 
          type="text" 
          placeholder="Name" 
          value={formData.name} 
          onChange={(e) => setFormData({ ...formData, name: e.target.value })} 
        />
      </label>
      <label>
        Email
        <input 
          type="email" 
          placeholder="Email" 
          value={formData.email} 
          onChange={(e) => setFormData({ ...formData, email: e.target.value })} 
        />
      </label>
      <label>
        Password
        <input 
          type="password" 
          placeholder="Password" 
          value={formData.password} 
          onChange={(e) => setFormData({ ...formData, password: e.target.value })} 
        />
      </label>
      <label>
        Confirm Password
        <input 
          type="password" 
          placeholder="Confirm Password" 
          value={formData.confirmPassword} 
          onChange={(e) => {
            setFormData({ ...formData, confirmPassword: e.target.value });
            if (formData.password !== e.target.value) {
              setErrorMessage("Passwords do not match.");
            } else {
              setErrorMessage('');
            }
          }} 
        />
      </label>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <label>
        Role
        <select 
          value={formData.role} 
          onChange={(e) => setFormData({ ...formData, role: e.target.value })} 
        >
          <option value="">Select a role</option>
          <option value="admin">Admin</option>
          <option value="customer">customer</option>
          
          <option value="designer">Designer</option>
          <option value="manufacturer">Manufacturer</option>
        </select>
      </label>
      <button type="button" onClick={handleSubmit}>Submit</button>
      <button type="button">Cancel</button>
      <p>If you already have an account, <span onClick={handleLogin}>sign in</span></p>
    </>
  )
}

export default SignUpForm