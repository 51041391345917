import React from 'react'
import { Routes, Route } from 'react-router-dom';
import ShapeTablePage from '../../page/shape/ShapeTablePage';
import ShapeEdit from '../../page/shape/ShapeEdit';
const ShapeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ShapeTablePage />} />
      <Route path="/edit" element={<ShapeEdit />} />
    </Routes>
  )
}

export default ShapeRoutes
