import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddSegmentModel, GroupListModel, MainGroupModel } from "../../../Model/groupingModel/GroupingModel";
import { SegMentGroupModel } from "../../../Model/masterArray/MasterArrayModel";


interface GroupListStates {
   segmentsGroups:MainGroupModel[]
   masterGroup:SegMentGroupModel[],
   addSegmentvalue:AddSegmentModel,
  //  addSegmentGroup:string
 
}

const initialState: GroupListStates = {
    segmentsGroups:[],
    masterGroup:[],
    addSegmentvalue:{},
    // addSegmentGroup:""

};

const GroupSlice = createSlice({
    name: "groupSlice",
    initialState,
    reducers: {
       addGroup:(state,action)=>{
        state.segmentsGroups=action.payload
       }, 
       addMasterGroup:(state,action)=>{
        state.masterGroup= action.payload
       },
      
       updateGroup: (state, action) => {
        const { group, groupName } = action.payload;

        const generateId = () => `id-${Math.random().toString(36).substr(2, 9)}`;

        const groupIndex = state.segmentsGroups.findIndex(item => item.title === group);
    
        if (groupIndex !== -1) {
          const targetGroup = state.segmentsGroups[groupIndex];
 
          if (targetGroup.groups) {
            targetGroup.groups.push({
              id: generateId(),
              title: groupName,
              isGroup: false,
              children: []
            });
          } else {
            // If 'groups' is undefined, initialize it and push the new group
            targetGroup.groups = [{
              id: generateId(),
              title: groupName,
              isGroup: false,
              children: []
            }];
          }
        }
      },
       
       changeChildGroup : (state, action) => {
        const { groupName, subGroupName, child } = action.payload;
        
        const groupIndex = state.segmentsGroups.findIndex(item => item.title === groupName);
      
        if (groupIndex !== -1) {
          const mainGroup = state.segmentsGroups[groupIndex];
      
          if (mainGroup.groups) {
           
            const subgroupIndex = mainGroup.groups.findIndex(item => item.title === subGroupName);
            if (subgroupIndex !== -1) {
              const childGroup = mainGroup.groups[subgroupIndex];
              if (childGroup.children) {
                childGroup.children.push(child);
              } else {
                childGroup.children = [child]; 
              }
            }
          }
        }
      },
       
      afterChangeChildGroup:(state,action)=>{
        const { groupName, subGroupName, child } = action.payload;
        const groupIndex = state.segmentsGroups.findIndex(item => item.title === groupName);
      
        if (groupIndex !== -1) {
          const mainGroup = state.segmentsGroups[groupIndex];
          if (mainGroup.groups) {
           
            const subgroupIndex = mainGroup.groups.findIndex(item => item.title === subGroupName);
            if (subgroupIndex !== -1) {

              
              const childGroup = mainGroup.groups[subgroupIndex];
              if (childGroup.children) {
                 const childGroupindex= childGroup.children.findIndex(item=>item.title==child.title)
                if (childGroupindex!=-1){
                  childGroup.children.splice(childGroupindex, 1);
                }
              } 
            }
          }
      }

     }, 
     addSegment:(state,action)=>{
      const{isOpenModel,groupName}=action.payload
      state.addSegmentvalue.isOpenModel=isOpenModel
      //state.addSegmentvalue.groupName=groupName
     },
     updateChildAddSegment:(state,action)=>{
        const {groupName,childGroupName,shortName,subGroupLength}=action.payload
      // state.addSegmentvalue.isOpenModel=false
      state.addSegmentvalue.groupName=groupName
      state.addSegmentvalue.childGroupName=childGroupName
      state.addSegmentvalue.shortName=shortName
      state.addSegmentvalue.subGroupLength=subGroupLength
     },
     resetAddSegmentModel:(state)=>{
      state.addSegmentvalue.isOpenModel=false
     },
     resetAddSegment:(state)=>{
      state.addSegmentvalue.isOpenModel=false
      state.addSegmentvalue.groupName=""
      state.addSegmentvalue.childGroupName=""
     } 
    }
}
)

export const {addGroup,updateGroup,
  addMasterGroup,addSegment,resetAddSegmentModel,resetAddSegment,
  changeChildGroup,afterChangeChildGroup,updateChildAddSegment,
}=GroupSlice.actions;
export const getGroupList=  (state:{groupSlice:GroupListStates})=>state.groupSlice.segmentsGroups
export const getmastergroup=  (state:{groupSlice:GroupListStates})=>state.groupSlice.masterGroup
export const getAddSegmentGroup=  (state:{groupSlice:GroupListStates})=>state.groupSlice.addSegmentvalue

export default GroupSlice.reducer