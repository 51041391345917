import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserJobBase64Upload, getUserJobData, resetUpload } from '../../../slice/userJobSlice/UserJobSlice'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import { UpdateBase64 } from '../../../api/jobs/JobApi'



const UpdateBase64Db = () => {
    const dispatch= useDispatch()
    const getUserJobDatas= useSelector(getUserJobData)
    const getUserJobBase64Uploads= useSelector(getUserJobBase64Upload)
    const isApi= useRef(true)
    useEffect(()=>{

        if(getUserJobDatas && 
            getUserJobDatas.id &&
            getUserJobBase64Uploads.base64&&
            getUserJobBase64Uploads.isUpload&&

            isApi.current
        ){
       isApi.current= false
       const base64= JSON.stringify(getUserJobBase64Uploads.base64,)
       updateBase64Db( base64, getUserJobDatas.id)
        }
    },[getUserJobDatas,getUserJobBase64Uploads])


    const updateBase64Db=async(base64Image:string,jobId:number)=>{
        try{
        const response= await UpdateBase64(base64Image, jobId)
        if(response && response.status==200){
            dispatch(addMessage({
                isShow:true,
                mess:"Update jobs sucessfully",
                 toastType:"success"
               }))
               isApi.current= true
               dispatch(resetUpload())
              
        }
        }catch(err){
            dispatch(resetUpload())
            dispatch(addMessage({
                isShow:true,
                mess:"Error in updating base64 image",
                //  toastType:"Error"
               }))
               isApi.current= true
              
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default UpdateBase64Db