
import axios from "axios";
import { ApiModel } from "../../../../Model/apiModel/APiModel";
import { ReplaceWindowModel } from "../../../../Model/replaceSwatchModel/ReplaceWindowSwatch";
const backEndUrl=process.env.REACT_APP_BACKEND_URL

const API_URL = "https://api.dzinly.org/api"
const serverApi =process.env.REACT_APP_SERVER_URL
// console.log("serverApi ->", serverApi)

export function GetTransparentImage(apiPath:string, data:ApiModel) {

    return axios.post(`${API_URL}/${apiPath}`,data)
        .then(response => {
            return response.data
        }).catch(error => {
            throw error;
        });


}

// delete segment
export function DeleteSegmemtFromGroup(jobId:number, segName:string) {
 const data={
    jobId:jobId,
    segName:segName
 }
    return axios.post(`${serverApi}/job/delete-segment`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

// update group changes
export function UpdateSegmetGroup(jobId:number, segName:string) {
 const data={
    jobId:jobId,
    segName:segName
 }
    return axios.post(`${serverApi}/job/delete-segment`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}



// replace window/ door/ garageDoor swatch

export function ReplaceWindowDoorGarageSwatch(data:ReplaceWindowModel,apiName:string) {

       return axios.post(`${backEndUrl}/api/wrapped-${apiName}-swatch`,data)
           .then(response => {
               return response
           }).catch(error => {
               throw error;
           });
   
   
   }

// replace wall swatch

export function ReplaceWallSwatch(data:ReplaceWindowModel, apiName:string) {

       return axios.post(`${backEndUrl}/api/wrapped-${apiName}-swatch`,data)
           .then(response => {
               return response
           }).catch(error => {
               throw error;
           });
   
   
   }

   //Replace Trim
export function ReplaceTrimSwatch(data:ReplaceWindowModel) {

       return axios.post(`${backEndUrl}/api/replace-trim-swatch`,data)
           .then(response => {
               return response
           }).catch(error => {
               throw error;
           });
   
   
   }




//    repalce roof

export function ReplaceRoofSwatch(data:ReplaceWindowModel) {

       return axios.post(`${backEndUrl}/api/replace-roof-swatch`,data)
           .then(response => {
               return response
           }).catch(error => {
               throw error;
           });
   
   
   }