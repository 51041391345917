import React from 'react'
import StyleTable from './StyleTable'


type  Props={
    isStyle:boolean
}
const StyleHome = ({isStyle}:Props) => {

  return (
    <div>
       {isStyle && <StyleTable/>}
    </div>
  )
}

export default StyleHome