import React from 'react'
import AdminProjectsTable from '../../../module/admin/projectAdmin/AdminProjectsTable'
import { Header } from 'antd/es/layout/layout'

const AdminProjectTablePage = () => {
  return (
    <>
     {/* <Header/> */}
     <div   style={{marginTop:50}}>
     
      <AdminProjectsTable/>
    </div>
    </>
   
  )
}

export default AdminProjectTablePage