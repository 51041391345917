import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { getGenAiImage } from '../../../slice/userJobSlice/UserJobSlice';
import { ProjectImage } from '../../../Model/mediaModel/MediaModel';
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice';
import { changeSegmentTab } from '../../../slice/tabControl/TabControlSlice';
import { getSwitchCanvas } from '../../../slice/tabControl/TabControlSlice';

const s3BucketUrl=process.env.REACT_APP_S3Bucket
const GenAiCanvas = () => {
  const dispatch= useDispatch()
  const getGenAiImages = useSelector(getGenAiImage);
  const [genImage, setGenImage] = useState<ProjectImage[]>([]);
  const getMasterArrays = useSelector(getMasterArray)
  const getSwitchCanvass= useSelector(getSwitchCanvas)
  useEffect(()=>{
    if(getSwitchCanvass==="outline"
    ){
      dispatch(changeSegmentTab('outline'))
    }
    else  if(getSwitchCanvass==="compare" 
    ){
      dispatch(changeSegmentTab('compare'))
     
    }
    else  if(getSwitchCanvass==="dimension"
    ){

      dispatch(changeSegmentTab('dimension'))
      
    }
    else  if(getSwitchCanvass==="genAiImage" 
    ){
      dispatch(changeSegmentTab('genAiImage'))
      
    }
    else  if(getSwitchCanvass==="comments"
    ){
      dispatch(changeSegmentTab('comments'))
      
    }
    else  if(getSwitchCanvass==="segment"
    ){
      dispatch(changeSegmentTab('segment'))
      
    }
   },[getSwitchCanvass])


  useEffect(() => {
    const allImage: ProjectImage[]=[]
    if(getMasterArrays && getMasterArrays.image && getMasterArrays.project_id){
        const data={
            name:"Project Image",
            image:`projects/${getMasterArrays.project_id}/${getMasterArrays.image}`,
            size:0
        }
        allImage.push(data||{})
    }
    if (getGenAiImages && getGenAiImages.length > 0) {
      const filters = getGenAiImages.filter(item => !item.image.includes('canny'));
     // setGenImage(filters);
     allImage.push(...filters)
     const cannyItem = getGenAiImages.find(item => item.image.includes("canny"));
     if(cannyItem){
         const data2={
          name:"Canny Image",
          image:cannyItem.image,
          size:cannyItem.size,
          lastModified:cannyItem.lastModified
         }
         allImage.push(data2)
     }
    }

    if(allImage.length>0){
      setGenImage(allImage)
  }
  }, [getGenAiImages]);

    // return to main canavs
    const handlehoverLayer = () => {
     
      dispatch(changeSegmentTab("segment"));
      
    };
  return (
    <>
    <div className="position-relative">
        <div className="re-anno-section" onClick={handlehoverLayer}>
          <button className=" d-flex  align-items-center btn btn-secondary border-0 fs-6">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            Gen-Ai Gallery
          </button>
        </div>
        <Carousel  slide={false}>
        {genImage && genImage.length>0 &&
        genImage.map(item=>{
            let names:string=""
             const path=`${s3BucketUrl}/${item.image}`
             if(item.name){
                names= item?.name.charAt(0).toUpperCase() + item?.name.slice(1).toLowerCase();
           }
            return(
                <Carousel.Item className='carousel-pro-img'>
                <img className='w-100' src={path}  />
                <Carousel.Caption>
                  <h3>{names}</h3>

                </Carousel.Caption>
              </Carousel.Item>
            )
        })
        }
  
   
   
  </Carousel>
      </div>
   
    </>
 
  );
};

export default GenAiCanvas;
