import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { GetS3BucketFolderFiles } from '../../../api/folderApi/FolderApi'
import { addGenAi } from '../../../slice/userJobSlice/UserJobSlice'
import { stopGenAiImage } from '../../../slice/genAi/GenAiAttributesSlice'
import { addMessage } from '../../../slice/messageToast/ToastSlice'

const GetGenAiImage = () => {
     const dispatch= useDispatch()
    const getMasterArrays= useSelector(getMasterArray)
    useEffect(()=>{
        if(getMasterArrays && 
            getMasterArrays.project_id
        ){
            genAiImages( getMasterArrays.project_id)
        }
    },[getMasterArrays])

    const genAiImages=async(projectid:number)=>{
        const folderName=`projects/${projectid}/styleGen/`
        try{
           const response= await GetS3BucketFolderFiles(folderName)
          // console.log("genApi respinse", response)
           if(response && response.status==200){

            dispatch(addGenAi({
              allImages:response.data}))
            }

            dispatch(stopGenAiImage())
        }catch(err){
           // console.log("Error on  getting GenAi Image", err)

            dispatch(addMessage({
              isShow:true,
              mess:`Error on  getting GenAi Image- ${err},`,
               toastType:"Error"
             }))
        }

    }
  return (
    <div>
        
    </div>
  )
}

export default GetGenAiImage