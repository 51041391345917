import React, { useEffect, useRef, useState } from 'react'
import GetFolder from './GetFolder'


type Props={
    path:string
    resetCheck:()=>void
}
const CheckHome = ({path,resetCheck}:Props) => {

    const [isFolder, setIsFolder]= useState<boolean>(false)
    const [isFiles, setIsFiles]= useState<boolean>(false)
    const isCall= useRef(true)
    useEffect(()=>{
        if(path && isCall.current){
            setIsFolder(true)
            isCall.current= false
        }
    },[path])

    const handleResetfolder=(data:boolean)=>{
        setIsFolder(false)
        if(data){
             resetCheck()
        }else{
            // seerch files
        }

    }
  return (
    <div>
        

{isFolder &&
<GetFolder
path={path}
resetFolder={handleResetfolder}
/>}

    </div>
  )
}

export default CheckHome