import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea, RadioGroup, Radio, Button } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import Example from './StyleRadioCard';
import { useSelector } from 'react-redux';
import { getMaterialStyle } from '../../../slice/materialSlice/MaterialStyleSlice';
import { MaterialStyleModel } from '../../../Model/material/MaterialStyleModel';
import { getMaterialBrand } from '../../../slice/materialSlice/MaterialBrandSlice';
import { MaterialBrandModel } from '../../../Model/material/MaterialBrandModel';

// import Example from './RadioCard';
// import "./EditProjectFrom.css"

type Props={
    addNewBrand:(data:MaterialStyleModel)=>void
   }


const EditStyleCreateFrom= ({addNewBrand}:Props) => {

    // const [categoryId, setCategoryId]= useState<number>()

    const [description, setDescription]= useState<string>("")
    const [slug, setSlug]= useState<string>("")
    const [title, setTitle]= useState<string>("")
    const [styleId, setStyleId]= useState<number>()
    const [sort_order, setSort_order]= useState<number>()

    const getMaterialBrands= useSelector(getMaterialBrand) as MaterialBrandModel[]

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleDragImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            // Handle the file
            console.log(event.dataTransfer.files[0]);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            // Handle the file
            console.log(event.target.files[0]);
        }
    };

    const handleSortOrder=(val:string)=>{
        setSort_order(parseInt(val))
       }

      const [status, setStatus]= useState<string>("0")


       const handleStatus=(val:string)=>{
        if(val){
           setStatus(val)
        }
      
       }

       const handleCancel= ()=>{
        // dispatch(resetEditBrand())
        // navigate("/dashboard/swatch-brands")
    
       }

       const handleSave=()=>{
        if(styleId!=undefined&&
           
          title!=""&& 
          slug !="" && 
          description!="" &&
          sort_order!=undefined&&
          status!=undefined
        ){
          const data:MaterialStyleModel={
              material_brand_id: styleId,

              title: title,
              slug: slug,
              description: description,
              sort_order: sort_order,
              status: parseInt(status)
              
        }
  
        addNewBrand(data)
     }else {
      alert("check all the input")
     }
  
      
     }

    //  const handleSelectCategory=(val:number)=>{

    //     if(val){
    //       console.log("val",val);
    //       setStyleId(val)
    //     }
  
    //  }

    return (
        <div>
          


            <Card className="card-edit ">
                <div className="row container from-edit">
                    <div className="col-12 from-list">
                        <FormLabel> Material Brand </FormLabel>
                        <Select className="input-primary" placeholder="JobView" 
                            value={styleId}
                            onChange={(e) => setStyleId(Number(e.target.value))}
                        >
                              {getMaterialBrands && getMaterialBrands.length > 0 &&
                     getMaterialBrands.map(item => (
                        <option key={item.id} value={item.id}>
                                 {item.title}
                            </option>
    ))
  }
                        </Select>
                 
                        
                    </div> 


                    <div className="col-12 from-list">
                        <FormLabel> Material Title </FormLabel>
                        <Input className="input-primary"  
                           value={title}
                           onChange={(e)=>setTitle(e.target.value)}
                        />
                    </div>

                    <div className="col-12 from-list">
                        <FormLabel> Slug </FormLabel>
                        <Input className="input-primary" 
                         value={slug}
                         onChange={(e)=>setSlug(e.target.value)}
                        />
                    </div>

                    <div className="col-12 from-list" >
                        <FormLabel>Description</FormLabel>
                        <Textarea style={{ backgroundColor: "#fff" }} 
                         value={description}
                         onChange={(e)=>setDescription(e.target.value)}
                        />
                    </div>

                    <div className="col-6 from-list" >
                        <FormLabel>Sort Order</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}
                        value={sort_order}
                        onChange={ handleSortOrder}
                        >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                            
                        </NumberInput>

                    </div>

                    
                    

                
                 

                    <div className="col-6 mt-2 checkbox-status">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button radio-button-from '>
                        <RadioGroup className='radio-btn buttonradio'
                        onChange={handleStatus}
                        value={status}
                         >
                                <Radio value="1">Active</Radio>
                                <Radio value="0">Inactive</Radio>
                            </RadioGroup>
                        </div>

                    </div>

                    <div className='btn-from '>
                        <Button  className='from-btn' onClick={handleCancel}>  
                            Cancel 
                        </Button>
                        <Button className='from-save-btn'  onClick={handleSave}>  
                            Save 
                        </Button>
                    </div>


                </div>
            </Card>
        </div>
    );
};

export default EditStyleCreateFrom;