import React, { useEffect, useRef, useState } from 'react'
import { CreateProjectModel, ProjectModel } from '../../Model/project/ProjectModel'
import { userCreateProject, userProject } from '../../api/projects/ProjectApi'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogin, updateUserprojects } from '../../slice/user/UserSlice'
import { v4 as uuidv4 } from 'uuid';
import {  addNewProject, updateProject } from '../../slice/user/UserProject'
type Props={
  createProjectData:CreateProjectModel,
  resetProjectApi:(data:string)=>void
}
const CreateProjectAPi = ({createProjectData,resetProjectApi}:Props) => {
  const uniqueId = uuidv4();
  const dispatch= useDispatch()
  const [ customerId, setCustomerId]= useState<number|null>(0)
  const [ customerRole, setCustomerRole]= useState<string|null|undefined>("")
  const [ customerEmail, setCustomerEmail]= useState<string|null|undefined>("")
    const getUserLogins= useSelector(getUserLogin);
    const isApi= useRef(true)
    useEffect(()=>{
        
      if(getUserLogins && getUserLogins.length>0){
        const custId= getUserLogins[0].id;
        setCustomerEmail(getUserLogins[0].email);
        setCustomerRole(getUserLogins[0].role)
        if(custId){
          setCustomerId(custId) 
        }
       
      }else if(getUserLogins && getUserLogins.length===0){
        setCustomerEmail("guest@gmail.com");
        setCustomerRole("guest")
        setCustomerId(999) 
      }
    },[getUserLogins])

      useEffect(()=>{
        
        if(createProjectData.image && 
          createProjectData.projectName  &&
          customerId &&
        customerEmail &&
      customerRole &&
      isApi.current
    ){

         const dates= new Date()
        
          const data:ProjectModel={
            name:createProjectData.projectName,
            slug:uniqueId.toString(),
            image:"",
            customer_id:customerId,
            created: dates.toString(),
            modified:dates.toString(),
            status:true,
            jobs:[]

          }
              isApi.current= false
          createProjectDB(data,customerEmail||"",customerRole||"")
        }
      },[createProjectData,customerEmail, customerRole, customerId])



    const createProjectDB= async(projectList:ProjectModel,email:string,role:string)=>{

        try{
             const response= await userCreateProject(projectList,email,role)
                  if(response && response.status===200){
                    
                    if(email!="guest@gmail.com"){
                      // upadate ptojects
                    dispatch(updateProject({
                      project:response.data.newProject
                    }))
                    // update user project
                    dispatch(updateUserprojects({
                      user:response.data.userProject
                    }))

                    // add new project object
                    dispatch(addNewProject(response.data.newProject))

                    }
                       else{
                        dispatch(addNewProject(response.data.newProject))

                       }

                    
                  }

               isApi.current=true
               resetProjectApi("success")
        }catch(err){
              alert("error in creating Project")
              resetProjectApi("fail")
        }
    }


  return (
    <div>


    </div>
  )
}

export default CreateProjectAPi