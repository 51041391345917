import React from 'react'
import AdminStyleTable from '../../../module/admin/AdminMaterial/style/AdminStyleTable'
import AdminCategoryTable from '../../../module/admin/AdminMaterial/category/AdminCategoryTable'

const AdminCategoryTablePage = () => {
  return (
    <div  style={{marginTop:50}}>
       <AdminCategoryTable/>
    </div>
  )
}

export default AdminCategoryTablePage