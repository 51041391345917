import React from 'react'
import UserComments from '../LeftSections/comments/UserComments'

export const UserCommentsTab = () => {
  return (
    <>
           <UserComments/>
    </>
  )
}
