import React, { useEffect, useState } from 'react'
import "./RightSection.css"
import Tools from './tools/Tools'
import MainCanvas from './MainCanvas'
import RegenerationHome from '../../regeneration/RegenerationHome'
import { useSelector } from 'react-redux'
import { getIsRegenaration } from '../../../slice/modelData/ModelDataSlice'
import GetComments from './comment/GetComments'
import { getUserJobData } from '../../../slice/userJobSlice/UserJobSlice'

const RightSection = () => {
    const[isRegeneration, setIsRegeneration]= useState<boolean>(false)
    const[jobId, setJobId]= useState<number|null>(null)

      const getIsRegenarations= useSelector(getIsRegenaration)
  const getUserJobDatas= useSelector(getUserJobData)
  useEffect(()=>{
    if(getIsRegenarations){
      
      setIsRegeneration(getIsRegenarations)
    }else if(!getIsRegenarations){
      setIsRegeneration(getIsRegenarations)
    }
  },[getIsRegenarations])
   

  // setting jobId for getting all user Commnets
  useEffect(()=>{
    if(getUserJobDatas &&
       getUserJobDatas.id){
      setJobId(getUserJobDatas.id)
    }
  },[ getUserJobDatas])
// reset comments get Api

const handleResetcomments=()=>{
  setJobId(null)
}


  return (
    // <div className='right-project-layer-img mt-0 h-100'>
   <>


 <MainCanvas/>


{ isRegeneration &&
 <RegenerationHome/>}

 {/* get user Comments */}
{ jobId &&
<GetComments
 jobId={jobId}
 resetGetComment={handleResetcomments}
 />}

 
   </>
    


  )
}

export default RightSection
