import React from 'react'
import AdminStyleEdit from '../../../module/admin/AdminMaterial/style/AdminStyleEdit'
import AdminCategoryEdit from '../../../module/admin/AdminMaterial/category/AdminCategoryEdit'

const AdminCategoryEditPage = () => {
  return (
    <div style={{marginTop:50}}>
      <AdminCategoryEdit/>
    </div>
  )
}

export default AdminCategoryEditPage