import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUserJobData } from '../../slice/userJobSlice/UserJobSlice';

const CanvasHtml = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const getUserJobDatas = useSelector(getUserJobData);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');

      // Set canvas dimensions (optional)
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

    
    }
  }, []);

  return (
    <div className='bg-dark h-100 w-100'>
      <canvas ref={canvasRef} className='dzi-van'></canvas>
    </div>
  );
};

export default CanvasHtml;
