import React, { useEffect, useRef } from 'react'
import { MaterialCategoryModel } from '../../../../../Model/material/MaterialCategoryModel'
import { AddCategories, DeleteCategories, UpdateCategories } from '../../../../../api/material/category/CategoryApi'
import { useDispatch } from 'react-redux'
import { deletecategory } from '../../../../../slice/materialSlice/MaterialCategorySlice'


type Props={
   deleteId:number
    resetDelete:(data:string)=>void
}
const DelteCategoryApi = ({resetDelete,deleteId}:Props) => {
     const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{
      if(deleteId &&
         isApi.current
      ){
         deleteCategory(deleteId)
         isApi.current=false
      }
    },[deleteId])

    const deleteCategory=async(id:number)=>{

         try{
            const response= await DeleteCategories(id);
             if(response && response.status===200){
               //  console.log("update", response)
                isApi.current=true
                 dispatch(deletecategory({
                  id:id
                 }))
                resetDelete("success")
             }
         }catch(err){
            isApi.current= true
            resetDelete("fail")
         }
    }
  return (
    <div></div>
  )
}

export default DelteCategoryApi