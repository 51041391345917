import React, { useEffect, useState } from 'react'

import { ProjectModel } from '../../../Model/project/ProjectModel'
import ListOfProject from './ListOfProject'
import { getprojectResponse, getUserProject } from '../../../slice/user/UserProject'
import { useSelector } from 'react-redux'
import { Button, Link } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { getUserLogin } from '../../../slice/user/UserSlice'
import Loading from '../../loading/Loading'
import { getLoading } from '../../../slice/loading/LoadingSlice'
import Shimmer from './Shimmer'
import Header from '../../header/Header'

import Item from 'antd/es/list/Item'
const AssetImage =process.env.REACT_APP_ASSERT_Image

const masterImage = `${AssetImage}/images/no-image-.jpg`
const ShowListOfProject = () => {
  const getUserProjects = useSelector(getUserProject) as ProjectModel[]

  const [userAllProjects, setUserAllProjects] = useState<ProjectModel[]>([])
  const navigate = useNavigate()
  const getUserLogins = useSelector(getUserLogin)
  const getprojectResponses = useSelector(getprojectResponse)


   const[role, setRole]= useState<string>()
   useEffect(()=>{
    if(getUserProjects && getUserProjects.length>0){
      const newShort = [...getUserProjects].sort((a, b) => {
        if (a.id === undefined || b.id === undefined) {
          return 0;
        }
        return b.id - a.id;
      });
      setUserAllProjects(newShort??getUserProjects)
      

    }
  }, [getUserProjects])
  // console.log("All user project",getUserProjects)
  // update user role
  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      const rol = getUserLogins[0].role
      setRole(rol)
    }
  }, [getUserLogins])

  // handle Create Project
  const handleCreateProject = () => {
    navigate(`/${role}/create-project`)
  }

  const getLoadings = useSelector(getLoading)
  const [isLoading, setisLoading] = useState<boolean>(false)
  useEffect(() => {
    // console.log("getLoadings",getLoadings)
    if (getLoadings) {
      setisLoading(true)
    }
  }, [getLoadings])

  // move project 3d

  const handleProject3D = () => {
    navigate('/project/view/3d')
  }
  return (
    <>
      <Header />
      <div className='pojects py-3 py-md-5' style={{ marginTop: 10 }}>
        <div className='container'>
          <div className='d-flex align-items-end justify-content-between mb-3'>
            <div className='project-main-title text-start' key='projectstarted'>
              <h4>Project</h4>
               {/* {getUserProjects?.length} */}
              <p className='m-0'>You have {getUserProjects?.length} project</p>
              {/* <p>Give us a moment! We are loading your projects.</p> */}
            </div>
            <Button
              variant='primary'
              className='btn btn-dark rounded-pill d-none'
              key='create_Project'
              onClick={handleCreateProject}
            >
              Create Project
            </Button>
          </div>

          <div className='row g-3 g-md-4'>
            {isLoading && <Loading />}
            {getprojectResponses==""?( <Shimmer />)
           
             : (
              userAllProjects &&
              userAllProjects.length > 0 ?
              ( userAllProjects.map((item: ProjectModel) => (
                <ListOfProject projectItem={item} key={item.id} />
              ))):(
                <span>{getprojectResponses}</span>
              )
             
            )}
          </div>
        </div>
        {isLoading && <Loading />}
      </div>
    </>
  )
}

export default ShowListOfProject
