import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect, useState } from 'react'
import { Button, Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addFirstFolderPath, getMediaFolder } from '../../../../slice/media/MediaSlice'
import FunctionButton from '../functionButton/FunctionButton'
import CreateFolderHome from '../createFolder/CreateFolderHome'
import { folderStructureModel } from '../../../../Model/mediaModel/MediaModel'
import CheckHome from './checkFolderFile/CheckHome'
import "./MainFolderCss.css"
type Props={
  
    homeFolder:()=>void
}
const Mainfolder = ({homeFolder}:Props) => {
    const [firstFolder, setFirstFolder]= useState<string[]>([])
   const [ isCreateFolder, setIsCreateFolder]= useState<boolean>(false)
   const getMediaFolders= useSelector(getMediaFolder)
  const dispatch= useDispatch()
const [ path, setPath]= useState<string|null>(null)
   const [mediaFolder, setMediaFolder]= useState<folderStructureModel[]>([]) 

    useEffect(()=>{
      if(getMediaFolders && 
        getMediaFolders.length>0){
        setMediaFolder(getMediaFolders)
      }
    },[getMediaFolders])

    const handleProjectFolder=(item:string)=>{
     // const path=`${item}/`
      setPath(item)
      // dispatch(addFirstFolderPath(path))
      //   homeFolder()
    }

    const handleResetcheck=()=>{
      setPath(null)
    }

    const handleCreateFolder=()=>{
      setIsCreateFolder(true)
    }

   const handleResetCreateFolder=()=>{
    setIsCreateFolder(false)
   }

  
   const folderRender = (media: folderStructureModel[]) => {
    return (
        <div>
           <FunctionButton
                        createS3Folder={handleCreateFolder}
                        />
            {media && media.length > 0 && 
                media.map(item => (
                
                    item.isShow && (
                      <>
                      
                          <button key={item.name}
                        onClick={()=>handleProjectFolder(item.path??"")}
                        >
                            {item.name}
                        </button>
                        {item &&
                        item.child &&
                        item.child.length>0 &&
                        folderRender(item.child)
                        }
                      </>
                    
                    )
                ))
            }
        </div>
    );
}

  return (
    <div>

      
           
           {mediaFolder &&
           mediaFolder.length>0 &&
           <div>
            {folderRender(mediaFolder)}
           </div>
          
           }
     

            {/*check folder or files  */}

        {  path!=null &&
         < CheckHome
          path={path}
          resetCheck={handleResetcheck}
         />}


     {/* create folder */}
    { isCreateFolder &&
    <CreateFolderHome
    isCreateFolder={isCreateFolder}
    resetCreateFolder={handleResetCreateFolder}
    />
    }
    </div>
  )
}

export default Mainfolder