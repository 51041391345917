import React from 'react'
import ThreeFiber3d from '../../module/3dCanvas/ThreeFiber3d'
import Canvas3DHome from '../../module/3dCanvas/Canvas3DHome'

const ThreeFiberCanvas = () => {
  return (
    <div>
        <Canvas3DHome/>
    </div>
  )
}

export default ThreeFiberCanvas