import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray, getUpdateIndexing, getUpdateSegregation, resetisDeleteSegFromcanvas, resetUpdateSegragation, updateIndexing } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { deleteSegmentFromCanvas, getNewSegment, getStartIndexing, stopIsStartIndexing, updateAfterIndexing } from '../../../../slice/segegratedSlice/SegregatedSlice'
import { Segment } from '../../../../Model/Job/JobModel'

const UpdateAllIndexing = () => {
      const dispatch= useDispatch()
     
      const getUpdateIndexings= useSelector(getUpdateIndexing)
      const getStartIndexings= useSelector(getStartIndexing)
       const getUpdateSegregations= useSelector(getUpdateSegregation)
     
       const getMasterArrays= useSelector(getMasterArray)
     
     
      // delete segment from canavs and update into Db and update indexing
      useEffect(()=>{
      
         // delete data from segregated data
        if(getUpdateIndexings &&
          getUpdateIndexings.childname &&
          getUpdateIndexings.seg_short &&
          getUpdateIndexings.isDeleteSegFromcanvas
        ){
           dispatch(resetisDeleteSegFromcanvas())
         dispatch(deleteSegmentFromCanvas({
            childName:getUpdateIndexings.childname,
            childShortName:getUpdateIndexings.seg_short
          }))


        }

        // update indexing
        if(getUpdateIndexings &&
          getUpdateIndexings.groupName &&
          getUpdateIndexings.subGroupName &&
          getUpdateIndexings.childname&&
          getStartIndexings
        ){
          
          //stop IsStarttIndexingIn Job Slice
          dispatch(stopIsStartIndexing())
          //indexing master Array
          dispatch(updateIndexing({
            groupName:getUpdateIndexings.groupName,
            subGroupName:getUpdateIndexings.subGroupName,
            childName: getUpdateIndexings.childname
          }))
        }
      },[getUpdateIndexings,getStartIndexings])

      // update segration point after index

      useEffect(()=>{
        if(getUpdateSegregations && 
            getUpdateSegregations.isUpdateSegregation &&
            getUpdateSegregations.groupName &&
            getUpdateSegregations.childName
         ){

          // dispatch(updateAfterIndexing({
          //   groupName: getUpdateSegregations.groupName ,
          //   childName:getUpdateSegregations.childName
          // }))
           
          dispatch(resetUpdateSegragation())
       
            }
      },[
        getUpdateSegregations,getMasterArrays
      ])

      
  return (
    <div>
        
    </div>
  )
}

export default UpdateAllIndexing