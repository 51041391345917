import React, { useEffect, useState } from 'react'
import { CatBrandStyleSwatchModel } from '../../../../Model/material/CatBrandStyleSwatchModel'
import DoorSwatch from './doorSwatch/DoorSwatch'
import { getSelectedSegment } from '../../../../slice/userJobSlice/UserJobSlice'
import { useDispatch, useSelector } from 'react-redux'
import WallSwatch from './wallSwatch/WallSwatch'
import { addWallSwatches, addDoorSwatches, getDoorSwatches, getGarageSwatches, getGutterSwatches, getRoofSwatches, getTrimSwatches, getWallSwatches, getWindowSwatches } from '../../../../slice/swatches/SwatchSlices'
import WindowSwatch from './windowSwatch/WindowSwatch'
import RoofSwatch from './roofSwatch/RoofSwatch'
import GarageSwatch from './garageSwatch/GarageSwatch'
import GutterSwatch from './gutterSwatch/GutterSwatch'
import TrimSwatch from './trimSwatch/TrimSwatch'


const GetAllSwatchImage = () => {

    const doorSwatches= useSelector(getDoorSwatches)
    const windowSwatches= useSelector(getWindowSwatches)        
    const roofSwatches= useSelector(getRoofSwatches)
    const trimSwatches= useSelector(getTrimSwatches)
    const garageSwatches= useSelector(getGarageSwatches)
    const gutterSwatches= useSelector(getGutterSwatches)
    const wallSwatches= useSelector(getWallSwatches)
  
    const[isDoor, setIsDoor]=useState(false)
    const[isWindow, setIsWindow]=useState(false)
    const[isRoof, setIsRoof]=useState(false)
    const[isTrim, setIsTrim]=useState(false)
    const[isGarage, setIsGarage]=useState(false)
    const[isGutter, setIsGutter]=useState(false)
    const[isWall, setIsWall]=useState(false)
    const dispatch= useDispatch()

    useEffect(()=>{
           if(doorSwatches.length===0){
            setIsDoor(true)
           }else{
            setIsDoor(false)
           }
    },[doorSwatches])

    useEffect(()=>{
        if(windowSwatches.length===0){
            setIsWindow(true)
        }else{
            setIsWindow(false)
        }
    },[windowSwatches])

    useEffect(()=>{
        if(roofSwatches.length===0){
            setIsRoof(true)
        }else{
            setIsRoof(false)
        }
    },[roofSwatches])   


    useEffect(()=>{
        if(trimSwatches.length===0){
            setIsTrim(true)
        }else{
            setIsTrim(false)
        }
    },[trimSwatches])  
    
    
    useEffect(()=>{
        if(garageSwatches.length===0){
            setIsGarage(true)
        }else{
            setIsGarage(false)
        }
    },[garageSwatches])

    useEffect(()=>{
        if(gutterSwatches.length===0){
            setIsGutter(true)
        }else{
            setIsGutter(false)
        }
    },[gutterSwatches])
 
    useEffect(()=>{
        if(wallSwatches.length===0  ){
            setIsWall(true)
        }else{
            setIsWall(false)
        }
    },[wallSwatches])

  

  const handleDoorSwatches=()=>{
    setIsDoor(false)
  }

  const handeWallSwatches=(data:CatBrandStyleSwatchModel[])=>{
    dispatch(addWallSwatches(data))
    setIsWall(false)
  }
  const handleWindowSwatches=()=>{
    setIsWindow(false)
    }

    const handleRoofSwatches=()=>{
        setIsRoof(false)
    }


    const handleGarageSwatches=()=>{
        setIsGarage(false)
    }

    const handleGutterSwatches=()=>{
        setIsGutter(false)
    }



    const handleTrimSwatches=()=>{
        setIsTrim(false)
    }
  return (
    <>
    


{isDoor &&
 <DoorSwatch
resetswatchDoor={handleDoorSwatches}
/>}

{isWall &&
 <WallSwatch
swatchWall={handeWallSwatches}
/>}

{isWindow &&
 <WindowSwatch
resetswatchDoor={handleWindowSwatches}
/>}

{isRoof &&
 <RoofSwatch
resetswatchRoof={handleRoofSwatches}
/>}

{ isGarage &&
 <GarageSwatch
resetswatchGarage={handleGarageSwatches}
/>}

{isGutter &&
 <GutterSwatch
resetswatchGutter={handleGutterSwatches}
/>      }


{isTrim &&
 <TrimSwatch
resetswatchTrim={handleTrimSwatches}
/>}

    </>
  )
}

export default GetAllSwatchImage