import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialModel, SwatchesModel } from "../../Model/material/MaterialModel";


interface MaterialStates {
    material:MaterialModel[]
    allSwatches:SwatchesModel[]
   
    
}

const initialState: MaterialStates = {
    material:[],
    allSwatches:[]

};

const MaterialSlice = createSlice({
    name: "material",
    initialState,
    reducers: {
        addMaterial: (state, action) => {
            const mat = state.material;
            
            if (mat.length === 0) {
              
                state.material = action.payload;
            } else {
                
                state.material = [...mat, ...action.payload];
            }
        },
       addSwathes:(state,action)=>{
        state.allSwatches=action.payload
       }

    }
}
)

export const {addMaterial ,addSwathes}=MaterialSlice.actions;
export const getMaterial=  (state:{material:MaterialStates})=>state.material.material;
// export const getAllSwatches=  (state:{material:MaterialStates})=>state.material.allSwatches;
export const getAllSwatches = (state: { material: MaterialStates }): SwatchesModel[]=> {
    return state.material.allSwatches|| [];
}
export default MaterialSlice.reducer