import React, { useEffect, useState } from 'react'
import GetAllProject from './showProject/GetAllProject'
import ShowListOfProject from './showProject/ShowListOfProject'
import { useSelector } from 'react-redux'
import { getUserLogin } from '../../slice/user/UserSlice'
import { userModel } from '../../Model/user/UserModel'
import { getLoading } from '../../slice/loading/LoadingSlice'
import Loading from '../loading/Loading'
import GetScreenResolution from '../getUserScreenResolution/GetUserScreenResolution'
import WebSocketComponent from '../webSocket/StartWebSocket'
import RefreshHome from '../../Refresh/RefreshHome'
import ProjectPageRefresh from '../../Refresh/ProjectPageRefresh'
import Header from '../header/Header'
import GetAlgeniAttributes from '../ImageView/exteriorsAi/getAttributes/GetAlgeniAttributes'
import GetAllMaterialsHome from '../getAllMaterials/GetAllMaterialsHome'

const ProjectHome = () => {


  const [isuserProjectApi, setIsUserProjectApi] = useState<boolean>(false)
  const getUserLogins = useSelector(getUserLogin) as userModel[]

  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      if (getUserLogins[0] && 
        getUserLogins[0].projects && getUserLogins[0].projects.length > 0) {
        localStorage.removeItem("getMasterArrays")
        localStorage.removeItem("getSegregateSegments")
      }
    }
  }, [getUserLogins])
  const getLoadings= useSelector(getLoading)
  const [isLoading, setisLoading]= useState<boolean>(false)
  useEffect(()=>{
   console.log("getLoadings",getLoadings)
   if(getLoadings){
     setisLoading(true)
   }else{
    setisLoading(false)
   }
 },[getLoadings])

  return (

    <>
    
      <GetAllProject />
      
    {isLoading && <Loading/>}
      <ShowListOfProject />

      <GetScreenResolution/>
 
 
      <ProjectPageRefresh/> 

      <GetAlgeniAttributes/>

      <GetAllMaterialsHome/>
     
    </>
  )
}


export default ProjectHome
