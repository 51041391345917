import React, { useEffect, useRef, useState } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";
// import EditProjectFrom from "../EditProjectFrom"
import CreateProjectModel from "../CreateProjectModel";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import { Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import Search from "antd/es/transfer/search";
import LeftSection from "../../leftSection/LeftSection";
import EditCreateProject from "../EditCreateProject";
import EditCreateproject from "./SegEditCreateproject";
import SegEditCreateproject from "./SegEditCreateproject";
import { SegmentModel } from "../../../Model/segment/SegmentModel";
import { useSelector } from "react-redux";
import { getAllSegment } from "../../../slice/segment/SegmentSlice";
import Loading from "../../loading/Loading";
import { Image } from "antd";
interface TableData {
  name: string;
 
  labels:string;
  media: string;
  color:string;
  colorcode: string;
 
  state: string;
}

const SegmentTable = () => {
    const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
        {}
      );
      const searchInput = useRef<InputRef>(null);
      const [selectAll, setSelectAll] = useState(false);
      const navigate = useNavigate();
       const getAllSegments= useSelector(getAllSegment)
       const[allSegment, setAllSegments]= useState<SegmentModel[]>([])
      const handleCheckboxChange = (id: string) => {
        setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
      };

      // getAll Segments from rrdux
      useEffect(()=>{
        if(getAllSegments &&
          getAllSegments.length>0
        ){
          setAllSegments(getAllSegments)
        }
      },[getAllSegments])
      const handleSelectAllChange = () => {
        const newCheckedItems: { [key: string]: boolean } = {};
       
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
      };
    
      const [isEditProjectFrom, setIsProEditForm] = useState(false);
    

      const handleEditSegment = () => {
        console.log('handleEditSegment')
        navigate("/segments/edit");
      };

      const columns: TableColumnsType<SegmentModel> = [

        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1 }}>
              <Input
                placeholder="Search Id"
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onKeyUp={(e) => {
                  confirm({ closeDropdown: false });
    
                }}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          ),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                searchInput.current?.select();
              });
            }
          },
          onFilter: (value, record) => {
            if (!record.id) {
              return false;
            }
    
            return record.id.toString() === value.toString();
          },
          filterSearch: true,
        },
        {
          title: "Image",
          dataIndex: "icon",
          key: "icon",
          render: (value, record) => {
            const path =
              "https://dzinlystrapi.s3.us-east-2.amazonaws.com";
            const imagePath = record.icon;
            return <Image width={50} src={`${path}/${imagePath}`} />;
          },
        },
        {
          title: 'Title',
          dataIndex: 'name',
          key: 'name',
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1, }}>
              <Input
                ref={searchInput}
    
                placeholder="Search Title"
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onKeyUp={(e) => {
                  confirm({ closeDropdown: false });
    
                }}
                style={{ width: 188, marginBottom: 8, display: "block" }}
              />
            </div>
          ),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                setTimeout(() => searchInput.current?.select(), 1000);
              });
            }
          },
          onFilter: (value, record) => {
            let check = false;
            const valUpper = value.toString().toUpperCase();
            const valLower = value.toString().toLowerCase();
    
            if (record && record.name) {
              check = record.name.includes(valUpper) || record.name.includes(valLower);
            }
    
    
            return check;
          },
          filterSearch: true,
        },
        {
          title: 'Color',
          dataIndex: 'color',
          key: 'color',
        },
    
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Index',
          dataIndex: 'index',
          key: 'index',
        },
        {
          title: 'Status',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (value, record) => (
            record.isActive ? 'Active' : 'Inactive'
          ),
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          render: (value, record) => {
            return (
              <div className="d-flex action">
              <div
                className="action-icon-table"
              >
                <i className="bi bi-pencil-square mx-0"></i>
              </div>
              <div className="action-icon-table">
                <i className="bi bi-copy mx-0"></i>
              </div>
              <div className="action-icon-table">
                <i className="bi bi-box-arrow-up-right mx-0"></i>
              </div>
              <div className="action-icon-table trash-icon-media">
                <i className="bi bi-trash3 mx-0"></i>
              </div>
            </div>
    
    
    
    
            )
          },
        },
      ]
    
  return (

    < div className="row"> 

      <LeftSection/>

    <div className="col-9 brand-right-section">
    <div className="row mt-3">
      <div className="col-9 brand-title">
        <h1 className="job-title">Segments</h1>
        <p className="m-0">0 entries Found</p>
      </div>

      <div className="col-3 breadcrumb-link">
        <Breadcrumb
          items={[
            // {
            //   title: <a href="">Segment</a>,
            // },
            {
              title: "Segments",
            },
          ]}
        />
      </div>
    </div>

    <div className="row table-input">
      <div className="col-6 search-bar">
        <Search
          placeholder="Search swatch"
          //  onSearch={handleSearch}
          //  enterButton
          // style={{ marginBottom: '20px' }}
        />
      </div>

      <div className="col-6">
      <SegEditCreateproject />
      </div>
    </div>
   

    <Card className="mt-4">
    {allSegment &&
            allSegment.length == 0 ? (
            <Loading />
          ) :
            (
              <Table
                columns={columns}
                dataSource={allSegment?.map((item) => ({ ...item, key: item.id }))}
              // onChange={onChange}
              // showSorterTooltip={{ target: 'sorter-icon' }}
              />)}
    </Card>
  </div>
  </div>
  )
}

export default SegmentTable
