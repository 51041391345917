import axios from "axios";
import { error } from "console";
import { GeneratePromptImageModel, PromptModel } from "../../Model/genAiAttributes/GenAiAttributes";

 const backEndUr=process.env.REACT_APP_BACKEND_URL
const serverApi =process.env.REACT_APP_SERVER_URL


// getAll gen Ai attributes
export function GetAllGenAiAttributes(){
  return axios.get(`${serverApi}/gen-ai-attributes`) 
  .then(response => {
         
    return response
}).catch(error => {
    throw error;
});
}

// generate prompt
 export function GeneratePromptImage(selectedDropdownValues:PromptModel){

  return axios.post(`${backEndUr}/api/prompt-gen`,selectedDropdownValues)
  .then(response=>{
    return response
  }).catch(error=>{
    throw error
  })
 }

//  export function GeneratePromptBaesedImage(date:GeneratePromptImageModel){

//   return axios.post(`${backEndUr}/genai/gen-ai-images`,date)
//   .then(response=>{
//     return response
//   }).catch(error=>{
//     throw error
//   })
//  }

 


// based on prompt generate images
export function GetGenAiStyle(imagePath:string,prompt:string){

    const data={
        url:imagePath ,
        prompt: [prompt]
      }
    return axios.post(`${backEndUr}/genai/gen-ai-images`,data)
    .then(response => {
         
        return response
    }).catch(error => {
        throw error;
    });
}


// get all GEn Ai image
// add job into job collection
export function GetGenAi(projectId:number){
  return axios.get(`${serverApi}/get-gen-ai-image`)
  .then(response => {
     
      return response
  }).catch(error => {
      throw error;
  });
}

