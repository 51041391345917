import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminPage from '../../page/admin/AdminPage';
import AdminJobTablePage from '../../page/admin/adminJob/AdminJobTablePage';
import AdminProjectTablePage from '../../page/admin/adminProject/AdminProjectTablePage';
import AdminEditProjectPage from '../../page/admin/adminProject/AdminEditProjectPage';
import AdminEditJobPage from '../../page/admin/adminJob/AdminEditJobPage';
import AdminBrandTablePage from '../../page/admin/adminBrand/AdminBrandTablePage';
import AdminBrandEditPage from '../../page/admin/adminBrand/AdminBrandEditPage';
import AdminStyleEditPage from '../../page/admin/adminstyle/AdminStyleEditPage';
import AdminStyleTablePage from '../../page/admin/adminstyle/AdminStyleTablePage';
import AdminCategoryTablePage from '../../page/admin/adminCategory/AdminCategoryTablePage';
import AdminCategoryEditPage from '../../page/admin/adminCategory/AdminCategoryEditPage';
import AdminSwatchesTablePage from '../../page/admin/adminSwatches/AdminSwatchesTablePage';
import AdminSwatchesEditPage from '../../page/admin/adminSwatches/AdminSwatchesEditPage';


const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminPage />} />
      <Route path="/jobs" element={<AdminJobTablePage />} />
      <Route path="/jobs/edit" element={<AdminEditJobPage />} />


      <Route path="/projects" element={<AdminProjectTablePage />} />
      <Route path="/projects/edit" element={<AdminEditProjectPage />} />


      <Route path="/swatch-brands" element={<AdminBrandTablePage />} />
      <Route path="/swatch-brand/:id/edit" element={<AdminBrandEditPage />} />


      <Route path="/swatch-categories" element={<AdminCategoryTablePage />} />
      <Route path="/swatch-categories/:id/edit" element={<AdminCategoryEditPage />} />


      <Route path="/swatch-styles" element={<AdminStyleTablePage />} />
      <Route path="/swatch-styles/:id/edit" element={<AdminStyleEditPage />} />


      <Route path="/swatches" element={<AdminSwatchesTablePage />} />
      <Route path="/swatches/edit" element={<AdminSwatchesEditPage />} />


    </Routes>
  );
};

export default AdminRoutes;
