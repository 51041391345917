import React, { useEffect, useRef, useState } from 'react'
import { ProjectDeleteById } from '../../../../api/projects/ProjectApi'
import { useDispatch } from 'react-redux'
import { updateDeletingProject } from '../../../../slice/user/UserProject'

type Props={
    projectId:number
    resetProjectId:(data:boolean)=>void
}
const DeletEProjectById = ({projectId,resetProjectId}:Props) => {
       const [porjectid, setProjectId]= useState<number|null>(null)
       const isApiCall= useRef(true)

       const dispatch=useDispatch()
     useEffect(()=>{
        
        if(projectId && isApiCall.current){
            isApiCall.current= false
            setProjectId(projectId)
        }else {
          //  setProjectId(null)
        }
     },[projectId])

     useEffect(()=>{
        if(porjectid!=null){
            console.log("Id",porjectid)
           // setId(null)
            deleteProjectById(porjectid)  
        }
     },[porjectid])

    const deleteProjectById=async(projectId:number)=>{
        try{
               const response = await ProjectDeleteById(projectId)
               console.log("delete project", response)
               if(response && response.status===200){
               
                dispatch(updateDeletingProject({
                    projectId:projectId
                }))
                       isApiCall.current= true
                       resetProjectId(true)
               }
        }catch(error){
            resetProjectId(false)
            isApiCall.current= true
            console.log("error on Deleting Project")
        }

    }
  return (
    <div>
        


    
    </div>
  )
}

export default DeletEProjectById