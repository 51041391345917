import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { addSvgOption } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { startLoading } from '../../../../slice/loading/LoadingSlice'


type Props={
    isOptionModal:boolean
    onClose:()=>void
    chooseOption:(data:string)=>void
  
}
const OptionModal = ({isOptionModal,onClose,chooseOption}:Props) => {
    
    const dispatch= useDispatch()
    const handleReactCv=()=>{
   

      chooseOption("reactSvg")
    }

    const handleOpenCv=()=>{
      
        chooseOption("openCv")
        
    }

    const handleonClose=()=>{
      onClose()
    }
  return (
    <>
     <Modal
        show={isOptionModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h5 className='m-0'>Select Image Tool</h5>
        </Modal.Header>
        <Modal.Body className='select-svgimg'>
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body text-center">
                  <i className="bi bi-camera-fill fs-1 mb-3"></i>
                  <h5 className="card-title">Open CV</h5>
                  <ul className="list-unstyled text-start">
                    <li><i className="bi bi-check-circle-fill me-2"></i>Feature 1</li>
                    <li><i className="bi bi-check-circle-fill me-2"></i>Feature 2</li>
                    <li><i className="bi bi-check-circle-fill me-2"></i>Feature 3</li>
                  </ul>
                  <button className='btn btn-primary rounded-pill mt-3' onClick={handleOpenCv}>
                    Choose Open CV
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="card h-100">
                <div className="card-body text-center">
                  <i className="bi bi-bezier2 fs-1 mb-3"></i>
                  <h5 className="card-title">React SVG</h5>
                  <ul className="list-unstyled text-start">
                    <li><i className="bi bi-check-circle-fill me-2"></i>Feature 1</li>
                    <li><i className="bi bi-check-circle-fill me-2"></i>Feature 2</li>
                    <li><i className="bi bi-check-circle-fill me-2"></i>Feature 3</li>
                  </ul>
                  <button className='btn btn-primary rounded-pill mt-3' onClick={handleReactCv}>
                    Choose React SVG
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        
      </Modal>
        
    </>
  )
}

export default OptionModal
