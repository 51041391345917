import React, { useEffect, useRef } from 'react'
import { GetAllDoorSwatches } from '../../../../../api/material/catBrandStyleSwatch/CatBrandStyleSwatchApi'
import { CatBrandStyleSwatchModel } from '../../../../../Model/material/CatBrandStyleSwatchModel'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../../../slice/messageToast/ToastSlice'
import { addDoorSwatches } from '../../../../../slice/swatches/SwatchSlices'


type DoorSwatchProps={
    resetswatchDoor: () => void
}
const DoorSwatch = ({resetswatchDoor}:DoorSwatchProps) => {
     const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{
        if(isApi.current){
            getAllDoorSwatches()
            isApi.current=false
        }
    },[])

    const getAllDoorSwatches=async()=>{
        try{    
            const response=await GetAllDoorSwatches()
           // console.log("response Door",response)
            if(response && 
                    response.status===200 &&
                response.data &&
                response.data.materials
                ){
                    dispatch(addDoorSwatches(response.data.materials    ))
                resetswatchDoor()
            }
        }catch(err){
               dispatch(addMessage({
                isShow: true,
                message: "Error in getting all door swatches",
               }))
            console.log("err in getting all door swatches",err)
            resetswatchDoor()
        }
    }

  return (
    <>
    </>
  )
}

export default DoorSwatch