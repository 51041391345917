import React from 'react'
import { Routes, Route } from 'react-router-dom';
import UserTablePage from '../../page/user/UserTablePage';
import UserEdit from '../../page/user/UserEdit';
import DemoTablePage from '../../page/demoTablePage/DemoTablePage';
const DemoRoutes = () => {
  return (
    <Routes>
      <Route path="/table-1" element={<DemoTablePage />} />
      
    </Routes>
  )
}

export default DemoRoutes
