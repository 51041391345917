import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialCategoryModel } from "../../Model/material/MaterialCategoryModel";


interface MaterialcategoryStates {
    materialCategory:MaterialCategoryModel[]
      editCategorydata:MaterialCategoryModel
    
}

const initialState: MaterialcategoryStates = {
    materialCategory:[],
    editCategorydata:{}

};

const MaterialCategorySlice = createSlice({
    name: "materialCategory",
    initialState,
    reducers: {
       addMaterialCategory:(state,action)=>{
        state.materialCategory= action.payload
       },

       addEditcategory:(state,action)=>{
         state.editCategorydata= action.payload
       },
       removeEditCategory:(state)=>{
        state.editCategorydata={}
       },
       updateCategory:(state,action)=>{
        const {data}= action.payload;
        const index= state.materialCategory.findIndex(item=>item.id===data.id);
        if(index!=-1){
            state.materialCategory[index].description= data.description
            state.materialCategory[index].slug= data.slug
            state.materialCategory[index].sort_order= data.sort_order
            state.materialCategory[index].status= data.status
            state.materialCategory[index].title= data.title
        }
       }, 
       deletecategory:(state,action)=>{
              const {id}= action.payload
              const catIndex= state.materialCategory.findIndex(item=>item.id===id)
              if(catIndex!=-1){
                state.materialCategory.splice(catIndex, 1);
              }
       },
        addReduxCategory:(state,action)=>{
            const {data}=action.payload;
            state.materialCategory.push(data)
        }

    }
}
)

export const {addMaterialCategory,updateCategory,
    addEditcategory,removeEditCategory,deletecategory,
    addReduxCategory}=MaterialCategorySlice.actions;
export const getMaterialCategorys=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.materialCategory;
export const getEditCategorys=  (state:{materialCategory:MaterialcategoryStates})=>state.materialCategory.editCategorydata
export default MaterialCategorySlice.reducer