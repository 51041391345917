import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addGroupOnSegmentation,
  DeleteAterAddChildSegment,
  getMasterArray,
  startSegHoverEffect,
  stopSegHoverEffect,
  updatechildrenSegment,
} from "../../../slice/canvas/masterArray/MasterArraySlice";
import {
  GroupListModel,
  MainGroupModel,
} from "../../../Model/groupingModel/GroupingModel";
import {
  GroupMasterSegment,
  JobSegmentationModel,
  MasterArrayModel,
} from "../../../Model/masterArray/MasterArrayModel";
import {
  addGroup,
  afterChangeChildGroup,
  changeChildGroup,
  getGroupList,
  updateGroup,
} from "../../../slice/canvas/groupSlice.tsx/GroupSlice";
import { ReactSVG } from "react-svg";
import "./ListView.scss";
import { OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { debug } from "console";
import LayerPage from "./LayerPage";

interface ListItem {
  id: string;
  title: string;
  isGroup: boolean;
  children?: ListItem[];
}

const ListView: React.FC = () => {
  const [groupList, setGroupList] = useState<MainGroupModel[]>([]);
  const [listData, setListData] = useState<GroupListModel[]>([]);
  const getMasterArrays = useSelector(getMasterArray);
  const [draggedItem, setDraggedItem] = useState<GroupListModel | null>(null);
  const [dropTarget, setDropTarget] = useState<GroupListModel | null>(null);
  const [newGroupTitle, setNewGroupTitle] = useState<string>("");
  const dispatch = useDispatch();
  const [segmentGroupName, setSegmentGroupName] = useState<string | null>(null);
  const [preGroupName, setPreGroupName] = useState<string | null>(null);
  const generateId = () => `id-${Math.random().toString(36).substr(2, 9)}`;
  const isCallFirsttime = useRef(true);
  useEffect(() => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0 &&
      isCallFirsttime.current
    ) {
      isCallFirsttime.current = false;
      const groupArray: MainGroupModel[] = [];
      let groupName;
      getMasterArrays.allSeg.forEach((item) => {
        const mainGroup: GroupListModel[] = [];
        if (item.name) {
          //door
          groupName = item.name;
          const groups = item.segmentation as GroupMasterSegment[];

          if (groups && groups.length > 0) {
            groups.map((grp) => {
              const subGroup: GroupListModel[] = [];
              let subGroupName;
              Object.keys(grp).forEach((key) => {
                //Door1
                subGroupName = key;
                const allSegments = grp[key];

                allSegments.forEach((seg) => {
                  if (seg.name) {
                    subGroup.push({
                      id: generateId(),
                      title: seg.name,
                      isGroup: true,
                      label: seg.segName,
                    });
                  }
                });
              });
              mainGroup.push({
                id: generateId(),
                title: subGroupName,
                isGroup: false,
                children: subGroup,
              });
            });
          }
        }
        groupArray.push({
          id: generateId(),
          title: item.name,
          icon: item.icon,
          groups: mainGroup,
          color_code: item.color_code,
          color: item.color,
        });
      });
      if (groupArray.length > 0) {
        dispatch(addGroup(groupArray));
      }
    }
  }, [getMasterArrays]);

  const getGroupLists = useSelector(getGroupList);
  useEffect(() => {
    if (getGroupLists && getGroupLists.length > 0) {
      setGroupList(getGroupLists);
    } else {
      setGroupList([]);
    }
  }, [getGroupLists]);

  const findItemById = (items: ListItem[], id: string): ListItem | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const result = findItemById(item.children, id);
        if (result) return result;
      }
    }
    return null;
  };

  const removeItem = (
    items: GroupListModel[],
    id: string
  ): GroupListModel[] => {
    return items
      .map((item) => {
        if (item.id === id) return null;
        if (item.children) {
          item.children = removeItem(item.children, id);
        }
        return item;
      })
      .filter(Boolean) as GroupListModel[];
  };

  const insertItem = (
    items: GroupListModel[],
    item: GroupListModel,
    parent: GroupListModel | null
  ): GroupListModel[] => {
    if (parent) {
      return items.map((i) => {
        if (i.id === parent.id) {
          i.children = [...(i.children || []), item];
        } else if (i.children) {
          i.children = insertItem(i.children, item, parent);
        }
        return i;
      });
    }
    return [...items, item];
  };

  const handleSegmentGroup = (SegGroupName: string) => {
   // console.log("SegGroupName", SegGroupName);
    setSegmentGroupName(SegGroupName);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLLIElement>,
    childName: string,
    subgroupName: string,
    groupName: string,
    item: GroupListModel,
    // subGroupName: string
  ) => {
    // console.log("childName", childName);
    // console.log("subgroupName", subgroupName);
    // console.log("groupName", groupName);
    //if (!item.isGroup) return; // Prevent dragging groups
    // console.log("handleDragStart", item);
    // console.log("subGroupName", subGroupName);
    setDraggedItem(item);
    e.dataTransfer.effectAllowed = "move";
    setPreGroupName(subGroupName);
  };

  const handleDrop = (
    e: React.DragEvent<HTMLLIElement>,
    targetItem: GroupListModel | null
  ) => {
    e.preventDefault();
    // console.log("targetItem", targetItem);
    // console.log("draggedItem", draggedItem);
    if (draggedItem && targetItem && draggedItem.id === targetItem.id) return;

    dispatch(
      changeChildGroup({
        groupName: segmentGroupName,
        subGroupName: targetItem?.title,
        child: draggedItem,
      })
    );
    // delete from previous group
    dispatch(
      afterChangeChildGroup({
        groupName: segmentGroupName,
        subGroupName: preGroupName,
        child: draggedItem,
      })
    );
    // update in master Array
    dispatch(
      updatechildrenSegment({
        groupName: segmentGroupName,
        subGroupName: targetItem?.title,
        child: draggedItem,
        preGroupName: preGroupName,
      })
    );

    // delete form master Array

    dispatch(
      DeleteAterAddChildSegment({
        groupName: segmentGroupName,
        subGroupName: targetItem?.title,
        child: draggedItem,
        preGroupName: preGroupName,
      })
    );

    setDraggedItem(null);
    setDropTarget(null);
    setPreGroupName(null);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault(); // Allow drop
  };

  const handleDragEnter = (
    e: React.DragEvent<HTMLLIElement>,
    item: GroupListModel
  ) => {
    e.preventDefault();
    if (draggedItem && !draggedItem.isGroup) {
      setDropTarget(item);
    }
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setDropTarget(null);
  };

  const handleAddGroup = (eachGroup: GroupListModel[]) => {
    if (eachGroup && eachGroup.length > 0) {
      const title = eachGroup[0]?.title;

      if (title && eachGroup.length) {
        const name = title.replace(/\d+/g, ""); // Remove any digits
        const newGroupName = name + (eachGroup.length + 1);

        // add group in group slice
        dispatch(
          updateGroup({
            group: name,
            groupName: newGroupName,
          })
        );

        // add group in
        dispatch(
          addGroupOnSegmentation({
            segmentGroupName: name,
            groupName: newGroupName,
          })
        );
      }
    }

    if (!newGroupTitle.trim()) return;

    const newGroup: ListItem = {
      id: generateId(),
      title: newGroupTitle.trim(),
      isGroup: true,
      children: [],
    };

    setListData((prev) => insertItem(prev, newGroup, null));
    setNewGroupTitle("");
  };
  const [subGroupName, setSubGroupName] = useState<string | null>(null);
  const [dragGroupName, setDragGroupName] = useState<string | null>(null);

  const [isCollapse, setIsCollapse] = useState<boolean>(false);
  const handleGroup = (subGroup: string) => {
    console.log("subGroup", subGroup);
    console.log("subGroupName", subGroupName);
    setDragGroupName(subGroup);
    if (subGroupName == subGroup) {
      setIsCollapse(!isCollapse);
      setSubGroupName(null);
    } else {
      setIsCollapse(true);
    }
    setSubGroupName(subGroup);
  };

  const handleHover = (data: GroupListModel, groupName: string) => {
    if (
      !data.isGroup &&
      data.title &&
      data.children &&
      data.children.length > 0
    ) {
      HoverSubGroup(data.title);
    } else if (data.isGroup && data.label && groupName) {
      HoverChild(data.label, groupName);
    }
  };

  const HoverSubGroup = (subGroupName: string) => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      const modifiedSegName = subGroupName.replace(/[0-9]/g, ""); // Remove numbers

      const getMasterSeg = getMasterArrays.allSeg.find(
        (item) => item.name === modifiedSegName
      ) as MasterArrayModel;
      const allSegmentation =
        getMasterSeg?.segmentation as GroupMasterSegment[];
      if (allSegmentation.length > 0) {
        const allSeg: JobSegmentationModel[] = [];

        allSegmentation.forEach((item) => {
          Object.keys(item).forEach((grpchild) => {
            if (
              item[grpchild] &&
              grpchild === subGroupName &&
              item[grpchild].length > 0
            ) {
              item[grpchild].map((child) => {
                allSeg.push(child);
              });
            }
          });
        });
        if (allSeg.length > 0) {
          dispatch(
            startSegHoverEffect({
              seg: allSeg,
            })
          );
        }
      }
    }
  };

  const HoverChild = (childName: string, groupName: string) => {
    if (
      getMasterArrays &&
      getMasterArrays.allSeg &&
      getMasterArrays.allSeg.length > 0
    ) {
      const modifiedSegName = childName.replace(/[0-9]/g, ""); // Remove numbers

      const getMasterSeg = getMasterArrays.allSeg.find(
        (item) => item.name === modifiedSegName
      ) as MasterArrayModel;
      const allSegmentation =
        getMasterSeg?.segmentation as GroupMasterSegment[];
      if (allSegmentation.length > 0) {
        const allSeg: JobSegmentationModel[] = [];

        allSegmentation.forEach((item) => {
          Object.keys(item).forEach((grpchild) => {
            if (
              item[grpchild] &&
              grpchild === groupName &&
              item[grpchild].length > 0
            ) {
              item[grpchild].map((child) => {
                if (child.segName === childName) allSeg.push(child);
              });
            }
          });
        });
        if (allSeg.length > 0) {
          dispatch(
            startSegHoverEffect({
              seg: allSeg,
            })
          );
        }
      }
    }
  };

  const handleStopHover = () => {
    dispatch(stopSegHoverEffect());
  };
  const renderList = (items: GroupListModel[], groupName: string) => {
    return (
   
        <div>
          <ul className="seg-group-list  p-0 m-0">
            {groupName &&
              items.map((item) => (
                <li
                  key={item.id}
                  onMouseEnter={() => handleHover(item, groupName)}
                  onMouseLeave={handleStopHover}
                  draggable={item.isGroup}
                 // onDragStart={(e) => handleDragStart(e, item, groupName)}
                  onDragOver={handleDragOver}
                  onDragEnter={(e) => handleDragEnter(e, item)}
                  onDrop={(e) => handleDrop(e, item)}
                  onDragEnd={handleDragEnd}
                
                  >
                  <div
                    className=" border-top seg-group-box"
                    onClick={() => handleGroup(item.title || "")}>
                    {item.children && item.children.length > 0 && (
                      <span className="drop-down-icon">
                        <i className="bi bi-chevron-down "></i>
                      </span>
                    )}
                    {item.title} 

                    <div>
                      {/* {item.children && item.children.length > 0 && (
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Add Segments
                            </Tooltip>
                          }>
                          <span className="add-segicon">
                            <i className="bi bi-bounding-box-circles"></i>
                          </span>
                        </OverlayTrigger>
                      )} */}
                      {item.isGroup && (
                        <span
                          className="drag-icon me-2"
                          //  draggable={item.isGroup}
                          //  onDragStart={(e) => handleDragStart(e, item, groupName)}
                          //  onDragOver={handleDragOver}
                          //  onDragEnter={(e) => handleDragEnter(e, item)}
                          //  onDrop={(e) => handleDrop(e, item)}
                          //  onDragEnd={handleDragEnd}
                        >
                          <i className="bi bi-grip-vertical"></i>
                        </span>
                      )}
                    </div>
                  </div>

                  {subGroupName == item.title &&
                    isCollapse &&
                    item.children &&
                      item.children.length > 0 &&
                      item.children.map((child)=>(
                  <div>
                     <ul className="seg-group-list p-0 m-0">
                        <div
                    className=" border-top seg-group-box"
                     >  

                     <li key={child.id}
                     onMouseEnter={() => handleHover(item, groupName)}
                     onMouseLeave={handleStopHover}
                     draggable={child.isGroup}
                     onDragStart={(e) => handleDragStart(e, child.title??"", item.title??"",groupName,child)}
                     onDragOver={handleDragOver}
                     onDragEnter={(e) => handleDragEnter(e, item)}
                     onDrop={(e) => handleDrop(e, item)}
                     onDragEnd={handleDragEnd}
                     className="d-flex align-items-center justify-content-between w-100"
                     >
                      <span>
                     {child.title}
                     </span>
         
                     <span className="drag-icon me-2">
                      <i className="bi bi-grip-vertical"></i>
                    </span>
                    {/* <i className="bi bi-chevron-down dropicon"></i> */}

                     </li>
                     </div>
                     </ul>
                    </div>
                      ))
                    
                    
                    }
                </li>
              ))}
          </ul>
        </div>

    );
  };

  return (
    <>

      <Tabs className="seg-tab-list mt-2 ">
        <Tab
          className="seg-stric-tab "
          eventKey="home"
          title={<span className="tab">Structure</span>}>
          <div>

            <div
              className="accordion accordion-flush seg-accordion "
              id="accordionFlushExample">
              {groupList &&
                groupList.length > 0 &&
                groupList.map((item) => {
                  const collapseId = `flush-collapse-${item.id}`;
                  return (
                    <div className="accordion-item border" key={item.id}>
                      <div
                        className="accordion-header p-2 ps-2 d-flex align-items-center justify-content-between collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${collapseId}`}
                        onClick={() => handleSegmentGroup(item.title ?? "")}>
                        <div className="d-flex align-items-center">
                          <span className="icon">
                            <ReactSVG
                              src={`${item.icon}`}
                              beforeInjection={(svg) => {
                                svg.setAttribute(
                                  "style",
                                  "width: 100%; height: 100%;"
                                );
                              }}
                              onError={(error) => {
                                console.error("Error loading SVG:", error);
                              }}
                            />
                          </span>

                          <span
                            className="ms-3 seg-item-title"
                            aria-expanded="false"
                            aria-controls={collapseId}
                            onClick={() =>
                              handleSegmentGroup(item.title ?? "")
                            }>
                              {/* wall */}
                            {item.title}     
                          </span>
                        </div>

                        {/* Right Side: Color circle and Chevron icon */}
                        <div className="d-flex align-items-center">
                          <span
                            style={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: `${item.color_code}`,
                              borderRadius: "50%",
                              marginRight: "40px", // Add spacing between the color circle and the chevron
                            }}></span>

                          <i className="bi bi-chevron-down dropicon"></i>
                        </div>
                      </div>

                      <div
                        id={collapseId}
                        className="accordion-collapse collapse "
                        data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body p-0">
                          <div className="w-100 d-flex justify-content-between my-1 ">
                          <button
                              className="bg-transparent text-primary border-0 fw-semibold text-center p-2 ms-2 "
                              onClick={() => handleAddGroup(item.groups || [])}>
                           <i className="bi bi-bounding-box-circles me-2"></i>
                              Add Segments
                              
                            </button>
                            <button
                              className="bg-transparent text-primary border-0 fw-semibold text-center p-2 me-2"
                              onClick={() => handleAddGroup(item.groups || [])}>
                              <i className="bi bi-plus-lg px-1"></i> 
                              Add Group
                            </button>
                          </div>
                          <div>
                            {renderList(item.groups || [], item.title ?? "")}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

          </div>
        </Tab>

        <Tab
          className=" seg-accordion"
          eventKey="profile"
          title={<span className="tab">Layers</span>}>
          
       <LayerPage/>
        </Tab>
      </Tabs>

    </>
  );
};

export default ListView;
