export default function Roof({ className = "", ...props }) {
  return (
    <svg
      fill="#000000"
      width="24"
      height="24"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M491.342,0H20.659c-5.632,0-10.199,4.567-10.199,10.199v413.154c0,48.88,39.767,88.646,88.646,88.646 c33.997,0,63.581-19.242,78.447-47.404C192.419,492.758,222.003,512,256,512s63.581-19.242,78.447-47.404 C349.314,492.758,378.897,512,412.894,512c48.88,0,88.646-39.767,88.646-88.646V10.199C501.541,4.567,496.974,0,491.342,0z M167.354,423.354c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248v-80.629 c16.273,19.619,40.825,32.138,68.248,32.138s51.975-12.517,68.248-32.138V423.354z M167.354,285.635v0.58 c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248v-0.58v-81.209c16.273,19.62,40.825,32.138,68.248,32.138 s51.975-12.517,68.248-32.138V285.635z M167.354,147.917c0,37.632-30.616,68.248-68.248,68.248 c-37.632,0-68.248-30.616-68.248-68.248V20.398h136.496V147.917z M324.248,423.354c0,37.632-30.616,68.248-68.248,68.248 s-68.248-30.616-68.248-68.248v-80.629c16.273,19.619,40.825,32.138,68.248,32.138s51.975-12.517,68.248-32.138V423.354z M324.248,285.636v0.58c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248v-0.58v-81.209 c16.273,19.619,40.825,32.138,68.248,32.138s51.975-12.517,68.248-32.138V285.636z M324.248,147.917 c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248V20.398h136.496V147.917z M481.142,423.354 c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248v-80.629c16.273,19.619,40.825,32.138,68.248,32.138 s51.975-12.517,68.248-32.138V423.354z M481.142,285.636v0.58c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248 v-0.58v-81.209c16.273,19.619,40.825,32.138,68.248,32.138s51.975-12.517,68.248-32.138V285.636z M481.142,147.917 c0,37.632-30.616,68.248-68.248,68.248s-68.248-30.616-68.248-68.248V20.398h136.496V147.917z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M462.024,31.669c-5.632,0-10.199,4.567-10.199,10.199v46.916c0,5.632,4.567,10.199,10.199,10.199 s10.199-4.567,10.199-10.199V41.868C472.223,36.236,467.656,31.669,462.024,31.669z"></path>
          </g>
        </g>
        <g>
          <g>
            <path d="M462.024,109.182c-5.632,0-10.199,4.567-10.199,10.199v10.199c0,5.632,4.567,10.199,10.199,10.199 s10.199-4.567,10.199-10.199v-10.199C472.223,113.75,467.656,109.182,462.024,109.182z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}


