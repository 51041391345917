
import axios from "axios";
import { MaterialCategoryModel } from "../../../Model/material/MaterialCategoryModel";
import { MaterialBrandModel } from "../../../Model/material/MaterialBrandModel";

// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll material brands
export function GetAllMaterialBrands() {
    
    return axios.get(`${serverApi}/material-library/brands`)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}


// update 
export function UpdateBrand(data:MaterialBrandModel){
    return axios.post(`${serverApi}/material-library/brand/update`,data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// add
export function AddBrand(data:MaterialBrandModel){
    return axios.post(`${serverApi}/material-library/brand/add`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// delete
export function DeleteBrand(id:number){
    const data={
        id:id
    }
    return axios.post(`${serverApi}/material-library/brand/delete`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
