import React from 'react'
import DasboardPages from '../../module/dasboardpages/DasboardPages'

const DasboardPage = () => {
  return (
    <>
      <DasboardPages/>
    </>
  )
}

export default DasboardPage
