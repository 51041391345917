import { createContext } from "react";
import { Tensor } from "onnxruntime-web";
import { modelInputProps } from "../helper/Interfaces";

interface contextProps {
  click: [modelInputProps | null, (e: modelInputProps | null) => void];
  clicks: [modelInputProps[] | null, (e: modelInputProps[] | null) => void];
  image: [HTMLImageElement | null, (e: HTMLImageElement | null) => void];
  segmentTypes: ["Box" | "Click", (e: "Box" | "Click") => void];
  maskImg: [HTMLImageElement | null, (e: HTMLImageElement | null) => void];
  showLoadingModal: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
  ];
  predMask: [
    Tensor | null,
    React.Dispatch<React.SetStateAction<Tensor | null>>
  ];
  predMasks: [
    Tensor[] | null,
    React.Dispatch<React.SetStateAction<Tensor[] | null>>
  ];
  predMasksHistory: [
    Tensor[] | null,
    React.Dispatch<React.SetStateAction<Tensor[] | null>>
  ];
}

const AppContext = createContext<contextProps | null>(null);

export default AppContext;
