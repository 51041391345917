import React from 'react'

const DemoTablePage = () => {
  return (
    <div>
        {/* <DemoTableHome/> */}
    </div>
  )
}

export default DemoTablePage