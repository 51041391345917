import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import StartWebSocket from "../../module/webSocket/StartWebSocket";


interface WebSocketStates {
    isOpen:boolean,
    webSocketMessage:string
   
    
}

const initialState: WebSocketStates = {
    isOpen:false,
    webSocketMessage:""

};

const WebSocketSlice = createSlice({
    name: "webSocket",
    initialState,
    reducers: {
       startWebSocket:(state)=>{
        state.isOpen=true
       },
       stopWebSocket:(state)=>{
        state.isOpen=false
       },
       addWebSocketMessage:(state,action)=>{
        state.webSocketMessage=action.payload
       }

    }
}
)

export const {startWebSocket, stopWebSocket,addWebSocketMessage}=WebSocketSlice.actions;
export const getWebSocketStatus=  (state:{webSocket:WebSocketStates})=>state.webSocket.isOpen
export const getWebSocketMessage=  (state:{webSocket:WebSocketStates})=>state.webSocket.webSocketMessage
export default WebSocketSlice.reducer