import React, { useEffect } from 'react'
import GenAiCanvas from './GenAiCanvas'
import { useDispatch, useSelector } from 'react-redux'
import { changeSegmentTab, getSwitchCanvas } from '../../../slice/tabControl/TabControlSlice'

const GenAiCanvasHome = () => {

   const getSwitchCanvass= useSelector(getSwitchCanvas)
    const dispatch= useDispatch()
  useEffect(()=>{
    if(getSwitchCanvass==="outline"){
      dispatch(changeSegmentTab('outline'))
    }
    else if(getSwitchCanvass==="compare"){
      dispatch(changeSegmentTab('compare'))
    }
    else if(getSwitchCanvass==="dimension"){
      dispatch(changeSegmentTab('dimension'))
    }
    else if(getSwitchCanvass==="segment"){
      dispatch(changeSegmentTab('segment'))
    }
  },[getSwitchCanvass])
  return (
    <>
        
        <GenAiCanvas/>
    </>
  )
}

export default GenAiCanvasHome