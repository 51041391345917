import React from 'react'
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import "./Compare.css";
import OriginalCanvas from './OriginalCanvas';
import SelectedSwatchCanvas from './SelectedSwatchCanvas';
import { useDispatch } from 'react-redux';
import { changeSegmentTab, switchToOutline } from '../../../slice/tabControl/TabControlSlice';
import { UpdateToolActive } from '../../../slice/toolActive/ToolActiveSlice';
const CompareHome = () => {
  const dispatch= useDispatch()
  // return to main canavs
   // return to main canavs
   const handlehoverLayer = () => {
    dispatch(UpdateToolActive(0))
    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab("segment"));
   
  };
  return (
    <div>
      {/* <h3>Hello</h3> */}
      <div className="re-anno-section"
       onClick={handlehoverLayer}
       >
          <button className=" d-flex  align-items-center btn btn-secondary border-0 fs-6">
            <span className="fs-4">
              <i className="bi bi-arrow-left-short pe-1"></i>
            </span>
            Compare
          </button>
        </div>
         <div
          className='compare-image'>
      <ImgComparisonSlider className='comapare-img' 
    //   hover="hover"
      >
    

        <figure slot="first" className="before">
          <OriginalCanvas/>
 {/* { project_image&&  <img width="100%" src={project_image}></img>} */}
    <figcaption>Before</figcaption>
  </figure>
  <figure slot="second" className="after">
    {/* <img width="100%" src="https://dzinlystrapi.s3.us-east-2.amazonaws.com/before_7e38401ca2.jpg"></img>
     */}
    < SelectedSwatchCanvas/>
    <figcaption>After</figcaption>
  </figure>
    </ImgComparisonSlider>


    </div>
    </div>
  )
}

export default CompareHome