import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectCommentModel } from "../../Model/comments/CommentsModel";
import { truncate } from "fs";


interface ProjectCommentStates {
  projectComments:ProjectCommentModel[],
   isCommentApiHit:boolean,
    isCommentTab:boolean
    iscomment:boolean
    addReply:string
    canvasGroup:{
      groupName:string,
      subGroupName:string,
      segShortName:string,
      segName:string,
      isMainComment:boolean,
      isUserIcon:boolean,
      isEditComment:boolean
      pointer:{
        x:number,
        y :number
      }
    },
  
   
    
}

const initialState: ProjectCommentStates = {
  projectComments:[],
  isCommentApiHit:false,
    isCommentTab:false,
    iscomment:false,
    canvasGroup:{
      groupName:"",
      subGroupName:"",
      segShortName:"",
      segName:"",
      isMainComment:false,
      isUserIcon:false,
      isEditComment:false,
      pointer:{
        x:0,
        y:0
      }
    }
, addReply:""
};

const ProjectCommentSlice = createSlice({
    name: "projectComment",
    initialState,
    reducers: {
      addProjectComment:(state,action)=>{
        const {addMessage}=action.payload
           state.isCommentApiHit=true
          const comment= state.projectComments
       
          if(comment && comment.length==0){
            state.projectComments= action.payload.addMessage.map((comment: ProjectCommentModel) => ({
              ...comment,
              isShow: false,
              isHover:false 
            }));
          }
        
      },
      updateProjectComment:(state,action)=>{
        const {updateMessage}=action.payload
        const comment= state.projectComments
       
        if(comment && comment.length==0){
          state.projectComments= [{
            ...action.payload.updateMessage,
            isShow: false ,
            isHover:false // Set default value for isShow
          }];
        }else{
          state.projectComments.push({
            ...updateMessage,
            isShow: false ,
            isHover:false // Set default value for isShow
          });
        }
      },
      
      openCommentTab:(state)=>{
        state.isCommentTab= true
        state.iscomment=true
      },
      closeCommentTab:(state)=>{
        state.isCommentTab= false
      
      }, 
      addComment:(state,action)=>{
        state.projectComments.forEach(item=>{
          item.isShow= false
        })
        const {groupName,subGroupName,segShortName,segName,isMainComment,isUserIcon,isEditComment, x ,y}= action.payload
        state.canvasGroup.groupName= groupName
        state.canvasGroup.subGroupName= subGroupName
        state.canvasGroup.segShortName= segShortName
        state.canvasGroup.segName= segName
        state.canvasGroup.isMainComment= isMainComment
        state.canvasGroup.isUserIcon= isUserIcon
        state.canvasGroup.isEditComment= isEditComment
        state.canvasGroup.pointer.x=x
        state.canvasGroup.pointer.y=y
        state.addReply=""
        state.isCommentTab= true
        state.iscomment=true


      }, 
      closeMainCanvas:(state)=>{
        state.canvasGroup.isMainComment= false
        state.canvasGroup.isUserIcon= true

      },
       resetCanvasComment:(state)=>{
        state.canvasGroup.groupName= ""
        state.canvasGroup.subGroupName= ""
        state.canvasGroup.segShortName= ""
        state.canvasGroup.segName= ""
        state.canvasGroup.isMainComment= false
        state.canvasGroup.isUserIcon= false
        state.canvasGroup.isEditComment= false
        state.canvasGroup.pointer.x=0
        state.canvasGroup.pointer.y=0
        state.isCommentTab=false
        state.iscomment= false
        state.projectComments=[]
      },
      addReply:(state,action)=>{
        const {_id, x,y}= action.payload
        state.addReply=_id
        state.canvasGroup.pointer.x=x
        state.canvasGroup.pointer.y=y
        state.isCommentTab= true
        state.iscomment= true
        state.canvasGroup.isMainComment= true
      } ,
      updateAddReply:(state,action)=>{
          const {_id,replies}= action.payload
          const getcommmentIndex= state.projectComments.findIndex(item=>item._id==_id)
                if(getcommmentIndex!-1){
                  const getComment= state.projectComments[getcommmentIndex];
                      getComment.replies=replies
                     
                }
               
                
      },
      resetReply:(state)=>{
        state.addReply=""
      },
      resetCommnets:(state)=>{
        state.addReply=""
       // state.isCommentTab= false
        state.iscomment= false
      },
      showCommentsBox:(state,action)=>{
        const{id}=action.payload
        state.addReply=id
        const commentIndex= state.projectComments.findIndex(item=>item._id==id)
        state.projectComments.forEach(comment => {

          comment.isShow = false;

      });
         const show= state.projectComments[commentIndex].isShow

           if(commentIndex!=-1 &&!show){
            state.projectComments[commentIndex].isShow= true

           
          
           }
      },
      hideCommentBox:(state)=>{
        
       
        state.projectComments.forEach(comment => {

          comment.isShow = false;

      });
      },
      commentHover:(state,action)=>{
        const {id}= action.payload
         const commentIndex= state.projectComments.findIndex(item=>item._id==id)
           state.projectComments.forEach(com=>{
            com.isShow= false
           })
           if(commentIndex!=-1){
            const hoverComments= state.projectComments[commentIndex].isShow
              if(!hoverComments){
                state.projectComments[commentIndex].isShow= true
              }
           }
      }
    
    }
}
)

export const {openCommentTab,closeCommentTab,
  addComment,closeMainCanvas,resetCanvasComment,
  addProjectComment,updateProjectComment,
  addReply,resetReply,updateAddReply,resetCommnets,
  showCommentsBox ,commentHover,hideCommentBox
}=ProjectCommentSlice.actions;
export const getComment= (state:{projectComment:ProjectCommentStates})=>state.projectComment;
export const getCavasGroupComments= (state:{projectComment:ProjectCommentStates})=>state.projectComment.canvasGroup;
 export const getAddRelyId=(state:{projectComment:ProjectCommentStates})=>state.projectComment.addReply;
export default ProjectCommentSlice.reducer


