import React from 'react'
import SegmentTable from '../../module/brandPage/Segment/SegmentTable'

const SegmentsTablePage = () => {
  return (
    <div>
   <SegmentTable/>
    </div>
  )
}

export default SegmentsTablePage
