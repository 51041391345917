import React from 'react'
import AdminStyleEdit from '../../../module/admin/AdminMaterial/style/AdminStyleEdit'

const AdminStyleEditPage = () => {
  return (
    <div style={{marginTop:50}}>
      <AdminStyleEdit/>
    </div>
  )
}

export default AdminStyleEditPage