import React from 'react'
import { useFilePicker } from 'use-file-picker';
import {
    FileAmountLimitValidator,
    FileTypeValidator,
    FileSizeValidator,
  } from "use-file-picker/validators";
import MenuComponent from './Component';
import Callary from "../../samImage/icon/callary.png";
import Refresh from "../../samImage/icon/refresh.png";
const UploadImage = () => {

    const { openFilePicker, filesContent,clear } = useFilePicker({
        readAs: "DataURL",
        accept: "image/*",
        multiple: false,
        validators: [
          new FileAmountLimitValidator({ max: 1 }),
          new FileTypeValidator(["jpg", "png"]),
          new FileSizeValidator({ maxFileSize: 50 * 1024 * 1024 /* 50 MB */ }),
        ],
      });
  return (
    <>
        <div className="menu">
          <div className="px-4">
            <div className="gx-5">
              <div className="col" style={{ width: 300 }}>
                <div className="p-3 border bg-light content-layer" id="menu-components">
                  <div className="flex box button-group">
                    <img className="menu-icon" src={Callary} alt="Callary" style={{ width: 50 }} />
                    <div id="fileButton" style={{ marginRight: '15px' }}>
                      <label>Upload</label>
                    </div>
                    <img className="menu-icon" src={Refresh} alt="Refresh" style={{ width: 50 }} />
                    <div id="reset">
                      <label>Reset</label>
                    </div>
                  </div>
                  <MenuComponent
                    title="Click & Hover"
                    description="You can see the status of the segmentation by hovering over it. If you click on an object more than once, you can refine it further."
                  />
                  <button
                    className="bg-purple-900 text-white hover:bg-blue-400 font-bold py-2 px-4 rounded"
                    onClick={() => openFilePicker()}
                  >
                    Select image{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default UploadImage