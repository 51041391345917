import React, { ChangeEvent, useState, useEffect } from 'react'
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Card,
  IconButton,
  InputGroup,
  InputRightElement,
  useToast
} from '@chakra-ui/react'

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

import { useNavigate } from 'react-router-dom'
import AuthApi from './AuthApi'
import { userModel } from '../../../Model/user/UserModel'
import './LoginPage.scss'
import { useDispatch } from 'react-redux'
import { startLoading } from '../../../slice/loading/LoadingSlice'
import Shimmer_Images from './shimmer/Shimmer_Images'
import loginimg from '../../../../public/img/login-img.jpg'

const AssetImage = process.env.REACT_APP_ASSERT_Image
const proimages = `${AssetImage}/images/project-img.jpg`
//const loginimg  = `${AssetImage}/images/login-img.jpg`;

//`${AssetImage}/images/login-img.jpg`

const logoicon = `${AssetImage}/images/logo-icon.svg`

type Props = {
  authStart: (email: string, password: string) => void
}

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loginForm, setLoginForm] = useState({
    email: 'carlos@dzinly.com',
    password: 'Dzinly1!'
  })
  const [formErrors, setFormErrors] = useState({ email: '', password: '' })
  const [isSubmited, setIsSubmited] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [response, setResponse] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isToken, setIsToken] = useState(false)
  const [showPassword, setShowPassword] = useState(false) // Added showPassword state
  const toast = useToast()
 
  useEffect(() => {
    // Your login logic here
  }, [formErrors])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setLoginForm({ ...loginForm, [name]: value })
    setFormErrors({ ...formErrors, [name]: '' }) // Reset errors when input changes
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // Validation logic
    if (!loginForm.email) {
      setFormErrors({ ...formErrors, email: 'Email is Required' })
      return
    }
    if (!loginForm.password) {
      setFormErrors({ ...formErrors, password: 'Password is Required' })
      return
    }
    setIsSubmited(true)
    dispatch(startLoading())
    // authStart(loginForm.email, loginForm.password)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleResetAuth = () => {
    setIsSubmited(false)
    setLoginForm({
      email: '',
      password: ''
    })
  }

    const handleCreateAccount=()=>{
      navigate("/auth/create-account")
    }
  return (
    <div className='container-fluid h-100 g-0' style={{ marginTop: 20 }}>
      <div className='row h-100 g-0'>
        <div className='col-12 col-md-8 h-100'>
          {/* <Shimmer_Images/>  */}
          <img
            className='loginimg'
            src={`${process.env.PUBLIC_URL}/img/login-img.jpg`}
            alt='loginimg'
          />
        </div>

        <div className='col-12 col-md-4'>
          <div className='d-flex align-items-center justify-content-center h-100'>
            <div className='login-box'>
              <div className='card-name'>
                <div className='create-title text-center'>
                  <img
                    width={40}
                    className='logoicon'
                    src={logoicon}
                    alt='logoicon'
                  />
                  <h4 className='mt-3'>Login</h4>
                  <p>Hello! let's join with us</p>
                </div>

                <Card className='login-section'>
                  <Container className='Container container-form login-details-from'>
                    <form onSubmit={handleSubmit}>
                      {' '}
                      {/* Wrap form elements in a form tag */}
                      <FormControl
                        className='form-box email-from mb-3'
                        isRequired
                        isInvalid={formErrors.email !== ''}
                      >
                        <FormLabel className='form-label form-email'>
                          Email
                        </FormLabel>
                        <Input
                          className='Input form-control'
                          type='email'
                          value={loginForm.email}
                          name='email'
                          onChange={handleInput}
                        />
                        <FormErrorMessage className='FormErrorMessage'>
                          {formErrors.email}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        className='login-fromlabel mb-3'
                        isRequired
                        isInvalid={formErrors.password !== ''}
                      >
                        <FormLabel className='form-label'>Password</FormLabel>
                        <InputGroup>
                          <Input
                            className='form-control form-control-pass'
                            type={showPassword ? 'text' : 'password'} // Toggle input type based on showPassword state
                            value={loginForm.password}
                            name='password'
                            onChange={handleInput}
                          />
                          <InputRightElement className='eye-icon'>
                            <IconButton
                              h='1.75rem'
                              size='sm'
                              onClick={togglePasswordVisibility}
                              icon={
                                showPassword ? <ViewOffIcon /> : <ViewIcon />
                              }
                              aria-label='Toggle password visibility' // Add aria-label for accessibility
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {formErrors.password}
                        </FormErrorMessage>
                      </FormControl>
                      <div className='d-flex flex-column align-items-center justify-content-center gap-3'>
                        <Button
                          variant='primary'
                          className='btn btn-primary rounded-pill Button form-btn sub-button px-4'
                          type='submit'
                        >
                          Log In
                        </Button>
                        <Button
                          variant='link'
                          className='p-0 btn btn-link shadow-none'
                        >
                          Forgot Password?
                        </Button>
                        <p className='m-0 d-flex align-items-center gap-1'>
                          Don't have an account?{' '}
                          <Button
                            variant='link'
                            className='p-0 btn btn-link shadow-none text-decoration-none'
                            onClick={handleCreateAccount}
                          >
                            Create Account
                          </Button>
                        </p>
                      </div>
                    </form>
                  </Container>
                </Card>

                {isSubmited && (
                  <AuthApi loginForm={loginForm} resetAuth={handleResetAuth} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
