import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Brands from '../../page/brand/Brands';
import BrandId from '../../page/brand/BrandId';


const BrandRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Brands />} />
      <Route path=":id" element={<BrandId />} />
    </Routes>
  );
};

export default BrandRoutes;
