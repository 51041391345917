import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { Spinner } from '@chakra-ui/react'
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import "./Loading.css";
const Loading = () => {
  return (
    <div className="select-create-project-loading">
      
      
  
      <div className="boxes">
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
    <div className="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>


</div>



  );
};


export default Loading;
