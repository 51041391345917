import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { folderStructureModel, ProjectImage } from "../../Model/mediaModel/MediaModel";

interface MediaStates {
    activeFolder:number,
   mediaFolder:folderStructureModel[],
   firstFolderpath:string,
   secondFolder:string[],
   secondFolderpath:string,
   thirdFolder:string[],
   thirdFolderPath:string,
   allFilesdata:ProjectImage[]
   typeBucket:string
    
}

const initialState: MediaStates = {
    activeFolder:0,
    mediaFolder:[],
    secondFolder:[],
    thirdFolder:[],
    allFilesdata:[],
    firstFolderpath:"",
    secondFolderpath:"",
    thirdFolderPath:"",
    typeBucket:"",
 
};

const MediaSlice = createSlice({
    name: "media",
    initialState,
    reducers: {
        resetMedia:(state)=>{
            state.allFilesdata=[]
            state.firstFolderpath=""
            state.typeBucket="folder"
            state.secondFolder=[]
        },
        addTypeBucket:(state,action)=>{
  state.typeBucket= action.payload.typeBucket
        },
        changeActiveFolder:(state,action)=>{
         state.activeFolder=action.payload.activeFolder
        },
        addFirstFolder:(state,action)=>{
            const {allFolder}=action.payload
            const fold:folderStructureModel[]=[]
            allFolder.map((item:string)=>{
                const data={
                    name:item,
                    isShow:true,
                    type:"folder",
                    path:`${item}/`,
                    child:[],
                    files:[]
                }
                fold.push(data)
            })
            if(fold.length>0){
                state.mediaFolder=fold
            }
        
        },
        
       updateFolder:(state,action)=>{
             const {allData}=action.payload
             const fold:folderStructureModel[]=[]
             let parentFolder:string;
            
             allData.map((item:string)=>{
                const paths = item.split('/');
                 parentFolder=paths[0]
                const data={
                    isShow:true,
                    name:paths[1],
                    type:"folder",
                    path:`${item}`,
                    child:[],
                    files:[]
                }
                fold.push(data)

             }

             )
             if(fold.length>0){
                const folderIndex= state.mediaFolder.findIndex(item=>item.name==parentFolder)
                 if(folderIndex!=-1){
                    state.mediaFolder[folderIndex].child = [...(state.mediaFolder[folderIndex].child || []), ...fold];
                     
                 }
                 state.mediaFolder.map(item=>{
                    if(item.name!=parentFolder){
                        item.isShow= false
                    }
                 })
             }
       },


        addFirstFolderPath:(state,action)=>{
            state.firstFolderpath=action.payload
        },
        resetFirstFolderPath:(state)=>{
            state.firstFolderpath=""
        },
        addSecondFolder:(state,action)=>{
            
            state.secondFolder= action.payload

        } ,
        addSecondFolderPath:(state,action)=>{
       state.secondFolderpath=action.payload
        },
        addThirdFolder:(state,action)=>{
            state.thirdFolder= action.payload

        } ,
        addThirdFolderPath:(state,action)=>{
            state.thirdFolderPath=action.payload
        },
        addFiles:(state,action)=>{
            state.allFilesdata= action.payload
        }
       

    }
}
)

export const {
    addFirstFolderPath,updateFolder,addFirstFolder,resetFirstFolderPath,
    addSecondFolderPath, addSecondFolder,addTypeBucket,
   addThirdFolder,addThirdFolderPath,
    addFiles,
    changeActiveFolder,resetMedia
}=MediaSlice.actions;
export const getMediaFolder=  (state:{media:MediaStates}):folderStructureModel[]=>{
        return state.media.mediaFolder||[]
}
export const getSecondFolder=  (state:{media:MediaStates}):string[]=>{
        return state.media.secondFolder||[]
}
export const getTypeBucket=  (state:{media:MediaStates}):string=>{
        return state.media.typeBucket||""
}
export const getThirdFolder=  (state:{media:MediaStates}):string[]=>{
        return state.media.thirdFolder||[]
}
export const getFilesData=  (state:{media:MediaStates}):ProjectImage[]=>{
        return state.media.allFilesdata||[]
}
export const getactiveFolder=  (state:{media:MediaStates}):number=>{
        return state.media.activeFolder||1
}
export const getFirtsFolderPath=  (state:{media:MediaStates}):string=>{
        return state.media.firstFolderpath||""
}
export const getSecondFolderPath=  (state:{media:MediaStates}):string=>{
        return state.media.secondFolderpath||""
}
export const getThirdFolderPath=  (state:{media:MediaStates}):string=>{
        return state.media.thirdFolderPath||""
}


export default MediaSlice.reducer