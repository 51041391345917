import React from 'react'
import "./layout.scss";
import { Outlet, Link } from 'react-router-dom';

import Footer from '../module/footer/Footer';
import LeftSection from '../module/leftSection/LeftSection';
import { UseDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../slice/user/UserSlice';
import Header from '../components/HeaderTheme';
const Layout = () => {

  const getUserLogins= useSelector(getUserLogin)
  return (
    <div className='main-body-wrapper'>
       
          <main>
           
            <Outlet/>
          </main>
        <Footer/>
    </div>
  )
}

export default Layout