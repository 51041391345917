import React from 'react'
import LeftSection from '../../leftSection/LeftSection'
import EditProjectForm from '../../brandPage/projects/EditProjectFrom'
import GetAllMaterialsHome from '../../getAllMaterials/GetAllMaterialsHome'

const EditProjectAdmin = () => {
  return (
    <div className='row'>


    <LeftSection
     
    />
<div className="col-9 brand-right-section">

         <EditProjectForm/>
        


    

    </div>

 

    {/* getAll materials */}
    <GetAllMaterialsHome/>
  </div>
  )
}

export default EditProjectAdmin