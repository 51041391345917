import React from 'react';
import './App.scss';
import Routes from './routes/Routes';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { AppContextProvider } from './page/samModel/new/useContext/context';
import { ThemeProvider } from './context/ThemeContext';
import ThemeToggle from './components/ThemeToggle';

function App() {
  return (
    <ThemeProvider>
      <AppContextProvider>
        <div className="App">
          {/* <ThemeToggle /> */}
          <Routes />
        </div>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
