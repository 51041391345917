import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface TabStates {
    tabControl:string
    switchCanvas:string|null,
    isDownload:boolean
  
}

const initialState: TabStates = {
    tabControl:"segment",
    switchCanvas:null,
    isDownload:false
   

};

const TabSlice = createSlice({
    name: "tabControl",
    initialState,
    reducers: {
      changeSegmentTab:(state,action)=>{
        state.tabControl=action.payload;
      },
      resetSegmentTab:(state)=>{
        state.tabControl="segment"
        state.switchCanvas="segment"

      },
      switchToOutline:(state,action)=>{
        state.switchCanvas=action.payload
      },
      resetSwitchCanvas:(state)=>{
        state.switchCanvas= null
      },
      startDownload:(state)=>{
        state.isDownload= true
      },
      stopDownload:(state)=>{
        state.isDownload= false
      }
    }
}
)

export const {
  changeSegmentTab,resetSegmentTab,
  switchToOutline,resetSwitchCanvas,
  startDownload,stopDownload
}=TabSlice.actions;
export const getTabControl=  (state:{tabControl:TabStates})=>state.tabControl.tabControl
export const getSwitchCanvas=  (state:{tabControl:TabStates})=>state.tabControl.switchCanvas
export const getDownloadStatus=  (state:{tabControl:TabStates})=>state.tabControl.isDownload
export default TabSlice.reducer