import React from 'react'
import EditSegment from '../../module/brandPage/Segment/EditSegment'

const SegmentEdit = () => {
  return (
    <div>
    <EditSegment/>
   
    </div>
  )
}

export default SegmentEdit
