import React, { useEffect, useState } from 'react'
import GetUserJob from './jobs/GetUserJob'
import GetBase64FromApi from './base64Image/GetBase64FromApi'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage } from '../../slice/messageToast/ToastSlice'
import { getJobBase64, resetJobBase64 } from '../../slice/userJobSlice/UserJobSlice'
import { JobBase64Model } from '../../Model/Job/ExtraModel'
import Base64Image from './base64Image/Base64Image'
import { startLoading, stopLoading } from '../../slice/loading/LoadingSlice'

const GetJobHome = () => {
    const  [imageUrl, setImageUrl] = useState<string | null>()
   const [projectId, setProjectId] = useState<number | null>(null)
   const [jobId, setJobId] = useState<number | null | undefined>(null)
   const [isJobImage, setIsJobImage] = useState<boolean>(false)
   const dispatch= useDispatch()
   const getJobBase64s= useSelector(getJobBase64) as JobBase64Model
   useEffect(()=>{
    if(getJobBase64s &&
         getJobBase64s.imagePath &&
          getJobBase64s.jobId && 
          getJobBase64s.projectId){
            setImageUrl( getJobBase64s.imagePath)
            setJobId(getJobBase64s.jobId)
            setProjectId(getJobBase64s.projectId)
           dispatch(startLoading())
    }
   },[getJobBase64s])

   

   const handleResetJob = (mess: string) => {
    
    if (mess === "success") {
        
      setIsJobImage(true)
    }
    else {
      dispatch(addMessage({
        isShow:true,
        mess:"Some thing went wrong",
         toastType:"Error"
       }))
      
    }
   
  }


  const handleResetImageUrl = (mess: boolean) => {
    setJobId(null)

    setIsJobImage(false)
    setJobId(null)
    setImageUrl(null)
    setProjectId(null)
    dispatch(stopLoading())
    if (mess) {
       
           dispatch(resetJobBase64())
    } else {
        dispatch(resetJobBase64())
      dispatch(addMessage({
        isShow:true,
        mess:"Error in getting base64 image",
         toastType:"Error"
       }))
     
    }

  }

  return (
    <>
    {
        jobId != null &&
        <GetUserJob
          jobId={jobId}
          resetJob={handleResetJob}
        />}


        {/* get base64 through Api */}
       {isJobImage &&
       imageUrl &&
       projectId &&
        <GetBase64FromApi
        url={imageUrl}
        projectId={projectId}
        resetCallApi={handleResetImageUrl}
        />}  

          {/* {
        isJobImage &&
        imageUrl &&
        projectId &&
        <Base64Image
          imageUrl={imageUrl}
          resetbase64={handleResetImageUrl}
          projectId={projectId}

        />
      }   */}
    </ >
  )
}

export default GetJobHome