import React from 'react'
import ImageViewProject from '../../module/ImageView/ImageView'

const ImageView = () => {
  return (
    <div>
     <ImageViewProject/>

  
    </div>
  )
}

export default ImageView
