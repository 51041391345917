import React, { useEffect , useState} from 'react'
import { TimelineModel } from '../../../../Model/project/ProjectModel'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { useSelector } from 'react-redux'
import { getUserProject } from '../../../../slice/user/UserProject'


const TimeLine = () => {
    const getUserProjects= useSelector(getUserProject)
    const getMasterArrays= useSelector(getMasterArray)
    const [currentTimeLine, setCurrentTimeLine]= useState<TimelineModel[]>([])
    useEffect(()=>{

       if(getMasterArrays && 
         getMasterArrays.project_id &&
         getUserProjects && 
         getUserProjects.length>0
       ){
      const getCurrentProject= getUserProjects.find(item=>item.id==getMasterArrays.project_id)
     // console.log("getCurrentProject",getCurrentProject?.timeline)
      if(getCurrentProject && 
       getCurrentProject.timeline &&
       getCurrentProject.timeline.length>0
     ){
      
       setCurrentTimeLine( getCurrentProject.timeline) 
      }
       }
    },[getMasterArrays,getUserProjects])

    // useEffect(()=>{
    //  //console.log("currentTimeLine", currentTimeLine)
    // },[currentTimeLine])
  return (
    <>
      <div className='timeline-content'>
        {
           currentTimeLine &&
           currentTimeLine.length==0?(
            <h4>No timeline</h4>
           ):(
              currentTimeLine.map(item=>{
                return(
                    <div className="timeline-item d-flex">
                    <div className="avatar-section p-2 ps-0 ">
                      <div className="user-avatar-circle position-relative">
                        <div className="avatar-circle-rounded rounded-circle bg-primary text-white d-flex align-items-center justify-content-center">
                          <p className="m-0">{item.name.charAt(0)}</p>
                          <div className="hover-tooltip position-absolute bg-dark text-white px-2 py-1 rounded">
                            <span>{item.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="timeline-details flex-grow-1 d-flex flex-column gap-1">
                      <div className="timeline-text text-gray-800">
                        {item.remark}
                      </div>
                      <div className="timeline-date font-monospace text-gray-600">
                        {item.date.toString()}
                      </div>
                    </div>
                  </div>
      
                )
              })
           ) 
        }
           
            {/* <div className="timeline-item d-flex">
              <div className="avatar-section p-2 ps-0 pt-0">
                <div className="user-avatar-circle position-relative">
                  <div className="avatar-circle rounded-circle bg-success text-white d-flex align-items-center justify-content-center">
                    <p className="m-0">j</p>
                    <div className="hover-tooltip position-absolute bg-dark text-white px-2 py-1 rounded">
                      <span>john.doe@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="timeline-details flex-grow-1 d-flex flex-column gap-1">
                <div className="timeline-text text-gray-800">
                  consectetur adipiscing elit, sed do eiusmod tempor
                </div>
                <div className="timeline-date font-monospace text-gray-600">
                  9/4/2024, 3:15:00 PM
                </div>
              </div>
            </div> */}
          </div>  
    </>
  )
}

export default TimeLine