import React, { useEffect } from 'react'
import { UseDispatch, useDispatch } from 'react-redux'

const EditBrand = () => {
    const dispatch= useDispatch()
  useEffect(()=>{},[
   
  ])
  return (
    <div></div>
  )
}

export default EditBrand