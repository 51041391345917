import React, { useEffect, useRef } from 'react'
import { CreateAccount } from '../../../api/auth/Auth'
import { userModel } from '../../../Model/user/UserModel'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../slice/messageToast/ToastSlice'

type Props={
    userData:userModel
    resetSignUp:()=>void
}
const SignUpCallApi = ({userData,resetSignUp}:Props) => {
       const isApi= useRef(true)
    useEffect(()=>{
        if(isApi.current && userData){
            isApi.current= false
            createAccount(userData)
        }
    },[userData])

     const dispatch= useDispatch()
    const createAccount= async(userData:userModel)=>{
        try{
      const response= await CreateAccount(userData)
       console.log("user register", response)
          if(response && response.status==200){
            isApi.current= true
                resetSignUp()
          }
        }catch(err){
            console.log("error in creating Acount", err)
            dispatch(addMessage({
                        isShow:true,
                        mess:err
                       }))
                       isApi.current= true
                       resetSignUp()
        }
    }
  return (
    <></>
  )
}

export default SignUpCallApi