import React, { useState } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Checkbox,
  Card,
} from "@chakra-ui/react";

// import EditProjectFrom from "../EditProjectFrom"
import CreateProjectModel from "../CreateProjectModel";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import Search from "antd/es/transfer/search";
import LeftSection from "../../leftSection/LeftSection";
import EditCreateProModel from "./EditCreateProModel";

interface TableData {
  username: string;
  id: number;
  email: string;
  confirmed: string;
  role: string;

}

const UserTable = () => {
    const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
        {}
      );
      const [selectAll, setSelectAll] = useState(false);
      const navigate = useNavigate();
      const tableData: TableData[] = [
        {
          id: 1992,
          username: "aa",
          email:"aabb@gmail.com",
            confirmed: "true",
            role: "customer",
       
        },
        {
          id: 1991,
          username: "bb",
          email:"aabb@gmail.com",
            confirmed: "true",
            role: "customer",
        
        },
        {
          id: 1990,
          username: "cc",
          email:
           "aabb@gmail.com",
            confirmed: "true",
            role: "Designer",
      
        },
      ];
    
      const handleCheckboxChange = (id: number) => {
        setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
      };
    
      const handleSelectAllChange = () => {
        const newCheckedItems: { [key: number]: boolean } = {};
        tableData.forEach((row) => {
          newCheckedItems[row.id] = !selectAll;
        });
        setCheckedItems(newCheckedItems);
        setSelectAll(!selectAll);
      };
    
      const [isEditProjectFrom, setIsProEditForm] = useState(false);
    
      const handleuseredit = () => {
        navigate("/user/edit");
      };

  return (

    < div className="row"> 

      <LeftSection/>

    <div className="col-9 brand-right-section">
    <div className="row mt-3">
      <div className="col-9 brand-title">
        <h1 className="job-title">Users</h1>
        <p className="m-0">0 entries Found</p>
      </div>

      <div className="col-3 breadcrumb-link">
        {/* <Breadcrumb
          items={[
            {
              title: <a href="">Segment</a>,
            },
            {
              title: "Segments",
            },
          ]}
        /> */}
      </div>
    </div>

    <div className="row table-input">
      <div className="col-6 search-bar">
        <Search
          placeholder="Search swatch"
          //  onSearch={handleSearch}
          //  enterButton
          // style={{ marginBottom: '20px' }}
        />
      </div>

      <div className="col-6">
      <EditCreateProModel />
      </div>
    </div>
   
    <Card className="mt-4">
      <TableContainer className="seg-table-container">
        <Table variant="simple" className="table-container">
          <Thead className="table-head">
            <Tr>
              <Th>
                <Checkbox
                  isChecked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </Th>
              <Th>ID</Th>
              <Th>USERNAME</Th>
              <Th>EMAIL</Th>
              <Th>CONFIRMED</Th>
              <Th>ROLE</Th>
              <Th>ACTION</Th>
            </Tr>
          </Thead>
          <Tbody className="table-body">
            {tableData.map((row) => (
              <Tr key={row.id}>
                <Td>
                  <Checkbox
                    isChecked={checkedItems[row.id] || false}
                    onChange={() => handleCheckboxChange(row.id)}
                  />
                </Td>
                <Td>{row.id}</Td>
                <Td>{row.username}</Td>
                <Td>
              
                  {row.email}
   
                </Td>
                <Td>{row.confirmed}</Td>
                <Td>{row.role}</Td>
            
                <Td>
               


                  <div className="d-flex action">
            <div className="action-icon-table"  onClick={handleuseredit}>
            <i className="bi bi-pencil-square mx-0"></i>
            </div>

            <div className="action-icon-table">
            <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-box-arrow-up-right mx-0"></i>
            </div> 

            <div className="action-icon-table trash-icon-media">
            <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Card>
  </div>
  </div>
  )
}

export default UserTable
