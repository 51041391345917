import React, { useEffect, useRef } from 'react';
import { MaterialStyleModel } from '../../../../../Model/material/MaterialStyleModel';
import { AddCategories, DeleteCategories, UpdateCategories } from '../../../../../api/material/category/CategoryApi';
import { useDispatch } from 'react-redux';
// import { deletecategory } from '../../../../../slice/materialSlice/MaterialCategorySlice';
import { DeleteStyle } from '../../../../../api/material/style/styleApi';
import { deleteStyle } from '../../../../../slice/materialSlice/MaterialStyleSlice';


type Props = {
  deleteStyleId: number;
  resetDeleteStyle: (data: string) => void;
};

const DeleteStyleApi = ({ resetDeleteStyle, deleteStyleId }: Props) => {

   
  const dispatch = useDispatch();
  const isApi = useRef(true);

  useEffect(() => {
    if (deleteStyleId && isApi.current) {
      deleteCategory(deleteStyleId);
      isApi.current = false;
    }
  }, [deleteStyleId]);

  const deleteCategory = async (id: number) => {
    try {
      const response = await DeleteStyle(id);
      if (response && response.status === 200) {
        // console.log('delete', response);
        isApi.current = true;
        dispatch(
         deleteStyle({
            id: id,
          })
        );
        resetDeleteStyle('success');
      }
    } catch (err) {
      isApi.current = true;
      resetDeleteStyle('fail');
    }
  };
  

  return <div></div>;
};

export default DeleteStyleApi;
