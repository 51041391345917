import Door from "./door";
import Roof from "./roof";
import Wall from "./wall";
import Window from "./window";
import Autoloop from "./autoloop";

export const BottombarIcons = {
  Door,
  Roof,
  Wall,
  Window,
  Autoloop,
};
