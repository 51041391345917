import React, { useEffect, useRef } from 'react'
import { AddStyle } from '../../../../../api/material/style/styleApi'

import { useDispatch } from 'react-redux'
import { addMaterialStyle } from '../../../../../slice/materialSlice/MaterialStyleSlice'


import { MaterialStyleModel } from '../../../../../Model/material/MaterialStyleModel'
import { AddBrand } from '../../../../../api/material/brand/BrandApi'
import { addNewBrand } from '../../../../../slice/materialSlice/MaterialBrandSlice'


type Props={
    resetAdd:(data:string)=>void
    addBrandData:MaterialStyleModel
}
const AddStyleApi = ({resetAdd,addBrandData}:Props) => {
const dispatch= useDispatch()
     const isApi= useRef(true)
    useEffect(()=>{

      if(addBrandData && 
         addBrandData.title && 
         isApi.current
      ){
         isApi.current= false
         addBrand(addBrandData)  
      }
    },[addBrandData])

    const addBrand=async(data:MaterialStyleModel)=>{

         try{
            const response= await AddBrand(data);
             if(response && response.status===200){
               //  console.log("add", response)
                isApi.current= true
                  dispatch(addNewBrand({
                     data:response.data
                  }))
                resetAdd("success")
             }
         }catch(err){
            isApi.current= true
            resetAdd("fail")
         }
    }
  return (
    <div></div>
  )
}

export default AddStyleApi