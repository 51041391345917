import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addUserProject, getUserProject } from '../slice/user/UserProject'
import { getUserLogin } from '../slice/user/UserSlice'
import AuthRefresh from './AuthRefresh'
import { addSegment, getAllSegment } from '../slice/segment/SegmentSlice'
import { is } from '@react-three/fiber/dist/declarations/src/core/utils'

const ProjectPageRefresh = () => {
    const dispatch= useDispatch()
   const isUserProject= useRef(true)
   const getAllSegments= useSelector(getAllSegment)
    const getUserProjects= useSelector(getUserProject) 
    const getUserLogins= useSelector(getUserLogin)
    const isprojects= useRef(true)
    const isUserLogin= useRef(true)
    const isAllSegment= useRef(true)
    useEffect(()=>{
        if(getUserProjects && getUserProjects.length===0){
            isUserProject.current=true
            const projectData= localStorage.getItem("getUserProjects")
            if(projectData){
                const projectDataParse= JSON.parse(projectData)
                dispatch(addUserProject(projectDataParse))
            }
        }else if (getUserProjects &&
             getUserProjects.length>0 && 
             isUserProject.current ){
                isUserProject.current=false
            localStorage.setItem("getUserProjects",JSON.stringify(getUserProjects))
        }
    },[getUserProjects])



     useEffect(()=>{
        if(getAllSegments && getAllSegments.length===0){
            isAllSegment.current= true
            const allSegment= localStorage.getItem("getAllSegments")
            if(allSegment){
                const allSegementdata= JSON.parse(allSegment)
                dispatch(addSegment(allSegementdata))
            }
            
        }else if(getAllSegments && getAllSegments.length>0 && isAllSegment.current){
            isAllSegment.current= false
            localStorage.setItem("getAllSegments",JSON.stringify(getAllSegments))
        }
     },[getAllSegments])


  
  return (
    <div>

        <AuthRefresh/>
    </div>
  )
}

export default ProjectPageRefresh