import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SamModel } from "../../Model/Job/SamModel";

interface ModelDataStates {
  apiModelData: SamModel[] // specify the type if possible
  isupload:boolean,
  uploadMessage:string
  isRegeneration:boolean
}

const initialState: ModelDataStates = {
  apiModelData: [],
  isupload:false,
  uploadMessage:"", 
  isRegeneration:false
};

const ApiModelDataSlice = createSlice({
  name: "apiModel",
  initialState,
  reducers: {
    addModelData: (state, action) => {
     
      state.apiModelData.push(action.payload)
    },
    startUplaod:(state,action)=>{
      state.isupload=action.payload
    },
    addUploadMessage:(state,action)=>{
      state.uploadMessage= action.payload
    },
    addRegeneration:(state,action)=>{
     state.isRegeneration=action.payload
    },
    resetModalData:(state)=>{
      state.apiModelData=[] 
    },
    resetUploadMessage:(state)=>{
      state.isupload=false
      state.uploadMessage=""
    },
    resetIsRegenaration:(state)=>{
  state.isRegeneration=false
    }
  },
});

export const { addModelData,resetModalData,startUplaod ,
                addUploadMessage,resetUploadMessage,
                addRegeneration,resetIsRegenaration} = ApiModelDataSlice.actions;
export const getModelData = (state: { apiModel: ModelDataStates }) =>state.apiModel.apiModelData;
export const getIsUploadStart = (state: { apiModel: ModelDataStates }) =>state.apiModel.isupload;
export const getUploadMessage = (state: { apiModel: ModelDataStates }) =>state.apiModel.uploadMessage;
export const getIsRegenaration = (state: { apiModel: ModelDataStates }) =>state.apiModel.isRegeneration;
export default ApiModelDataSlice.reducer;
