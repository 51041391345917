import React, { useState } from 'react'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import LeftSection from '../../../leftSection/LeftSection'
import CategoryTable from '../../../brandPage/category/CategoryTable'
import Header from '../../../header/Header'
import Sidebar from '../../../dasboardpages/components/Sidebar'
import PageContentHeader from '../../../dasboardpages/components/pageContent/PageContentHeader'
import SearchAdd from '../../../dasboardpages/components/pageContent/SearchAdd'

const AdminCategoryTable = () => {
  const [isActive, setIsActive] = useState(false); // State to track whether the div is active
  const [firstHeader, setFirstHeader] = useState<string>('Material Library'); 
  const [secondHeader, setSecondHeader] =useState<string>('Category') 
 
  const [breadCum1, setbreadCum1] =useState<string>('Home') 
  const [breadCum2, setbreadCum2] =useState<string>('Library') 
  const [breadCum3, setbreadCum3] =useState<string>('Category') 
 
  
 


  const toggleActiveClass = () => {
    setIsActive(!isActive); // Toggle the isActive state
  };
  return (
  <>
 
 <div  className={`wrapper ${isActive ? 'active' : ''}`}>
         <Sidebar toggleActiveClass={toggleActiveClass} />
        {/* content header */}
        
         <PageContentHeader
         firstHeader={firstHeader}
         secondHeader={secondHeader}
         breadCum1={breadCum1}
         breadCum2={breadCum2}
         breadCum3={breadCum3}
         
          />
        

        <SearchAdd/>

          {/* table */}
         
          <CategoryTable/>



         
    </div>

<GetAllMaterialsHome />
    {/* <div className='row'>


<LeftSection
 
/>
<div className="col-9 brand-right-section">

   <CategoryTable/>

</div>




<GetAllMaterialsHome/>
</div> */}
  </>
  )
}

export default AdminCategoryTable