import React, { useRef, useState } from 'react'
import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea, RadioGroup, Radio } from '@chakra-ui/react';

const CreateEditProject = () => {
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFile(event.dataTransfer.files[0]);
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null);
        }
    };

    const handleDragImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };


    return (
        <div>
            {/* onClick={handleBackToProjects} */}
            <div className="back-icon"  >
                <i className="bi bi-arrow-left-short"></i>
                <span>Back</span>
            </div>

            <Card className="card-edit py-4 mt-4">
                <div className="row container from-edit">
                    <div className="col-12 from-list">
                        <FormLabel>Name</FormLabel>
                        <Input className="input-primary" />
                    </div>

                    <div className="col-12 from-list">
                        <FormLabel>Color</FormLabel>
                        <Input className="input-primary" />
                    </div>
                    
                  

                    <div className="col-12 from-lable from-list">
                        <FormLabel>icon</FormLabel>
                        <Box
                            className="box-primary"
                            border="1px solid #cbd5e0"
                            borderRadius="md"
                            padding="20px"
                            textAlign="center"
                            onClick={handleDragImage}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30"
                                fill="currentColor"
                                className="bi bi-image-fill"
                                viewBox="0 0 16 16">
                                <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
                            </svg>
                            <Text
                                mt={2}
                                color="gray.500"
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "rgb(102, 102, 135)",
                                }}>
                                Click to add an asset or drag and drop one in this area
                            </Text>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                display="none"
                            />
                        </Box>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>Status</FormLabel>
                        <div className='radio-button radio-button-from'>
                            <RadioGroup className='radio-btn'
                            // onChange={handleStatus}
                            // value={status !== undefined ? status.toString() : ''}
                            >
                                <Radio value="1">Active</Radio>
                                <Radio value="0">Inactive</Radio>
                            </RadioGroup>
                        </div>

                    </div>

                   



                    <div className="col-6 from-list">
                        <FormLabel>ColorCode</FormLabel>
                        <Input className="input-primary" />
                    </div>
                

                    <div className="col-12 from-lable from-list">
                        <FormLabel>Label</FormLabel>
                        <Select className="input-primary" placeholder="Add relation">

                            <option>Demo Door</option>
                            <option>Demo Wall</option>
                            <option>Demo Window</option>
                            <option>Demo Roof</option>
                            <option>Closed</option>

                        </Select>
                    </div>

                    <div className="col-6 mt-2">
                        <FormLabel>index</FormLabel>
                        <NumberInput style={{ backgroundColor: "#fff" }}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

               
                    <div className="col-6 mt-2">
                        <FormLabel>Show</FormLabel>
                        <div className='radio-button radio-button-from'>
                            <RadioGroup className='radio-btn'
                            // onChange={handleStatus}
                            // value={status !== undefined ? status.toString() : ''}

                            >
                                <Radio value="1">Active</Radio>
                                <Radio value="0">Inactive</Radio>
                            </RadioGroup>
                        </div>

                    </div>


                 

                


                </div>
            </Card>

        </div>
    )
}

export default CreateEditProject
