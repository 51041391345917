import React, { useEffect, useRef, useState } from "react";
import "./Swatch.css";
import { SwatchesModel } from "../../../Model/material/MaterialModel";
import { Breadcrumb, Card, Input, InputRef, Table, TableColumnsType } from "antd";
import Loading from "../../loading/Loading";
import { useSelector } from "react-redux";
import { getAllSwatches } from "../../../slice/materialSlice/MaterialSlice";
import CreateNewEntrySwatch from "./CreateNewEntrySwatch";
import { Image } from "antd";
import Search from "antd/es/transfer/search";
import { title } from "process";
const Swatch = () => {
  const [allSwatches, setAllSwatches] = useState<SwatchesModel[]>([]);
  const searchInput = useRef<InputRef>(null);
  const searchInputBrand = useRef<InputRef>(null);
  const searchInputStyle = useRef<InputRef>(null);

  const searchInputTitle = useRef<InputRef>(null);

  const getAllSwatche = useSelector(getAllSwatches);

  const columns: TableColumnsType<SwatchesModel> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            placeholder="Search Id"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.current?.select();
          });
        }
      },
      onFilter: (value, record) => {
        if (!record.id) {
          return false;
        }

        return record.id.toString() === value.toString();
      },
      filterSearch: true,
    },
    {
      title: "Image",
      dataIndex: "photo",
      key: "phote",
      render: (value, record) => {
        const path =
          "https://dzinlyv2.s3.us-east-2.amazonaws.com/liv/materials";
        const imagePath = record.photo;
        return <Image width={50} src={`${path}/${imagePath}`} />;
      },
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8, width: "300px", position: "absolute", top: -90, zIndex: 1 }}>
          <Input
            ref={searchInputTitle}
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onKeyUp={() => confirm({ closeDropdown: false })}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInputTitle.current?.select(), 100);
        }
      },
      onFilter: (value, record) => {
        if (!record.title) return false;
        return record.title.toLowerCase().includes(value.toString().toLowerCase());
      },
      filterSearch: true,
    },


    
    {
      title: "Style",
      dataIndex: "style_brand_category",
      key: "style_brand_category",
    
      render: (value, record) => {
        return record.style_brand_category?.title;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            ref={searchInputStyle}
            placeholder="Search Style"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            setTimeout(() => searchInput.current?.select(), 1000);
          });
        }
      },
      onFilter: (value, record) => {
        let check = false;
        const val = value.toString().toLowerCase();
    
        if (record && record.style_brand_category) {
          const title = record.style_brand_category.title?.toLowerCase() || '';
          check = title.includes(val);
        }
       
    
        return check;
      },
      filterSearch: true,
    },
    

    
    {
      title: "Brand",
      dataIndex: "style_brand_category",
      key: "style_brand_category",
      render: (value, record) => {
        return record?.style_brand_category?.brand?.title;
      },
   
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            ref={searchInputBrand}
            placeholder="Search Brand"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            setTimeout(() => searchInput.current?.select(), 1000);
          });
        }
      },
      onFilter: (value, record) => {
        let check = false;
        const val = value.toString().toLowerCase();
    
        if (record && record.style_brand_category && record.style_brand_category.brand) {
          const title = record.style_brand_category.brand.title?.toLowerCase() || '';
          check = title.includes(val);
        }
    
        return check;
      },
      filterSearch: true,
    },
    
    
    
    

    
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, record) => {
        return record?.style_brand_category?.brand?.category?.title;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
          style={{
            padding: 8,
            width: "300px",
            position: "absolute",
            top: -90,
            zIndex: 1,
          }}>
          <Input
            ref={searchInput}
            placeholder="Search Category"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            setTimeout(() => searchInput.current?.select(), 1000);
          });
        }
      },
      onFilter: (value, record) => {
        const valUpper = value.toString().toUpperCase();
        const valLower = value.toString().toLowerCase();
    
        if (record && record.style_brand_category &&
           record.style_brand_category.brand &&
            record.style_brand_category.brand.category && 
            record.style_brand_category.brand.category.title) {
          const categoryTitle = record.style_brand_category.brand.category.title;
          return (
            categoryTitle.toUpperCase().includes(valUpper) ||
            categoryTitle.toLowerCase().includes(valLower)
          );
        }
    
        return false;
      },
      filterSearch: true,
    },
    

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: number) => (status === 1 ? "Active" : "Inactive"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: () => {
        return (
          <div className="d-flex action">
            <div className="action-icon-table">
            <i className="bi bi-pencil-square mx-0"></i>
            </div>

            <div className="action-icon-table">
            <i className="bi bi-copy mx-0"></i>
            </div>
            <div className="action-icon-table">
              <i className="bi bi-box-arrow-up-right mx-0"></i>
            </div> 

            <div className="action-icon-table trash-icon-media">
            <i className="bi bi-trash3 mx-0"></i>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (getAllSwatche && getAllSwatche.length > 0) {
      setAllSwatches(getAllSwatche);
    }
  }, [getAllSwatche]);

  return (
    <>
      <div>
        <div className="row mt-3">
          <div className="col-9 brand-title">
            <h1 className="job-title">Swatch</h1>
            <p className="m-0">4 entries Found</p>
          </div>

          <div className="col-3 breadcrumb-link">
          <Breadcrumb
              items={[
                {
                  title: <a href="">Material Library</a>,
                },
                {
                  title: 'Swatch',
                },
              ]}
            />
          </div>


       

          <div className='row table-input'>

          <div className='col-6 search-bar'>
            <Search
              placeholder="Search swatch"
            //  onSearch={handleSearch}
            //  enterButton
            // style={{ marginBottom: '20px' }}
            />
          </div>
          
          <div className='col-6'>
          <CreateNewEntrySwatch />
          </div>
        </div>


        </div>
        <Card className='mb-5 card-table-category'>
        {allSwatches && allSwatches.length == 0 ? (
          <Loading />
        ) : (
          <Table
            columns={columns}
            dataSource={allSwatches?.map((item) => ({ ...item, key: item.id }))}
            // onChange={onChange}
            // showSorterTooltip={{ target: 'sorter-icon' }}
            pagination={{
              position: ['topRight', 'bottomRight'], // Positions pagination at the top and bottom
              defaultPageSize: 200,
             
              showTotal: (total) => <span className="ant-pagination-total-text ">Total <span className='total-page '> <i> {total} </i></span> items</span>,
              showSizeChanger: true, // Show page size changer
              pageSizeOptions: ['100', '200', '300', '400', '500', '600', '1000'], // Page size options
            }}
          />
          
        )}
              </Card>
      </div>
    </>
  );
};

export default Swatch;
