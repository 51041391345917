import React, { useEffect, useRef, useState } from 'react'

import { JobModel } from '../../../Model/Job/JobModel'
import { addUserJob } from '../../../api/jobs/JobApi'
import { useDispatch, useSelector } from 'react-redux'
import { getUserJobData, updateJobId } from '../../../slice/userJobSlice/UserJobSlice'
import { resetModalData } from '../../../slice/modelData/ModelDataSlice'



type Props={
    resetJob:(data:string)=>void,
    
}
const JobDB = ({ resetJob}:Props) => {
 const isApi= useRef(true)
 const isApi2= useRef(true)
 const dispatch= useDispatch()
     const getUserJobDatas= useSelector(getUserJobData)
     const[addJob, setAddJob]= useState<JobModel|null>(null)

        useEffect(()=>{
          
            if(getUserJobDatas &&
              getUserJobDatas.image!="" &&
              isApi.current
            ){
              isApi.current= false
              setAddJob(getUserJobDatas)
            }
        },[getUserJobDatas])

        useEffect(()=>{
          if(addJob && addJob.image!="" && isApi2.current){
            isApi2.current= false
            setAddJob(null)
            addJobDb(addJob)
          }
        },[addJob])

    const addJobDb=async(data:JobModel)=>{
     
       try{
        const response= await addUserJob(data);
       
          if(response  && response.status==200){
            setAddJob(null)
            isApi.current=true
            isApi2.current= true
            dispatch(updateJobId({
              job:response.data
            }))
            dispatch(resetModalData())
           
              resetJob("success")
          }
       }
       catch(err){
        setAddJob(null)
            isApi.current=true
            isApi2.current=true
       
        resetJob("fail")
       }
    }
  return (
    <div>
        
    </div>
  )
}

export default JobDB