// src/slice/appSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tensor } from "onnxruntime-web";
import { modelInputProps } from '../../page/samModel/new/helper/Interfaces';

interface SamModelState {
  click: modelInputProps|null;
  clicks: modelInputProps[];
  image: string | null;
  maskImg: string | null;
  predMask: Tensor | null;
  predMasksHistory: Tensor | null;
  predMasks: Tensor | null;
  showLoadingModal: boolean;
  segmentTypes:string
  imageData:{
    width:number,
    height:number
  }
}

const initialState: SamModelState = {
  click: null,
  clicks: [],
  image: null,
  maskImg: null,
  predMask: null,
  predMasksHistory: null,
  predMasks: null,
  showLoadingModal: false,
  segmentTypes:"Click", 
  imageData:{
    width:0,
    height:0
  }
};

const SamModelSlice = createSlice({
  name: 'samModelSlice',
  initialState,
  reducers: {

    addImageData:(state,action)=>{
      const {width, height}=action.payload
      state.imageData.width= width
      state.imageData.height= height
    },
    setClick(state, action) {
      state.click = action.payload;
    },
    setClicks(state, action) {
      state.clicks = action.payload;
    },
    setImage(state, action) {
      state.image = action.payload;
    },
    setMaskImg(state, action) {
      state.maskImg = action.payload;
    },
    setPredMask(state, action) {
      state.predMask = action.payload;
    },
    setPredMasksHistory(state, action) {
      state.predMasksHistory = action.payload;
    },
    setPredMasks(state, action) {
      state.predMasks = action.payload;
    },
    setShowLoadingModal(state, action) {
      state.showLoadingModal = action.payload;
    },
    setSegmentTypes(state, action) {
      state.segmentTypes = action.payload;
    },
  },
});

export const {
  setClick,
  setClicks,
  setImage,
  setMaskImg,
  setPredMask,
  setPredMasksHistory,
  setPredMasks,
  setShowLoadingModal,
  addImageData
} = SamModelSlice.actions;

export const getClick = (state: { samModelSlice: SamModelState })=> state.samModelSlice.click||null;

export const getClicks = (state: { samModelSlice: SamModelState })=> state.samModelSlice.clicks||null;

export const getImage = (state: { samModelSlice: SamModelState })=>state.samModelSlice.image||null;

export const getMaskImg = (state: { samModelSlice: SamModelState })=> state.samModelSlice.maskImg||null;
export const getPredMask = (state: { samModelSlice: SamModelState })=> state.samModelSlice.predMask||null;
export const getpredMasksHistory = (state: { samModelSlice: SamModelState })=> state.samModelSlice.predMasksHistory||null;
export const getPredMasks = (state: { samModelSlice: SamModelState })=> state.samModelSlice.predMasks||null;
export const getSegmentTypes = (state: { samModelSlice: SamModelState })=> state.samModelSlice.segmentTypes||null;
export const getImageData = (state: { samModelSlice: SamModelState })=> state.samModelSlice.imageData||null;


export default SamModelSlice.reducer;
