import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MaterialStyleModel, SwatchStyleModel } from "../../Model/material/MaterialStyleModel";

interface MaterialStyleStates {
    materialStyle: MaterialStyleModel[];
    swatchStyle: SwatchStyleModel[];
    editStyle: SwatchStyleModel;
}

const initialState: MaterialStyleStates = {
    materialStyle: [],
    swatchStyle: [],
    editStyle: {} as SwatchStyleModel,
};

const MaterialStyleSlice = createSlice({
    name: "materialStyle",
    initialState,
    reducers: {
        addMaterialStyle: (state, action) => {
            state.materialStyle = action.payload;
        },
        addSwatchStyle: (state, action) => {
            state.swatchStyle = action.payload;
        },
        addEditStyle: (state, action) => {
            state.editStyle = action.payload;
        },

        updateEditBrand: (state, action) => {
            const { data } = action.payload;
            const brandIndex = state.materialStyle.findIndex((item) => item.id === data.id);
            if (brandIndex !== -1) {
                state.materialStyle[brandIndex].description = data.description;
                state.materialStyle[brandIndex].material_brand_id = data.material_category_id;
                state.materialStyle[brandIndex].title = data.title;
                state.materialStyle[brandIndex].slug = data.slug;
                state.materialStyle[brandIndex].sort_order = data.sort_order;
                state.materialStyle[brandIndex].status = data.status;
            }
        },
        addNewBrand: (state, action) => {
            const { data } = action.payload;
            state.materialStyle.push(data);
        },
        resetEditBrand: (state) => {
            state.editStyle = {} as SwatchStyleModel;
        },
        deleteStyle: (state, action) => {
            const { id } = action.payload;
            const brandIndex = state.swatchStyle.findIndex((item) => item.id === id);
            if (brandIndex !== -1) {
                state.swatchStyle.splice(brandIndex, 1);
            }
        },
    },
});

export const {
    addMaterialStyle,
    addSwatchStyle,
    addEditStyle,
    updateEditBrand,
    addNewBrand,
    resetEditBrand,
    deleteStyle,
} = MaterialStyleSlice.actions;

export const getMaterialStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.materialStyle;
export const getSwatchStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.swatchStyle;

export const getEditSwatchStyle = (state: { materialStyle: MaterialStyleStates }) =>
    state.materialStyle.editStyle;

export default MaterialStyleSlice.reducer;
