import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedSegment, getUserJobBase64 } from '../../../slice/userJobSlice/UserJobSlice';
import { getCanvasSize } from '../../../slice/userScreenSlice/userScreenResolution';
import { getMasterArray, getReAnnotationSegment } from '../../../slice/canvas/masterArray/MasterArraySlice';
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice';
import { PointModel } from '../../../Model/masterArray/MasterArrayModel';
import { changeSegmentTab, switchToOutline } from '../../../slice/tabControl/TabControlSlice';
import { Object, Transform } from 'fabric/fabric-impl';


interface CustomCircleOptions extends fabric.ICircleOptions {
    id?: number;
    className?: string;
  }
  interface CustomLineOptions extends fabric.ILineOptions {
    id?: number;
    className?: string;
  }
  
  interface CustomFabricObject extends fabric.Object {
    id?: string | number;
  }
  interface EditablePolygon extends fabric.Polygon {
    edit?: boolean;
    points?: fabric.Point[];
  }
  
  // Define a type for controls with dynamic keys
  interface PolygonControls {
    [key: string]: fabric.Control;
  }
const EditAnnoatationCanvas = () => {

    const canvasRef = useRef<fabric.Canvas|null>(null);
    const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
   
    const magnifierContentRef = useRef<HTMLCanvasElement | null>(null);
    
    const getUserJobBase64s = useSelector(getUserJobBase64);
    const [scaleX, setScalex] = useState<number|null>();
    const [scaleY, setScaley] = useState<number|null>();
    const scaleRefX = useRef<number | null>(null);
    const scaleRefY = useRef<number | null>(null);
    const [segName, setsegName]= useState<string|null>(null)
    const [segShortName, setSegShortName]= useState<string|null>(null)
    const [groupName, setGroupName]= useState<string|null>(null)
    const [childGroupName, setChildGroupName]= useState<string|null>(null)
  const getSelectedSegments = useSelector(getSelectedSegment)
  const [isStartEdit, setIsStartEdit] = useState<boolean>(false)
    const dispatch= useDispatch()
    
    const [annotation, setAnnotation]= useState<number[]>([])
    const [cordinate, setCordinate]= useState<number[]>([])
  
    // Add state to track dragging
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [lastPos, setLastPos] = useState<{ x: number; y: number } | null>(null);

    useEffect(()=>{
        if(getSelectedSegments && getSelectedSegments.length>0){
          const selected=getSelectedSegments[0]
           const grpseg=selected.details
           if(grpseg &&
            grpseg.seg_short &&
            grpseg.label &&
            grpseg.seg_type &&
            grpseg.group &&
            grpseg.annotation &&
            grpseg.bb_annotation_int
          ){
            setSegShortName(grpseg.seg_short)
            setsegName(grpseg.label)
            setGroupName(grpseg.seg_type)
            setChildGroupName(grpseg.group)
            setAnnotation( grpseg.annotation)
            setCordinate(grpseg.bb_annotation_int)
           }
        }
      },[getSelectedSegments])

      // add canvas 
      const isCanvas= useRef(true)
      useEffect(() => {
        if (canvasElementRef.current && isCanvas.current) {
          isCanvas.current= false
          // Initialize Fabric canvas
          const rightSection = document.querySelector('.canvas-right') as HTMLElement;
          const canvas = new fabric.Canvas(canvasElementRef.current, {
            width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
            height: window.innerHeight,
            backgroundColor: '#f0f0f0', // Canvas background
          });
  
        canvas.requestRenderAll();
          canvasRef.current = canvas;
        //   canvas.on('mouse:down', (event) => {
         
        //     handleMouseDown(event);
        //   });
          
        //   canvas.on('mouse:up',
        //     (event) => {
        //       handleMouseUp(event);
        //    });

        //    canvas.on('mouse:move', (event) => {
        //     handleMouseMove(event); // Add mouse move event to show the dynamic point/line
        //   });

           canvas.on('mouse:over', (event) => {
            handleMouseOver(event); // Add mouse move event to show the dynamic point/line
          });
  
        //   canvas.on('mouse:wheel',
        //     (event) => {
        //       handleMouseWheel(event);
        //    });

          //  canvas.on('mouse:out', (event) => {
          //   handleMouseOut(event); // Add mouse move event to show the dynamic point/line
          // });
           
          return () => {
            // Clean up the canvas when the component unmounts
            if (canvasRef.current) {
            //   canvasRef.current.off('mouse:down', handleMouseDown);
            //   canvasRef.current.off('mouse:up', handleMouseUp);
             // canvasRef.current.off('mouse:move', handleMouseMove);
              canvasRef.current.off('mouse:over', handleMouseOver);
            }
          
          }
          
        } else{
          isCanvas.current= true
        }
      }, []);


         // ad image on canavs
    useEffect(() => {
        if (getUserJobBase64s && canvasRef.current) {
          const image = getUserJobBase64s;
          const encodedImage = "data:image/png;base64," + image;
          //const encodedImage =  image;
          const decodedImage = atob(encodedImage.split(",")[1]);
          const uint8Array = new Uint8Array(decodedImage.length);
          for (let i = 0; i < decodedImage.length; i++) {
            uint8Array[i] = decodedImage.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: "image/png" });
          const imageUrl = URL.createObjectURL(blob);
    
          fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
            const canvas = canvasRef.current;
            if (canvas && canvas.width && canvas.height && img.width && img.height) {
              const canvasWidth = canvas.width;
              const canvasHeight = canvas.height;
    
              img.scaleToWidth(canvasWidth);
              img.scaleToHeight(canvasHeight);
    
              canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
                scaleX: canvasWidth / img.width,
                scaleY: canvasHeight / img.height,
                originX: 'left',
                originY: 'top',
              });
              setScalex(canvasWidth / img.width);
              setScaley(canvasHeight / img.height);
              console.log("canvasWidth / img.width--x",canvasWidth / img.width)
              console.log("canvasHeight / img.height--y",canvasHeight / img.height)

              canvas.requestRenderAll();
              
            }
          });
        }
      }, [getUserJobBase64s]);
  

 // handleMouse over
 const handleMouseOver=(event:fabric.IEvent)=>{
    // console.log("event", event.target)
    // console.log("cabavas", canvasRef.current)
 }


      // return to main canavs
      const handlehoverLayer=()=>{
        dispatch(switchToOutline("segment"))
        dispatch(changeSegmentTab('segment'))
        // navigate(`/project/view/${jobId}`)
         
      }
// creat polygon
      useEffect(()=>{
        if(cordinate &&
            annotation && 
            segName &&
            scaleX!=null&&
            // scaleX !== undefined && // Check if scaleX is defined
            // scaleY !== undefined && 
            scaleY!=null&&
            canvasRef.current){
                const  point:PointModel[]=[]
                let i;
                for ( i = 0; i < annotation.length; i += 2) {
                  const x = annotation[i] * (scaleX ?? 1);
                  const y = annotation[i + 1] * (scaleY ?? 1);
                    point.push({ x, y });
                  }
                console.log("scaleY",scaleY)
                console.log("scaleX",scaleX)
                    if(point && point.length>0){
                    makePolygon(point,cordinate,segName)
                    }
        }
      },[cordinate, annotation,segName, canvasRef,scaleX , scaleY])

      const makePolygon=(point:PointModel[],coordinate:number[], polyName:string)=>{
        if(point&&
          point.length>0&&
          coordinate&&
          polyName &&
          scaleX!=null&&
          scaleY!=null&&
          
          canvasRef.current
          ){
  
            var polygon = new fabric.Polygon(point, {
              left: coordinate[0] * (scaleX ?? 1),
              top: coordinate[1] * (scaleY ?? 1),
              fill: "transparent",
              strokeWidth: 2,
              stroke: "rgb(7 239 253)",
              scaleX: 1,
              scaleY: 1,
              objectCaching: false,
              transparentCorners: false,
              cornerColor: "red",
              cornerStyle: "circle",
              selectable:false
            
            });
           // setPolygon(polygon)
            canvasRef.current.viewportTransform = [1, 0, 0, 1, 0, 0];
            canvasRef.current.add(polygon);
             setIsStartEdit(true)
          
            }
          }

           //call edit function
        useEffect(()=>{
            if(isStartEdit && canvasRef.current){
              Edit()
            }
          },[isStartEdit,canvasRef])
          
          const Edit = () => {
            if (canvasRef.current) {
              const objects = canvasRef.current.getObjects();
              if (objects.length > 0) {
                let poly = objects[0] as EditablePolygon;
                canvasRef.current.setActiveObject(poly);
                poly.edit = !poly.edit;
          
                if (poly.edit && poly.points) {
                  const lastControl = poly.points.length - 1;
                  poly.lockMovementX = true;
                  poly.lockMovementY = true;
                  poly.cornerStyle = 'circle';
                  poly.cornerColor = 'rgb(255 1 154)';
                  poly.cornerSize = 7;
                  // poly.cornerStrokeWidth = 3; // This property might not exist in the Fabric.js version you are using
                  poly.cornerStrokeColor = 'rgb(7 239 253)';
                  
                  // Type the controls assignment properly
                  poly.controls = poly?.points.reduce<PolygonControls>((acc, point, index) => {
                     console.log("index",index)
                    acc[`p${index}`] = new fabric.Control({
                      cursorStyle: 'pointer',
                      positionHandler: polygonPositionHandler.bind({ pointIndex: index }),
                      actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandlers).bind({ pointIndex: index }),
                      actionName: 'modifyPolygon',
                    });
                    return acc;
                  }, {});
                } else {
                  poly.cornerColor = 'black';
                  poly.cornerStyle = 'rect';
                  poly.controls = fabric.Object.prototype.controls;
                }
                poly.hasBorders = !poly.edit;
                canvasRef.current.requestRenderAll();
              }
            }
          };

          const polygonPositionHandler = function (
            this: { pointIndex: number }, // Type for this
            dim: { x: number; y: number },
            finalMatrix: any,
            fabricObject: any
          ): fabric.Point {
            //console.log("pointIndex", fabricObject.points[this.pointIndex]);
            
            // Check if fabricObject and this.pointIndex are valid
            if (fabricObject && fabricObject.points && fabricObject.points[this.pointIndex]) {
              const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
              const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
                console.log("x",x)
                console.log("y",y)
              // Create a fabric.Point object for transformPoint
              const transformedPoint = fabric.util.transformPoint(
                new fabric.Point(x, y), // Use fabric.Point instead of plain object
                fabric.util.multiplyTransformMatrices(
                  fabricObject.canvas.viewportTransform,
                  fabricObject.calcTransformMatrix()
                )
              );
             console.log("transformedPoint",transformedPoint)
              return transformedPoint;
            }
            console.log(" Return a default point if fabricObject or pointIndex is invalid")
            // Return a default point if fabricObject or pointIndex is invalid
            return new fabric.Point(0,0);
          };
          

          const anchorWrapper = function (
            anchorIndex: number,
            fn: (eventData: MouseEvent, transform: Transform, x: number, y: number) => boolean
          ) {
            return function (eventData: MouseEvent, transform: Transform, x: number, y: number) {
                const fabricObject = transform.target as fabric.Polygon;

                if (fabricObject && fabricObject.points && fabricObject.points[anchorIndex]) {
                    const absolutePoint = fabric.util.transformPoint(
                        new fabric.Point(
                            fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
                            fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y
                        ),
                        fabricObject.calcTransformMatrix()
                    );

                    const actionPerformed = fn(eventData, transform, x, y); // Here `eventData` is correctly passed.

                    // Check if fabricObject is defined before calling getObjectSizeWithStroke
                    if (fabricObject &&fabricObject.scaleX &&fabricObject.scaleY) {
                        const polygonBaseSize = getObjectSizeWithStroke(fabricObject);
                        const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
                        const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;

                        const adjustedX = newX + 0.5;
                        const adjustedY = newY + 0.5;
                        fabricObject.setPositionByOrigin(absolutePoint, 'center', 'center');

                        // If you're adjusting by numbers, then you should manually move the object, like this:
                        fabricObject.left = adjustedX;
                        fabricObject.top = adjustedY;
                        fabricObject.setCoords();
                    }
                    return actionPerformed;
                } else {
                    console.warn('Points or the specific point is undefined.');
                    return false;
                }
            };
          };
          
          // Modify actionHandlers to correctly handle the IEvent parameter
          const actionHandlers = function (
            eventData:MouseEvent, // Ensure the eventData type is IEvent<Event>
            transform: Transform,
            x: number,
            y: number
          ): boolean {
            console.log("actionHandlers")
            const polygon = transform.target as fabric.Polygon;
            if (!polygon || !polygon.points) {
              console.warn('Polygon or its points are undefined.');
              return false;
            }
          
            const corner = transform.corner;
            const currentControl = parseInt(corner.match(/\d+/)?.[0] || "0", 10);
            const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), "center", "center");
            const polygonBaseSize = getObjectSizeWithStroke(polygon);
            const size = polygon._getTransformedDimensions(0, 0);
          
            const finalPointPosition = new fabric.Point(
              (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
              (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y
            );
          
            polygon.points[currentControl] = finalPointPosition;
          
            polygon.setCoords();
            canvasRef.current?.renderAll();
          
            return true;
          };
          
          

              // Define getObjectSizeWithStroke function with appropriate types
   function getObjectSizeWithStroke(object: fabric.Object): fabric.Point {
          
         const scaleX = object.scaleX!; // Default to 1 if scaleX is undefined
        //const scaleX = 1.327468230694037; // Default to 1 if scaleX is undefined
        const scaleY = object.scaleY!; // Default to 1 if scaleY is undefined
        //const scaleY = 0.6216216216216216; // Default to 1 if scaleY is undefined
        console.log("scaleX", scaleX);
        console.log("scaleY", scaleY);
        const stroke = new fabric.Point(
            object.strokeUniform ? 1 / scaleX:1,
            object.strokeUniform ? 1 / scaleY:1
        ).multiply(object.strokeWidth || 0);

        return new fabric.Point(
            (object.width ?? 0) + stroke.x,  // Ensure width is not undefined
            (object.height ?? 0) + stroke.y  // Ensure height is not undefined
        );
    
   
  }

  useEffect(() => {
    if (canvasRef.current) {
        const canvas = canvasRef.current;

        canvas.on('mouse:down', (event) => {
            const target = event.target;
            if (target && target.type === 'polygon') {
                setIsDragging(true);
                setLastPos({ x: event.e.clientX, y: event.e.clientY });
                canvas.setActiveObject(target);
                // Store the initial position
                target.set({ left: target.left, top: target.top });
            }
        });

        canvas.on('mouse:move', (event) => {
            if (isDragging && lastPos) {
                const deltaX = event.e.clientX - lastPos.x;
                const deltaY = event.e.clientY - lastPos.y;
                const activeObject = canvas.getActiveObject();
                if (activeObject && activeObject.left && activeObject.top) {
                    activeObject.set({
                        left: activeObject.left + deltaX,
                        top: activeObject.top + deltaY,
                    });
                    activeObject.setCoords();
                    canvas.renderAll();
                }
                setLastPos({ x: event.e.clientX, y: event.e.clientY });
            }
        });

        canvas.on('mouse:up', () => {
            setIsDragging(false);
            setLastPos(null);
        });

        return () => {
            canvas.off('mouse:down');
            canvas.off('mouse:move');
            canvas.off('mouse:up');
        };
    }
}, [isDragging, lastPos]);



  return (
    <>
    <div  className='position-relative'>
<div className='re-anno-section'  onClick={handlehoverLayer}
>
      <button className=' d-flex  align-items-center btn btn-secondary border-0 fs-5'> 
      <span className='fs-4'>
      <i className="bi bi-arrow-left-short pe-1"></i>
      </span>
        
        {segShortName}-{childGroupName}
   </button> 
     
      </div>
<canvas ref={canvasElementRef}  className='dzi-van'/>






</div>
        
    </>
  )
}

export default EditAnnoatationCanvas