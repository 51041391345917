
import axios from "axios";
import { AuthModel } from "../../Model/auth/AuthModel";
import { userModel } from "../../Model/user/UserModel";
// const serverApi = import.meta.env.SERVER_URL
//  const serverApi ="http://localhost:5000"
const serverApi =process.env.REACT_APP_SERVER_URL
//const serverApi ="https://ztrbpzguq1.execute-api.us-east-2.amazonaws.com/dev"

const databaserServer="http://localhost:5000"

// login 
export function AuthLogin(loginForm:AuthModel) {
    const data={
        email:loginForm.email,
        password:loginForm.password
    }
    
    return axios.post(`${serverApi}/auth/login`,data)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

// create Account
export function CreateAccount(userData:userModel) {
    
    
    return axios.post(`${serverApi}/auth/create-account`,userData)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}



