import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DimPointModel } from '../../../Model/masterArray/MasterArrayModel'
import { addDimension } from '../../../slice/canvas/masterArray/DimensionSlice'
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice'


const DimensionCalculate = () => {

    const getSegregateSegments= useSelector(getSegregateSegment)
    const dispatch= useDispatch()
    useEffect(()=>{
        const allDimension:DimPointModel[]=[]
        if(getSegregateSegments && getSegregateSegments.length>0){
           
           const perFeet:number=getPerFeet();
            getSegregateSegments.map(item=>{
                  
                const alldim:DimPointModel[]=[]
                  let name:string|undefined
                Object.keys(item).forEach(seg=>{
                     const value= item[seg]
                     if(value && value.details &&
                         value.details.annotation && value.details.annotation.length>0 ){
                            const annotation=value.details.annotation
                          
                             name= value.details.label??""
                            let i;
                             let x0:number|undefined;
                             let y0:number|undefined;
                             let x1:number|undefined
                             let y1:number|undefined
                             let x2:number|undefined
                             let y2:number|undefined
                             let midpointX:number|undefined
                             let midpointY:number|undefined
                             let distance:number|undefined

                            for ( i = 0; i < annotation.length; i += 2) {
                                
                                if(i==annotation.length-2){
                                    x1 = annotation[0];
                                     y1 = annotation[1];
                                     x2=annotation[i ];
                                     y2=annotation[i +1];
                                     midpointX = (x1 + x2) / 2;
                                     midpointY = (y1 + y2) / 2;
                                      distance = (Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)))/(perFeet)
                                }else{
                                     x1 = annotation[i];
                                     y1 = annotation[i + 1];
                                     x2=annotation[i + 2];
                                     y2=annotation[i + 3];
                                     midpointX = (x1 + x2) / 2;
                                     midpointY = (y1 + y2) / 2;
                                      distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))/(perFeet);
                                }
                               
                                    
                                if(midpointX && midpointX && distance){
                                    const data={
                                        dim:distance,
                                        center:[midpointX,midpointY],
                                        bb_dimension_pixel:value.details.bb_dimension_pixel??[]
                                    }
                                    alldim.push(data)
                                }
                               
                              }
                            
                         }
                })
                const data1={
                    [name??""]:alldim
                 }
                 allDimension.push(data1)
            })

        }
         if(allDimension.length>0){
            dispatch(addDimension(allDimension))
         }
    },[getSegregateSegments])


    const getPerFeet=()=>{
      const segment= getSegregateSegments.find(item=>
        Object.keys(item).some(key => key.includes("Window1") || key.includes("Door1")));
      
    //   if(segment){
    //     Object.keys(segment).forEach(seg=>{
    //      const value= segment[seg]
    //      const dd_pixel=value.details?.bb_dimension_pixel
    //      if(dd_pixel && dd_pixel.length>0){
    //       const perFt:number=parseInt(dd_pixel[0])/6
    //         return perFt 
    //      }
    //     })
    //   }
    // else{
    //   return 1
    // }

      if (segment) {
        for (const seg of Object.keys(segment)) {
            const value = segment[seg];
            const dd_pixel = value.details?.bb_dimension_pixel;

            if (dd_pixel && dd_pixel.length > 0) {
              const perft= parseInt(dd_pixel[0]) / 6;
                return perft
            }
        }
    }
    return 1;
  
    }
  return (
    <div>
        
    </div>
  )
}

export default DimensionCalculate


