import React, { useRef, useState } from 'react'
import { Box, Input, Text, FormLabel, Card, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useRadio, useRadioGroup, HStack, Select, Textarea, RadioGroup, Radio } from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";

const UserCreateProject = () => {
    const navigate= useNavigate()

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setSelectedFile(event.dataTransfer.files[0]);
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setSelectedFile(files[0]);
        } else {
            setSelectedFile(null);
        }
    };

    const handleDragImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    


    return (
        <div>
         

            <Card className="card-edit py-4 mt-4">
                <div className="row container from-edit">
                    <div className="col-12 from-list">
                        <FormLabel>Username</FormLabel>
                        <Input className="input-primary" />
                    </div>

                    <div className="col-12 from-list">
                        <FormLabel>Email</FormLabel>
                        <Input className="input-primary" />
                    </div>
                    <div className="col-6 from-list">
                        <FormLabel>Password
                        </FormLabel>
                        <Input className="input-primary" />
                    </div>

               
                    <div className="col-6 from-list">
                        <FormLabel>Confirmed</FormLabel>
                        <Input className="input-primary" />
                    </div>
                    
                   
                    <div className="col-6 mt-2">
                        <FormLabel>Blocked
                        </FormLabel>
                        <div className='radio-button radio-button-from'>
                            <RadioGroup className='radio-btn'
                            // onChange={handleStatus}
                            // value={status !== undefined ? status.toString() : ''}

                            >
                                <Radio value="1">Active</Radio>
                                <Radio value="0">Inactive</Radio>
                            </RadioGroup>
                        </div>

                    </div>


                    <div className="col-6 from-lable from-list">
                        <FormLabel>Role</FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                           
                            <option>Demo Door</option>
                            <option>Demo Wall</option>
                            <option>Demo Window</option>
                            <option>Demo Roof</option>
                            <option>Closed</option>

                        </Select>
                    </div>

                    <div className="col-12 from-lable from-list">
                        <FormLabel>Projects</FormLabel>
                        <Select className="input-primary" placeholder="Add relation">
                           
                            <option>6 May</option>
                            <option>7 May</option>
                            <option>24 June</option>
                        </Select>
                    </div>
                </div>
            </Card>

        </div>
    )
}

export default UserCreateProject
