import React, { useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  ButtonGroup,
  PopoverAnchor,
  useDisclosure,
  Portal,
} from '@chakra-ui/react'
import JobBrand from '../brandPage/jobs/JobBrand';
import { callbackify } from 'util';
import { useNavigate } from 'react-router-dom';
import "./Setting.css";

type Props = { 
  callbackLeftSection : ()=> void,
  callbackProject : ()=> void,
}; 
const Paths = () => {
    //  const navigate= useNavigate()
//   const handleJobbrand = () => {
   
//     navigate("/dashboard/jobs")
//   };

//   const handleProjectbrand = () =>{
  
//     navigate("/segments/table")
//   }
  return (
  

<div className='media-section'>
<Popover trigger="hover"   >
  <PopoverTrigger>

    <div className="d-flex card-list-title ">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-diagram-2 paths-icon " viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
</svg>
      <h2 className="project-title ">Paths</h2>
    </div>
  </PopoverTrigger>
  <Portal>
    <PopoverContent className=' popover-content setting-right-section'>
      <PopoverHeader className="types-title" fontWeight='semibold'> <span className='type-title'>PATHS TYPES </span><span className='show-type'>3</span></PopoverHeader>

      <PopoverBody>
        <Popover
        >
         

          <div className="list-title">
                  <ul>
                    
                    <li >
                    {/* onClick={handleProjectbrand} */}
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Projects</span>
                        </div>
                      </div>
                    </li>

                    <li >
                    {/* onClick={handleProjectbrand} */}
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span> Segment Icons
                          </span>
                        </div>
                      </div>
                    </li>


                    <li >
                    {/* onClick={handleProjectbrand} */}
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Category Icons</span>
                        </div>
                      </div>
                    </li>


                    <li >
                    {/* onClick={handleProjectbrand} */}
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Category Gallery</span>
                        </div>
                      </div>
                    </li>

               

               

                  </ul>
                </div>

        </Popover>
      </PopoverBody>
    </PopoverContent>
  </Portal>
</Popover>


</div>

  )
}

export default Paths
