import React, { useEffect, useRef } from 'react'
import { GetAllS3Folder } from '../../../api/folderApi/FolderApi'
import { useDispatch } from 'react-redux'
import { addFirstFolder, addTypeBucket, changeActiveFolder } from '../../../slice/media/MediaSlice'

type Props={
    isAllFolder:boolean,
    resetMain:()=>void
}
interface ErrMess{
  response:{
    data:{
      message:string
    }
  }
}
const GetAllS3BucketFolder = ({isAllFolder,resetMain}:Props) => {
   const isApi= useRef(true)
   const dispatch= useDispatch()
    useEffect(()=>{
        if(isAllFolder && isApi.current){
          getAllfolder()
        }
    },[isAllFolder])

    const getAllfolder=async()=>{
        try{

          const response=await  GetAllS3Folder()
          // console.log("reseponse from folder Api", response)
          if(response && response.status===200){
             const allfolder:string[]= response.data
              const newallfolder:string[]= allfolder.map(item=>{
              const updatedPath = item.replace(/\/$/, '');
              return updatedPath
             })
              isApi.current=true
             dispatch(addFirstFolder({allFolder:newallfolder}))
             dispatch(addTypeBucket({
              typeBucket:"folder"
             }))
             resetMain()
        }
      }catch(err){
        isApi.current=true
        const error=err as ErrMess
        // alert(error.response.data)
          const mess=error.response.data.message
          alert(mess)
          resetMain()
        }
    }
  return (
    <div>
        
      
    </div>
  )
}

export default GetAllS3BucketFolder