import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSegment } from '../../slice/segment/SegmentSlice'
import { GroupMasterSegment, JobSegmentationModel, MasterArrayModel } from '../../Model/masterArray/MasterArrayModel';
import { getUserJobData, resetJobData } from '../../slice/userJobSlice/UserJobSlice';
import Project from '../leftSection/Project';

import { addMasterArray } from '../../slice/canvas/masterArray/MasterArraySlice';
import { JobSegmentModel, Segment } from '../../Model/Job/JobModel';
import { SegmentDetails, Swatch } from '../../Model/Job/SamModel';
import { getmastergroup } from '../../slice/canvas/groupSlice.tsx/GroupSlice';

import { getUserLogin } from '../../slice/user/UserSlice';
import SortOverAllSwatches from './SortOverAllSwatches';

const CreateMasterArray = () => {
    const dispatch= useDispatch()
    const getAllSegments = useSelector(getAllSegment);
    const [masterArray, setMasterArray] = useState<MasterArrayModel[]>([]);
    const getUserJobDatas = useSelector(getUserJobData);
        const getmastergroups= useSelector(getmastergroup)
       const getUserLogins= useSelector(getUserLogin )

useEffect(()=>{

    if(getAllSegments &&
                getAllSegments.length > 0 && 
                getUserJobDatas &&
                getUserJobDatas.id &&
                getUserJobDatas.segments &&
                getmastergroups &&
                getmastergroups.length>0 &&
                getUserLogins && 
                getUserLogins.length>0
            ){
                const userId= getUserLogins[0].id
                    const arraymaster: MasterArrayModel[] = [];
                    getmastergroups.map(grp=>{
                        

                        Object.keys(grp).forEach(eachGrp=>{
                           
                             const eachmasterGroup=grp[eachGrp]   // Door
                             const allsegIco= getAllSegments.find(item=>item.name==eachGrp)
                            console.log("eachmasterGroup",eachmasterGroup)
                           
                             const allSameGroup:GroupMasterSegment[]=[]
                             if(eachmasterGroup && eachmasterGroup.length>0 && userId){
                              
                                eachmasterGroup.map(item=>{  // Door1
                                    const allSameSeg: JobSegmentationModel[] = [];
                                const allJobSeg= getUserJobDatas.segments||{} ;
                                if(allJobSeg){
                                    Object.keys(allJobSeg).forEach(seg=>{
                                       
                                        const details= allJobSeg[seg].details
                                        const allSwatcDesigner=allJobSeg[seg].designer
                                        let allswatch:Swatch[]=[]
                                         if(allSwatcDesigner && allSwatcDesigner.length>0){
                                            
                                           const userSwatch= allSwatcDesigner.filter(item=>item.user_id==userId)
                                         //  console.log("userSwatch",userSwatch)
                                        
                                                if(userSwatch && userSwatch.length ){
                                                    const userAllSwatch= userSwatch[0].swatch??[]
                                                    const uniqueDesigners = Array.from(new Map(userAllSwatch.map(designer => [designer.swatch_id, designer])).values());
                                                //    console.log("uniqueDesigners",uniqueDesigners)
                                                     
                                                     uniqueDesigners.map(item=>{
                                                        const data:Swatch={
                                                            swatch_id:item.swatch_id,
                                                            title:item.title??"",
                                                            photo:item.photo??"",
                                                            isActive:false,
                                                            isApproved:item.isApproved,
                                                            cost:item.cost
  
                                                        }
                                                        allswatch.push(data)
                                                     })

                                                }

                                         }
                                  //console.log("allswatch",allswatch)

                                        if(details?.group===item){
                                            allSameSeg.push({
                                                segName:details.label,
                                                    name: details.seg_short,
                                                    isActive:false,
                                                    details: details,
                                                    designer: Array.isArray(allJobSeg[seg].designer) ? allJobSeg[seg].designer : [],
                                                   // overAllSwatch: allswatch||[]
                                            }) 
                                        }
                                    })
                                }
                                //console.log("allswatch",allswatch)
                                if(allSameGroup && allSameSeg.length>0)
                                allSameGroup.push({
                                    [item]:allSameSeg,
                                   
                                    
                                })
                                })
                             }
                           
                           //  const uniqueDesigners = Array.from(new Map(allswatch.map(designer => [designer.swatch_id, designer])).values());
                             const data = {
                                         name:eachGrp ??"",
                                         id: allsegIco?.id ??0,
                                         icon:allsegIco?.icon??"",
                                         icon_svg:allsegIco?.icon_svg??"",
                                         color_code:allsegIco?.color_code??"",
                                         color:allsegIco?.color??"",
                                         short_code:allsegIco?.short_code,
                                         overAllSwatch:[],
                                         segmentation:allSameGroup??[],
                                         isActive:false
                                                                            
                                      };
                                    //  console.log("data",data)
                                     arraymaster.push(data)
                        })
                       
                       
                       setMasterArray(arraymaster);
                    })

                }else {
                    setMasterArray([])
                }
},[getAllSegments, getUserJobDatas, getmastergroups,getUserLogins])


    
    // after getting Array
    useEffect(()=>{
      if(getUserJobDatas &&
        getUserJobDatas.id &&
        masterArray &&
        masterArray.length>0
      ){
        setMasterArray([])
        const data={
            jobId:getUserJobDatas?.id,
    project_id:getUserJobDatas?.project_id,
    // job_json:getUserJobDatas?.job_json,
    image:getUserJobDatas.image,
    compressionImage:getUserJobDatas.compressImage,
    job_view:getUserJobDatas.job_view,
    isArch:getUserJobDatas.isArch,
    dimension_pixel:getUserJobDatas?.dimension_pixel,
    seg_labels_count:getUserJobDatas?.seg_labels_count,
    allSeg:masterArray,
    segment_order:[]
        }

        // console.log("master data", data)
        dispatch(addMasterArray(data))
       
      }
    },[masterArray,getUserJobDatas])

    return (
        <>
           <SortOverAllSwatches/> 
        </>
    )
}

export default CreateMasterArray;
