import React, { useEffect, useRef } from 'react'
import { MaterialCategoryModel } from '../../../../../Model/material/MaterialCategoryModel'
import { AddCategories, UpdateCategories } from '../../../../../api/material/category/CategoryApi'
import { useDispatch } from 'react-redux'
import { updateCategory } from '../../../../../slice/materialSlice/MaterialCategorySlice'


type Props={
    resetUpdate:(data:string)=>void,
    editCategory:MaterialCategoryModel
}
const UpdateCategoryApi = ({resetUpdate,editCategory}:Props) => {
     const dispatch= useDispatch()
    const isApi= useRef(true)
    useEffect(()=>{
      if(editCategory && 
          editCategory.id &&
          isApi.current
         ){
         updatecategory(editCategory)
         isApi.current=false
      }
    },[editCategory])

    const updatecategory=async(data:MaterialCategoryModel)=>{

         try{
            const response= await UpdateCategories(data);
             if(response && response.status===200){
                console.log("update", response)
                isApi.current=true;
                dispatch(updateCategory({
                  data:response.data
                }))
                resetUpdate("success")
             }
         }catch(err){
          isApi.current=true;
            resetUpdate("fail")
         }
    }
  return (
    <div></div>
  )
}

export default UpdateCategoryApi