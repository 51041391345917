// This source code is licensed under the license found in the
// LICENSE file in the root directory of this source tree.

import React, { useContext, useEffect, useState } from "react";
import { ToolProps } from "./helper/Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { getImage, getImageData, getMaskImg, getSegmentTypes, setMaskImg } from "../../../slice/samModel/SamModelSlice";

const Tool = ({
  handleMouseMove,
  handleMouseUp,
  handleMouseDown,
  hasClicked,
  maskImage,
  maskImageString
}: ToolProps) => {
  

  // Determine if we should shrink or grow the images to match the
  // width or the height of the page and setup a ResizeObserver to
  // monitor changes in the size of the page
  const dispatch= useDispatch()
  const getImages= useSelector(getImage)
  const getSegmenTypes= useSelector(getSegmentTypes)
  const getMaskImgs= useSelector(getMaskImg)
  const getImageDatas= useSelector(getImageData)
  //  console.log("getMaskImgs",getMaskImgs)
  const [shouldFitToWidth, setShouldFitToWidth] = useState(true);
  const bodyEl = document.body;
  const fitToPage = () => {
    if (!getImages) return;
   // const imageAspectRatio = getImages.width / getImages.height;
    const imageAspectRatio = (getImageDatas.width/getImageDatas.height??1);
    const screenAspectRatio = window.innerWidth / window.innerHeight;
    setShouldFitToWidth(imageAspectRatio > screenAspectRatio);
  };
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.target === bodyEl) {
        fitToPage();
      }
    }
  });

  useEffect(() => {
    fitToPage();
    resizeObserver.observe(bodyEl);
    return () => {
      resizeObserver.unobserve(bodyEl);
    };
  }, [getImages]);

  const imageClasses = "";
  const maskImageClasses = `absolute opacity-40 pointer-events-none`;

  // Render the image and the predicted mask image on top
  return (
    <>
      {getImages && (
        <img
        onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseOut={() => {
            if (getSegmenTypes === "Click" && !hasClicked) {
              dispatch(setMaskImg(null));
            }
          }}
          src={getImages}
          className={`${
            shouldFitToWidth ? "w-full" : "h-full"
          } ${imageClasses}`}
          draggable={false}
        ></img>
      )}
      {/* {getMaskImgs && (
        <img
          src={getMaskImgs}
          className={`${
            shouldFitToWidth ? "w-full" : "h-full"
          } ${maskImageClasses}`}
        ></img>
      )} */}
      
      {maskImage && (
        <img
          src={maskImage.src}
          className={`${
            shouldFitToWidth ? "w-full" : "h-full"
          } ${maskImageClasses}`}
        ></img>
      )}
      {/* {maskImageString && (
        <img
          src={maskImageString}
          className={`${
            shouldFitToWidth ? "w-full" : "h-full"
          } ${maskImageClasses}`}
        ></img>
      )} */}
    </>
  );
};

export default Tool;
