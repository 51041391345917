import React from 'react'
import LoginHome from '../../module/auth/Login/LoginHome'


const LoginPage = () => {
  return (
    
        <LoginHome/>
    
  )
}

export default LoginPage