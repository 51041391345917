import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface LoadingStates {
    isloading:boolean
   
    
}

const initialState: LoadingStates = {
isloading:false,

};

const LoadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
       startLoading:(state)=>{
        state.isloading=true
       },
       stopLoading:(state)=>{
        state.isloading=false
       }

    }
}
)

export const {startLoading, stopLoading}=LoadingSlice.actions;
export const getLoading=  (state:{loading:LoadingStates})=>state.loading.isloading;
export default LoadingSlice.reducer