import React, { useContext, useEffect, useState } from "react";
import Tool from "./Tool";
import { modelInputProps, StageProps } from "./helper/Interfaces";
import AppContext from "./useContext/createContext";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getClicks, getImageData, getSegmentTypes, setClick, setClicks } from "../../../slice/samModel/SamModelSlice";
import "./Stage.scss"
type Points = { sx: number; sy: number; x: number; y: number };

const Stage = ({ hasClicked, setHasClicked,maskImage,maskImageString }: StageProps) => {
  const appContext = useContext(AppContext);
  const dispatch= useDispatch()
  const getImageDatas= useSelector(getImageData)
  const getSegmentTypess= useSelector(getSegmentTypes)
  // Safely destructure properties from appContext, providing defaults if null
  // const {
  //   click: [, setClick] = [null, () => {}],
  //   clicks: [clicks, setClicks] = [[], () => {}],
  //   segmentTypes: [segmentTypes] = [null],
  //   image: [image] = [null],
  // } = appContext || {};
  
  const [, setInputs] = useState<Array<modelInputProps>>([]);
  const [newInput, setNewInput] = useState<Array<modelInputProps>>([]);
  const [hasInput, setHasInput] = useState<boolean>(false);
  const [numOfDragEvents, setNumOfDragEvents] = useState<number>(0);
  const [points, setPoints] = useState<Points | undefined>();
  const DRAG_THRESHOLD = 4;
  const getSegmenTypes= useSelector(getSegmentTypes)
  const getInput = ({ sx, sy, x, y }: Points): modelInputProps => {
    return {
      x: sx,
      y: sy,
      width: x - sx,
      height: y - sy,
      clickType: 2,
    };
  };

  const clicks=useSelector(getClicks)

  const getClick = (x: number, y: number): modelInputProps => {
    // console.log("getClick x", x)
    // console.log("getClick y", y)
    const clickType = 1;
    return { x, y, width: undefined, height: undefined, clickType };
  };

  const handleSegmentByClick = (x: number, y: number) => {
    const click = getClick(x, y);
    if (click) {
     dispatch( setClicks([...(clicks || []), click]));
    } else {
      console.error("Failed to create a click object");
    }
  };

  const getXY = (e: any) => {
    // console.log("getXY",e)
    const el = e.nativeEvent.target as HTMLElement;
    // console.log("el",el)
    const rect = el.getBoundingClientRect();
    // console.log("rect",rect)
    let x = e.clientX - rect.left;
    let y = e.clientY - rect.top;
    // console.log("x",x)
    // console.log("y",y)
    const imageScale =getImageDatas?( getImageDatas.width / el.offsetWidth) : 1;
    x *= imageScale;
    y *= imageScale;
    //console.log("x",x)
    //console.log("y",y)
    return { x, y };
  };

  const handleMouseMove = (e:MouseEvent) => {
   // console.log("handleMouseMove", e)
    const { x, y } = getXY(e);
    if (getSegmenTypes === "Click" && !hasClicked) {
      //console.log("getSegmenTypes", getSegmenTypes)
      handleMoveToMask(e, x, y);
    } else if (newInput.length === 1) {
      const sx = newInput[0].x??0;
      const sy = newInput[0].y??0;
      setNewInput([getInput({ sx, sy, x, y })]);
      setInputs([]);
      setPoints({ sx, sy, x, y });
      setNumOfDragEvents((prevValue) => prevValue + 1);
    }
  };

  useEffect(() => {
    if (numOfDragEvents === DRAG_THRESHOLD && points) {
      setNumOfDragEvents(0);
      handleSegmentByBox(points);
    }
  }, [numOfDragEvents, points]);

  const handleMoveToMask = _.throttle((e: unknown, x: number, y: number) => {
    const click = getClick(x, y);
    if (click){
      dispatch(setClicks([click]));
    }
      
  }, 15);

  const handleMouseUp = (e: MouseEvent, shouldSetClick?: boolean) => {
   // setHasClicked(true);
    const { x, y } = getXY(e);

    switch (getSegmentTypess) {
      case "Click":
        if (hasClicked || shouldSetClick) {
          if (shouldSetClick) {
            const newClick = getClick(x, y) || null;
            if (newClick) {
              dispatch(setClick(newClick));
            } else {
              console.error("Failed to create a new click on mouse up");
            }
          } else {
            handleSegmentByClick(x, y);
          }
        }
        break;
      case "Box": {
        if (!hasInput) return;

        const sx = newInput[0].x??0;
        const sy = newInput[0].y??0;
        const width = x - sx;
        const height = y - sy;

        const isClick = width === 0 && height === 0;

        setNewInput([]);
        setHasInput(false);

        if (isClick) {
          // Check if a box exists before accepting a click
          if (clicks?.length && clicks[0].width && clicks[0].height) {
            const newClick = getClick(x, y);
            if (newClick) {
              const boxPoints = {
                sx: clicks[0].x??0,
                sy: clicks[0].y??0,
                x: clicks[0].width??0,
                y: clicks[0].height??0,
              };
              adjustPointsToRange(boxPoints, newClick);
              setInputs([getInput(boxPoints)]);
              handleSegmentByBox(boxPoints, newClick);
            } else {
              console.error("Failed to create a new click when isClick is true");
            }
          } else {
           // setHasClicked(false);
          }
        } else {
          const points = { sx, sy, x, y };
          setPoints(points);
          adjustPointsToRange(points);
          setInputs([getInput(points)]);
          handleSegmentByBox(points);
        }
        break;
      }
      default:
        console.warn("Unknown segment type:", getSegmentTypess);
        return;
    }
  };

  const handleMouseDown = (e: React.ChangeEvent<MouseEvent>) => {
    if (getSegmentTypess === "Box") {
      const { x, y } = getXY(e);
      setNumOfDragEvents(0);
      if (newInput.length === 0) {
        setNewInput([{ x, y, width: 0, height: 0, clickType: -1 }]);
        setHasInput(true);
      }
    }
  };

  const handleSegmentByBox = (
    { sx, sy, x, y }: Points,
    extraClick?: modelInputProps,
    newerClicks?: modelInputProps[],
  ) => {
    if (!sx || !sy || !x || !y) {
      console.error("Invalid points passed to handleSegmentByBox", { sx, sy, x, y });
      return;
    }

    const newClick = {
      x: Math.min(sx, x),
      y: Math.min(sy, y),
      width: Math.max(sx, x),
      height: Math.max(sy, y),
      clickType: 2,
    };

    const newClicks = newerClicks || [...(clicks || [])];
    if (extraClick) {
      newClicks.push(extraClick);
    }

    if (newClicks[0] && !newClicks[0].width) {
      newClicks.unshift(newClick);
    } else {
      newClicks[0] = newClick;
    }
    setClicks(newClicks);
  };

  const adjustPointsToRange = (
    points: Points,
    extraClick?: modelInputProps,
    newClicks?: modelInputProps[],
  ) => {
    const range = findClickRange(extraClick, newClicks);
    if (!range || !range.xMin || !range.yMin || !range.xMax || !range.yMax) {
      console.warn("Invalid range for point adjustment", range);
      return;
    }

    let { sx, sy, x, y } = points;
    const xMin = Math.min(sx, x);
    const yMin = Math.min(sy, y);
    const xMax = Math.max(sx, x);
    const yMax = Math.max(sy, y);

    if (range.xMin < xMin) {
      sx = sx < x ? range.xMin : sx;
      x = sx < x ? x : range.xMin;
    }
    if (range.yMin < yMin) {
      sy = sy < y ? range.yMin : sy;
      y = sy < y ? y : range.yMin;
    }
    if (range.xMax > xMax) {
      sx = sx > x ? range.xMax : sx;
      x = sx > x ? x : range.xMax;
    }
    if (range.yMax > yMax) {
      sy = sy > y ? range.yMax : sy;
      y = sy > y ? y : range.yMax;
    }

    points.sx = sx;
    points.sy = sy;
    points.x = x;
    points.y = y;
  };

  const findClickRange = (
    extraClick?: modelInputProps,
    newClicks?: modelInputProps[],
  ) => {
    let xMin: number | undefined;
    let yMin: number | undefined;
    let xMax: number | undefined;
    let yMax: number | undefined;

    const allClicks = newClicks ? newClicks : clicks ? [...clicks!] : [];
    if (allClicks.length === 0) return;

    if (extraClick) {
      allClicks.push(extraClick);
    }

    for (const click of allClicks) {
      if (click.width) continue;
      if (click.clickType === 0) continue;

      xMin = xMin !== undefined ? Math.min(xMin, click.x??0) : click.x;
      yMin = yMin !== undefined ? Math.min(yMin, click.y??0) : click.y;
      xMax = xMax !== undefined ? Math.max(xMax, click.x??0) : click.x;
      yMax = yMax !== undefined ? Math.max(yMax, click.y??0) : click.y;
    }

    return { xMin, yMin, xMax, yMax };
  };

  const flexCenterClasses = "flex items-center justify-center";
  return (
    <div className={`${flexCenterClasses} w-full h-full image-outer`}>
      <div className={`${flexCenterClasses} relative w-[90%] h-[90%] stage-img`  }>
       
        <Tool
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          handleMouseDown={handleMouseDown}
          hasClicked={hasClicked||false}
          maskImage={maskImage}
          maskImageString={maskImageString}  
        />
      </div>
    </div>
  );
};

export default Stage;
