import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

type Props = {
  isCreateFolderModel: boolean;
  resetCreateFolder: () => void;
  addFolder:(data:string)=>void
};

const CreateFolderModal = ({ isCreateFolderModel, resetCreateFolder,addFolder }: Props) => {
  const [folderName, setFolderName] = useState<string>('');

  // Function to close the modal
  const handleClose = () => {
    resetCreateFolder();
  };

  // Function to handle folder name input change
  const handleFolderName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(event.target.value);
  };

  // Function to handle the creation of the folder
  const handleCreateFolder = () => {
    if (folderName.trim() === '') {
      alert('Folder name cannot be empty');
      return;
    }
    console.log(folderName)
    
    setFolderName('');
    addFolder(folderName)
  };

  return (
    <div>
      <Modal show={isCreateFolderModel} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Create Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Enter Folder Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="folder 1"
                value={folderName}
                onChange={handleFolderName}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateFolder}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateFolderModal;
