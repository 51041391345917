import React from 'react'
import CreateProjectHomes from '../../module/createproject/CreateProjectHomes'
const CreateProject = () => {
  return (
    <div className='p-0'>
      <CreateProjectHomes/>
    </div>
  )
}

export default CreateProject
