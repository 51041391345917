import React, { createContext, useState } from 'react';

const AppContext = createContext<any>(null);

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [click, setClick] = useState(null);
  const [clicks, setClicks] = useState([]);
  const [image, setImage] = useState(null);
  const [segmentTypes, setSegmentTypes] = useState(null);
  const [maskImg, setMaskImg] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [predMask, setPredMask] = useState(null);
  const [predMasks, setPredMasks] = useState(null);
  const [predMasksHistory, setPredMasksHistory] = useState([]);

  const value = {
    click: [click, setClick],
    clicks: [clicks, setClicks],
    image: [image, setImage],
    segmentTypes: [segmentTypes, setSegmentTypes],
    maskImg: [maskImg, setMaskImg],
    showLoadingModal: [showLoadingModal, setShowLoadingModal],
    predMask: [predMask, setPredMask],
    predMasks: [predMasks, setPredMasks],
    predMasksHistory: [predMasksHistory, setPredMasksHistory],
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
