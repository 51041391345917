import React from 'react'
import AdminSwatchesEdit from '../../../module/admin/AdminMaterial/swatches/AdminSwatchesEdit'

const AdminSwatchesEditPage = () => {
  return (
    <div style={{marginTop:50}}>
        <AdminSwatchesEdit/>
    </div>
  )
}

export default AdminSwatchesEditPage