import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CanvasControlModel, CanvasSwatchModel, DimensionModel, GroupMasterSegment, JobSegmentationModel, MasterModel, ReAnnotationModel } from "../../../Model/masterArray/MasterArrayModel";


interface CanvasSwatchStates {
   
    canvasSwatch:CanvasSwatchModel[]
    
}

const initialState: CanvasSwatchStates = {
    canvasSwatch:[]

};


const SwatchOnCanvasSlice = createSlice({
    name: "swatchOnCanvas",
    initialState,
    reducers: {
        addSwatchOnCanvas:(state,action)=>{
            const { swatchId,swatchImages, swatchTitle, swatchPhoto }= action.payload
            Object.keys(swatchImages).forEach(seg=>{
                const swatchIndex= state.canvasSwatch.findIndex(item=>
                            Object.keys(item).includes(seg)) 
                             const swatchImage= swatchImages[seg]
                            if(swatchIndex !=-1){
                                         const segment=  state.canvasSwatch[swatchIndex][seg]
                                              if(segment){
                                                segment.swatch_seg_image=swatchImage
                                                segment.title=swatchTitle
                                                segment.photo=swatchPhoto
                                                segment.swatch_id=swatchId
                                              }
                                        }
                                        if(swatchIndex==-1){
                                            const data={
                                                swatch_id:swatchId ,
                                                swatch_seg_image: swatchImage,
                                                title:swatchTitle,
                                                photo:swatchPhoto,
                                                
                                            }
                                            state.canvasSwatch.push({
                                                [seg]:data
                                            })
                                        }
            })
        
       
        },
         resetSwatchOnCanvas:(state)=>{
            state.canvasSwatch=[]
              
        }, 
       
    }
})

export const {addSwatchOnCanvas,resetSwatchOnCanvas}=SwatchOnCanvasSlice.actions;
 export const getSwatchOnCanvas=  (state:{swatchOnCanvas:CanvasSwatchStates})=>state.swatchOnCanvas.canvasSwatch
export default SwatchOnCanvasSlice.reducer