import React, { useEffect, useState } from 'react'
import UpdateJobToUserJob from './UpdateJobToUserJob'
import JobDB from '../../createproject/samData/JobDB'
import { JobModel, UpdateJobModel } from '../../../Model/Job/JobModel'
import UpdateJobDB from '../../regeneration/UpdateJobDB'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useDispatch, useSelector } from 'react-redux'
import { getIsUpdateJob, resetJobUpdate, resetUpdateIndexing } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { addMessage } from '../../../slice/messageToast/ToastSlice'
import { startUpdateValue } from '../../../slice/canvas/updatevalue/UpdateValueSlice'
import { stopLoading } from '../../../slice/loading/LoadingSlice'


const UpdateJobHome = () => {
   
  const [isCallApi, setIsCallApi]= useState<boolean>(false)
  const [jobUpdate, setJobUpdate]= useState<UpdateJobModel|null>(null)
   const dispatch= useDispatch()
  const getIsUpdateJobs= useSelector(getIsUpdateJob)
  const [isSegegrateJob, setIsSegegrateJob]= useState<boolean>(false)
    useEffect(()=>{
    if(getIsUpdateJobs){
      setIsSegegrateJob(getIsUpdateJobs)  // if true
    }else{
      setIsSegegrateJob(getIsUpdateJobs) // if false
    }
  },[getIsUpdateJobs])


  const handleResetUSerJob=(data:UpdateJobModel)=>{
    setJobUpdate(data)
    setIsCallApi(true)
    dispatch(resetJobUpdate())
  }

   const handleResetJobApi=(data:boolean)=>{
    dispatch(stopLoading())
     setIsCallApi(false)
     setJobUpdate(null)
     if(data){
      dispatch(resetUpdateIndexing())
      dispatch(startUpdateValue())
      dispatch(addMessage({
        isShow:true,
        mess:"Update Job Sucessfully",
         toastType:"success"
       }))
     // alert("Update Job Sucessfully")
     }else{
      dispatch(addMessage({
        isShow:true,
        mess:"Some thing went wrong",
         toastType:"Error"
       }))
     // alert("Some thing went wrong")
     }
   }
  
  return (
    <div>
      {isSegegrateJob &&
      <UpdateJobToUserJob
      resetJobUser={handleResetUSerJob}
      />}

      {/* Add Job to Db */}
      {isCallApi &&
      jobUpdate!=null &&
        <UpdateJobDB
        updateJob={jobUpdate}
        resetJob= {handleResetJobApi}
        />
      }
      
    </div>
  )
}

export default UpdateJobHome