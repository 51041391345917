import React, { useRef, useState } from 'react';
import { Input, FormLabel, Select, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Textarea,
    Box,
    Text
} from '@chakra-ui/react'

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'

import "./SegmentationFrom.css"
import SelectSwatchJob from "../../projectPage/SelectSwatchJob";

const SegmentationFrom = () => {
    const [isSelectSwatchOpen, setIsSelectSwatchJob] = useState(false);
    const [entries, setEntries] = useState([{}]);

    const handleSelectSwatchJob = () => {
        setIsSelectSwatchJob(true);
    }

    const handleAddEntry = () => {
        setEntries([...entries, {}]);
    };

    return (

            <div className="seg-from mb-2">

                {/* {entries.map((_, index) => ( */}
                    <Tabs  variant='soft-rounded' className='seg-from-tab' colorScheme='green'>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItem className='accordion-item-seg '>
                                <AccordionButton className='acc-seg-btn'>
                                    <div className='dropdown-icons-seg'>

                                        <TabList>
                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/door_icon_c0ba19ace9.png'></img>
                                                Door
                                            </Tab>

                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/window_8a0a08a47b.png'></img>
                                                Window
                                            </Tab>

                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/wall_10a1f3a707.png'></img>
                                                Wall
                                            </Tab>

                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/roof_6629efd7d9.png'></img>
                                                Roof
                                            </Tab>

                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/trim_icon_8e4b3d6eb3.png'></img>
                                                Trim
                                            </Tab>
                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/gutter_icon_2_158cfe7fa3.png'></img>
                                                Gutter
                                            </Tab>

                                            <Tab className='tablist-seg'>
                                                <img src='https://dzinlystrapi.s3.us-east-2.amazonaws.com/garage_icon_c55b4bbc81.png'></img>
                                                Garage
                                            </Tab>

                                        </TabList>
                                    </div>
                                    <div className='action-icon-segjob'>
                                      
                                        <i className="bi bi-trash3-fill"></i>
                                       
                                        <AccordionIcon />
                                    </div>
                                </AccordionButton>

                                <AccordionPanel pb={4} style={{ backgroundColor: "#f9f9f9", borderRadius:"0px 0px 4px 4px", }}>
                                    <TabPanels>
                                        <TabPanel>
                                            <div className="row from-segmation">
                                                <div className="col-12">
                                                    <FormLabel>Name</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>Area_Pixel</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>Area_sqft</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>Dimension</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>AnnotationPolygon</FormLabel>
                                                    <Textarea style={{ backgroundColor: "#fff" }} />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>Segment</FormLabel>
                                                    <Select className="input-seg">
                                                        <option>Bay Window</option>
                                                        <option>Door</option>
                                                        <option>GarageDoor</option>
                                                        <option>Gutter</option>
                                                        <option>Light</option>
                                                        <option>Number</option>
                                                        <option>Roof</option>
                                                        <option>Shadows</option>
                                                        <option>Soffit</option>
                                                    </Select>
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>Confidence</FormLabel>
                                                    <NumberInput precision={2} step={0.2}>
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                </div>
                                                <div className="col-12" style={{ marginTop: "12px" }}>
                                                    <FormLabel>SelectSwatchJob <span>(0)</span></FormLabel>
                                                    {!isSelectSwatchOpen && (
                                                        <Box
                                                            className='box-primary'
                                                            border="1px solid #cbd5e0"
                                                            borderRadius="md"
                                                            padding="20px"
                                                            textAlign="center"
                                                            onClick={handleSelectSwatchJob}
                                                        >
                                                            <i className="bi bi-plus-circle-dotted"></i>
                                                            <Text mt={2} color="gray.500" style={{ fontSize: "12px", fontWeight: "400", color: "rgb(102, 102, 135)" }}>No entry yet. Click on the button below to add one.</Text>
                                                        </Box>
                                                    )}
                                                    {isSelectSwatchOpen && <SelectSwatchJob />}
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>AnnotationArray</FormLabel>
                                                    <Textarea style={{ backgroundColor: "#fff" }} />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>BoundingBoxFloat</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                                <div className="col-12 mt-2">
                                                    <FormLabel>AnnotationJSON</FormLabel>
                                                    <Textarea style={{ backgroundColor: "#fff" }} />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>BoundingBoxInt</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                                <div className="col-6 mt-2">
                                                    <FormLabel>Perimeter</FormLabel>
                                                    <Input className="input-seg" />
                                                </div>
                                            </div>
                                    
                                        </TabPanel>
                                        <TabPanel>
                                            <p>Tab 2 </p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Tab 3 </p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Tab 4 </p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Tab 5 </p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Tab 6 </p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Tab 7 </p>
                                        </TabPanel>

                                        <TabPanel>
                                            <p>Tab 8 </p>
                                        </TabPanel>

                                    </TabPanels>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Tabs>
                 {/* ))} */}

                {/* <div className="col-12">
                    <div>
                        <button type="button" className="chakra-button create-new-btn add-entry-btn css-ez23ye" onClick={handleAddEntry}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus mx-1" viewBox="0 0 16 16">
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                            </svg>
                            Create new entry
                        </button>
                    </div>
                </div> */}
            </div>

    );
};

export default SegmentationFrom;
