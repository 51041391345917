import React from 'react'
import Create_Image from './Create_Image'

const Generate_Imagehome = () => {
  return (
    <div>
     <Create_Image/>
    </div>
  )
}

export default Generate_Imagehome
