// Example.tsx

import React, { useEffect, useState } from 'react';
import { Radio, RadioGroup } from '@chakra-ui/react';

type Props={
  statusValue:number,
  sendValue:(data:string)=>void
}

const Example = ({ statusValue,sendValue }:Props) => {
    const [value, setValue] = useState<string>();

    useEffect(()=>{
           if(statusValue){
            setValue(statusValue.toString())
           }
    },[statusValue])

   const  handelStatus=(value:string)=>{
    setValue(value)
    sendValue(value)
   }

    return (
        <RadioGroup  className='radio-btn' onChange={handelStatus} value={value}>
            <Radio value="1">Active</Radio>
            <Radio value="0">Inactive</Radio>
        </RadioGroup>
    );
};

export default Example;
