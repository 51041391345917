import React from 'react'
import { Button } from 'react-bootstrap'

import "./Edit_Images.scss"
import { Link } from 'react-router-dom'
const AssetImage =process.env.REACT_APP_ASSERT_Image

const proimages  = `${AssetImage}/images/project-img.jpg`
const Edit_Imagehome = () => {
  return (
    <div className='generate-img'>

      <div className='segbtn'>
        <Button variant="outline-dark border-white text-white" size="lg" className='rounded-pill fs-6'>Cancel</Button>
        <Button variant="primary" size="lg" className='rounded-pill fs-6'>Done</Button>
      </div>


        <div className='proimg'>
          <img className='rounded-3  overflow-hidden' src={proimages} alt="proimg" />
        </div>


      <div className='seg-section '>
     
        <Link to="/generate-image/edit/door" className='seg'>
          <div className='segicon'>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="white" className="bi bi-door-closed" viewBox="0 0 16 16">
            <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3zm1 13h8V2H4z" />
            <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0" />
          </svg>
          </div>
          <h4 className='text-white text-center segtitle'>Door</h4>
          
        </Link>

        <Link to="/generate-image/edit/wall" className='seg'>
        
          <div className='segicon'>
          <svg fill="none" viewBox="0 0 32 32" width="30" height="30" xmlns="http://www.w3.org/2000/svg" className="emotion-19l25w4"><path d="M15 3V7H11V11H7V15H2V29H30V3H15ZM17 5H23V7H17V5ZM13 9H19V11H13V9ZM23 15H17V13H23V15ZM23 23H17V21H23V23ZM9 21H15V23H9V21ZM19 19H13V17H19V19ZM9 13H15V15H9V13ZM4 17H11V19H4V17ZM4 21H7V23H4V21ZM11 27H4V25H11V27ZM19 27H13V25H19V27ZM28 27H21V25H28V27ZM28 23H25V21H28V23ZM28 19H21V17H28V19ZM28 15H25V13H28V15ZM28 11H21V9H28V11ZM25 7V5H28V7H25Z" fill="white"></path></svg>
          </div>
       
    <h4 className="text-white text-center segtitle">Wall</h4>
        </Link>

        <Link to="/generate-image/edit/roof" className='seg'>
          <div className='segicon'>
          <svg fill="none" viewBox="0 0 32 32"  width="30" height="30" xmlns="http://www.w3.org/2000/svg" className="emotion-19l25w4"><path d="M26 7.7V1H22V5L16 1L1 11V31H3.3L16 22.2L28.7 31H31V11L26 7.7ZM17 4.1L29 12.1V24.6L17.1 16.4H17V4.1ZM3 12.1L15 4.1V16.3H14.9L3 24.6V12.1ZM29 28.8L16 19.8L3 28.8V27L16 18L29 27V28.8Z" fill="white"></path></svg>
          </div>
          <h4 className='text-white  text-center segtitle' >Roof</h4>
        </Link>

        <Link to="/generate-image/edit/window" className='seg'>
          
          <div className='segicon'>
          <svg fill="none" viewBox="0 0 32 32" width="30" height="30" xmlns="http://www.w3.org/2000/svg" className="emotion-19l25w4"><path d="M0 4V28H32V4H0ZM24 15H17V6H24V15ZM15 6V15H8V6H15ZM6 26H2V6H6V26ZM8 17H15V26H8V17ZM17 26V17H24V26H17ZM30 26H26V6H30V26Z" fill="white"></path></svg>
          </div>
          <h4 className='text-white  text-center segtitle'>Windows</h4>
        </Link>
      </div>
    </div>
  )
}

export default Edit_Imagehome
