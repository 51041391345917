import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReplaceWindowModel } from "../../Model/replaceSwatchModel/ReplaceWindowSwatch";


interface ReplaceSwatchStates {
 replaceSwatch:ReplaceWindowModel

}

const initialState: ReplaceSwatchStates = {
replaceSwatch:{}

};

const ReplaceSwatchSlice = createSlice({
    name: "replaceSwatch",
    initialState,
    reducers: {
       addReplaceSwatch:(state,action)=>{
        state.replaceSwatch=action.payload
       },
       resetReplaceSwatch:(state)=>{
        state.replaceSwatch={}
       },
       addImageProjectId:(state,action)=>{
        const {projectId, image}= action.payload
        state.replaceSwatch.projectId=projectId
        state.replaceSwatch.masterImage=image
       },
       addAllAnotation:(state,action)=>{
        const {allAnnotation}= action.payload
        state.replaceSwatch.allSegmentAnnotation=allAnnotation
       }

    }
}
)

export const {addReplaceSwatch, resetReplaceSwatch,
    addImageProjectId,addAllAnotation
}=ReplaceSwatchSlice.actions;
export const getReplaceSwatch=  (state:{replaceSwatch:ReplaceSwatchStates})=>state.replaceSwatch.replaceSwatch;
export default ReplaceSwatchSlice.reducer