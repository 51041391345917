
import axios from "axios";
const modelbackendApi= process.env.REACT_APP_BACKEND_URL

// get Base 64 image
export function GetBase64Image(imagePath:string){
    const data={
        masterimg_s3_url: imagePath
      }
    return axios.post(`${modelbackendApi}/s3-to-base64`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        console.log("base 64",error)
        throw error;
    });
}
export function GetMasterDataThroughApi(segmentationInt:number[], segName:string){
    const data={
        segmentationInt:segmentationInt ,
          className: segName,
          Perfeet: 20
      }
    return axios.post(`${modelbackendApi}/manual-annot-result`,data)
    .then(response => {
        // console.log("project", response)
        return response
    }).catch(error => {
        throw error;
    });
}

