import React from 'react'
import EditBrand from '../../module/brandPage/EditBrand'

const BrandId = () => {
  return (
    <div>
        <EditBrand/>
    </div>
  )
}

export default BrandId