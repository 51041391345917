import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from '../../page/login/LoginPage';
import SignUppage from '../../page/signUp/SignUppage';

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="create-account" element={<SignUppage />} />
     
    </Routes>
  );
};

export default ProjectRoutes;
