import React, { useEffect, useRef, useState } from 'react'
import CreateFolderModal from './CreateFolderModal'
import CreateFolderApi from './CreateFolderApi'
import { useSelector } from 'react-redux'
import { getactiveFolder, getFirtsFolderPath } from '../../../../slice/media/MediaSlice'


type Props={
  isCreateFolder:boolean
  resetCreateFolder:()=>void
}
const CreateFolderHome = ({isCreateFolder,resetCreateFolder}:Props) => {

  const[ isCreateFolderModel, setIsCreateFolderModal]= useState<boolean>(false)
  const[ isCreateFolderApi, setIsCreateFolderApi]= useState<boolean>(false)
  const [folderName, setFolderName] = useState<string>('');
  const iscreate= useRef(true)
  const getFirtsFolderPaths= useSelector(getFirtsFolderPath)
  const getactiveFolders= useSelector(getactiveFolder)
  
  
  useEffect(()=>{

    if(isCreateFolder && 
      getactiveFolders===1 &&
      iscreate.current){
      iscreate.current=false
      setIsCreateFolderModal(isCreateFolder)
    }else if(
      isCreateFolder && 
      getactiveFolders===2&&
      getFirtsFolderPaths&&
      iscreate.current
    ){
      setFolderName(getFirtsFolderPaths)
      setIsCreateFolderModal(isCreateFolder)
    }
  },[isCreateFolder,getactiveFolders,getFirtsFolderPaths])

  const handleResetModal=()=>{
    iscreate.current=true
    setIsCreateFolderModal(false)
    setFolderName('')
    resetCreateFolder()
  }

  const handleCreateFolder=(name:string)=>{
    setIsCreateFolderModal(false)
    const path= `${folderName}${name}`
    setFolderName(path)
    setIsCreateFolderApi(true)
    
  }

const handleResetCreateFolder=()=>{
  setFolderName("")
  setIsCreateFolderApi(false)
  resetCreateFolder()
}
  return (
    <div>
      
      {isCreateFolderModel &&
        <CreateFolderModal
        isCreateFolderModel={isCreateFolderModel}
    resetCreateFolder={handleResetModal}
    addFolder={handleCreateFolder}

        />}


        {isCreateFolderApi &&
        folderName &&
          <CreateFolderApi
          folderName={folderName}
          resetCreateFolder={handleResetCreateFolder}
          />
        }
    </div>
  )
}

export default CreateFolderHome