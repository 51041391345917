import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MasterArrayModel } from "../../Model/masterArray/MasterArrayModel";


interface ToolActiveStates {
   
    groupName:string,
    subGroupName:string,
    childName:string,
    
    eachSegment:MasterArrayModel,
    toolActiveNumber:number

   
    
}

const initialState: ToolActiveStates = {
 
  groupName:"",
  subGroupName:"",
  childName:"",
  eachSegment:{},
 
  toolActiveNumber:0,

};

const ToolActiveSlice = createSlice({
    name: "toolActive",
    initialState,
    reducers: {
      resetToolActive:(state)=>{
        return initialState
      },
       
      
      
       addGroupName:(state,action)=>{
        state.groupName=""
        state.groupName=action.payload
       },
       addSubGroupName:(state,action)=>{
        state.subGroupName=action.payload
       },
       addChildName:(state,action)=>{

        state.childName=action.payload
       },
       addEachSegment:(state,action)=>{
        state.eachSegment=action.payload
       },
      
       UpdateToolActive:(state,action)=>{
        state.toolActiveNumber=action.payload
       }

    }
}
)

export const {
      addGroupName,
      addSubGroupName,
      addChildName,
      resetToolActive,
      addEachSegment,
      
      UpdateToolActive
    }=ToolActiveSlice.actions;
        export const getToolActive=  (state:{toolActive:ToolActiveStates})=>state.toolActive;
        export const getGroupName=(state:{toolActive:ToolActiveStates})=>state.toolActive.groupName;
        export const getSubGroupName=(state:{toolActive:ToolActiveStates})=>state.toolActive.subGroupName;
        export const getChildName=(state:{toolActive:ToolActiveStates})=>state.toolActive.childName;
        export const getEachSegment=(state:{toolActive:ToolActiveStates})=>state.toolActive.eachSegment;
       
        export const getToolActiveNumber=(state:{toolActive:ToolActiveStates})=>state.toolActive.toolActiveNumber
export default ToolActiveSlice.reducer