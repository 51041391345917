import React from 'react'
import EditUserFrom from '../../module/brandPage/users/EditUserFrom'

const UserEdit = () => {
  return (
    <div>
      <EditUserFrom/>
    </div>
  )
}

export default UserEdit
