import React from 'react'
import UserTable from '../../module/brandPage/users/UserTable'

const UserTablePage = () => {
  return (
    <div>
      <UserTable/>
    </div>
  )
}

export default UserTablePage
