import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Projects from '../../page/project/Projects';
import ProjectIds from '../../page/project/ProjectIds';


const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Projects />} />
      <Route path=":id" element={<ProjectIds />} />
    </Routes>
  );
};

export default ProjectRoutes;
