import React, { useState, useRef, useEffect } from 'react';
import { JobSegmentationModel } from '../../../Model/masterArray/MasterArrayModel';
import { useDispatch, useSelector } from 'react-redux';
import { getSegregateSegment, startHoverLayerView, updateSegregation } from '../../../slice/segegratedSlice/SegregatedSlice';
import { getAllSegment } from '../../../slice/segment/SegmentSlice';
import "./LayerPage.scss"
import AnnotationList from '../../canavas/newSegment/addNewSegment/AnnotationList';
import ReactDOMServer from "react-dom/server";
import UpdateSegmentLayer from '../updateSegmentLayer/UpdateSegmentLayer';
import { getIsCallApilayer, startSegHoverEffect, stopSegHoverEffect, updateCallSortedApi } from '../../../slice/canvas/masterArray/MasterArraySlice';

const LayerPage: React.FC = () => {
  const [segregateSegment, setSegregateSegment] = useState<Record<string, JobSegmentationModel>[]>([]);
  const [draggedItem, setDraggedItem] = useState<Record<string, JobSegmentationModel> | null>(null);
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const [activePopover, setActivePopover] = useState<string | null>(null);
  const popoverRefs = useRef<{ [key: string]: any }>({});

  const getSegregateSegments = useSelector(getSegregateSegment);
  const getAllSegments = useSelector(getAllSegment);
  const dispatch= useDispatch()
  const getIsCallApilayers= useSelector(getIsCallApilayer)
  


  useEffect(() => {
    if (getSegregateSegments.length > 0) {
      setSegregateSegment(getSegregateSegments);
    }
  }, [getSegregateSegments]);

  useEffect(() => {
    const bootstrap = require('bootstrap');
    
    segregateSegment.forEach((item) => {
      Object.keys(item).forEach((key) => {
        const element = document.getElementById(`popover-${key}`);
        if (element) {
          popoverRefs.current[key] = new bootstrap.Popover(element, {
            container: 'body',
            html: true,
            content: ReactDOMServer.renderToString(<AnnotationList />),
            trigger: 'manual',
            customClass: "seg-popover popover-layers",
            placement: 'bottom',
          });
        }
      });
    });

    return () => {
      Object.values(popoverRefs.current).forEach((popover: any) => {
        if (popover && popover.dispose) {
          popover.dispose();
        }
      });
    };
  }, [segregateSegment]);

  // Close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (activePopover) {
        const popoverElement = document.getElementById(`popover-${activePopover}`);
        if (popoverElement && !popoverElement.contains(event.target as Node)) {
          popoverRefs.current[activePopover]?.hide();
          setActivePopover(null);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [activePopover]);

  const handleDragStart = (e: React.DragEvent<HTMLLIElement>, item: Record<string, JobSegmentationModel>, index: number) => {
    setDraggedItem(item);
    setDragOverIndex(index);
    e.dataTransfer.setData('text/plain', '');
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>, index: number) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDrop = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
    if (!draggedItem || dragOverIndex === null) return;
   

    const newItems = segregateSegment.filter(item => item !== draggedItem);
    newItems.splice(dragOverIndex, 0, draggedItem);

    setSegregateSegment(newItems);
    //console.log("newItems",newItems)
    dispatch(updateSegregation(newItems))
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    setDragOverIndex(null);
  };

  const handLeMouseHover = (data: JobSegmentationModel) => {
    console.log("data.details",data)
    const segName= Object.keys(data)[0]
    console.log("segName",segName)
    if(segName){
             dispatch(startHoverLayerView(segName))
        }else{
          alert("something went wrong")
        
    }
     
   
  };

  const handleMouseOut = () => {
    dispatch(stopSegHoverEffect())
  };

  const togglePopover = (key: string) => {
    if (activePopover === key) {
      popoverRefs.current[key]?.hide();
      setActivePopover(null);
    } else {
      if (activePopover) {
        popoverRefs.current[activePopover]?.hide();
      }
      popoverRefs.current[key]?.show();
      setActivePopover(key);
    }
  };

  // call the Api to update segment layer
  useEffect(()=>{
    
  },[getIsCallApilayers])

  
   const handleResetLayer=()=>{
      dispatch(updateCallSortedApi(false))
   }
  return (
    <>
     <ul className="seg-group-list p-0 m-0">
      {segregateSegment.map((item, index) => (
        <React.Fragment key={Object.keys(item)[0]}>
          {dragOverIndex === index && draggedItem !== item && (
            <li className="drag-placeholder"></li>
          )}
          <li
            draggable
            onDragStart={(e) => handleDragStart(e, item, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={handleDrop}
            onDragEnd={handleDragEnd}
            className={`seg-group-box ${draggedItem === item ? 'dragging' : ''}`}
            onMouseEnter={() => handLeMouseHover(item)}
            onMouseLeave={handleMouseOut}
          >
            {Object.keys(item).map((key) => {
              const jobDetail = item[key] as JobSegmentationModel;
              const segshortName = jobDetail?.details?.seg_short;
              const segName = jobDetail?.details?.seg_type;
              const segColor = getAllSegments.find(item => item.name === segName);
              const color = segColor?.color_code;

              return (
                <div key={key} className="d-flex align-items-center justify-content-between mx-2 px-2 border rounded-1 layer-section">
                  <div className="d-flex align-items-center">
                    <span
                      style={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: color || 'red',
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "20px",
                        marginLeft: "5px",
                      }}
                    ></span>
                    <span className='fs-7 fw-semibold'>{segName}-{segshortName} </span>
                  </div>

                  <div>
                    <span className="drag-icon me-2 move-icon">
                      <i className="bi bi-arrows-move fs-6"></i>
                    </span>
                    <i
                      id={`popover-${key}`}
                      className="bi bi-three-dots-vertical"
                      onClick={() => togglePopover(key)}
                    ></i>
                  </div>
                </div>
              );
            })}
          </li>
        </React.Fragment>
      ))}
    </ul>

    {/*  update the segment layer from layer to segregated,  segregated to master array . after master array update then call Api */}

   {getIsCallApilayers &&
    <UpdateSegmentLayer
    resetLayer={handleResetLayer}
    />}
    </>
   
  );
};

export default LayerPage;
