import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Checkbox,
  Card,
} from "@chakra-ui/react";
import "./ShowBrands.css";



import CreateProjectFrom from "../../createproject/CreateProjectFrom";
import { Navigate, useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import Search from "antd/es/transfer/search";
import EditJobs from "./EditJobs";
import JobCreateProject from "./JobCreateProject";

interface TableData {
  id: number;
  media: string;
  updatedAt: string;
  state: string;
}



const JobBrand: React.FC = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(false);
  const [isEditFrom, setIsEditForm] = useState(false);

  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSelectAllChange = () => {
    const newCheckedItems: { [key: number]: boolean } = {};
    tableData.forEach((row) => {
      newCheckedItems[row.id] = !selectAll;
    });
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const tableData: TableData[] = [
    {
      id: 1,
      media:
        "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png",
      updatedAt: "Tuesday, November 28, 2023 at 12:19 PM",
      state: "Published",
    },
    {
      id: 2,
      media:
        "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png",
      updatedAt: "Tuesday, November 28, 2023 at 12:19 PM",
      state: "Published",
    },
    {
      id: 3,
      media:
        "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png",
      updatedAt: "Tuesday, November 28, 2023 at 12:19 PM",
      state: "Published",
    },
  ];
  const navigate = useNavigate()
  const handleEditFrom = () => {
    navigate("/dashboard/jobs/edit")
  };

  const dispatch = useDispatch();
  useEffect(() => {

  }, [dispatch]);

  return (
    <>



      <div>

        <div className="row mt-3">
          <div className="col-9 brand-title">
            <h1 className='job-title'>Job</h1>
            <p className="m-0">0 entries Found</p>
          </div>
          <div className="col-3 breadcrumb-link">
            <Breadcrumb
              items={[
                {
                  title: <a href="">Project</a>,
                },
                {
                  title: "Job",
                },
              ]}
            />

          </div>
        </div>
        <div className="row table-input">
          <div className="col-6 search-bar">
            <Search
              placeholder="Search job"
            //  onSearch={handleSearch}
            //  enterButton
            // style={{ marginBottom: '20px' }}
            />
          </div>

          <div className="col-6">
            <JobCreateProject />
          </div>
        </div>


        <Card className="mt-4">
          <TableContainer className="seg-table-container">
            <Table variant="simple" className="table-container">
              <Thead className="table-head">
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </Th>
                  <Th>ID</Th>
                  <Th>MEDIA</Th>
                  <Th>UPDATED AT</Th>
                  <Th>STATE</Th>
                  <Th>ACTION</Th>
                </Tr>
              </Thead>
              <Tbody className="table-body">
                {tableData.map((row) => (
                  <Tr key={row.id}>
                    <Td>
                      <Checkbox
                        isChecked={checkedItems[row.id] || false}
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                    </Td>
                    <Td>{row.id}</Td>
                    <Td>
                      <div className="media-img">
                        <img src={row.media} alt="media" />
                      </div>
                    </Td>
                    <Td>{row.updatedAt}</Td>
                    <Td>
                      <Button className="btn-media">{row.state}</Button>
                    </Td>
                    <Td>

                      <div className="d-flex action">
                        <div className="action-icon-table" onClick={handleEditFrom}>
                          <i className="bi bi-pencil-square mx-0"></i>
                        </div>
                        <div className="action-icon-table">
                          <i className="bi bi-copy mx-0"></i>
                        </div>
                        <div className="action-icon-table">
                          <i className="bi bi-box-arrow-up-right mx-0"></i>
                        </div>
                        <div className="action-icon-table trash-icon-media" >
                          <i className="bi bi-trash3 mx-0"></i>
                        </div>
                      </div>


                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </div>


    </>
  );
};

export default JobBrand;
