import React from 'react'
import LeftSection from '../../leftSection/LeftSection'
import GetAllMaterialsHome from '../../getAllMaterials/GetAllMaterialsHome'
import EditJobs from '../../brandPage/jobs/EditJobs'
import Header from '../../header/Header'

const AdminEditJob = () => {
  return (
    <>
    {/* <Header/> */}
      <div>

<div className='row'>


<LeftSection
 
/>
<div className="col-9 brand-right-section">

    <EditJobs/>
    




</div>



{/* getAll materials */}
<GetAllMaterialsHome/>
</div>
    </div>
    </>
  
  )
}

export default AdminEditJob