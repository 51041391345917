import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import LeftSection from '../../leftSection/LeftSection';
import Mainfolder from './allFolder/Mainfolder';
import SubMainFolderHome from './secondFolder/SecondFolder';

import { useDispatch, useSelector } from 'react-redux';
import { addSecondFolder, addTypeBucket, changeActiveFolder, getactiveFolder, getFirtsFolderPath, getMediaFolder, getSecondFolder } from '../../../slice/media/MediaSlice';
import SecondFolder from './secondFolder/SecondFolder';
import SecondFolderHome from './secondFolder/SecondFolderHome';
import GetAllS3BucketFolder from './GetAllS3BucketFolder';
import Header from '../../header/Header';
import { folderStructureModel } from '../../../Model/mediaModel/MediaModel';
import GetAllFolder from './allFolder/checkFolderFile/GetAllFolder';
const MediaLibraryHome = () => {
  const dispatch= useDispatch()
  const [isAllFolder, setIsAllFolder]= useState<boolean>(true)
  const [isSearchFolder, setIsSearchFolder]= useState<boolean>(false)
  const [isSecondFolder, setIsSecondFolder]= useState<boolean>(false)
  const[isThirdFolder, setIsThirdFolder]= useState<boolean>(false)
 
  const [isMainFolder, setIsMainFolder ]= useState<boolean>(true)

  const [searchFolderName, setSearchFolderName]= useState<string|null>()
  const [secondFolderName, setSecondFolderName]= useState<string[]>([])
  
  const [folderActive, setfolderActive]= useState<number>()
    const getactiveFolders= useSelector(getactiveFolder)
    const getFirtsFolderPaths= useSelector(getFirtsFolderPath)
  
    const getSecondFolders= useSelector(getSecondFolder)
   
    useEffect(()=>{
      setIsAllFolder(true)
    },[])

    //get media folder
    const [mediaFolder, setMediaFolder]= useState<folderStructureModel[]>([])
    const getMediaFolders= useSelector(getMediaFolder)
    useEffect(()=>{
      if(getMediaFolders && getMediaFolders.length>0){
        setMediaFolder(getMediaFolders)
      }
    },[getMediaFolders])
   
   const handleSubFolder=()=>{
 setIsSearchFolder(true)
//  setSearchFolderName(folderName)
   }

   // get all lfolder from s3
   const handleResetS3Folder=()=>{
    
    setIsAllFolder(false)
   }

   
  


  return (
    <div>
       {/* <Header/> */}
<div className='row'>


      <LeftSection

      />
      <div className="col-9 brand-right-section">
        
        { 
        isAllFolder &&
         <GetAllS3BucketFolder
          resetMain={handleResetS3Folder}
          isAllFolder={isAllFolder}
          />}

       {/* main folder */}
       {mediaFolder&&
       <Mainfolder
       
       homeFolder={handleSubFolder}
       />}

       {/*  search second Folder */}
        {/* {isSearchFolder &&
      
        <GetAllFolder
        resetSubFolder={handleResetSubFolder}
        resetSubFolderErr={handleResetSearchFolderErr}
        />
        } */}

       {/* second folder home */}
     {/* { isSecondFolder &&
      <SecondFolderHome
      resetSecondHome={handleOpenFirstFolder}
      />
     } */}
      
      

  
     

     
     
      </div>


    </div>
    </div>
  )
}

export default MediaLibraryHome