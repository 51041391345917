import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserNewProject, getUserProjectCompressUrl, getUserProjectImageUrl, updateCurrentProjectImage, updateProject } from '../../slice/user/UserProject'
import { getUserLogin, updateUserprojects } from '../../slice/user/UserSlice';
import { UpdateProjectData } from '../../api/projects/ProjectApi';
import { UpdateProjectModel } from '../../Model/project/ProjectModel';
import { getUserJobData } from '../../slice/userJobSlice/UserJobSlice';



type Props={
  resetUpdateProject:(data:string)=>void
}
const UpdateNewProjectApi = ({resetUpdateProject}:Props) => {
       const dispatch= useDispatch()
       const getUserProjectCompressUrls= useSelector(getUserProjectCompressUrl)
    const getUserNewProjects= useSelector(getUserNewProject);
      const getUserProjectImageUrls= useSelector(getUserProjectImageUrl)
       const getUserLogins= useSelector(getUserLogin)
       const isApi= useRef(true)
       const isApi2= useRef(true)
     const  getUserJobDatas= useSelector(getUserJobData)
     const [updateProjectData, setUpdateProjectData]= useState<UpdateProjectModel|null>(null)
      useEffect(()=>{
        
 if(getUserNewProjects && 
    getUserNewProjects.id&&
    getUserProjectImageUrls &&
    getUserProjectCompressUrls&&
    getUserLogins[0].email &&
    getUserJobDatas?.id &&
    isApi.current
  ){
         isApi.current=false
        const data={
            id:getUserNewProjects.id,
            image:getUserProjectImageUrls,
            compressImage:getUserProjectCompressUrls,
            email:getUserLogins[0].email,
            role:getUserLogins[0].role,
            jobId:getUserJobDatas?.id
        }
        setUpdateProjectData(data)
        // updateProjectImage(data)

 }

      },[getUserNewProjects,getUserProjectImageUrls,getUserLogins,getUserJobDatas])



      // ,call Api

      useEffect(()=>{
        if(updateProjectData && 
          updateProjectData.id &&
          updateProjectData.image &&
          updateProjectData.email &&
          updateProjectData.role &&
          updateProjectData.jobId &&
          updateProjectData.compressImage&&
          isApi2.current
        ){
          isApi2.current=false
            updateProjectImage(updateProjectData)
          }
      },[updateProjectData])
      const updateProjectImage=async(data:UpdateProjectModel)=>{
        try{
             
            const response= await UpdateProjectData(data);
           
            if(response && response.status===200){
              setUpdateProjectData(null)
                  isApi.current= true
                  isApi2.current=true
                        dispatch(updateCurrentProjectImage({
                          project:response.data.project
                        }))
                      resetUpdateProject("success")
            }

        } catch(err){
          setUpdateProjectData(null)
          isApi.current= true
          isApi2.current=true
          
            alert("error in updating Project")
            resetUpdateProject("fail")
        }
      }

  return (
    <div></div>
  )
}

export default UpdateNewProjectApi