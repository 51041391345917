import axios from "axios";


 const serverApi =process.env.REACT_APP_SERVER_URL


//get all s3Bucket folder
export function GetAllS3Folder(){
   
   
    return axios.get(`${serverApi}/get-all-folder`)
    .then(response => {
        // console.log("fiolder ", response)
        return response
    }).catch(error => {
        throw error;
    });
}
export function GetS3BucketFolder(folderName:string){
    const data={
        folderName:folderName
    }
    return axios.post(`${serverApi}/get-folder`,data)
    .then(response => {
        // console.log("fiolder ", response)
        return response
    }).catch(error => {
        throw error;
    });
}
export function GetS3BucketFolderFiles(folderName:string){
    const data={
        folderName:folderName
    }
    return axios.post(`${serverApi}/get-folder-files`,data)
    .then(response => {
        // console.log("files ", response)
        return response
    }).catch(error => {
        throw error;
    });
}
export function CreateFolderS3Bucket(folderName:string){
    const data={
        folderName:folderName
    }
    return axios.post(`${serverApi}/create-folder`,data)
    .then(response => {
        // console.log("files ", response)
        return response
    }).catch(error => {
        throw error;
    });
}
