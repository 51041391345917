import React, { useState } from "react";
import CreateProjectFrom from "../../createproject/CreateProjectFrom";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Checkbox,
  Card,
} from "@chakra-ui/react";

// import EditProjectFrom from "../EditProjectFrom"
import CreateProjectModel from "../CreateProjectModel";
import { useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import Search from "antd/es/transfer/search";

interface TableData {
  name: string;
  id: number;
  media: string;
  createdat: string;
  segmentcount: string;
  state: string;
}

const Projects = () => {
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [selectAll, setSelectAll] = useState(false);
  const navigate = useNavigate();
  const tableData: TableData[] = [
    {
      id: 1992,
      name: "New Project",
      media:
        "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png",
      segmentcount: "-",
      createdat: "Tuesday, November 28, 2023 at 12:19 PM",
      state: "Published",
    },
    {
      id: 1991,
      name: "New Project",
      media:
        "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png",
      segmentcount: "-",
      createdat: "Tuesday, November 28, 2023 at 12:19 PM",
      state: "Published",
    },
    {
      id: 1990,
      name: "New Project",
      media:
        "https://dzinlystrapi.s3.us-east-2.amazonaws.com/download_6_4980e00438.png",
      segmentcount: "-",
      createdat: "Tuesday, November 28, 2023 at 12:19 PM",
      state: "Published",
    },
  ];

  const handleCheckboxChange = (id: number) => {
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSelectAllChange = () => {
    const newCheckedItems: { [key: number]: boolean } = {};
    tableData.forEach((row) => {
      newCheckedItems[row.id] = !selectAll;
    });
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const [isEditProjectFrom, setIsProEditForm] = useState(false);

  const handleProEditFrom = () => {
    navigate("/dashboard/projects/edit");
  };

  return (
    <>
      <div>
        <div className="row mt-3">
          <div className="col-9 brand-title">
            <h1 className="job-title">Project</h1>
            <p className="m-0">0 entries Found</p>
          </div>

          <div className="col-3 breadcrumb-link">
            <Breadcrumb
              items={[
                {
                  title: <a href="">Project</a>,
                },
                {
                  title: "Project",
                },
              ]}
            />
          </div>
        </div>

        <div className="row table-input">
          <div className="col-6 search-bar">
            <Search
              placeholder="Search swatch"
              //  onSearch={handleSearch}
              //  enterButton
              // style={{ marginBottom: '20px' }}
            />
          </div>

          <div className="col-6">
            <CreateProjectModel />
          </div>
        </div>

        <Card className="mt-4">
          <TableContainer className="seg-table-container">
            <Table variant="simple" className="table-container">
              <Thead className="table-head">
                <Tr>
                  <Th>
                    <Checkbox
                      isChecked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  </Th>
                  <Th>ID</Th>
                  <Th>NAME</Th>
                  <Th>IMAGE</Th>
                  <Th>SEGMENTCOUNT</Th>
                  <Th>CREATEDAT</Th>
                  <Th>STATE</Th>
                  <Th>ACTION</Th>
                </Tr>
              </Thead>
              <Tbody className="table-body">
                {tableData.map((row) => (
                  <Tr key={row.id}>
                    <Td>
                      <Checkbox
                        isChecked={checkedItems[row.id] || false}
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                    </Td>
                    <Td>{row.id}</Td>
                    <Td>{row.name}</Td>
                    <Td>
                      <div className="media-img">
                        <img src={row.media} alt="media" />
                      </div>
                    </Td>
                    <Td>{row.segmentcount}</Td>
                    <Td>{row.createdat}</Td>
                    <Td>
                      <Button className="btn-media">{row.state}</Button>
                    </Td>
                    <Td>
                     

                      <div className="d-flex action">
                        <div
                          className="action-icon-table"
                          onClick={handleProEditFrom}>
                          <i className="bi bi-pencil-square mx-0"></i>
                        </div>
                        <div className="action-icon-table">
                          <i className="bi bi-copy mx-0"></i>
                        </div>
                        <div className="action-icon-table">
                          <i className="bi bi-box-arrow-up-right mx-0"></i>
                        </div>
                        <div className="action-icon-table trash-icon-media">
                          <i className="bi bi-trash3 mx-0"></i>
                        </div>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </>
  );
};

export default Projects;
