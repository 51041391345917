import React from 'react'
import EditCanvasHome from '../../../module/editCanvas/EditCanvasHome'

const EditCanavsPage = () => {
  return (
    <div>
        <EditCanvasHome/>
    </div>
  )
}

export default EditCanavsPage