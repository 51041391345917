import React, { useEffect, useRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { getAllSegment, openAddSegmentModal, updateSegment } from '../../../../slice/segment/SegmentSlice'
import { MasterArrayModel } from '../../../../Model/masterArray/MasterArrayModel'

const AddSegment = () => {
  const dispatch= useDispatch()
   const getMasterArrays= useSelector(getMasterArray)
   const isFetch= useRef(false)
    const getAllSegments= useSelector(getAllSegment)
    //console.log("getAllSegments",getAllSegments)


    useEffect(()=>{
      isFetch.current= true
    },[])
    useEffect(()=>{
      
      if(getAllSegments && 
        getAllSegments.length>0 &&
        getMasterArrays && getMasterArrays.allSeg  &&
        getMasterArrays.allSeg.length>0 &&
        isFetch.current
      ){
        isFetch.current= false
        getAllSegments.map(seg=>{
          const segName= seg.name
          //console.log("segName",segName)
          const segIndex= getMasterArrays.allSeg?.map((item:MasterArrayModel)=>
    
             Object.keys(item).map((key:string)=>{
             // console.log("key",key)
               // console.log("item[key]",item.name)
               
                if(item.name==segName){
                  console.log("item.name",item.name)
                 dispatch(updateSegment({name:segName,isDisabled:true}))
              }
             })
          )
         
          
         
        })
      }
    },[getAllSegments,getMasterArrays])
  const handleAddSegment=()=>{
    isFetch.current= true
    dispatch(openAddSegmentModal())
  }
  return (
    <div >
      <OverlayTrigger placement='right' overlay={<Tooltip id="tooltip-disabled">Add New Segment</Tooltip>}>
        <span className='add-seg-icons 1'
        onClick={handleAddSegment}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-houses" viewBox="0 0 16 16">
            <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207z" />
          </svg>
        </span>
      </OverlayTrigger>
    </div>
  )
}

export default AddSegment