import React from 'react'
import "./Shimmer.scss"
const Shimmer = () => {
    return (
        <>
         <div className=" row g-md-4 g-3">
        <div className=' col-12 col-md-4 '>
            <div className="shimmer-card">
                <div className="shimmerBG media"></div>
                <div className='d-flex justify-content-between mt-2 mx-2'>
                    <div className="shimmerBG title-line "></div>
                    <div className='d-flex sim-icon'>
                        <div className="shimmerBG title-line end mx-2"></div>
                        <div className="shimmerBG title-line end "></div>
                    </div>
                </div>

            </div>
        </div>

        <div className=' col-12 col-md-4'>
            <div className="shimmer-card">
                <div className="shimmerBG media"></div>
                <div className='d-flex justify-content-between mt-2 mx-2'>
                    <div className="shimmerBG title-line "></div>
                    <div className='d-flex sim-icon'>
                        <div className="shimmerBG title-line end mx-2"></div>
                        <div className="shimmerBG title-line end "></div>
                    </div>
                </div>

            </div>
        </div>


        <div className=' col-12 col-md-4'>
            <div className="shimmer-card">
                <div className="shimmerBG media"></div>
                <div className='d-flex justify-content-between mt-2 mx-2'>
                    <div className="shimmerBG title-line "></div>
                    <div className='d-flex sim-icon'>
                        <div className="shimmerBG title-line end mx-2"></div>
                        <div className="shimmerBG title-line end "></div>
                    </div>
                </div>

            </div>
        </div>


        <div className=' col-12 col-md-4'>
            <div className="shimmer-card">
                <div className="shimmerBG media"></div>
                <div className='d-flex justify-content-between mt-2 mx-2'>
                    <div className="shimmerBG title-line "></div>
                    <div className='d-flex sim-icon'>
                        <div className="shimmerBG title-line end mx-2"></div>
                        <div className="shimmerBG title-line end "></div>
                    </div>
                </div>

            </div>
        </div>

        <div className=' col-12 col-md-4'>
            <div className="shimmer-card">
                <div className="shimmerBG media"></div>
                <div className='d-flex justify-content-between mt-2 mx-2'>
                    <div className="shimmerBG title-line "></div>
                    <div className='d-flex sim-icon'>
                        <div className="shimmerBG title-line end mx-2"></div>
                        <div className="shimmerBG title-line end "></div>
                    </div>
                </div>

            </div>
        </div>

        <div className=' col-12 col-md-4'>
            <div className="shimmer-card">
                <div className="shimmerBG media"></div>
                <div className='d-flex justify-content-between mt-2 mx-2'>
                    <div className="shimmerBG title-line "></div>
                    <div className='d-flex sim-icon'>
                        <div className="shimmerBG title-line end mx-2"></div>
                        <div className="shimmerBG title-line end "></div>
                    </div>
                </div>

            </div>
        </div>
        </div>
        </>
        
    )
}

export default Shimmer
