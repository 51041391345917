
import axios from "axios";
import { MaterialStyleModel } from "../../../Model/material/MaterialStyleModel";

// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll maetrial catorgyies
export function GetAllMaterialsStyle() {
    
    return axios.get(`${serverApi}/material-library/styles`)
        .then(response => {
            return response
        }).catch(error => {
            throw error;
        });


}

// update 
export function UpdateStyle(data:MaterialStyleModel){
    return axios.post(`${serverApi}/material-library/style/update`,data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// add
export function AddStyle(data:MaterialStyleModel){
    return axios.post(`${serverApi}/material-library/style/add`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// delete
export function DeleteStyle(id:number){
    const data={
        id:id
    }
    return axios.post(`${serverApi}/material-library/style/delete`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
