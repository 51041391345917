import React from 'react'

const AnnotationList = () => {
  return (
    <div>

  <div className="seg-pop-cont">
        <ul className="seg-anno-act">
          {/* <li>
            {" "}
            <i className="bi bi-ban"></i> Convert to Negative
          </li> */}
          {/* <li>
            <i className="bi bi-cursor"></i> Convert to Smart Polygon
          </li> */}
          {/* <li>
            <i className="bi bi-bounding-box-circles"></i>Convert to Box
          </li> */}
          <li>
            <i className="bi bi-front"></i> Bring to Front
          </li>
          <li>
            <i className="bi bi-back"></i> Send to Back
          </li>
          <li>
            <i className="bi bi-layer-forward"></i> Bring Forward
          </li>
          <li>
            <i className="bi bi-layer-backward"></i> Send Backward
          </li>
          <li>
            {" "}
            <i className="bi bi-copy"></i> Duplicate Object
          </li>
          <li>
            <i className="bi bi-trash3"></i>Delete
          </li>
        </ul>
      </div>
  </div>

  )
}

export default AnnotationList
