import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsOverAllSwatches, getMasterArray, resetIsUpdateOverAllSwatch, updateOverAllSwatchImages } from '../../slice/canvas/masterArray/MasterArraySlice'
import { Swatch } from '../../Model/Job/SamModel'
import { getUserLogin } from '../../slice/user/UserSlice'

const SortOverAllSwatches = () => {
    const getUserLogins= useSelector(getUserLogin )
    const getMasterArrays= useSelector(getMasterArray)
    const getIsOverAllSwatchess= useSelector(getIsOverAllSwatches)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(getMasterArrays && 
            getMasterArrays.allSeg &&
            getMasterArrays.allSeg.length>0 &&
            getUserLogins && 
            getUserLogins.length>0 &&
            getIsOverAllSwatchess
        ){
             dispatch(resetIsUpdateOverAllSwatch())
            getMasterArrays.allSeg.map((grp,index)=>{
                let allswatch:Swatch[]=[]
                const groupName= grp.name
                const userId= getUserLogins[0].id
                const groupSegments= grp.segmentation
                if(groupSegments && 
                    groupSegments.length>0 && 
                    userId){
                    groupSegments.map(subGrp=>{
                       Object.keys(subGrp).forEach(keys=>{
                        const subGroupArray=subGrp[keys]  // wall1 array
                    //console.log("subGroupArray", subGroupArray) // sub group Array
                       
                        if(subGroupArray && subGroupArray.length>0){
                           
                            subGroupArray.map(child=>{
                                const childUser= child.designer?.filter(item=>item.user_id===userId)
                                 if(childUser && childUser.length>0){
                                    const childSwatch=childUser[0].swatch??[]
                                    const uniqueDesigners = Array.from(new Map(childSwatch.map(designer => [designer.swatch_id, designer])).values()) ;
                                    console.log('uniqueDesigners==',uniqueDesigners)
                                    allswatch.push(...uniqueDesigners); // Spread operator to flatten the array
                                 }
                                
                            })
                        }
                       })
                    })
                }
                const uniqueDesigners = Array.from(new Map(allswatch.map(designer => [designer.swatch_id, designer])).values()) ;

                console.log('allswatch=======',allswatch)
                console.log('uniqueDesigners=======',uniqueDesigners)
                if(uniqueDesigners && uniqueDesigners.length>0){
                    
                    dispatch(updateOverAllSwatchImages({
                        groupName:groupName,
                        ovetAllSwatches:uniqueDesigners
                    }))
                }
                

            })
        }
    },[getMasterArrays,getUserLogins,getIsOverAllSwatchess])
  return (
    <></>
  )
}

export default SortOverAllSwatches