import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MediaLibraryHome from '../../module/admin/mediaLibrary/MediaLibraryHome';


const MediaRoutes = () => {
  return (
    <Routes>
      <Route path="/folder" element={<MediaLibraryHome />} />
      
    </Routes>
  );
};

export default MediaRoutes;
