import React from 'react'
import ShowProjects from '../../module/projectPage/ProjectHome'
import GetAllSwatchImage from '../../module/ImageView/LeftSections/segmentbasedSwatch/GetAllSwatchImage'

const Projects = () => {
  return (
    <div className='p-0'>
        <ShowProjects/>
        <GetAllSwatchImage/>
    </div>
  )
}

export default Projects