import React, { useState } from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import SwatchBrandTable from '../../../brandPage/brand/SwatchBrandTable'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import EditBrandForm from '../../../brandPage/brand/EditBrandForm'
import { MaterialBrandModel } from '../../../../Model/material/MaterialBrandModel'

import { useDispatch } from 'react-redux'
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice'
import UpdateBrandApi from './api/UpdateBrandApi'
import { useNavigate } from 'react-router-dom'
import Header from '../../../header/Header'

const AdminBrandEdit = () => {
   const dispatch= useDispatch()
  const [ isEditApi , setIsEditApi]= useState<boolean>(false)
   const[editBrand, setEditBrand]= useState<MaterialBrandModel|null>()
     const navigate= useNavigate()
     const handleUpdateBrand=(data:MaterialBrandModel)=>{
     dispatch(startLoading())
     
      setEditBrand(data)
      setIsEditApi(true)

     }

      const handleResetBrand=(mess:string)=>{
         if(mess==="success"){
          setIsEditApi(false)
          setEditBrand(null)
          dispatch(stopLoading())
          alert("Brand updated sucessfully")
         } else if (mess=="fail"){
          setIsEditApi(false)
          setEditBrand(null)
          dispatch(stopLoading())
          alert("Error in updating Brand")
          
         }
              navigate("/dashboard/swatch-brands")
        }
  return (
    <>
      {/* <Header/> */}

<div className='row'>


  <LeftSection
   
  />
<div className="col-9 brand-right-section">

   
    <EditBrandForm
    updatData={handleUpdateBrand}
    />



    {isEditApi && editBrand &&
       <UpdateBrandApi
       editBrand={editBrand}
       resetUpdate={handleResetBrand}
       
       />
    }
  </div>



  {/* getAll materials */}
  <GetAllMaterialsHome/>
</div>
    </>
  
  )
}

export default AdminBrandEdit