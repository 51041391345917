import React from 'react'
import CategoryTable from './CategoryTable'

type Props={
  isCategory:boolean
}

const CategoryHome = ({isCategory}:Props) => {
  return (
    <div>

      {isCategory &&<CategoryTable/>}
    </div>
  )
}

export default CategoryHome