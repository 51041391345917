import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CanvasControlModel, DimensionModel, GroupMasterSegment, JobSegmentationModel, MasterModel, ReAnnotationModel } from "../../../Model/masterArray/MasterArrayModel";


interface DimensionStates {
   
    canavasDimension:DimensionModel[]
    
}

const initialState: DimensionStates = {
    canavasDimension:[]

};


const DimensionSlice = createSlice({
    name: "canvasDimension",
    initialState,
    reducers: {
        addDimension:(state,action)=>{
           state.canavasDimension= action.payload  
        },
         resetDimension:(state)=>{
            state.canavasDimension=[]
              
        }, 
       
    }
})

export const {addDimension,resetDimension}=DimensionSlice.actions;
 export const getCanvasDimension=  (state:{canvasDimension:DimensionStates})=>state.canvasDimension.canavasDimension
export default DimensionSlice.reducer