import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import {  getSelectedSegment, getUserJobBase64 } from '../../../slice/userJobSlice/UserJobSlice';
import { getCanvasSize } from '../../../slice/userScreenSlice/userScreenResolution';
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice';
import { fabric } from 'fabric';
import { JobSegmentationModel, PointModel } from '../../../Model/masterArray/MasterArrayModel';
import { Button } from 'react-bootstrap';
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice';

interface EditablePolygon extends fabric.Polygon {
  edit?: boolean;
  points?: fabric.Point[];
}

// Define a type for controls with dynamic keys
interface PolygonControls {
  [key: string]: fabric.Control;
}
const EditCanavasRight = () => {
  const canvasRef = useRef<fabric.Canvas|null>(null);
  const [scaleX, setScalex] = useState<number|null|undefined>();
  const [scaleY, setScaley] = useState<number|null|undefined>();
  const getUserJobBase64s = useSelector(getUserJobBase64);
  const getCanvasSizes = useSelector(getCanvasSize);
  const getMasterArrays= useSelector(getMasterArray)
  const getSegregateSegments= useSelector(getSegregateSegment)
  const isApi= useRef(true)
    const [editSegName, setEditSegname]= useState<string|null>(null)
    const [editSeg, setEditSeg]= useState<JobSegmentationModel|null>(null)
    const getSelectedSegments= useSelector(getSelectedSegment)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [points, setPoints] = useState<fabric.Point[]>([]);
   // intailly isApi is true
   useEffect(()=>{  
       
    if(canvasRef.current===null && 
      !isApi.current
      ){
      isApi.current=true
    }
  },[canvasRef.current])

 // get canvas
  useEffect(() => {
    if (getCanvasSizes?.canvasWidth != null &&
       getCanvasSizes?.canvasHeight != null && 
       isApi.current) {
      isApi.current= false
  
      const canvasElement = document.getElementById('edit-layer') as HTMLCanvasElement;
      const rightSection = document.querySelector('.canvas-right') as HTMLElement;
      const canvas = new fabric.Canvas(canvasElement, {
        selectionBorderColor: 'green',
        width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
        height: window.innerHeight, // Full height of the viewport
      });

      // Optional: Adjust the canvas size when the window is resized
      window.addEventListener('resize', () => {
        canvas.setWidth(rightSection==null?window.innerWidth:(window.innerWidth - rightSection.offsetWidth));
        canvas.setHeight(window.innerHeight);
        canvas.renderAll();
      });

      canvasRef.current = canvas;

      const imgGroup = new fabric.Group([], { selectable: false, visible: true });
    
      canvas.add(imgGroup);
      

       

      
    } else {
      isApi.current=true
    }
  }, [getCanvasSizes,isApi]);


  // get image on canvas
  useEffect(() => {
    if (getUserJobBase64s && canvasRef.current) {
      canvasRef.current.clear();
      const image = getUserJobBase64s;
      const encodedImage = "data:image/png;base64," + image;
      const decodedImage = atob(encodedImage.split(",")[1]);
      const uint8Array = new Uint8Array(decodedImage.length);
      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "image/png" });
      let imageUrl = URL.createObjectURL(blob);

      fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
        const canvas = canvasRef.current;
        if (canvas && canvas.width && canvas.height && img.width && img.height) {
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          img.scaleToWidth(canvasWidth);
          img.scaleToHeight(canvasHeight);

          canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
            scaleX: canvasWidth / img.width,
            scaleY: canvasHeight / img.height,
            originX: 'left',
            originY: 'top',
          });
          setScalex(canvasWidth / img.width);
          setScaley(canvasHeight / img.height);
          canvas.requestRenderAll();
        }
      });
    }
  }, [getUserJobBase64s]); 


  // get Selected Segment for Edit
  useEffect(()=>{
    if(getSelectedSegments &&
      getSelectedSegments.length>0
    ){
     
      if(getSelectedSegments.length>1){
        
      }else{
        setEditSegname(getSelectedSegments[0].name??"")
        setEditSeg(getSelectedSegments[0])
      }
    }
  },[getSelectedSegments])

  // create polygon
    useEffect(() => {
      //
      let allPoly;
      let coordinate;
      if (canvasRef.current &&
        scaleX!=null &&
        scaleY!=null&&
        canvasRef.current&&
        editSeg 
        ) {
          //  console.log("edit seg",editSeg)
     
           Object.keys(editSeg).forEach((key) => {
            const annotation = editSeg?.details?.annotation;
            const cordinate = editSeg?.details?.bb_annotation_int;
            const segName = editSeg.name;
            if (annotation && cordinate && segName) {
              collectPoints(annotation, segName, cordinate);
            }
           
           })
      }
    
    }, [editSeg, canvasRef,scaleX,scaleY]);


     // adding scaling to annotation point
     const collectPoints=(annotation:number[],segName:string, coordinates:number[])=>{
    
      if(annotation &&
       scaleX &&
       scaleY
      ){
       const  point:PointModel[]=[]
       let polyName=segName;
      let i;
              for ( i = 0; i < annotation.length; i += 2) {
                const x = annotation[i]*scaleX;
                const y = annotation[i + 1]*scaleY;
                point.push({ x, y });
              }
       
                if(point && point.length>0){
                makePolygon(point,coordinates,polyName)
                }
      }

       
     }
 


     const makePolygon=(point:PointModel[],coordinate:number[], polyName:string)=>{
      if(point&&
        point.length>0&&
        coordinate&&
        polyName &&
        scaleX &&
        scaleY &&
        canvasRef.current
        ){

          var polygon = new fabric.Polygon(point, {
            left: coordinate[0]*scaleX,
            top: coordinate[1]*scaleY,
            fill: "transparent",
            strokeWidth: 2,
            stroke: "rgb(7 239 253)",
            scaleX: 1,
            scaleY: 1,
            objectCaching: false,
            transparentCorners: false,
            cornerColor: "red",
            cornerStyle: "circle",
            selectable:false
          
          });
         // setPolygon(polygon)
          canvasRef.current.viewportTransform = [1, 0, 0, 1, 0, 0];
          canvasRef.current.add(polygon);
          setIsEdit(true)
        
          }
        }
        const polygonPositionHandler = function (
          this: { pointIndex: number }, // Type for this
          dim: any,
          finalMatrix: any,
          fabricObject: any
        ) {
          // Using this.pointIndex with correct type annotation
          const x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x;
          const y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
        
          // Create a fabric.Point object for transformPoint
          const transformedPoint = fabric.util.transformPoint(
            new fabric.Point(x, y), // Use fabric.Point instead of plain object
            fabric.util.multiplyTransformMatrices(
              fabricObject.canvas.viewportTransform,
              fabricObject.calcTransformMatrix()
            )
          );
        
          return transformedPoint;
        };
        
        
          // Define anchorWrapper function
          const anchorWrapper = function (
            anchorIndex: number,
            fn: (eventData: fabric.IEvent, transform: fabric.Transform, x: number, y: number) => boolean
          ) {
            return function (eventData: fabric.IEvent, transform: fabric.Transform, x: number, y: number) {
              
              const fabricObject = transform.target as fabric.Polygon;

              if (fabricObject.points && fabricObject.points[anchorIndex]) {
                const absolutePoint = fabric.util.transformPoint(
                  new fabric.Point(
                    fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
                    fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y
                  ),
                  fabricObject.calcTransformMatrix()
                );
                const actionPerformed = fn(eventData, transform, x, y);
             // const newDim = fabricObject._setPositionDimensions({});
              const polygonBaseSize = getObjectSizeWithStroke(fabricObject);
              const newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x;
              const newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
             
              fabricObject.setPositionByOrigin(absolutePoint, 'center', 'center');
              return actionPerformed;
              } else {
                console.warn('Points or the specific point is undefined.');
              }}
          
              
            };
        
    

        //call edit function
        useEffect(()=>{
          if(isEdit && canvasRef.current){
            Edit()
          }
        },[isEdit,canvasRef])
        
        const Edit = () => {
          if (canvasRef.current) {
            const objects = canvasRef.current.getObjects();
            if (objects.length > 0) {
              let poly = objects[0] as EditablePolygon;
              canvasRef.current.setActiveObject(poly);
              poly.edit = !poly.edit;
        
              if (poly.edit && poly.points) {
                const lastControl = poly.points.length - 1;
                poly.lockMovementX = true;
                poly.lockMovementY = true;
                poly.cornerStyle = 'circle';
                poly.cornerColor = 'rgb(255 1 154)';
                poly.cornerSize = 7;
                // poly.cornerStrokeWidth = 3; // This property might not exist in the Fabric.js version you are using
                poly.cornerStrokeColor = 'rgb(7 239 253)';
        
                // Type the controls assignment properly
                poly.controls = poly?.points.reduce<PolygonControls>((acc, point, index) => {
                  acc[`p${index}`] = new fabric.Control({
                    cursorStyle: 'pointer',
                    positionHandler: polygonPositionHandler.bind({ pointIndex: index }),
                    // actionHandler: anchorWrapper(index > 0 ? index - 1 : poly.points.length - 1, actionHandlers).bind({ pointIndex: index }),
                    actionName: 'modifyPolygon',
                  });
                  return acc;
                }, {});
              } else {
                poly.cornerColor = 'black';
                poly.cornerStyle = 'rect';
                poly.controls = fabric.Object.prototype.controls;
              }
              poly.hasBorders = !poly.edit;
              canvasRef.current.requestRenderAll();
            }
          }
        };
        
    // Define actionHandler function
 // Import necessary fabric types if not already imported
// Update the actionHandler function to accept IEvent<Event>
const actionHandlers = function (
  transform: fabric.Transform,
  x: number,
  y: number
): boolean {
  const polygon = transform.target as fabric.Polygon;
  if (!polygon || !polygon.points) {
    console.warn('Polygon or its points are undefined.');
    return false;
  }

  const corner = transform.corner;
  const currentControl = parseInt(corner.match(/\d+/)?.[0] || "0", 10);
  const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), "center", "center");
  const polygonBaseSize = getObjectSizeWithStroke(polygon);
  const size = polygon._getTransformedDimensions(0, 0);

  const finalPointPosition = new fabric.Point(
    (mouseLocalPosition.x * polygonBaseSize.x) / size.x + polygon.pathOffset.x,
    (mouseLocalPosition.y * polygonBaseSize.y) / size.y + polygon.pathOffset.y
  );

  polygon.points[currentControl] = finalPointPosition;

  setPoints([...polygon.points]);

  polygon.setCoords();
  canvasRef.current?.renderAll();

  return true;
};

// Define getObjectSizeWithStroke function with appropriate types
function getObjectSizeWithStroke(object: fabric.Object): fabric.Point {
  const scaleX = object.scaleX ?? 1; // Default to 1 if scaleX is undefined
  const scaleY = object.scaleY ?? 1; // Default to 1 if scaleY is undefined

  const stroke = new fabric.Point(
    object.strokeUniform ? 1 / scaleX : 1,
    object.strokeUniform ? 1 / scaleY : 1
  ).multiply(object.strokeWidth || 0);

  return new fabric.Point(
    (object.width ?? 0) + stroke.x,  // Ensure width is not undefined
    (object.height ?? 0) + stroke.y  // Ensure height is not undefined
  );
}


  return (
    <div>
      <h4>Edit canavs</h4>
       
      
       <canvas id='edit-layer' className='dzi-van' ></canvas>
    </div>
  )
}

export default EditCanavasRight