import React, { useEffect } from 'react'
import Header from '../header/Header'
import MiddleSection from './MiddleSection'
import Footer from '../footer/Footer'
import { useDispatch } from 'react-redux'
import { getUserLogin } from '../../slice/user/UserSlice'
import GetAllMaterialsHome from '../getAllMaterials/GetAllMaterialsHome'
import GetScreenResolution from '../getUserScreenResolution/GetUserScreenResolution'
import WebSocketComponent from '../webSocket/StartWebSocket'
const Home = () => {
  const dispatch= useDispatch()
 
  return (
    <div> 
     <GetAllMaterialsHome/>
      <MiddleSection/>
      <GetScreenResolution/>
{/* web socket component */}
      <WebSocketComponent/>
    </div>
  )
}

export default Home