import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenAiAttributesModel, PromptModel } from "../../Model/genAiAttributes/GenAiAttributes";


interface GenAiAttributesStates {
    genAi_attributes:GenAiAttributesModel[]
    promptvalue:PromptModel,
    isStartGenAiImage:boolean
   
    
}

const initialState: GenAiAttributesStates = {
    genAi_attributes:[],
    promptvalue:{},
    isStartGenAiImage:false


};

const GenAiAttributesSlice = createSlice({
    name: "genai_attributes",
    initialState,
    reducers: {
      addGenAiAttributes:(state,action)=>{
        state.genAi_attributes=action.payload
      },
      resetGenAiAttributes:(state)=>{
        state.genAi_attributes=[]
      },
      addPrompvalue:(state,action)=>{
        const {promptKey, promptvalue}= action.payload
        const allvalue= state.promptvalue;
        if(allvalue){
            const updated={
              ...allvalue,
              [promptKey]:promptvalue
            }
            state.promptvalue=updated
        }else{
    state.promptvalue={
      [promptKey]:promptvalue

    }
        }
      }, 
      resetPrompValue:(state)=>{
        state.promptvalue={}
      }, 
      startGenAiImage:(state)=>{
        state.isStartGenAiImage=true
      }, stopGenAiImage:(state)=>{
        state.isStartGenAiImage= false
      }
      

    }
}
)

export const {addGenAiAttributes,
   resetGenAiAttributes,
   addPrompvalue,resetPrompValue,
   startGenAiImage,stopGenAiImage
   
  }=GenAiAttributesSlice.actions;
export const getGenAiAttributes=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.genAi_attributes
export const getPromptValues=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.promptvalue
export const getGenAiImage=  (state:{genai_attributes:GenAiAttributesStates})=>state.genai_attributes.isStartGenAiImage
export default GenAiAttributesSlice.reducer