import React, { useRef, useState } from "react";
import { Text, FormControl, Select, Box, Card } from "@chakra-ui/react";
import { Input, FormLabel } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import ParametersFrom from "./ParametersFrom";
import SegmentationFrom from "./SegmentationFrom";
import "./EditProject.css"
import { useNavigate } from "react-router-dom";
const EditJobs = () => {

  const navigate= useNavigate()
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(null);
    }
  };
  

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setSelectedFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragImage = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  

  const [isSegFromOpen, setisSegFromOpen] = useState(false);
  const handleSegFrom = () => {
    setisSegFromOpen(true);
  };

  const [isPraFromOpen, setisPraFromOpen] = useState(false);
  const handlePraFrom = () => {
    setisPraFromOpen(true);
  };

  const handleBack=()=>{
      navigate("/dashboard/jobs")
  }
  
  return (
    <>  
     <div className="back-icon" onClick={handleBack}>
     <i className="bi bi-arrow-left-short"></i>
    <span>Back</span>
   </div>
   
    <Card className="card-edit py-4 mt-4">
      <div className="row container  from-edit">
        <div className="col-6 from-list">
          <FormLabel>Project </FormLabel>
          <Select className="input-primary" placeholder="Project">
            <option>1 May</option>
            <option>10 June</option>
          </Select>
        </div>

        <div className="col-6 from-list">
          <FormLabel>JobId</FormLabel>
          <Input className="input-primary"  />
        </div>

        <div className="col-12 from-lable from-list">
          <FormLabel>JobImage</FormLabel>
          <Box
            className="box-primary"
            border="1px solid #cbd5e0"
            borderRadius="md"
            padding="20px"
            textAlign="center"
            onClick={handleDragImage}
            onDrop={handleDrop}
            onDragOver={handleDragOver}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-image-fill"
              viewBox="0 0 16 16">
              <path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0" />
            </svg>
            <Text
              mt={2}
              color="gray.500"
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "rgb(102, 102, 135)",
              }}>
              Click to add an asset or drag and drop one in this area
            </Text>
            <Input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              display="none"
            />
          </Box>
        </div>

        <div className="col-12 from-list" >
          <FormLabel>Jobjson</FormLabel>
          <Textarea style={{ backgroundColor: "#f6f6f9" }} />
        </div>

        <div className="col-6 from-lable from-list">
          <FormLabel>JobView</FormLabel>
          <Select className="input-primary" placeholder="JobView">
            <option>Choose here</option>
            <option>Front</option>
            <option>back</option>
            <option>Left</option>
            <option>Right</option>
          </Select>
        </div>

        <div className="col-6 from-lable from-list">
          <FormLabel>JobType</FormLabel>
          <Select className="input-primary" placeholder="JobType">
            <option>Choose here</option>
            <option>Design</option>
            <option>Arch</option>
          </Select>
        </div>

        <div className="col-12 from-list" >
          <FormLabel>Shadows</FormLabel>
          <Textarea style={{ backgroundColor: "#f6f6f9" }} />
        </div>

        <div className="col-12 mt-1">
          <FormLabel >Segmentation </FormLabel>

          {!isSegFromOpen && (
            <Box
              className="box-primary"
              border="1px solid #cbd5e0"
              borderRadius="md"
              padding="32px 0 15px 0"
              textAlign="center"
              onClick={handleSegFrom}>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-plus-circle-dotted"
                viewBox="0 0 16 16">
                <path d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
              </svg>
              <Text
                mt={2}
                color="gray.500"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgb(102, 102, 135)",
                }}>
                No entry yet. Click on the button below to add one.
              </Text>
            </Box>
          )}
        </div>

        {isSegFromOpen && <SegmentationFrom />}

        <div className="col-6  from-list">
          <FormLabel>PixelUnit</FormLabel>
          <Input className="input-primary" />
        </div>

        <div className="col-6  from-list">
          <FormLabel>PixelValue</FormLabel>
          <Input className="input-primary" />
        </div>

        <div className="col-6  from-list">
          <FormLabel>key</FormLabel>
          <Input className="input-primary" />
        </div>

        <div className="col-6  from-list">
          <FormLabel>Designers</FormLabel>
          <Select className="input-primary" placeholder="Project">
            <option>AAbb</option>
            <option>AAcc</option>
          </Select>
        </div>

        <div className="col-12 from-list" >
          <FormLabel>Parameters</FormLabel>
          {!isPraFromOpen && (
            <Box
              className="box-primary"
              border="1px solid #cbd5e0"
              borderRadius="md"
              padding="32px 0px 15px 0px"
              textAlign="center"
              onClick={handlePraFrom}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-plus-circle-dotted" viewBox="0 0 16 16">
                <path d="M8 0q-.264 0-.523.017l.064.998a7 7 0 0 1 .918 0l.064-.998A8 8 0 0 0 8 0M6.44.152q-.52.104-1.012.27l.321.948q.43-.147.884-.237L6.44.153zm4.132.271a8 8 0 0 0-1.011-.27l-.194.98q.453.09.884.237zm1.873.925a8 8 0 0 0-.906-.524l-.443.896q.413.205.793.459zM4.46.824q-.471.233-.905.524l.556.83a7 7 0 0 1 .793-.458zM2.725 1.985q-.394.346-.74.74l.752.66q.303-.345.648-.648zm11.29.74a8 8 0 0 0-.74-.74l-.66.752q.346.303.648.648zm1.161 1.735a8 8 0 0 0-.524-.905l-.83.556q.254.38.458.793l.896-.443zM1.348 3.555q-.292.433-.524.906l.896.443q.205-.413.459-.793zM.423 5.428a8 8 0 0 0-.27 1.011l.98.194q.09-.453.237-.884zM15.848 6.44a8 8 0 0 0-.27-1.012l-.948.321q.147.43.237.884zM.017 7.477a8 8 0 0 0 0 1.046l.998-.064a7 7 0 0 1 0-.918zM16 8a8 8 0 0 0-.017-.523l-.998.064a7 7 0 0 1 0 .918l.998.064A8 8 0 0 0 16 8M.152 9.56q.104.52.27 1.012l.948-.321a7 7 0 0 1-.237-.884l-.98.194zm15.425 1.012q.168-.493.27-1.011l-.98-.194q-.09.453-.237.884zM.824 11.54a8 8 0 0 0 .524.905l.83-.556a7 7 0 0 1-.458-.793zm13.828.905q.292-.434.524-.906l-.896-.443q-.205.413-.459.793zm-12.667.83q.346.394.74.74l.66-.752a7 7 0 0 1-.648-.648zm11.29.74q.394-.346.74-.74l-.752-.66q-.302.346-.648.648zm-1.735 1.161q.471-.233.905-.524l-.556-.83a7 7 0 0 1-.793.458zm-7.985-.524q.434.292.906.524l.443-.896a7 7 0 0 1-.793-.459zm1.873.925q.493.168 1.011.27l.194-.98a7 7 0 0 1-.884-.237zm4.132.271a8 8 0 0 0 1.012-.27l-.321-.948a7 7 0 0 1-.884.237l.194.98zm-2.083.135a8 8 0 0 0 1.046 0l-.064-.998a7 7 0 0 1-.918 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
              </svg>
              <Text
                mt={2}
                color="gray.500"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgb(102, 102, 135)",
                }}>
                No entry yet. Click on the button below to add one.
              </Text>
            </Box>
          )}
        </div>
        {isPraFromOpen && <ParametersFrom />}
      </div>
    </Card>
    </>
  );
}

export default EditJobs;
