import React, { useRef, useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  ButtonGroup,
  PopoverAnchor,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { Input, FormLabel } from "@chakra-ui/react";
import { Text, FormControl, Select, Box, Card } from "@chakra-ui/react";
import "../../createproject/CreateProjectFrom.css";

import CreateProjectShape from "./CreateProjectShape";
const EditShapeCreate = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      setSelectedFile(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };


  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isSegFromOpen, setisSegFromOpen] = useState(false);
  const handleSegFrom = () => {
    setisSegFromOpen(true);
  };

  const [isPraFromOpen, setisPraFromOpen] = useState(false);
  const handlePraFrom = () => {
    setisPraFromOpen(true);
  };

  return (
    <>
      <div className="new-entry-btn">
        <button className="create-new-entry-btn" onClick={onToggle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-plus"
            viewBox="0 0 16 16">
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
          </svg>
          Create new entry
        </button>
      </div>

      <div className="create-from-section">
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          placement="right"
          closeOnBlur={false}>
          <PopoverContent className="create-entry-from">
            <PopoverHeader fontWeight="semibold" className="contact-from-title">
              <h1>Create new entry</h1>
            </PopoverHeader>
            <PopoverArrow className="close-btn" />


            <PopoverCloseButton className="close-button" />
            <PopoverBody>
              <div className="edit-from">
                <h4>Edit this from</h4>
                <span>
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-box-arrow-up-right"
                    viewBox="0 0 16 16">
                    <path
                      fill-rule="evenodd"
                      d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                    />
                  </svg>
                </span>
              </div>

              <div className="edit-project-from edit-job-from">
                <CreateProjectShape/>        
   

              </div>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};

export default EditShapeCreate;
