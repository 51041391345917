import React from 'react'
import ShapeTable from '../../module/brandPage/shapes/ShapeTable'


const ShapeTablePage = () => {
  return (
    <div>
    <ShapeTable/>
    </div>
  )
}

export default ShapeTablePage
