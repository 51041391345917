import React from 'react'

const ProjectIds = () => {
  return (
    <div>
        {/* <EditProject/> */}
    </div>
  )
}

export default ProjectIds