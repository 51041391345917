import React from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  ButtonGroup,
  PopoverAnchor,
  useDisclosure,
  Portal,
} from "@chakra-ui/react";


import "./Media.css"

import { useNavigate } from "react-router-dom";

type Props = { 

}; 

const Media = () => {

   const navigate= useNavigate()
  const handleSwatch = () =>{
    navigate("/dashboard/swatches")
  }

  const handleStyle=()=>{
    navigate("/dashboard/swatch-styles")
  }

  const handleBrand=()=>{
        navigate("/dashboard/swatch-brands")
  }


  const handleCategory=()=>{
    navigate("/dashboard/swatch-categories")
  }
  const { isOpen, onToggle, onClose } = useDisclosure();
  return (
    <div className="media-section">
      <Popover trigger="hover">
        <PopoverTrigger>
          <div className="d-flex card-list-title mt-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-image mx-3"
              viewBox="0 0 16 16">
              <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
              <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
            </svg>
            <h2 className="project-title">Material Library </h2>
          </div>
        </PopoverTrigger>
        <Portal>
          <PopoverContent className="model-content ">
            <PopoverHeader className="types-title pb-2 px-2" fontWeight="semibold">
              {" "}
              <span className="type-title">MATERIAL LIBRARY TYPES</span>
              <span className="show-type">3</span>
            </PopoverHeader>

            <PopoverBody>
              <Popover>
                <div className="list-title">
                  <ul>
                    <li onClick={handleCategory}>
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Category</span>
                        </div>
                      </div>
                    </li>

                    <li  onClick={handleBrand}>
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Brands</span>
                        </div>
                      </div>
                    </li>

                    <li  onClick={handleStyle}>
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Style</span>
                        </div>
                      </div>
                    </li>
                    <li onClick={handleSwatch}>
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Swatch</span>
                        </div>
                      </div>
                    </li>

                  </ul>
                </div>
              </Popover>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </div>
  );
};

export default Media;
