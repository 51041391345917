import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ModelApi from '../../../createproject/CallModelAPI/ModelApi'


type Props={
    resetImageUrl:(data:string)=>void
    imageUrl:string
}
const GetJobbase64 = ({resetImageUrl,imageUrl}:Props) => {

    const dispatch= useDispatch();
    const [url, setUrl]= useState<string|null>()
    const[isModelApi, setisModelApi]= useState<boolean>(false)
   const isApi= useRef(true)
     useEffect(()=>{
 
        if(imageUrl &&
            isApi.current
        ){
            isApi.current=false
            setUrl(imageUrl)
            setisModelApi(true)
        }
     },[imageUrl])

     

     const handleResetBase64=(data:string)=>{
        if(data==="success"){
          setUrl(null)
          setisModelApi(false)
            isApi.current=true
            resetImageUrl("success")
            
        }else if (data==="fail"){
         setUrl(null)
         setisModelApi(false)
         resetImageUrl("fail")
         isApi.current=true
        }
  }

  return (
    <div>

        {isModelApi && url &&
        <ModelApi
        url={url}
        isBase64={isModelApi}
    resetCallApi={handleResetBase64}
        />
        }
    </div>
  )
}

export default GetJobbase64