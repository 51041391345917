import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UpdateIndexModel } from "../../../Model/masterArray/UpdateIndexModel";


interface UpdateValueStates {
    isUpdateValue:boolean
    isDownload:boolean
    isAddSegment:boolean
   
}

const initialState: UpdateValueStates = {
    isUpdateValue:false,
    isDownload:false,
    isAddSegment:false
 
};

const UpdateValueSlice = createSlice({
    name: "updateValue",
    initialState,
    reducers: {
       startUpdateValue:(state)=>{
        state.isUpdateValue= true
       },
       stopUpdateValue:(state)=>{
        state.isUpdateValue= false
       }, 
       startDownlaod:(state)=>{
        state.isDownload=true
       },
       stopDownlaod:(state)=>{
        state.isDownload=false
       }, 
       startAddSegment:(state)=>{
        state.isAddSegment=true
       },
       stopAddSegment:(state)=>{
        state.isAddSegment=false
       }, 
      
      
    }
}
)

export const {startUpdateValue, stopUpdateValue,
    startDownlaod,stopDownlaod,
    startAddSegment,stopAddSegment
}=UpdateValueSlice.actions;
export const getUpdateValueStatus=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isUpdateValue
export const getDownloadStatus=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isDownload
export const getAddSegmentStatus=  (state:{updateValue:UpdateValueStates})=>state.updateValue.isAddSegment
 export default UpdateValueSlice.reducer