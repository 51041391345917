import React from 'react'
import Edit_Imagehome from './Edit_Imagehome'
import "./Edit_Images.scss"
const Edit_Images = () => {
  return (
    <div>
    <Edit_Imagehome/>
    </div>
  )
}

export default Edit_Images
