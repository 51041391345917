import React, { useEffect, useRef, useState } from 'react'
import { ReplaceWindowModel, SegmentAnnotationModel, SegmentReplace } from '../../../../Model/replaceSwatchModel/ReplaceWindowSwatch'
import { useDispatch, useSelector } from 'react-redux'
import { addSvgOpenVCImage } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { ReplaceWallSwatch } from '../api/SelectSwatchApi'
import { SwatchesModel } from '../../../../Model/material/MaterialModel'
import { getUserLogin } from '../../../../slice/user/UserSlice'
import { updateSwatchImage } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { addSwatchOnCanvas } from '../../../../slice/canvas/masterArray/SwatchOnCanvas'
import { CatBrandStyleSwatchModel } from '../../../../Model/material/CatBrandStyleSwatchModel'
import { addApiMessage } from '../../../../slice/messageToast/ToastSlice'
import { updateCurrentSwatchImage } from '../../../../slice/segegratedSlice/SegregatedSlice'


type Props={
  projectId:number,
  apiName?:string,
    palletUrl:string
    master_image_url:string
    allSegmentAnnotation:SegmentAnnotationModel[]
    classNames:SegmentReplace[]
    selctedSwatch:CatBrandStyleSwatchModel
    groupName:string
    subGroupName:string
    bbox_dimension_pxl:SegmentAnnotationModel[]
    resetWallSwatch:(data:boolean)=>void
  }

const WallRoofReplace = ({master_image_url,allSegmentAnnotation,palletUrl,classNames,apiName,
  selctedSwatch,groupName,subGroupName,bbox_dimension_pxl,resetWallSwatch,projectId}:Props) => {
    const  dispatch= useDispatch()
    const isAPi = useRef(true)
    const[userId, setUserId]= useState<number|null>(null)
    const getUserLogins= useSelector(getUserLogin)
// set user Id
  useEffect(()=>{
    if(getUserLogins && getUserLogins.length>0){
      const id= getUserLogins[0].id
      if(id){
        setUserId(id)
      }
      
    }
  },[getUserLogins])




// call Api
    useEffect(()=>{
        
      if(palletUrl &&
        master_image_url&&
        apiName &&
        allSegmentAnnotation&&allSegmentAnnotation.length>0  &&
        classNames  &&classNames.length>0 &&
        userId &&
        projectId &&
        selctedSwatch &&
        groupName &&subGroupName&&
        bbox_dimension_pxl && 
        bbox_dimension_pxl.length>0 &&
        isAPi.current){
          isAPi.current= false
            dispatch(addApiMessage(`Request to create pallet for ${groupName}`))
          wallReplaceSwatch()
      }
    },[palletUrl,master_image_url,allSegmentAnnotation,apiName,
      classNames,userId, groupName,subGroupName,bbox_dimension_pxl])
  
    const wallReplaceSwatch=async()=>{
  
      const data:ReplaceWindowModel={
        masterImage:master_image_url||"",
        swatchUrl:palletUrl||"",
        projectId:projectId,
        className:classNames||[],
        bbox_dimension_pxl :bbox_dimension_pxl||[],
        segmentationIntValue:allSegmentAnnotation||[],
        image_name:`${userId}_${groupName}_`
      }
      try{
        
        const response= await ReplaceWallSwatch( data, apiName??"") 
        console.log("replavce window", response)
        if(response && response.status==200 ){
          dispatch(addApiMessage(`Pallet updated on canvas for ${groupName}`))
          const value = response.data
          dispatch(addSvgOpenVCImage({
            SwatchImage:value
            
          }))
          
          const data={
            groupName:groupName,
            subGroupName:subGroupName,
            swatchImage:value,
            userId:userId,
            swatchId:selctedSwatch.id,
            swatchTitle:selctedSwatch.description,
            swatchPhoto:selctedSwatch.photo
          }

            const data2={
              swatchImages:value,
              swatchId:selctedSwatch.id,
              swatchTitle:selctedSwatch.description,
              swatchPhoto:selctedSwatch.photo,
              segmentNames:classNames||[]
            }
            // add swatch image in master Array
          dispatch(updateSwatchImage(data))

         // SwatchonCanvas to swatch on canavas
         dispatch(addSwatchOnCanvas(data2))
          // update in segerated slice
          dispatch(updateCurrentSwatchImage(data2))
        }
        isAPi.current= true
        resetWallSwatch(true)
      }catch(err){
        isAPi.current= true
        resetWallSwatch(false)
        console.log("Error on replacing wall swatch")
      }
    }
  return (
    <div>

    </div>
  )
}

export default WallRoofReplace