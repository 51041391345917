import React from 'react'
import "../../../ImageView/LeftSections/LeftSections.scss"
// import "../../ImageView/LeftSections/LeftSections.scss"
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Segments from '../../../ImageView/LeftSections/segments/Segments'


const ReAnnoatationLeft = () => {
    return (
        <>
            <div className="canvas-left-header">

                <div className='backtohome p-3 d-flex align-items-center justify-content-between border-bottom'>
                    <span className='logo'>
                        <img src="/img/logo-white.svg" alt="" />
                    </span>
                    <h5 className='m-0'>Canvas View </h5>
                    <i className="bi bi-arrow-left-short"
                    //onClick={handleCustomerProfile}
                    ></i>
                </div>

                <div className='p-0 bg-white border-bottom'>
                    <ul className="nav nav-underline nav-fill" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Tooltip!</Tooltip>}>
                                <button className="nav-link active" id="segments-tab" data-bs-toggle="tab" data-bs-target="#segments" type="button" role="tab" aria-controls="segments" aria-selected="true">SEGMENTS</button>
                            </OverlayTrigger>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="old-segments-tab " data-bs-toggle="tab" data-bs-target="#old-segments" type="button" role="tab" aria-controls="old-segments" aria-selected="false">
                                <i className="bi bi-grid"></i>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="customer-view-tab" data-bs-toggle="tab" data-bs-target="#customer-view" type="button" role="tab" aria-controls="customer-view" aria-selected="false">
                                <i className="bi bi-card-heading"></i>
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="combo-tab" data-bs-toggle="tab" data-bs-target="#combo" type="button" role="tab" aria-controls="combo" aria-selected="false">
                                <i className="bi bi-columns-gap list-icon"></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="canvas-left-body">

                <div className="tab-content">
        
                    <div className="tab-pane active" id="segments" role="tabpanel" aria-labelledby="segments-tab">
                        <Segments />
                    </div>
                    <div className="tab-pane" id="old-segments" role="tabpanel" aria-labelledby="old-segments-tab">bbb</div>
                    <div className="tab-pane" id="customer-view" role="tabpanel" aria-labelledby="customer-view-tab">ccccc</div>
                    <div className="tab-pane" id="combo" role="tabpanel" aria-labelledby="combo-tab">ddddd</div>
                </div>
            </div>


        </>
    )
}

export default ReAnnoatationLeft