import React, { useEffect, useState } from 'react'
import LeftSection from '../leftSection/LeftSection';
import GetAllMaterialsHome from '../getAllMaterials/GetAllMaterialsHome';
import GetScreenResolution from '../getUserScreenResolution/GetUserScreenResolution';
import Header from '../header/Header';

const AdminHome = () => {


    

 
    



  return (
    <>
    <Header/>
     <div className='row' >


<LeftSection
 
/>
<div className="col-9 brand-right-section">


</div>



{/* getAll materials */}
<GetAllMaterialsHome/>
<GetScreenResolution/>
</div>
    </>
   
  )
}

export default AdminHome