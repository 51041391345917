import React, { useState } from 'react'
import "./middleSection.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Button} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/less/navigation';
import 'swiper/css';
import Header from '../header/Header';

const AssetImage =process.env.REACT_APP_ASSERT_Image
const step1 = `${AssetImage}/images/step1.jpg`;
const step2 = `${AssetImage}/images/step2.jpg`;
const step3 = `${AssetImage}/images/step3.jpg`;
const step4 = `${AssetImage}/images/step4.jpg`;
const step5 = `${AssetImage}/images/step5.jpg`;
const step6 = `${AssetImage}/images/step6.png`;

const ba1b = `${AssetImage}/images/before-after/anjuli-skirvin-before.jpg`;
const ba1a = `${AssetImage}/images/before-after/anjuli-skirvin-after.jpg`;
const ba2b = `${AssetImage}/images/before-after/archie-uenishi-before.jpg`;
const ba2a = `${AssetImage}/images/before-after/archie-uenishi-after.jpg`;
const ba3b = `${AssetImage}/images/before-after/blake-before.jpg`;
const ba3a = `${AssetImage}/images/before-after/blake-after.jpg`;
const ba4b = `${AssetImage}/images/before-after/marylyn-before.png`;
const ba4a = `${AssetImage}/images/before-after/marylyn-after.png`;
const ba5b = `${AssetImage}/images/before-after/meghan-before.jpg`;
const ba5a = `${AssetImage}/images/before-after/meghan-after.jpg`;


const MiddleSection = () => {
   const navigate= useNavigate()
   // State management for each slide's active button
   const [activeButtons, setActiveButtons] = useState([
    { id: 1, activeButton: 'button1' },
    { id: 2, activeButton: 'button1' },
    { id: 3, activeButton: 'button1' },
    { id: 4, activeButton: 'button1' },
    { id: 5, activeButton: 'button1' },
  ]);

  // Handle button click for each slide
  const handleButtonClick = (slideId:any, buttonId:any) => {
    setActiveButtons((prevButtons) =>
      prevButtons.map((button) =>
        button.id === slideId ? { ...button, activeButton: buttonId } : button
      )
    );
  };

  const handleCreatProject=()=>{
     navigate("/createproject")
  }
  return (
    <div>
        <Header/>
      <section className='get-startd-top py-3 pt-md-5'
      style={{marginTop:5}}
      >
        <div className='container'>
          <div className='row'>
            <div className="col-12 col-md-7 m-auto text-center">
              <h1>Harmonizing Human Vision with the Speed of AI</h1>
              <h4>A New Era of Home Design</h4>
              <div className='d-flex align-items-center justify-content-center gap-3 mt-4'>
                <Button variant="primary" size="lg" 

                // as {Link} to="/createproject" 
                className='rounded-pill'
                onClick={handleCreatProject}
                >Create Project</Button>
                <Button variant="outline-dark" size="lg" className='rounded-pill'>Primary</Button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='get-startd-top py-3 pt-md-5'>
        <div className='container'>
          <h4>How DZINLY works</h4>
          <p>oin the thousands that have transformed their homes through DZINLY.</p>
          <Swiper spaceBetween={20} slidesPerView={3} onSlideChange={() => console.log('slide change')} onSwiper={(swiper) => console.log(swiper)}>
            <SwiperSlide>
              <h6>Step 1</h6>
              <img className='rounded-3' src={step1} alt="step1" />
              <div className='d-flex flex-column gap-1 mt-3'>
                <p className='m-0'>Take a photo of your existing home or home under construction. What's This?</p>
                <p className='m-0'>Need plans turned to 3D so you can visualize what it will look like? No problem. You will have this option in the process.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <h6>Step 2</h6>
              <img className='rounded-3' src={step2} alt="step2" />
              <div className='d-flex flex-column gap-1 mt-3'>
                <p className='m-0'>Share your inspirations and ideas.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <h6>Step 3</h6>
              <img className='rounded-3' src={step3} alt="step3" />
              <div className='d-flex flex-column gap-1 mt-3'>
                <p className='m-0'>Connect with a personal design coordinator, who will work with professional designers to provide the best exterior design ideas for your home.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <h6>Step 4</h6>
              <img className='rounded-3' src={step4} alt="step4" />
              <div className='d-flex flex-column gap-1 mt-3'>
                <p className='m-0'>Once you approve a proposed palette, these ideas will be rendered on your exact home within two business days.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <h6>Step 5</h6>
              <img className='rounded-3' src={step5} alt="step5" />
              <div className='d-flex flex-column gap-1 mt-3'>
                <p className='m-0'>Toggle between your designer's suggested colors/materials to select the exterior you like best. Purchase additional designer ideas, colors/materials, if necessary.</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <h6>Step 6</h6>
              <img className='rounded-3' src={step6} alt="step6" />
              <div className='d-flex flex-column gap-1 mt-3'>
                <p className='m-0'>Once you land on your perfect design, have the option to purchase architectural plans that include quantities of material, construction specifications and resources for implementation.</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section className='get-startd-top py-3 py-md-5'>
        <div className='container'>
          <div className='row mb-3'>
            <div className="col-12 col-md-7 m-auto text-center">
              <h1>Recent projects</h1>
            </div>
          </div>
        </div>
        <Swiper
          spaceBetween={50}
          slidesPerView={2.1}
          navigation
          loop={true}
          centeredSlides={true}
          centerInsufficientSlides={true}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide key={1}>
            <div className='beforeafter'>
              {/* Images */}
              <img
                className={`rounded-3 ${activeButtons[0].activeButton === 'button1' ? 'active' : ''}`}
                src={ba1b}
                alt="Anjuli Skirvin Before"
              />
              <img
                className={`rounded-3 ${activeButtons[0].activeButton === 'button2' ? 'active' : ''}`}
                src={ba1a}
                alt="Anjuli Skirvin After"
              />

              {/* Buttons */}
              <div className='beforeafter-btn'>
                <div className='position-relative'>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[0].activeButton === 'button1' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(1, 'button1')}
                  >
                    Before
                  </Button>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[0].activeButton === 'button2' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(1, 'button2')}
                  >
                    After
                  </Button>
                  <span className='glider'></span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide key={2}>
            <div className='beforeafter'>
              <img
                className={`rounded-3 ${activeButtons[1].activeButton === 'button1' ? 'active' : ''}`}
                src={ba2b}
                alt="Archie Uenishi Before"
              />
              <img
                className={`rounded-3 ${activeButtons[1].activeButton === 'button2' ? 'active' : ''}`}
                src={ba2a}
                alt="Archie Uenishi After"
              />

              <div className='beforeafter-btn'>
                <div className='position-relative'>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[1].activeButton === 'button1' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(2, 'button1')}
                  >
                    Before
                  </Button>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[1].activeButton === 'button2' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(2, 'button2')}
                  >
                    After
                  </Button>
                  <span className='glider'></span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {/* Repeat for other slides */}
          <SwiperSlide key={3}>
            <div className='beforeafter'>
              <img
                className={`rounded-3 ${activeButtons[2].activeButton === 'button1' ? 'active' : ''}`}
                src={ba3b}
                alt="Blake Before"
              />
              <img
                className={`rounded-3 ${activeButtons[2].activeButton === 'button2' ? 'active' : ''}`}
                src={ba3a}
                alt="Blake After"
              />

              <div className='beforeafter-btn'>
                <div className='position-relative'>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[2].activeButton === 'button1' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(3, 'button1')}
                  >
                    Before
                  </Button>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[2].activeButton === 'button2' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(3, 'button2')}
                  >
                    After
                  </Button>
                  <span className='glider'></span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide key={4}>
            <div className='beforeafter'>
              <img
                className={`rounded-3 ${activeButtons[3].activeButton === 'button1' ? 'active' : ''}`}
                src={ba4b}
                alt="Marylyn Before"
              />
              <img
                className={`rounded-3 ${activeButtons[3].activeButton === 'button2' ? 'active' : ''}`}
                src={ba4a}
                alt="Marylyn After"
              />

              <div className='beforeafter-btn'>
                <div className='position-relative'>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[3].activeButton === 'button1' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(4, 'button1')}
                  >
                    Before
                  </Button>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[3].activeButton === 'button2' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(4, 'button2')}
                  >
                    After
                  </Button>
                  <span className='glider'></span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide key={5}>
            <div className='beforeafter'>
              <img
                className={`rounded-3 ${activeButtons[4].activeButton === 'button1' ? 'active' : ''}`}
                src={ba5b}
                alt="Meghan Before"
              />
              <img
                className={`rounded-3 ${activeButtons[4].activeButton === 'button2' ? 'active' : ''}`}
                src={ba5a}
                alt="Meghan After"
              />

              <div className='beforeafter-btn'>
                <div className='position-relative'>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[4].activeButton === 'button1' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(5, 'button1')}
                  >
                    Before
                  </Button>
                  <Button
                    className={`btn-ba rounded-pill bg-transparent border-0 shadow-none ${activeButtons[4].activeButton === 'button2' ? 'active' : ''}`}
                    onClick={() => handleButtonClick(5, 'button2')}
                  >
                    After
                  </Button>
                  <span className='glider'></span>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

    </div>
  )
}

export default MiddleSection