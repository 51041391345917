import React from 'react'

const NewSegmentPage = () => {
  return (
    <>
    {/* <NewSegmentHome/> */}

    </>
  )
}

export default NewSegmentPage