import React, { useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  ButtonGroup,
  PopoverAnchor,
  useDisclosure,
  Portal,
} from '@chakra-ui/react'

import JobBrand from '../brandPage/jobs/JobBrand';
import { callbackify } from 'util';
import { useNavigate } from 'react-router-dom';
import "./Project.scss"
type Props = { 
  callbackLeftSection : ()=> void,
  callbackProject : ()=> void,
}; 
const Project = () => {
     const navigate= useNavigate()
  const handleJobbrand = () => {
   
    navigate("/dashboard/jobs")
  };

  const handleProjectbrand = () =>{
  
    navigate("/segments/table")
  }
  return (
  

<div className='media-section'>
<Popover trigger="hover"   >
  <PopoverTrigger>

    <div className="d-flex card-list-title mt-1">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard-check mx-3" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
          </svg>
      <h2 className="project-title">Project</h2>
    </div>
  </PopoverTrigger>
  <Portal>
    <PopoverContent className='popover-content'>
      <PopoverHeader className="types-title pb-2 px-2" fontWeight='semibold'> <span className='type-title '>PROJECT TYPES</span><span className='show-type'>3</span></PopoverHeader>

      <PopoverBody>
        <Popover
        >
         

          <div className="list-title">
                  <ul>
                    <li onClick={handleProjectbrand}>
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Project</span>
                        </div>
                      </div>
                    </li>

                    <li onClick={handleJobbrand}>
                      <div className="list-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-dot mx-2"
                          viewBox="0 0 16 16">
                          <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                        </svg>
                        <div>
                          <span>Job</span>
                        </div>
                      </div>
                    </li>

               

                  </ul>
          </div>

        </Popover>
      </PopoverBody>
    </PopoverContent>
  </Portal>
</Popover>


</div>

  )
}

export default Project
