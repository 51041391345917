import React from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addFirstFolderPath, changeActiveFolder, getactiveFolder, getFirtsFolderPath, getTypeBucket, resetFirstFolderPath } from '../../../../slice/media/MediaSlice'



type Props={
  createS3Folder:()=>void
}
const FunctionButton = ({createS3Folder}:Props) => {

    const dispatch= useDispatch()
    const getFirtsFolderPaths= useSelector(getFirtsFolderPath)
    const getactiveFolders= useSelector(getactiveFolder)
    
    const getTypeBuckets= useSelector(getTypeBucket)

    const handleBackFolder=()=>{
    const countStash= (getFirtsFolderPaths.match(/\//g) || []).length
      if(countStash==1){
       dispatch(changeActiveFolder({
        activeFolder:1
       }))
         dispatch(resetFirstFolderPath())
      }
      else if(countStash==2 && getTypeBuckets=="files"){
        const segments = getFirtsFolderPaths.split('/');
        const finalString = segments[0] + '/';
        dispatch(addFirstFolderPath(finalString))
      }


    }
    const handleCreateFolder=()=>{
       createS3Folder()
    }
  return (
    <div>
         {getactiveFolders!=1 &&
         <button
          style={{ marginRight: 20, marginBottom: 10 }}
          onClick={handleBackFolder}
        >
          <i className="bi bi-arrow-left"></i>
        </button>}
        <button style={{ marginRight: 20, marginBottom: 10 }}
           
        >
          <i className="bi bi-file-arrow-up"></i>
        </button>
        <button style={{ marginRight: 20, marginBottom: 10 }}
        onClick={handleCreateFolder}
        >
          <i className="bi bi-folder-plus"></i>
        </button>

        <button style={{ marginRight: 20, marginBottom: 10 }}
       // onClick={handleCreateFolder}
        >
         <i className="bi bi-backspace"></i>
        </button>
    </div>
  )
}

export default FunctionButton