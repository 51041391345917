import React from 'react'
import ProfileHome from '../../module/profile/ProfileHome'

const Profile = () => {
  return (
    <div>
        <ProfileHome/>
    </div>
  )
}

export default Profile