import React from 'react'
import AdminBrandTable from '../../../module/admin/AdminMaterial/brand/AdminBrandTable'
import Header from '../../../module/header/Header'

const AdminBrandTablePage = () => {
  return (

    <>
   <AdminBrandTable/>
  
   </>
   
  )
}

export default AdminBrandTablePage