import React from 'react'
import Edit_Images from '../../module/generate_Img_edit/Edit_Images'

const Edit_Image = () => {
  return (
    <div>
      <Edit_Images/>
    </div>
  )
}

export default Edit_Image
