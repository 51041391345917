import React from 'react'
import IndexPage from './IndexPage'
import { AppContextProvider } from './new/useContext/context'

const SamModelPage = () => {
  return (
    <>
        <h6>sam model</h6>
        <AppContextProvider>
        <IndexPage/>
        </AppContextProvider>
        
    </>
  )
}

export default SamModelPage