import useSelection from 'antd/es/table/hooks/useSelection'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserJobData } from '../../slice/userJobSlice/UserJobSlice'
import ModelApi from '../createproject/CallModelAPI/ModelApi'
import { useNavigate } from 'react-router-dom'
import SamData from '../createproject/samData/SamData'
import { addRegeneration, addUploadMessage, resetIsRegenaration, resetUploadMessage } from '../../slice/modelData/ModelDataSlice'
import JobDB from '../createproject/samData/JobDB'
import UpdateNewProjectApi from '../createproject/UpdateNewProjectApi'
import { stopLoading } from '../../slice/loading/LoadingSlice'
import { addCompressImage, addImageUrl } from '../../slice/user/UserProject'
import UpdateJobDB from './UpdateJobDB'
import UpdateJobData from './UpdateJobData'
import { JobModel, JobSegmentModel, UpdateJobModel } from '../../Model/Job/JobModel'
import { addMessage } from '../../slice/messageToast/ToastSlice'

const RegenerationHome = () => {
    const[isModelApi, setISModelApi]= useState<boolean>(false)
    const getUserJobDatas= useSelector(getUserJobData)
    const[originalImage,setOriginalImage]= useState<string|null>()
    const[compressImage,setCompressImage]= useState<string|null>()
    const [isSamData, setIsSamData] = useState<boolean>(false)
    const [isJobApi, setIsJobApi] = useState<boolean>(false)
    const [isUpdateProjectAPi, setIsUpdateProjectApi] = useState<boolean>(false)
    const isApi= useRef(true)
    const navigate= useNavigate()
     const dispatch= useDispatch()
    useEffect(()=>{
        
        if(getUserJobDatas && 
            getUserJobDatas.image &&
            getUserJobDatas.compressImage &&
             isApi.current){
                isApi.current=false
                dispatch(addImageUrl(getUserJobDatas.image))
                dispatch(addCompressImage(getUserJobDatas.compressImage))
                setOriginalImage(getUserJobDatas.image)
                setCompressImage(getUserJobDatas.compressImage)
            setISModelApi(true)
        }
    },[getUserJobDatas])

    const handleResetUrl=(data:string)=>{
        if(data==="success"){
            dispatch(addMessage({
              isShow:true,
             mess:"Re-generated segment point"
            }))
              setISModelApi(false)
              
             // setIsUpdateProjectApi(true)
               setIsSamData(true)
            //  navigate("/imageview")
         
        } else if (data==="fail"){
          setISModelApi(false)
          dispatch(addMessage({
            isShow:true,
           mess:"Fail to regenerate segments"
          }))
          navigate("/")
        }
      
      }

      const[ updateJob, setUpdateJob]= useState<UpdateJobModel|null>(null)
      const handleResetSamData=(data:UpdateJobModel)=>{
        dispatch(resetIsRegenaration())
        dispatch(addUploadMessage("Saving project details "))
        setIsSamData(false)
        setIsJobApi(true)
        setUpdateJob(data)
       }

const [newallSegment, setNewAllSegment]= useState<JobSegmentModel>()
       const handleResetJob=(data:JobSegmentModel)=>{
        setIsJobApi(false)
        setIsUpdateProjectApi(true)
        dispatch(resetUploadMessage())
        dispatch(stopLoading())
        dispatch(addRegeneration(false))
       
       if(data){

         // setNewAllSegment(data)
         // segegregateSegment(data)
        }else{
          
          dispatch(addMessage({
            isShow:true,
            mess:"something went wrong",
             toastType:"Error"
           }))
         // alert("something went wrong")
        }
        
        }


      
        const segegregateSegment=(data:JobSegmentModel)=>{
          if (data) {
            const allSeg :JobSegmentModel= data||{};
        
           
            if (allSeg) {
              // Convert object to an array for easier sorting
              const segmentsArray = Object.keys(allSeg).map((key) => ({
                 
                [key]: allSeg[key],
              }));
              
              // console.log("segmentsArray",segmentsArray)
        
              const sortedSegments = segmentsArray.sort((a, b) => {
                // Extract the key from each segment
                const keyA = Object.keys(a)[0];
                const keyB = Object.keys(b)[0];
              
                // Access details for each segment
                const perimeterA = a[keyA].details?.annotation_area_pixel || 0;
                const perimeterB = b[keyB].details?.annotation_area_pixel || 0;
              
                // Sort in descending order of annotation_area_pixel
                return perimeterB - perimeterA;
              });
              
        
              if (sortedSegments.length > 0) {
                console.log("sortedSegments",sortedSegments)
              //  dispatch(segegratedJobSegment(sortedSegments));
              }
            }
        
          }
            
          }
    

  return (
    <div>
        
           {/* call Model APi */}
           {isModelApi && 
             originalImage &&
             compressImage &&
                <ModelApi
                url={originalImage}
                
                isBase64={!isModelApi}
            resetCallApi={handleResetUrl}
                  />}


                   {/*  segregate data */}
              {isSamData &&
              <UpdateJobData
              resetSamData={handleResetSamData}
              />}

                {/* add job into db */}
                {/* {isJobApi &&
                updateJob &&
              <UpdateJobDB
              updateJob={updateJob}
              resetJob={handleResetJob}
             
              />} */}



              
     


    </div>
  )
}

export default RegenerationHome