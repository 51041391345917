import React, { useEffect, useRef } from 'react'
import { CreateFolderS3Bucket } from '../../../api/folderApi/FolderApi'
import { useDispatch } from 'react-redux'
import { addMessage } from '../../../slice/messageToast/ToastSlice'

type Props={
    isPalletCreated:boolean
    resetPalletFolder:(data:boolean)=>void
}
const CreatePalletFolder = ({isPalletCreated,resetPalletFolder}:Props) => {
    const isApi= useRef(true)
    const dispatch= useDispatch()
    useEffect(()=>{
           if(isPalletCreated && isApi.current){
            isApi.current= false
            createFolderAsPallet()
           }
    },[ isPalletCreated])

    const createFolderAsPallet=async()=>{
        const folderName="pallet"
        try{
            const response= await CreateFolderS3Bucket(folderName)
            if(response && response.status===200){
                 console.log("createv  pallet fodler", response)
               
                 dispatch(addMessage({
                    isShow:true,
                    mess:"Folder created"
                }))
                isApi.current= true
                resetPalletFolder(true)
            }
        }catch(err){
              
            dispatch(addMessage({
                isShow:true,
                mess:"Error in creating pallet folder"
            }))

            isApi.current= true
                resetPalletFolder(false)
        }
    }
  return (
    <div>
        
    </div>
  )
}

export default CreatePalletFolder