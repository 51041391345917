import React, { useState } from 'react'
import { Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import "./ActionProject.scss";
import DeleteModal from './deleteProject/DeleteModal';
import DeletEProjectById from './deleteProject/DeletEProjectById';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../slice/messageToast/ToastSlice';
type Props = {
  projectId:number
}

const ActionProject = ({projectId}:Props) => {
  const [isShowAction, setIsShowAction] = useState<boolean>(false)
  const [isDeletModal, setIsDeleteModal] = useState<boolean>(false)
  
  const [isDeleteApi, setIsDeleteApi] = useState<boolean>(false)
    const dispatch= useDispatch()
  
  const handleDelete = () => {
    setIsDeleteModal(true)
   
  }

  const handleActionProject = (id: number) => {
    // setIsShowAction(true)
    // setProjectId(id)
  }

  const handleCloseAction = () => {
    //setIsShowAction(false)
  }

  const handleConfirmDelete = () => {
    setIsDeleteModal(false)
    setIsDeleteApi(true)
  }
  const handleCloseModal = () => {
    setIsDeleteModal(false)
  }


  const handleResetDelete = (data: boolean) => {
    setIsDeleteApi(false)
    
    if (data) {
      
      dispatch(addMessage({
        isShow:true,
        mess:"Project deleted successfully",
         toastType:"success"
       }))
      //alert("Project deleted successfully")
    } else {
      dispatch(addMessage({
        isShow:true,
        mess:"Some thing went wrong",
         toastType:"Error"
       }))
      // alert("Some thing went wrong")
    }
  }
  return (
    <>
       <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled" >View</Tooltip>}>

<span
  // to={`/projectdraft/${image?.attributes?.jobs?.data[0]?.id}`} 
  //onClick={() =>handleJob(projectItem)}
>
  <i className="bi bi-box-arrow-up-right"></i>
</span>
</OverlayTrigger>

<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled"  >Edit</Tooltip>}>
<span>
  <i className="bi bi-pencil"></i>
</span>
</OverlayTrigger>

<OverlayTrigger  placement="bottom" overlay={<Tooltip id="tooltip-disabled" >Delete</Tooltip>}>
<span 
onClick={handleDelete}
 >  
  <i className="bi bi-trash"></i> 
</span>
</OverlayTrigger>


{/* delete  confirmation  modal*/}
{isDeletModal &&
        <DeleteModal
          isDeleteModal={isDeletModal}
          okDelete={handleConfirmDelete}
          okClose={handleCloseModal}
        />
      }
{/* delete Api */}

{isDeleteApi &&
        <DeletEProjectById
          projectId={projectId ?? 0}
          resetProjectId={handleResetDelete}
        />
      }
    </>
  )
}

export default ActionProject
