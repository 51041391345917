import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addMessage } from '../../slice/messageToast/ToastSlice';
import { addWebSocketMessage, getWebSocketMessage, getWebSocketStatus, stopWebSocket } from '../../slice/webSocketSlice/WebSocketSlice';

const StartWebSocket = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const pingCountRef = useRef<number>(0);  // Ref to track ping count
  const maxPingCount = 10;  // Max ping count before closing connection
  const dispatch= useDispatch()
  const lastMessageRef = useRef<string | null>(null);
  
  const getWebSocketStatuss = useSelector(getWebSocketStatus)
  const getWebSocketMessages = useSelector(getWebSocketMessage)

  // Restart web socket if closed
  useEffect(() => {
    if (getWebSocketMessages === "WebSocket connection closed.") {
      setIsConnected(true);
    }
  }, [getWebSocketMessages]);

  // Start or stop connection based on status
  useEffect(() => {
    if (getWebSocketStatuss) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [getWebSocketStatuss]);

  // Main WebSocket logic
  useEffect(() => {
    // Automatically initiate the WebSocket connection when the component mounts
    if (!socket && isConnected) {
      const ws = new WebSocket(`wss://api.dzinly.org/ws`);
      console.log("ws ->", ws);

      ws.onopen = function () {
        console.log("WebSocket connection established.");
        const message = "WebSocket connection established."
        setIsConnected(true);
        setError(null); 
        dispatch(addMessage({
          isShow: true,

          mess: message,
           toastType:"success"
        }))


      };

      ws.onmessage = function (event) {
        const triggerMessage = event.data;

        // Handle non-ping messages
        if (triggerMessage !== lastMessageRef.current) {
          dispatch(addMessage({
            isShow: true,
            mess: triggerMessage,
             toastType:"success"
          }));
          pingCountRef.current = 0;  // Reset ping count on non-ping messages
        }

        lastMessageRef.current = triggerMessage;

        console.log("Received message event triggered:", event.data);

        // Ping-Pong handling
        if (triggerMessage === "ping") {
          pingCountRef.current += 1;  // Increment the ping counter

          // Respond to ping with pong
          ws.send("pong");

          if (triggerMessage !== lastMessageRef.current) {
            dispatch(addMessage({
              isShow: true,
              mess: "Sent pong in response to server ping",
               toastType:"success"
            }));


          // Check if ping count reaches max and disconnect
          if (pingCountRef.current >= maxPingCount) {
            console.log(`Ping count reached ${maxPingCount}. Closing connection.`);
            ws.close();  // Close WebSocket after 10 pings with no other interactions
          }
        }
      };
    }

      ws.onerror = function (err) {
        console.error("WebSocket error:", err);
        const message="WebSocket error"
        dispatch(addMessage({
          isShow: true,
          mess: message,
           toastType:"Error"
        }))
        setError("WebSocket connection failed."); // Set error state
        ws.close(); // Close the connection on error

      };

      ws.onclose = function () {
        console.log("WebSocket connection closed.");

        const mess = "WebSocket connection closed.";
        dispatch(addWebSocketMessage(mess));
        dispatch(addMessage({
          isShow: true,
          mess: mess,
           toastType:"success"
        }));

        dispatch(stopWebSocket());

        setIsConnected(false);
        setSocket(null);

        console.log("WebSocket connection closed.");
      };

      setSocket(ws); 
    }

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.close(); 
      }
    };
  }, [socket]);

  return (
    <div>StartWebSocket</div>
  )
}

export default StartWebSocket;